import React from 'react';
import { MainMenu } from 'webcore-ux/react/components';
import MenuIcon from '@material-ui/icons/Menu';

import AppConfig from 'AppConfig';
import Authentication from 'Authentication';
import Locale from 'locale/Locale';

import './App.scss';

const t = Locale.getResourceString.bind(Locale);

class AppMenuNarrow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    render() {
        let userName =
            this.props.idTokenClaims != null && this.props.idTokenClaims.preferred_username != null
                ? this.props.idTokenClaims.preferred_username
                : t('header.anonymous');

        let itemClassName = null;
        let selectedItemClassName = 'app-main-menu-selected-item';
        let menuItems = [
            {
                type: 'heading',
                text: t('header.language'),
            },                        
            {
                type: 'item',
                text: t('header.english'),
                className: (this.props.localeName === 'en') ? selectedItemClassName : itemClassName,
                onClick: () => this.props.onChangeLocale('locale-en'),
            },                        
            {
                type: 'item',
                text: t('header.japanese'),
                className: (this.props.localeName === 'ja') ? selectedItemClassName : itemClassName,
                onClick: () => this.props.onChangeLocale('locale-ja'),
            },                        
            
            {
                type: 'heading',
                text: t('header.currency'),
            },                        
            {
                type: 'item',
                text: '$',
                className: (this.props.currencyName === 'USD') ? selectedItemClassName : itemClassName,
                onClick: () => this.props.onChangeCurrency('USD'),
            },                        
            {
                type: 'item',
                text: '¥',
                className: (this.props.currencyName === 'JPY') ? selectedItemClassName : itemClassName,
                onClick: () => this.props.onChangeCurrency('JPY'),
            },                        
            {
                type: 'item',
                text: '€',
                className: (this.props.currencyName === 'EUR') ? selectedItemClassName : itemClassName,
                onClick: () => this.props.onChangeCurrency('EUR'),
            },                        
            
            {
                type: 'heading',
                text: t('header.temperature'),
            },                        
            {
                type: 'item',
                text: '℃',
                className: (this.props.temperatureUnits === 'celsius') ? selectedItemClassName : itemClassName,
                onClick: () => this.props.onChangeTemperatureUnits('celsius'),
            },                        
            {
                type: 'item',
                text: '℉',
                className: (this.props.temperatureUnits === 'fahrenheit') ? selectedItemClassName : itemClassName,
                onClick: () => this.props.onChangeTemperatureUnits('fahrenheit'),
            },                        
            
            {
                type: 'heading',
                text: t('header.speed'),
            },                        
            {
                type: 'item',
                text: 'm/s',
                className: (this.props.speedUnits === 'metersPerSecond') ? selectedItemClassName : itemClassName,
                onClick: () => this.props.onChangeSpeedUnits('metersPerSecond'),
            },                        
            {
                type: 'item',
                text: 'mph',
                className: (this.props.speedUnits === 'milesPerHour') ? selectedItemClassName : itemClassName,
                onClick: () => this.props.onChangeSpeedUnits('milesPerHour'),
            },                        

            {
                type: 'heading',
                text: t('header.downloads'),
            },                        
        ];
        
        menuItems.push.apply(menuItems, this.props.downloads.map(download => { 
            return {
                type: 'item',
                text: t(download.captionKey),
                className: Authentication.isPurchased ? '' : 'app-menu-item-disabled',
                onClick: () => {
                    if (Authentication.isPurchased) {
                        download.download()
                    }
                },
            };
        }));

        if (Authentication.isPurchased === false) {
            menuItems.push({
                type: 'item',
                text: t('header.downloadsTooltip'),
                className: 'download-not-allowed-note'
            })   
        }

        return (
            <>
                <div className="app-top-controls">
                    <div className="app-header-menu-narrow">
                        <MenuIcon onClick={() => this.setState({ isOpen: !this.state.isOpen })} />
                    </div>
                </div>

                <MainMenu
                    className="app-main-menu-narrow"
                    displayNameText=""
                    emailAddressText={userName}
                    signOffButtonText={(Authentication.isSignedIn) ? t('header.signOut') : t('header.signIn')}
                    copyrightText={`© Copyright ${new Date().getFullYear()} ${AppConfig.company.name}`}
                    open={this.state.isOpen}
                    onClose={() => this.setState({ isOpen: false })}
                    onSignOff={() => {
                        if (Authentication.isSignedIn) {
                            this.props.onSignOut();
                        }
                        else {
                            this.props.onSignIn();
                        }
                    }}
                    disablePortal={true}
                    menuItems={menuItems}
                />          
            </>
        );
    }
}

export default AppMenuNarrow;
