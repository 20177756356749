import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SlidePanel } from 'webcore-ux/react/components';

import './PinnableSettings.scss';

class PinnableSettings extends React.Component {
    constructor(props, context) {
        super(props);

        this._contentRef = null;
        this._contentWidth = 0;

        this.state = {
            isPinned: false,
            transitionDuration: 200,
        };

        this._onResize = this._onResize.bind(this);
        this._setContentRef = this._setContentRef.bind(this);
    }


    get _isOpen() {
        return this.props.open;
    }
    set _isOpen(isOpen) {
        this.setState({ 
            // Whether opening or closing the filters, the panel is not pinned
            isPinned: false,
            transitionDuration: 200,
        });

        // When hiding the filter panel, notify that we're closed and not pinned
        if (!isOpen) {
            if (this.props.onClose != null) {
                this.props.onClose();
            }
            this._notifyIsPinned(false);
        }
    }

    get _isPinned() {
        return this.state.isPinned;
    }
    set _isPinned(isPinned) {
        this.setState({ 
            isPinned: isPinned,
            transitionDuration: 0,
        });
        
        this._notifyIsPinned(isPinned);
    }

    _notifyIsPinned(isPinned) {
        if (this.props.onPin != null) {
            this.props.onPin({
                isPinned: isPinned,
                viewKey: this.props.viewKey,
                width: this._contentWidth,
            });
        }
    }

    _onResize() {
        if (this._contentRef != null) {
            let width = this._contentRef.offsetWidth;
            if (width !== this._contentWidth) {
                this._contentWidth = width;
                if (this._isPinned) {
                    this._notifyIsPinned(this._isPinned);
                }
            }
        }
    }

    _setContentRef(contentRef) {
        this._contentRef = contentRef;
        if (this._contentRef != null) {
            this._contentWidth = this._contentRef.offsetWidth;
        }
    }



    componentDidMount() {
        // Watch for resize events
        window.addEventListener('resize', this._onResize);
    }

    componentWillUnmount() {
        // Clean up resize listener
        window.removeEventListener('resize', this._onResize);
    }

    render() {
        return (
            <SlidePanel
                anchor={this.props.anchor}
                open={this.props.open}
                title={this.props.title}
                showPinButton={this.props.showPinButton}
                variant={(this._isPinned) ? "persistent" : "temporary"}
                onClose={() => this._isOpen = false}
                onPin={(isPinned) => this._isPinned = isPinned}
                transitionDuration={this.state.transitionDuration}
            >
                <div className="pinnable-settings-scrollable" ref={this._setContentRef}>
                    <div className={classNames("pinnable-settings-container", this.props.contentClassName)}>
                        {this.props.children}
                    </div>
                </div>
            </SlidePanel>
       );
    }
}

PinnableSettings.propTypes = {
    anchor: PropTypes.string,
    contentClassName: PropTypes.string,
    open: PropTypes.bool,
    title: PropTypes.string,
    viewKey: PropTypes.string,
    onClose: PropTypes.func,
    onPin: PropTypes.func,
    showPinButton: PropTypes.bool,
}

PinnableSettings.defaultProps = {
    anchor: 'right',
    title: 'Pinnable Settings',
    showPinButton: true,
}

export default PinnableSettings;