import moment from 'moment';

import Impl_en from './en/Impl_en';
import Impl_ja from './ja/Impl_ja';

class Locale {
    constructor() {
        // This is for event handlers to observe changes in the locale
        this._localeChangedHandlers = {};
        // This is for event handlers to observe changes in the selected currency
        this._currencyChangedHandlers = {};
        // This is for event handlers to observe changes in user-selected units
        this._unitsChangedHandlers = {};

        // Data related to text localization
        let regex = new RegExp('^ja', 'i')
        let language = navigator.language || navigator.userLanguage || navigator.browserLanguage || navigator.systemLanguage
        this._localeName = regex.test(language) ? 'ja' : 'en';
        this._impls = {
            'en': new Impl_en(),
            'ja': new Impl_ja(),
        };

        // Data related to currency selection
        this._currencyName = 'JPY';
        this._currencyFormats = {
            USD: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }),
            JPY: new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }),
            EUR: new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'EUR' }),
        };

        // Temperature selection
        this._temperatureUnits = 'celsius';
        this._temperatureConverters = {
            // Convert from reported units to Celsius
            'celsius': (value, reportedUnits) => { 
                return (value == null) ? null : 
                       (reportedUnits === 'fahrenheit') ? (value - 32) * 5.0 / 9.0 : 
                       value; 
            },
            // Convert from reported units to Fahrenheit
            'fahrenheit': (value, reportedUnits) => { 
                return (value == null) ? null : 
                       (reportedUnits === 'celsius') ? value * 9.0 / 5.0 + 32 : 
                       value; 
            },
        }

        // Speed selection
        this._speedUnits = 'metersPerSecond';
        this._speedConverters = {
            // Convert from reported units to m/s
            'metersPerSecond': (value, reportedUnits) => { 
                return (value == null) ? null : 
                       (reportedUnits === 'milesPerHour') ? value / 2.237 : 
                       value; 
            },
            // Convert from reported units to mph
            'milesPerHour': (value, reportedUnits) => { 
                return (value == null) ? null :
                       (reportedUnits === 'metersPerSecond') ? value * 2.237 : 
                       value; 
            },
        }

        // Set locale
        moment.locale(this._localeName);
    }

    // Gets the locale implementation based on the current locale name
    get _impl() {
        return this._impls[this.localeName];
    }

    // Adds a function to be called when the locale changes
    // The handler will receive the name of the locale, but clients can always access
    // the selected locale through this class.
    addLocaleHandler(key, handler) {
        this._localeChangedHandlers[key] = handler;
    }

    // Removes a locale-changed handler
    removeLocaleHandler(key) {
        delete this._localeChangedHandlers[key];
    }

    // Adds a function to be called when the currency changes
    // The handler will receive the name of the currency, but clients can always access
    // the selected locale through this class.
    addCurrencyHandler(key, handler) {
        this._currencyChangedHandlers[key] = handler;
    }

    // Removes a currency-changed handler
    removeCurrencyHandler(key) {
        delete this._currencyChangedHandlers[key];
    }

    // Adds a function to be called when units change
    // No arguments are passed to the event handler
    addUnitsHandler(key, handler) {
        this._unitsChangedHandlers[key] = handler;
    }

    // Removes a units-changed handler
    removeUnitsHandler(key) {
        delete this._unitsChangedHandlers[key];
    }

    // Get supported locale names
    get localeNames() {
        return Object.keys(this._impls);
    }

    // Get/set locale name, which represents the selected locale
    get localeName() {
        return this._localeName;
    }
    set localeName(localeName) {
        if (this._localeName !== localeName) {
            // Set our own localeName property
            this._localeName = localeName;

            // Configure the moment library
            moment.locale(localeName);

            // Notify observers
            this._notifyLocaleChanged();
        }
    }

    // Get/set currency
    get currencyName() {
        return this._currencyName;
    }
    set currencyName(currencyName) {
        if (this._currencyName !== currencyName) {
            // Update the currency name
            this._currencyName = currencyName;

            // Notify observers
            this._notifyCurrencyChanged();
        }
    }
    isCurrencyNameValid(currencyName) {
        return this._currencyFormats[currencyName] != null;
    }

    // Get/set temperature units
    get temperatureUnits() {
        return this._temperatureUnits;
    }
    set temperatureUnits(temperatureUnits) {
        if (this._temperatureUnits !== temperatureUnits) {
            // Update the value
            this._temperatureUnits = temperatureUnits;

            // Notify observers
            this._notifyUnitsChanged();
        }
    }

    // Get/set temperature units
    get speedUnits() {
        return this._speedUnits;
    }
    set speedUnits(speedUnits) {
        if (this._speedUnits !== speedUnits) {
            // Update the value
            this._speedUnits = speedUnits;

            // Notify observers
            this._notifyUnitsChanged();
        }
    }

    // Gets a localized resource string by the string's resource key/identifier
    getResourceString(key, localeName) {
        let resourceString = null;

        if (key != null) {
            localeName = localeName != null ? localeName : this._localeName;
            let obj = this._impls[localeName].resources;
            let keys = key.split('.');

            for (let i = 0; obj != null && i < keys.length; ++i) {
                obj = obj[keys[i]];
            }

            resourceString = obj;
        }

        return resourceString;
    }

    // Gets the localized field value from a JSON field containing multiple
    // translations of a value.  e.g. { en_us: "Power Plant", ja_jp: "xyz" }
    getJSONFieldValue(jsonField, localeName) {
        let fieldValue = null;

        if (jsonField != null) {
            // Allow the caller to request a specific locale; if not specified,
            // use the current locale
            localeName = localeName != null ? localeName : this._localeName;
            let jsonFieldKeys = this._impls[localeName].jsonFieldKeys;

            // Find keys present in JSON field
            let keys = jsonFieldKeys.filter((k) => Object.keys(jsonField).includes(k));

            // If we have a key present, use it to get the value
            if (keys != null && keys.length > 0) {
                fieldValue = jsonField[keys[0]];
            }
        }

        return fieldValue;
    }

    // Gets the currency field value from a JSON field containing multiple
    // currencie values, keyed by the currency name.
    // e.g. { JPY: 1.23, USD: 4.56, EUR: 7.89 }
    getJSONCurrencyValue(jsonField, currencyName) {
        let currencyValue = null;

        if (jsonField != null) {
            // Allow the caller to request a specific currency name; if not specified,
            // use the current currency name
            currencyName = currencyName != null ? currencyName : this._currencyName;

            currencyValue = jsonField[currencyName];
        }

        return currencyValue;
    }

    // Gets the tile sources for the map
    // NOTE: it is assumed that each locale will return the same number of sources!
    getTileSources() {
        return this._impl.tileSources;
    }

    // Creates a "JSON field" (e.g. { en_us: "Power Plant", ja_jp: "xyz" })
    // using all known locales' resources, based on the specified resource 
    // string key.
    makeJSONField(key) {
        return this.localeNames.reduce((obj, localeName) => {
            obj[localeName] = this.getResourceString(key, localeName);
            return obj;
        }, {});
    }

    // Parses a date and time, assuming the following:
    // * The input value is formatted as: "2019-03-10T04:00:00"
    // * For JEMI, we will treat all DateTime values as UTC for simplicity, and
    //   we can because Japan doesn't observe DST and all of Japan is in one
    //   time zone.
    parseMoment(value) {
        let trimmed = value.trim();
        if (!trimmed.endsWith("Z")) {
            trimmed = trimmed + "Z";
        }
        return moment(new Date(trimmed)).utcOffset(0);
    }
    
    // Converts a temperature value from a specified reporting unit of measure to our currently-selected unit of measure
    convertTemperature(value, reportedUnits) {
        return (this._temperatureConverters.hasOwnProperty(this._temperatureUnits)) ?
            this._temperatureConverters[this._temperatureUnits](value, reportedUnits) :
            null;
    }
    
    // Converts a speed value from a specified reporting unit of measure to our currently-selected unit of measure
    convertSpeed(value, reportedUnits) {
        return (this._speedConverters.hasOwnProperty(this._speedUnits)) ?
            this._speedConverters[this._speedUnits](value, reportedUnits) :
            null;
    }

    // Formats a moment as a date using the current application locale
    formatDate(moment) {
        return moment != null ? this._impl.formatDate(moment) : null;
    }

    // Formats a moment as a date and time using the current application locale
    formatDateTime(moment) {
        return moment != null ? this._impl.formatDateTime(moment) : null;
    }

    // Formats a moment at a specified "granularity"
    formatMomentGranularity(moment, granularity) {
        return moment != null ? this._impl.formatMomentGranularity(moment, granularity) : null;
    }

    // Formats a number according to the configured locale using the specified number of digits
    formatNumber(value, digits) {
        return value != null ? this._impl.formatNumber(value, digits) : null;
    }

    // Formats a number as a percentage according to the configured locale using the specified number of digits
    formatPercent(value, digits) {
        return value != null ? this._impl.formatPercent(value, digits) : null;
    }

    // Formats a currency value using the current application currency
    formatCurrency(value) {
        return value != null ? this._currencyFormats[this._currencyName].format(value) : null;
    }

    // Formats a temperature value according to the current application temperature units
    formatTemperature(value, digits) {
        return (value != null) ?
            this._impl.formatTemperature(value, digits, this._temperatureUnits) :
            null;
    }

    // Formats a speed value according to the current application speed units
    formatSpeed(value, digits) {
        return (value != null) ?
            this._impl.formatSpeed(value, digits, this._speedUnits) :
            null;
    }

    // Formats a refcase vintage using vintage data
    formatVintage(vintages) {
        let vintageRecord = vintages?.[0];
        return vintageRecord ?
            this._impl.formatVintage(
                vintageRecord.ReleaseYearNbr, 
                this.getJSONFieldValue(vintageRecord.SeasonAbbr)) :
            '';
    }

    // Formats a refcase vintage and scenario using vintage data
    formatVintageAndScenario(vintages, scenarioId) {
        let vintageRecord = vintages?.find(v => v.ScenarioId === scenarioId);
        return vintageRecord ? 
            this._impl.formatVintageAndScenario(
                vintageRecord.ReleaseYearNbr, 
                this.getJSONFieldValue(vintageRecord.SeasonAbbr), 
                this.getJSONFieldValue(vintageRecord.ScenarioName)) :
            '';
    }

    // Gets the currency symbol for the currently-selected currency format
    getCurrencySymbol() {
        let array = this._currencyFormats[this._currencyName].formatToParts();
        let currency = array.filter(v => v.type === 'currency');
        return (currency != null && currency.length > 0) ? currency[0].value.trim() : '';
    }

    // Notifies all event handlers of a change in the selected locale
    _notifyLocaleChanged() {
        let stopNotifying = false;
        let handlers = Object.values(this._localeChangedHandlers);
        for (let i = 0; i < handlers.length && !stopNotifying; ++i) {
            stopNotifying = stopNotifying || handlers[i](this.localeName);
        }
    }

    // Notifies all event handlers of a change in the selected currency
    _notifyCurrencyChanged() {
        let stopNotifying = false;
        let handlers = Object.values(this._currencyChangedHandlers);
        for (let i = 0; i < handlers.length && !stopNotifying; ++i) {
            stopNotifying = stopNotifying || handlers[i](this.currencyName);
        }
    }

    // Notifies all event handlers of a change in user-selected units
    _notifyUnitsChanged() {
        let stopNotifying = false;
        let handlers = Object.values(this._unitsChangedHandlers);
        for (let i = 0; i < handlers.length && !stopNotifying; ++i) {
            stopNotifying = stopNotifying || handlers[i]();
        }
    }
}

const _instance = new Locale();
Object.seal(_instance);

export default _instance;
