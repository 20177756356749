import React, { Component } from 'react';

import Locale from 'locale/Locale';

import ForwardMarketChart from './ForwardMarketChart';
import ForwardMarketChartPalette from './ForwardMarketChartPalette';

const dl = require('@hitachi.energy.digitalforce.node/datalib').dl;
const t = Locale.getResourceString.bind(Locale);

////////////////////////////////////////////////////////////////////////////////
// This class renders the specialized tooltip used in the forward market 
// chart.  The idea is to start with the markup rendered by recharts for the
// standard tooltip, and then customize it to generate our tooltip.
//
// The following shows the JSX-ified markup that serves as a starting point
// for a standard recharts tooltip:
//
// <div 
//     className="recharts-default-tooltip"
//     style={{margin: 0, padding: 10, backgroundColor: '#fff', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap'}}
// >
//     <p 
//         className="recharts-tooltip-label" 
//         style={{margin: 0}}
//     >
//         <span>Label at the top of the tooltip</span>
//     </p>
//     <ul className="recharts-tooltip-item-list" style={{padding: 0, margin: 0}}>
//         {/* Each item in the tooltip looks like this... */}
//         <li className="recharts-tooltip-item" style={{display: 'block', paddingTop: 4, paddingBottom: 4, color: p.color }}>
//             <span className="recharts-tooltip-item-name">Item Name</span>
//             <span className="recharts-tooltip-item-separator">{this.props.separator}</span>
//             <span className="recharts-tooltip-item-value">Item Value</span>
//             <span className="recharts-tooltip-item-unit"></span>
//         </li>
//     </ul>
// </div>
////////////////////////////////////////////////////////////////////////////////
class ForwardMarketChartTooltip extends Component {
    constructor(props, context) {
        super(props)
    }

    _renderItems(items, detailClassName) {
        return (
            <div
                className={"recharts-default-tooltip forward-market-tooltip " + detailClassName}
                style={{ margin: 0, padding: 10, backgroundColor: '#fff', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}
            >
                <p
                    className="recharts-tooltip-label"
                    style={{ margin: 0 }}
                >
                    {this.props.labelFormatter(this.props.label)}
                </p>
                {/* This list renders the header items */}
                <ul className="recharts-tooltip-item-list" style={{ padding: 0, margin: 0 }}>
                    <li className="recharts-tooltip-item">
                        <span className="recharts-tooltip-item-name">{t('priceHistory.charts.forwardMarket.tooltips.category')}</span>
                        <span className="recharts-tooltip-item-name">{t('priceHistory.charts.forwardMarket.tooltips.volume')}</span>
                        <span className="recharts-tooltip-item-name">{t('priceHistory.charts.forwardMarket.tooltips.minValue') + '/' + t('units.kiloWattHour')}</span>
                        <span className="recharts-tooltip-item-name">{t('priceHistory.charts.forwardMarket.tooltips.maxValue') + '/' + t('units.kiloWattHour')}</span>
                        <span className="recharts-tooltip-item-name">{t('priceHistory.charts.forwardMarket.tooltips.avgValue') + '/' + t('units.kiloWattHour')}</span>
                    </li>
                </ul>
                {/* This list renders the line items in the tooltip */}
                <ul className="recharts-tooltip-item-list" style={{ padding: 0, margin: 0 }}>
                    {items}
                </ul>
            </div>
        );
    }

    _renderDetailed() {
        let payload = this.props.payload || [];
        let items = payload.map(p => {
            let seriesCategory = ForwardMarketChart.SeriesPatternRegex.exec(p.dataKey)[1];
            return (
                <li key={p.dataKey} className="recharts-tooltip-item" style={{ display: 'block', color: p.color }}>
                    <span className="recharts-tooltip-item-name">{p.name}</span>
                    <span className="recharts-tooltip-item-value">{this.props.formatter(p.value, p.name, p)}</span>
                    <span className="recharts-tooltip-item-value">{Locale.formatCurrency(p.payload[seriesCategory + '_min_value'])}</span>
                    <span className="recharts-tooltip-item-value">{Locale.formatCurrency(p.payload[seriesCategory + '_max_value'])}</span>
                    <span className="recharts-tooltip-item-value">{Locale.formatCurrency(p.payload[seriesCategory + '_avg_value'])}</span>
                </li>
            );
        });
        
        return this._renderItems(items, "forward-market-tooltip-detailed");
    }

    _renderSummary(getKey, getSeriesType) {
        let items = [];
        let visitedKeys = {};
        let palette = ForwardMarketChartPalette.getSummaryPalette(this.props.colorBy);
        let payload = this.props.payload || [];

        for (let i = 0; i < payload.length; ++i) {
            let p = this.props.payload[i];
            let key = getKey(p);
            if (key != null && visitedKeys[key] == null) {
                visitedKeys[key] = true;

                let payloads = this.props.payload.filter(p => key === getKey(p));
                let value = dl.sum(payloads, p => p.value);
                let min = dl.min(payloads, p => p.payload[ForwardMarketChart.SeriesPatternRegex.exec(p.dataKey)[1] + '_min_value']);
                let max = dl.max(payloads, p => p.payload[ForwardMarketChart.SeriesPatternRegex.exec(p.dataKey)[1] + '_max_value']);
                let avg = dl.mean(payloads, p => p.payload[ForwardMarketChart.SeriesPatternRegex.exec(p.dataKey)[1] + '_avg_value']);
                items.push(
                    <li key={p.dataKey} className="recharts-tooltip-item" style={{ display: 'block', color: palette.getSeriesColor(p.dataKey) }}>
                        <span className="recharts-tooltip-item-name">{t('priceHistory.charts.forwardMarket.seriesTypes.' + getSeriesType(key))}</span>
                        <span className="recharts-tooltip-item-value">{this.props.formatter(value, p.name, p)}</span>
                        <span className="recharts-tooltip-item-value">{Locale.formatCurrency(min)}</span>
                        <span className="recharts-tooltip-item-value">{Locale.formatCurrency(max)}</span>
                        <span className="recharts-tooltip-item-value">{Locale.formatCurrency(avg)}</span>
                    </li>
                );
            }
        }

        return this._renderItems(items, "forward-market-tooltip-summary");
    }

    _renderByInterconnect() {
        return this._renderSummary(
            p => ForwardMarketChart.SeriesPatternRegex.exec(p.dataKey)[2],
            key => ForwardMarketChartTooltip.InterconnectMappings[key]
        );
    }

    _renderByPeakType() {
        return this._renderSummary(
            p => ForwardMarketChart.SeriesPatternRegex.exec(p.dataKey)[4],
            key => ForwardMarketChartTooltip.PeakTypeMappings[key]
        );
    }

    _renderByContractLength() {
        return this._renderSummary(
            p => ForwardMarketChart.SeriesPatternRegex.exec(p.dataKey)[3],
            key => ForwardMarketChartTooltip.ContractLengthMappings[key]
        );
    }

    render() {
        if (!this.props.active) {
            return null;
        }

        if (this.props.isDetailedTheme) {
            return this._renderDetailed();
        }
        else if (this.props.colorBy === 'interconnect') {
            return this._renderByInterconnect();
        }
        else if (this.props.colorBy === 'peakType') {
            return this._renderByPeakType();
        }
        else if (this.props.colorBy === 'contractLength') {
            return this._renderByContractLength();
        }
    }
}

ForwardMarketChartTooltip.InterconnectMappings = {
    kn: 'kansai',
    sys: 'system',
    tk: 'tokyo',
};

ForwardMarketChartTooltip.PeakTypeMappings = {
    '24': 'allHours',
    dt: 'daytime',
};

ForwardMarketChartTooltip.ContractLengthMappings = {
    wk: 'week',
    mth: 'month',
    yr: 'year',
}

export default ForwardMarketChartTooltip;