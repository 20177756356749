import CSVDownload from 'CSVDownload';

////////////////////////////////////////////////////////////////////////////////
// Encapsulates an item to be downloaded
////////////////////////////////////////////////////////////////////////////////
export default class CSVDownloadItem {
    constructor(captionKey, route, fileName) {
        this._captionKey = captionKey;
        this._route = route;
        this._fileName = fileName;
    }

    ////////////////////////////////////////////////////////////////////////////////
    // Gets the translation key used to render a menu item for the download
    ////////////////////////////////////////////////////////////////////////////////
    get captionKey() {
        return this._captionKey;
    }

    ////////////////////////////////////////////////////////////////////////////////
    // Gets the route to download the item
    ////////////////////////////////////////////////////////////////////////////////
    get route() {
        return (typeof(this._route) === 'function') ?
            this._route() :
            this._route;
    }

    ////////////////////////////////////////////////////////////////////////////////
    // Gets the filename that the browser should use to save the file locally
    ////////////////////////////////////////////////////////////////////////////////
    get fileName() {
        return this._fileName;
    }

    ////////////////////////////////////////////////////////////////////////////////
    // Download the item
    ////////////////////////////////////////////////////////////////////////////////
    download() {
        CSVDownload.get(this.route, this.fileName);
    }
}
