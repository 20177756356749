import React from 'react';
import { withRouter } from 'react-router';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { Select } from 'ol/interaction';

import { ToggleButton } from 'webcore-ux/react/components';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import CSVDownloadItem from 'CSVDownloadItem';
import App from 'App';
import Locale from '../locale/Locale';
import AppTrace from '../AppTrace';
import BusyState from '../BusyState';
import ErrorBoundary from 'ErrorBoundary';
import ErrorState from '../ErrorState';
import VisualBox from '../common/VisualBox';
import EntitySearch from './EntitySearch';
import LayerChooser from './LayerChooser';
import AnimationChooser from './AnimationChooser';
import Legend from './legend/Legend';
import FeaturePopup from './popup/FeaturePopup';
import PowerPlants from './layers/PowerPlants';
import LNGFacilities from './layers/LNGFacilities';
import Substations from './layers/Substations';
import WeatherStations from './layers/WeatherStations';
import TransmissionLines from './layers/TransmissionLines';
import Prefectures from './layers/Prefectures';
import UtilityServiceTerritories from './layers/UtilityServiceTerritories';

import NoneAnimation from './themes/animations/NoneAnimation';
import OffPeakPriceForecast from './themes/animations/OffPeakPriceForecast';
import OnPeakPriceForecast from './themes/animations/OnPeakPriceForecast';
import AveragePriceForecast from './themes/animations/AveragePriceForecast';
import AreaPriceAnimation from './themes/animations/AreaPriceAnimation';
import WindSpeedAndDirectionAnimation from './themes/animations/WindSpeedAndDirectionAnimation';

import './SpatialAwareness.scss';
import SearchDataFactory from './SearchDataFactory';

const t = Locale.getResourceString.bind(Locale);
const Projection = require('ol/proj');

class SpatialAwareness extends React.Component {
    static get ViewKey() {
        return 'spatial-awareness';
    }

    static get Downloads() {
        return [
            new CSVDownloadItem('spatialAwareness.layers.lngFacilities.title', '/spatial-awareness/lng-facilities/download', 'lng-facilities.csv'),
            new CSVDownloadItem('spatialAwareness.layers.powerPlants.title', '/spatial-awareness/power-plants/download', 'power-plants.csv'),
            new CSVDownloadItem('spatialAwareness.layers.prefectures.title', '/spatial-awareness/prefectures/download', 'prefectures.csv'),
            new CSVDownloadItem('spatialAwareness.layers.substations.title', '/spatial-awareness/substations/download', 'substations.csv'),
            new CSVDownloadItem('spatialAwareness.layers.weatherStations.title', '/spatial-awareness/weather-stations/download', 'weather-stations.csv'),
            new CSVDownloadItem('spatialAwareness.layers.transmissionLines.title', '/spatial-awareness/transmission-lines/download', 'transmission-lines.csv'),
            new CSVDownloadItem('spatialAwareness.layers.utilityServiceTerritories.title', '/spatial-awareness/utility-service-territories/download', 'utility-service-territories.csv'),
            new CSVDownloadItem('spatialAwareness.layers.download', '/spatial-awareness/shape-files', 'jemi.zip'),
        ]
    };

    constructor(props, context) {
        super(props);

        this._mapRef = React.createRef();
        this._map = null;
        this._tileLayers = [];
        this._select = null;
        // The setter for this property will give a valid default value instead of null
        this._mappableEntities = null;

        // To facilitate finding the layer corresponding to a search result, map entity types
        // to our layer keys.  A numeric ID would be preferable, but we don't have that 
        // at this time.
        this._entityTypeToLayerLookup = {
            "Electric Service Territory": 'utilityServiceTerritories',
            "LNG Facility": 'lngFacilities',
            "Power Plant": 'powerPlants',
            "Prefecture": 'prefectures',
            "Substation": 'substations',
            "Transmission Line": 'transmissionLines',
            "Weather Station": 'weatherStations',
        }

        this._layerLookup = {
            powerPlants: new PowerPlants(),
            lngFacilities: new LNGFacilities(),
            substations: new Substations(),
            weatherStations: new WeatherStations(),
            transmissionLines: new TransmissionLines(),
            prefectures: new Prefectures(),
            utilityServiceTerritories: new UtilityServiceTerritories(),
        };

        this._animationLookup = {
            none: new NoneAnimation(null),
            offPeakPriceForecast: new OffPeakPriceForecast(this._layerLookup.utilityServiceTerritories),
            onPeakPriceForecast: new OnPeakPriceForecast(this._layerLookup.utilityServiceTerritories),
            averagePriceForecast: new AveragePriceForecast(this._layerLookup.utilityServiceTerritories),
            areaPrice: new AreaPriceAnimation(this._layerLookup.utilityServiceTerritories),
            windSpeedAndDirection: new WindSpeedAndDirectionAnimation(this._layerLookup.weatherStations),
        };

        this.state = {
            isLegendVisible: true,
            isSomethingPinned: false,
            selectedAnimationKey: this._animationLookup.none.key,
            selectedFeatures: [],
        };

        this._renderMap = this._renderMap.bind(this);
        this._onLocaleChanged = this._onLocaleChanged.bind(this);
        this._onChangeLayerIsVisible = this._onChangeLayerIsVisible.bind(this);
        this._onChangeLayerIsEnabled = this._onChangeLayerIsEnabled.bind(this);
        this._onChangeAnimation = this._onChangeAnimation.bind(this);
        this._onMapSelect = this._onMapSelect.bind(this);
        this._onCloseFeaturePopup = this._onCloseFeaturePopup.bind(this);
        this._onPin = this._onPin.bind(this);
        this._onSearch = this._onSearch.bind(this);

        this._layers.forEach((l) => (l.onIsEnabledChanged = this._onChangeLayerIsEnabled));
    }

    get _view() {
        return this._map.getView();
    }

    get _layers() {
        return Object.values(this._layerLookup);
    }

    get _visibleLayers() {
        return this._layers.filter((l) => l.isVisible);
    }

    get _animations() {
        return Object.values(this._animationLookup);
    }

    _renderMap() {
        return new Promise((resolve, reject) => {
            try {
                // Create the tile layer
                this._tileLayers = Locale.getTileSources().map(source => new TileLayer({ source: source }));
                
                // Create the map
                this._map = new Map({
                    target: this._mapRef.current,
                    layers: this._tileLayers,
                    view: new View({
                        center: Projection.fromLonLat([138.2529, 36.2048]),
                        zoom: 6,
                    }),
                });

                // Add an event listener for when moving the map has ended
                this._map.on('moveend', () => {
                    // Notify all layers that the view is stationary
                    this._layers.forEach((l) => l.notifyMoveEnd(this._map.getView()));
                });

                // Create a click-based map interation
                this._select = new Select({
                    multi: true,
                });

                // Add the interaction, and listen for selections
                this._map.addInteraction(this._select);
                this._select.on('select', this._onMapSelect);

                resolve();
            } catch (err) {
                ErrorState.setFault('Error rendering map');
                reject(err);
            }
        });
    }

    _loadAndShowLayer(layer) {
        return new Promise((resolve, reject) => {
            // If the layer is null, then resolve immediately
            if (layer == null) {
                resolve();
            } else {
                // Log the request to show the layer
                AppTrace.traceInfo(layer.key, AppTrace.categories.layer);

                // Ensure that the data for the layer is loaded
                layer.load(this._view).then((layer) => {
                    // Set the visibility of the layer
                    layer.isVisible = true;

                    // Try to find the layer in the map's layers collection
                    let vectorLayers = this._map
                        .getLayers()
                        .getArray()
                        .filter((l) => l instanceof VectorLayer);
                    if (vectorLayers.find((vectorLayer) => vectorLayer.getProperties().key === layer.key) == null) {
                        // Not there - find the index of the first layer whose zIndex
                        // is greater than this layer, and add it there
                        let layers = vectorLayers.map((vectorLayer) => this._layerLookup[vectorLayer.getProperties().key]);
                        let index = layers.findIndex((l) => l.zIndex > layer.zIndex);
                        if (index === -1) {
                            this._map.addLayer(layer.vectorLayer);
                        } else {
                            // Insert at 1 index higher than desired index to account for imagery/raster layer
                            this._map.getLayers().insertAt(index + 2, layer.vectorLayer);
                        }
                    }

                    // Resolve the promise
                    resolve();
                });
            }
        });
    }

    get _mappableEntities() {
        return this.__mappableEntities;
    }
    set _mappableEntities(value) {
        if (value != null) {
            // Create a place to store the entities
            let entities = {};

            // Extract field indexes
            let idIndex = value.metadata.columns.id.index;
            let typeIndex = value.metadata.columns.type.index;
            let nameIndex = value.metadata.columns.name.index;

            // For each locale, pre-sort and construct the options
            ['en', 'ja'].forEach(localeName => {
                // Sort by entity type, then by name
                value.rows.sort((a, b) => {
                    let cmp = Locale.getJSONFieldValue(a[typeIndex], localeName).localeCompare(Locale.getJSONFieldValue(b[typeIndex], localeName), localeName);
                    if (cmp === 0) {
                        cmp = Locale.getJSONFieldValue(a[nameIndex], localeName).localeCompare(Locale.getJSONFieldValue(b[nameIndex], localeName), localeName);
                    }
                    return cmp;
                });

                // Create options
                entities[localeName] = value.rows.map(e => {
                    return {
                        id: e[idIndex],
                        type: e[typeIndex],
                        name: e[nameIndex],
                        // Include a field with both English and Japanese text to faciltate searching in both languages at the same time
                        searchString: `${Locale.getJSONFieldValue(e[nameIndex], 'en')}${Locale.getJSONFieldValue(e[nameIndex], 'ja')}`,
                    };
                });
            });

            // Store transformed mappable entities
            this.__mappableEntities = entities;
        }
        else {
            // Assign a default value that won't cause any problems with downstream components
            this.__mappableEntities = {
                en: [],
                ja: [],
            };
        }
    }

    _onLocaleChanged(localeName) {
        // Update tile source
        Locale.getTileSources().forEach((source, index) => {
            if (index < this._tileLayers.length) {
                this._tileLayers[index].setSource(source);
            }
        });

        // Notify all layers of the change in locale
        this._layers.forEach((layer) => {
            if (layer != null && typeof layer.notifyLocaleChanged === 'function') {
                layer.notifyLocaleChanged(localeName);
            }
        });
    }

    _onChangeLayerIsVisible(layer, visible) {
        return new Promise((resolve, reject) =>
        {
            // If a layer is being set invisible, and it is associated with a current animation,
            // then reset the current animation to none.
            if (!visible && this._animationLookup[this.state.selectedAnimationKey].layer === layer) {
                this._onChangeAnimation(this._animationLookup.none.key);
            }

            // If the layer is loaded and is being turned off, don't load it - just hide it
            if (layer.isLoaded && !visible) {
                // Set layer visibility
                layer.isVisible = visible;

                // Trigger render to reflect new visible state
                this.forceUpdate();

                // Resolve
                resolve(layer);
            } else {
                // Either the layer hasn't been loaded yet, or we're turning it on.  Regardless,
                // the right thing to do is to let the layer try to load itself with the current
                // view and then add it to the collection if needed
                this._loadAndShowLayer(layer)
                    .then(() => {
                        // Trigger render to reflect new visible state
                        this.forceUpdate();

                        // Resolve
                        resolve(layer);
                    });
            }
        });
    }

    _onChangeLayerIsEnabled(layer, isEnabled) {
        // Hide the layer if it's not enabled
        if (!isEnabled) {
            layer.isVisible = false;
        }

        // Set state to trigger redrawing the LayerChooser
        this.forceUpdate();
    }

    _onChangeAnimation(animationKey) {
        // Disable all previous animations
        this._animations.forEach((a) => (a.isActive = false));

        let animation = this._animationLookup[animationKey];
        let layer = animation.layer;

        // Check whether or not any data needs to be loaded, and if so, show load indicator
        if (!animation.isLoaded || (layer != null && !layer.isLoaded)) {
            BusyState.isBusy = true;
        }

        // Show the layer that's associated with the animation
        this._loadAndShowLayer(layer)
            .then(() => {
                // Log use of the animation
                if (animation.key !== this._animationLookup.none.key) {
                    AppTrace.traceInfo(animation.key, AppTrace.categories.timeSeriesAnimation);
                }

                // Ensure that the animation's data is loaded
                return animation.load();
            })
            .then(() => {
                // Set the animation active
                this._animationLookup[animationKey].isActive = true;

                // Set the active animation
                this.setState({
                    selectedAnimationKey: animationKey,
                });

                // Hide load indicator
                BusyState.isBusy = false;
            })
            .catch((err) => {
                BusyState.isBusy = false;
                ErrorState.setFault('Error changing animation');
            });
    }

    _onMapSelect(e) {
        this.setState({
            selectedFeatures: e.target.getFeatures().getArray(),
        });
    }

    _onCloseFeaturePopup() {
        this._select.getFeatures().clear();
        this.setState({
            selectedFeatures: [],
        });
    }

    _onPin(args) {
        // Keep track of whether or not anything we're responsible for is pinned
        if (args.hasOwnProperty('isPinned')) {
            this.setState({ isSomethingPinned: args.isPinned });
        }

        if (this.props.onPin != null) {
            this.props.onPin(args);
        }
    }

    _onSearch(value) {
        let entityTypeName = Locale.getJSONFieldValue(value.type, 'en');
        if (this._entityTypeToLayerLookup.hasOwnProperty(entityTypeName)) {
            // First, ensure that the layer containing the feature is turned on
            this._onChangeLayerIsVisible(this._layerLookup[this._entityTypeToLayerLookup[entityTypeName]], true)
                .then(layer => {
                    // Try to find the feature in the layer
                    let feature = layer.findFeatureByEntityId(value.id);
                    if (feature != null) {
                        // Zoom to the feature so it's visible
                        layer.zoomToFeature(feature, this._map.getView());

                        // Clear any existing selection, and then select the new feature
                        this._onCloseFeaturePopup();
                        this._select.getFeatures().push(feature);
                        this._select.dispatchEvent('select');
                    }
                });
        }
    }



    componentDidMount() {
        // Listen for locale-changed events, at which time we'll want to localize map resources
        // which are not managed by the React component lifecycle
        Locale.addLocaleHandler("SpatialAwareness", this._onLocaleChanged);

        // Set busy state to true
        BusyState.isBusy = true;

        this._renderMap()
            .then(() => {
                // Get entity search data
                return SearchDataFactory.get();
            })
            .then((mappableEntities) => {
                // Save mappable entities
                this._mappableEntities = mappableEntities;

                // Load the utility service territories layer
                AppTrace.traceInfo(this._layerLookup.utilityServiceTerritories.key, AppTrace.categories.layer);
                return this._layerLookup.utilityServiceTerritories.load(this._view);
            })
            .then(() => {
                // Load the power plants layer
                AppTrace.traceInfo(this._layerLookup.powerPlants.key, AppTrace.categories.layer);
                return this._layerLookup.powerPlants.load(this._view);
            })
            .then(() => {
                // Add default layers
                this._map.addLayer(this._layerLookup.utilityServiceTerritories.vectorLayer);
                this._map.addLayer(this._layerLookup.powerPlants.vectorLayer);

                // Set busy state to false
                BusyState.isBusy = false;
            })
            .catch((err) => {
                BusyState.isBusy = false;
                console.error(err);
                ErrorState.setFault('Error loading spatial awareness');
            });
    }

    componentDidUpdate(previousProps) {
        // https://pgga-es.atlassian.net/browse/VS-1571
        // If the map is created and we're on our current tab, update the size of the map
        if (this._map != null && App.currentView === SpatialAwareness.ViewKey) {
            this._map.updateSize();
        }

        // Determine whether or not this is the current view
        if (!this.props.location.pathname.includes(SpatialAwareness.ViewKey)) {
            // Pause any active animation
            if (this._animationLookup[this.state.selectedAnimationKey] != null) {
                this._animationLookup[this.state.selectedAnimationKey].pause();
            }
        }
    }

    componentWillUnmount() {
        if (this._map != null) {
            // Destroy the map
            this._map.setTarget(null);
            this._map = null;
        }

        // Clean up locale handler
        Locale.removeLocaleHandler("SpatialAwareness");
    }

    renderAnimation() {
        if (this.state.selectedAnimationKey == null || this._animationLookup[this.state.selectedAnimationKey] == null) {
            return null;
        }

        return <div key={this.state.selectedAnimationKey}>{this._animationLookup[this.state.selectedAnimationKey].render()}</div>;
    }

    render() {
        return (
            <ErrorBoundary>
                <div className="spatial-awareness">
                    <VisualBox caption={t('spatialAwareness.caption')}>
                        <EntitySearch
                            entities={this._mappableEntities}
                            onSearch={this._onSearch}
                        />

                        <LayerChooser 
                            disabled={this.state.isSomethingPinned}
                            layers={this._layers} 
                            onChangeLayerIsVisible={this._onChangeLayerIsVisible}
                            onPin={this._onPin}
                        />

                        <AnimationChooser
                            disabled={this.state.isSomethingPinned}
                            animations={this._animations}
                            selectedAnimationKey={this.state.selectedAnimationKey}
                            onChangeAnimation={this._onChangeAnimation}
                            onPin={this._onPin}
                        />

                        <div className="spatial-awareness-map-buttons">
                            <div className="spatial-awareness-legend-button">
                                <ToggleButton 
                                    size="small" 
                                    selected={this.state.isLegendVisible} 
                                    title={(this.state.isLegendVisible) ? t('spatialAwareness.legend.hideLegend') : t('spatialAwareness.legend.showLegend')}
                                    value="isLegendVisible"
                                    onClick={() => this.setState({ isLegendVisible: !this.state.isLegendVisible })}
                                >
                                    <VpnKeyIcon fontSize="small" />
                                </ToggleButton>
                            </div>
                        </div>

                        {this.state.isLegendVisible && (
                            <Legend
                                layers={this._layers}
                            />
                        )}

                        <FeaturePopup
                            layerLookup={this._layerLookup}
                            selectedFeatures={this.state.selectedFeatures}
                            onClose={this._onCloseFeaturePopup}
                        />

                        <div className="spatial-awareness-map" ref={this._mapRef} />

                        {this.renderAnimation()}
                    </VisualBox>
                </div>
            </ErrorBoundary>
        );
    }
}

const SpatialAwarenessWithRouter = withRouter(SpatialAwareness);
export default SpatialAwarenessWithRouter;
