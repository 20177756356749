import CompanyFinancialsPivotGrid from './CompanyFinancialsPivotGrid';

/////////////////////////////////////////////////////////////////////////////
// This class encapsulates the statement of income grid
/////////////////////////////////////////////////////////////////////////////
class StatementOfIncomeGrid extends CompanyFinancialsPivotGrid {
    constructor(props, context) {
        super(props);
    }

    get _numericFields() {
        const numericFields = [
            'oper_revenue_val', 
            'electric_oper_revenue_val', 
            'other_oper_revenue_val', 
            'non_oper_revenue_val', 
            'tot_ordinary_revenue_val', 
            'oper_expenses_val', 
            'electric_oper_expenses_val', 
            'other_oper_expenses_val', 
            'non_oper_expenses_val', 
            'total_ordinary_expenses_val', 
            'total_ordinary_income_val', 
            'extraordinary_income_loss_val', 
            'income_before_taxes_val', 
            'income_taxes_current_val', 
            'income_taxes_deferred_val', 
            'net_inc_attrib_to_owners_val', 
        ];

        return numericFields;
    }
}

export default StatementOfIncomeGrid;
