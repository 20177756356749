import DataFormatting from 'DataFormatting';

export default class PriceHistoryDataFactory {
    get() {
        throw new Error('Not Implemented');
    }

    _postProcessData(data) {
        // Postprocess all column captions
        Object.values(data.metadata.columns).forEach(col => col.caption = DataFormatting.parseJSON(col.caption));

        // Postprocess currency values
        let currencyColumns = Object.values(data.metadata.columns).filter(c => c.tag != null && c.tag.isCurrencyField);
        let convUSD = data.metadata.columns.conv_usd;
        let convEUR = data.metadata.columns.conv_eur;
        
        for (let i = 0; i < data.rows.length; ++i) {
            let row = data.rows[i];

            // Convert all currency columns
            for (let j = 0; j < currencyColumns.length; ++j) {
                let col = currencyColumns[j];
                let value = row[col.index];
                if (value != null) {
                    row[col.index] = {
                        JPY: value,
                        USD: value * row[convUSD.index],
                        EUR: value * row[convEUR.index],
                    };
                }
                else {
                    row[col.index] = {
                        JPY: null,
                        USD: null,
                        EUR: null,
                    }
                }
            }
        }
    }
}