import axios from 'axios';

import AppConfig from './AppConfig';
import Authentication from './Authentication';

////////////////////////////////////////////////////////////////////////////////
// Faciliates logging UI events
////////////////////////////////////////////////////////////////////////////////
class AppTrace {
    constructor() {
        // Make it easy to internally check whether or not an input category is known
        this._validCategories = Object.values(this.categories).reduce((obj, cat) => {
            obj[cat] = true;
            return obj;
        }, {});
    }

    get categories() {
        ////////////////////////////////////////////////////////////////////////////////
        // These are the available logging categories.  If one is added here for
        // use in the web app - THEN BE SURE TO UPDATE THE API APP APP CONFIGURATION
        // TO BE AWARE OF THE NEW CATEGORY BECAUSE THE CATEGORY IS VALIDATED AND 
        // IF THE API DOESN'T KNOW ABOUT IT THEN THE LOG REQUEST IS REJECTED.
        ////////////////////////////////////////////////////////////////////////////////
        const Categories = {
            authenticate: 'Authenticate',
            download: 'Download',
            error: 'Error',
            layer: 'Layer',
            licensing: 'Licensing',
            navigate: 'Navigate',
            timeSeriesAnimation: 'Time Series Animation',
            signOut: 'Sign Out',
        };

        return Categories;
    }

    traceInfo(message, category) {
        this._traceMessage(message, category, 'information');
    }

    traceError(message) {
        this._traceMessage(message, this.categories.error, 'error');
    }

    _validateCategory(category) {
        let valid = false;
        if (category == null) {
            console.error('category is required');
        }
        else if (!this._validCategories.hasOwnProperty(category)) {
            console.error('unsupported category: ' + category);
        }
        else {
            valid = true;
        }

        return valid;
    }

    _traceMessage(message, category, endpoint) {
        if (this._validateCategory(category)) {
            try {
                Authentication.getAccessToken()
                    .then(accessToken => {
                        // Build url and configuration
                        let authString = 'Bearer '.concat(accessToken);
                        let url = AppConfig.dataService.baseUrl + '/log/' + endpoint;
                        let config = {
                            headers: {
                                Authorization: authString,
                                Accept: 'application/json',
                                'cache-control': 'no-cache',
                            },
                        };

                        // Post the request
                        axios
                            .post(url, { category: category, url: this._getUrl(), message: message }, config)
                            .then(function (response) {
                                // No-op
                            })
                            .catch((error) => {
                                console.error('Error calling log service: ' + error);
                            });
                    })
                    .catch((error) => {
                        console.error('Error getting access token: ' + error);
                    });
            } catch (err) {
                console.error('Error tracing message: ' + err);
            }
        }
    }

    _getUrl() {
        let url = '';
        try {
            if (window != null && window.location != null && window.location.href != null) {
                url = window.location.href;
            }
        } catch (err) {
            // No op
        }
        return url;
    }
}

const _instance = new AppTrace();
Object.seal(_instance);

export default _instance;
