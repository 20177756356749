import AppConfig from 'AppConfig';
import WeatherDataFactory from './WeatherDataFactory';

export default class TemperatureDataFactory extends WeatherDataFactory {
    ////////////////////////////////////////////////////////////////////////////
    // "Protected" interface
    ////////////////////////////////////////////////////////////////////////////
    get _key() {
        return 'temperature'
    }

    _getUrl(ids) {
        let idQuery = ids.map(id => `id=${id}`).join('&');
        return `${AppConfig.dataService.baseUrl}/weather/temperature?${idQuery}`
    }
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
}