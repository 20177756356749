import AppConfig from 'AppConfig';
import AppTrace from 'AppTrace';
import Authentication from 'Authentication';

const axios = require('axios');

export default class WeatherStationsDataFactory {
    constructor() {
        this._weatherStations = null;
    }

    getWeatherStations() {
        return new Promise((resolve, reject) => {
            // Check for cached weather stations and return them if present
            if (this._weatherStations != null) {
                resolve(this._weatherStations);
            }
            else {
                // Get access token
                Authentication.getAccessToken()
                    // Get API data
                    .then(accessToken => {
                        let authString = 'Bearer '.concat(accessToken);
                        let url = `${AppConfig.dataService.baseUrl}/weather/weather-stations`;

                        return axios.get(url, {
                            headers: {
                                Authorization: authString,
                                Accept: "application/json",
                                "cache-control": "no-cache",
                            }
                        });
                    })
                    .then(response => {
                        this._weatherStations = response.data;

                        // Postprocess JSON names
                        this._weatherStations.forEach(w => w.name = JSON.parse(w.name));

                        // Build a lookup from ID to name
                        this._weatherStations.lookup = this._weatherStations.reduce((obj, weatherStation) => {
                            obj[weatherStation.id] = weatherStation.name;
                            return obj;
                        }, {});

                        resolve(this._weatherStations);
                    })
                    .catch((error) => {
                        AppTrace.traceError('Error in WeatherStationsDataFactory.getWeatherStations: ' + error.message);
                        reject(new Error("Error getting weather stations"));
                    });
            }
        });
    }
}