import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {DataGrid} from 'webcore-ux/react/components'

import Locale from 'locale/Locale';
import ErrorBoundary from 'ErrorBoundary';
import Utils from 'Utils';

import 'common/DataGrid.scss';

/////////////////////////////////////////////////////////////////////////////
// This class encapsulates the company financials grid components
/////////////////////////////////////////////////////////////////////////////
class CompanyFinancialsPivotGrid extends Component {
    constructor(props, context) {
        super(props);

        this._getNumericFieldName = this._getNumericFieldName.bind(this);
        this._getNumericColumnHeader = this._getNumericColumnHeader.bind(this);
        this._getNumericColumnCell = this._getNumericColumnCell.bind(this);
    }

    _getNumericFieldName(row) {
        return (<div>{Locale.getJSONFieldValue(this.props.data.metadata.columns[row.value].caption)}</div>);
    }

    _getNumericColumnHeader(props) {
        let text = (this.props.chartBy === 'year') ?
            Locale.formatMomentGranularity(moment.utc([props.column.id, 1, 1]), 'A') :
            Utils.getShortServiceTerritoryName(Locale.getJSONFieldValue(this.props.ustsLookup[props.column.id]));

        return (<div style={{ width: '100%', textAlign: 'right' }}>{text}</div>);
    }

    _getNumericColumnCell(row) {
        return (<div style={{ width: '100%', textAlign: 'right' }}>{Locale.formatNumber(row.value, 0)}</div>);
    }

    get _numericFields() {
        throw new Error("Not implemented");
    }

    render() {
        // This column will represent each financial attribute
        let attributeColumn = {
            id: 'attribute_name',
            columnType: 'string',
            Header: '',
            accessor: 'attribute_name',
            width: this.props.groupColumnWidth,
            Cell: this._getNumericFieldName,
        };
        
        // The remaining columns are the grouped and aggregated attribute values
        let columns = this.props.gridData.map(group => { 
            return {
                id: group[this.props.chartBy],
                columnType: 'number',
                Header: this._getNumericColumnHeader,
                accessor: group[this.props.chartBy].toString(),
                width: (this.props.chartBy === 'year') ? this.props.yearColumnWidth : this.props.ustsColumnWidth,
                Cell: this._getNumericColumnCell,
            }
        });

        // Pivot out the numeric fields
        let data = this._numericFields.map(attributeName => 
            Object.assign(
                this.props.gridData.reduce((obj, group) => { obj[group[this.props.chartBy]] = group[attributeName]; return obj; }, {}),
                { attribute_name: attributeName }
            )
        );

        // Insert the attribute column at the beginning of the array of columns
        columns.unshift(attributeColumn);

        return (
            <ErrorBoundary>
                <div className="de-data-grid-container company-financials-grid-container">
                    <DataGrid 
                        columns={columns}
                        data={data} 
                        defaultPageSize={this.props.pageSize}
                        pagination={data.length > this.props.pageSize}
                        showPagination={data.length > this.props.pageSize}
                        showPageSizeOptions={false}

                        previousText={Locale.getResourceString('grids.previous')}
                        pageText={Locale.getResourceString('grids.page')}
                        ofText={Locale.getResourceString('grids.of')}
                        nextText={Locale.getResourceString('grids.next')}
                    />
                </div>
            </ErrorBoundary>
        );
    }
}

CompanyFinancialsPivotGrid.propTypes = {
    gridData: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.object,
    ustsLookup: PropTypes.object,
    numericColumnWidth: PropTypes.number,
    groupColumnWidth: PropTypes.number,
    yearColumnWidth: PropTypes.number,
    ustsColumnWidth: PropTypes.number,
    pageSize: PropTypes.number,
}

CompanyFinancialsPivotGrid.defaultProps = {
    gridData: [],
    data: {},
    ustsLookup: {},
    groupColumnWidth: 250,
    yearColumnWidth: 140,
    ustsColumnWidth: 200,
    pageSize: 20,
}

export default CompanyFinancialsPivotGrid;