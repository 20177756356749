import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import MUIDialog from '@material-ui/core/Dialog';
import { Dialog, LoadingIndicator, SlidePanel, Tab, TabContainer } from 'webcore-ux/react/components';

import AppMenuNarrow from 'AppMenuNarrow';
import AppMenuWide from 'AppMenuWide';
import AppConfig from 'AppConfig';
import AppTrace from 'AppTrace';
import Authentication from 'Authentication';
import BusyState from 'BusyState';
import ErrorBoundary from 'ErrorBoundary';
import ErrorState from 'ErrorState';
import SessionState from 'SessionState';
import Locale from 'locale/Locale';

import AppLicensing from './common/AppLicensing';
import JEMIStripe from './common/JEMIStripe';
import TabPanel from './common/TabPanel';

import SpatialAwareness from './spatial-awareness/SpatialAwareness';
import SupplyAndDemand from './supply-and-demand/SupplyAndDemand';
import PriceHistory from './price-history/PriceHistory';
import PriceForecast from './price-forecast/PriceForecast';
import CompanyFinancials from './company-financials/CompanyFinancials';
import Weather from './weather/Weather';

import './App.scss';

const t = Locale.getResourceString.bind(Locale);

class App extends React.Component {
    constructor(props) {
        super(props);

        // Configure session state
        this.sessionStateKey = 'emi-japan.App';
        this.identityPopupAnchorElement = null;
        this.downloadPopupAnchorElement = null;
        this.languagePopupAnchorElement = null;

        // Initialize state
        let stateOverrides = {
            isAppLicensingVisible: false,
            isFaulted: false,
            isLoadingData: true,
            idTokenClaims: null,

            isIdentityPopupOpen: false,
            isDownloadPopupOpen: false,
            isLanguagePopupOpen: false,

            // Pinning state
            pinning: {},

            // Menu style
            isRegularMenu: window.innerWidth >= App.MenuWidthThreshold,
        };
        let defaultInitialState = {
            localeName: Locale.localeName,
            currencyName: Locale.currencyName,
            temperatureUnits: Locale.temperatureUnits,
            speedUnits: Locale.speedUnits,
        };

        // Restore state; override with specific values we want applied
        this.state = SessionState.get(this.sessionStateKey, defaultInitialState, stateOverrides);

        // Configure locale
        Locale.localeName = this.state.localeName;
        Locale.currencyName = this.state.currencyName;
        Locale.temperatureUnits = this.state.temperatureUnits;
        Locale.speedUnits = this.state.speedUnits;

        // Initialize the UI as busy
        BusyState.isBusy = this.state.isLoadingData;

        // Assign event handlers for faults, busy-state, etc.
        ErrorState.addFaultHandler((isFaulted) => {
            this.setState({
                isFaulted: isFaulted,
            });
        });
        BusyState.addHandler((isBusy) => {
            this.setState({
                isLoadingData: isBusy,
            });
        });

        // Bind event handlers
        this._signInWithSilentFail = this._signInWithSilentFail.bind(this);
        this._signIn = this._signIn.bind(this);
        this._signOut = this._signOut.bind(this);
        this._onMenuItemClick = this._onMenuItemClick.bind(this);
        this._onChangeCurrency = this._onChangeCurrency.bind(this);
        this._onChangeTemperatureUnits = this._onChangeTemperatureUnits.bind(this);
        this._onChangeSpeedUnits = this._onChangeSpeedUnits.bind(this);
        this._onChangeView = this._onChangeView.bind(this);
        this._onPin = this._onPin.bind(this);
        this._onResize = this._onResize.bind(this);
        this._resetInactivityTimeout = this._resetInactivityTimeout.bind(this);

        // Sign out after a period of inactivity
        this._resetInactivityTimeout();
        window.document.onclick = this._resetInactivityTimeout;
    }

    static get MenuWidthThreshold() {
        return 1030;
    }

    static get currentView() {
        const HashPattern = /[#/]*([\w\d-]+)/;

        let array = HashPattern.exec(window.location.hash);
        return (array != null && array.length > 1) ?
            array[1] :
            '';
    }

    get _isInPopupOrIFrame() {
        try {
            // iFrame tests
            return (window.self !== window.top) || 
                (window.location !== window.parent.location) ||
                // Popup tests
                (window.opener != null && window.opener !== window);
        } catch (err) {
            return true;
        }
    }

    get _faultActionButtons() {
        let actionButtons = {};
        actionButtons[ErrorState.faultCategories.Default] = [
            {
                key: 'fault-refresh',
                variant: 'discrete',
                text: t('fault.buttonRefresh'),
                handleClick: () => this._onFaultRefresh(),
            },
            {
                key: 'fault-ok',
                variant: 'primary',
                text: t('fault.buttonOkay'),
                handleClick: () => this._onFaultOK(),
            },
        ];
        actionButtons[ErrorState.faultCategories.Fatal] = [
            {
                key: 'fault-refresh',
                variant: 'primary',
                text: t('fault.buttonRefresh'),
                handleClick: () => this._onFaultRefresh(),
            },
        ];
        actionButtons[ErrorState.faultCategories.SignIn] = [
            {
                key: 'fault-signout',
                variant: 'primary',
                text: t('header.signOut'),
                handleClick: () => this._signOut(),
            },
        ];
        actionButtons[ErrorState.faultCategories.PopupBlocked] = [
            {
                key: 'fault-refresh',
                variant: 'primary',
                text: t('fault.buttonRefresh'),
                handleClick: () => this._onFaultRefresh(),
            },
        ];

        return actionButtons;
    }

    _resetInactivityTimeout() {
        const MaxIdleSeconds = 2 * 60 * 60;

        clearTimeout(this._inactivityTimeout);
        this._inactivityTimeout = setTimeout(this._signOut, MaxIdleSeconds * 1000);
    }

    _getFaultMessaging(fault) {
        let messaging = 
            (ErrorState.faultCategory === ErrorState.faultCategories.SignIn) ?
                (
                    <>
                        <p>{t('fault.signInFailed')}</p>
                        <p>{t('fault.signInInstructions')}</p>
                    </>
                ) :
            (ErrorState.faultCategory === ErrorState.faultCategories.PopupBlocked) ?
                (
                    <>
                        <p>{t('fault.popupBlocked')}</p>
                        <p>{t('fault.popupInstructions')}</p>
                    </>
                ) :        
                (
                    <>
                        <p>{t('fault.contentOverview')}</p>
                        <p style={{ textIndent: '2.0em' }}>
                            <i>&quot;{fault}&quot;</i>
                        </p>
                        <p>{t('fault.contentInstructions')}</p>
                    </>
                );

        return messaging;
    }

    _getMarginRight(currentView) {
        return (this.state.pinning.hasOwnProperty(currentView) && this.state.pinning[currentView].isPinned) ?
            this.state.pinning[currentView].width :
            0;
    }

    _signInWithSilentFail() {
        this._signIn()
            .then((idTokenClaims) => {
                AppTrace.traceInfo(App.currentView, AppTrace.categories.navigate);
                this.forceUpdate();
            })
            .catch((err) => {
                console.error('Error in App._signInWithSilentFail: ' + err.message);
                if (err.errorCode != null && err.errorCode === "popup_window_error") {
                    ErrorState.setFault(t('signIn.popupBlocked'), ErrorState.faultCategories.PopupBlocked);
                }
                else {
                    ErrorState.setFault(t('signIn.loginFailed'), ErrorState.faultCategories.SignIn);
                }
            });
    }

    _signIn() {
        return new Promise((resolve, reject) => {
            Authentication.signIn()
                .then((idTokenClaims) => {
                    AppTrace.traceInfo("Successfully authenticated", AppTrace.categories.authenticate);

                    this.setState({
                        idTokenClaims: idTokenClaims,
                    });

                    resolve(idTokenClaims);
                })
                .catch((error) => {
                    console.error('Error in App._signIn: ' + error.message);
                    reject(error);
                });
        });
    }

    _signOut() {
        // Trace before we sign out since the API call is authenticated
        AppTrace.traceInfo("Signing out...", AppTrace.categories.signOut);

        Authentication.signOut().then(() => {
            this.setState({
                idTokenClaims: null,
            });
        });
    }

    _onMenuItemClick(itemKey) {
        if (itemKey === 'sign-in') {
            this._signInWithSilentFail();
        } else if (itemKey === 'sign-out') {
            this._signOut();
        } else if (itemKey === 'locale-en') {
            Locale.localeName = 'en';
            this.setState({ localeName: Locale.localeName });
        } else if (itemKey === 'locale-ja') {
            Locale.localeName = 'ja';
            this.setState({ localeName: Locale.localeName });
        }
    }

    _onChangeCurrency(value) {
        if (Locale.isCurrencyNameValid(value)) {
            Locale.currencyName = value;
            this.setState({ currencyName: value });
        }
    }

    _onChangeTemperatureUnits(value) {
        Locale.temperatureUnits = value;
        this.setState({ temperatureUnits: value });
    }

    _onChangeSpeedUnits(value) {
        Locale.speedUnits = value;
        this.setState({ speedUnits: value });
    }

    _onChangeView(key, routerProps) {
        routerProps.history.push({ pathname: '/' + key });
        AppTrace.traceInfo(key, AppTrace.categories.navigate);
    }

    _onFaultRefresh() {
        window.location.reload(true);
    }

    _onFaultOK() {
        ErrorState.clearFault();
    }

    _onPin(args) {
        if (args.hasOwnProperty('isPinned') && args.hasOwnProperty('width') && args.hasOwnProperty('viewKey')) {
            // Merge in new pinning state
            let pinningInfo = {};
            pinningInfo[args.viewKey] = args;
            let pinning = Object.assign(this.state.pinning, pinningInfo);

            // Clean up pinning info for a view that's not pinned
            if (!args.isPinned) {
                delete pinning[args.viewKey];
            }

            this.setState({
                pinning: pinning,
            });
        }
    }

    _onResize() {
        if (window.innerWidth >= App.MenuWidthThreshold && !this.state.isRegularMenu) {
            this.setState({ isRegularMenu: true });
        }
        else if (window.innerWidth < App.MenuWidthThreshold && this.state.isRegularMenu) {
            this.setState({ isRegularMenu: false });
        }
    }



    componentDidMount() {
        // Watch for resize events
        window.addEventListener('resize', this._onResize);

        if (this._isInPopupOrIFrame === false) {
            this._signInWithSilentFail();
        }
    }

    componentDidUpdate() {
        // Persist current state
        SessionState.set(this.sessionStateKey, this.state);
    }

    componentWillUnmount() {
        // Clean up resize listener
        window.removeEventListener('resize', this._onResize);
    }

    // Renders app-wide fault information
    _renderFault() {
        if (this.state.isFaulted && ErrorState.isFaulted) {
            // Get application name and error caption in English for email to English-speaking support
            const applicationName = t('fault.applicationName', 'en');
            const subject = encodeURIComponent(
                applicationName + ' ' + t('fault.caption', 'en')
            );

            // Build email content
            let now = new Date();
            let fault = ErrorState.fault;
            let faultBody =
                encodeURIComponent(AppConfig.support.name + ',') +
                '%0d%0a%0d%0a' +
                encodeURIComponent(
                    'The following error occurred on ' +
                        now.toLocaleDateString() +
                        ' at ' +
                        now.toLocaleTimeString() +
                        ' while using "' +
                        applicationName +
                        '":'
                ) +
                '%0d%0a%0d%0a' +
                encodeURIComponent(fault);

            let messaging = this._getFaultMessaging(fault);
            let content = (
                <div style={{ whiteSpace: 'normal' }}>
                    {messaging}
                    <span><b>{t('fault.contentSupport')}</b></span>
                    <br />
                    <a
                        href={
                            'mailto:' +
                            AppConfig.support.email +
                            '?subject=' +
                            subject +
                            '&body=' +
                            faultBody
                        }
                    >
                        {AppConfig.support.email}
                    </a>
                    <br />
                    <span>{AppConfig.support.phone}</span>
                </div>
            );

            const actionButtons = this._faultActionButtons;

            return (<Dialog
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    open={true}
                    // onClose={() => setIsOpen(false)}
                    title={t('fault.caption')}
                    showCloseButton={false}
                    disablePortal={true}
                    actionButtons={actionButtons[ErrorState.faultCategory]}
                >
                    {content}
                </Dialog>
            );
        } else {
            return null;
        }
    }
    
    // Renders the app-wide load indicator
    _renderLoadIndicator() {
        let open = !this.state.isFaulted && this.state.isLoadingData;

        return (
            <MUIDialog disableBackdropClick={true} disableEscapeKeyDown={true} open={open}>
                <div className="load-indicator-content">
                    <LoadingIndicator />
                    <span>{t('loadIndicator.caption')}</span>
                </div>
            </MUIDialog>
        );
    }

    _renderTabs(currentView, routeProps) {
        return (
            <ErrorBoundary>
                <TabContainer
                    className="app-tabs-border"
                    layoutVariant="scrollable"
                    variant="primary"
                    value={currentView}
                    onChange={(event, value) => {
                        this._onChangeView(value, routeProps);
                    }}
                >
                    <Tab label={t('tabs.spatialAwareness')} value={SpatialAwareness.ViewKey} />
                    <Tab label={t('tabs.supplyAndDemand')} value={SupplyAndDemand.ViewKey} />
                    <Tab label={t('tabs.priceHistory')} value={PriceHistory.ViewKey} />
                    <Tab label={t('tabs.priceForecast')} value={PriceForecast.ViewKey} />
                    <Tab label={t('tabs.companyFinancials')} value={CompanyFinancials.ViewKey} />
                    <Tab label={t('tabs.weather')} value={Weather.ViewKey} />
                </TabContainer>

                <div className="app-tab-contents">
                    {Authentication.isSignedIn && (
                        <>
                            <TabPanel valuekey={SpatialAwareness.ViewKey} value={currentView}>
                                <SpatialAwareness 
                                    onPin={this._onPin}
                                />
                            </TabPanel>
                            <TabPanel valuekey={SupplyAndDemand.ViewKey} value={currentView}>
                                <SupplyAndDemand
                                    onPin={this._onPin}
                                />
                            </TabPanel>
                            <TabPanel valuekey={PriceHistory.ViewKey} value={currentView}>
                                <PriceHistory
                                    onPin={this._onPin}
                                />
                            </TabPanel>
                            <TabPanel valuekey={PriceForecast.ViewKey} value={currentView}>
                                <PriceForecast 
                                    onPin={this._onPin}
                                />
                            </TabPanel>
                            <TabPanel valuekey={CompanyFinancials.ViewKey} value={currentView}>
                                <CompanyFinancials
                                    onPin={this._onPin}
                                />
                            </TabPanel>
                            <TabPanel valuekey={Weather.ViewKey} value={currentView}>
                                <Weather
                                    onPin={this._onPin}
                                />
                            </TabPanel>
                        </>
                    )}
                </div>
            </ErrorBoundary>
        );
    }

    _renderHeaderControls() {
        return (this.state.isRegularMenu) ?
            (
                <AppMenuWide
                    idTokenClaims={this.state.idTokenClaims}
                    downloads={App._downloads[App.currentView]}
                    localeName={this.state.localeName}
                    currencyName={this.state.currencyName}
                    temperatureUnits={this.state.temperatureUnits}
                    speedUnits={this.state.speedUnits}
                    onSignOut={this._signOut}
                    onSignIn={this._signInWithSilentFail}
                    onChangeLocale={this._onMenuItemClick}
                    onChangeCurrency={this._onChangeCurrency}
                    onChangeTemperatureUnits={this._onChangeTemperatureUnits}
                    onChangeSpeedUnits={this._onChangeSpeedUnits}
                />
            ) :
            (
                <AppMenuNarrow
                    idTokenClaims={this.state.idTokenClaims}
                    downloads={App._downloads[App.currentView]}
                    localeName={this.state.localeName}
                    currencyName={this.state.currencyName}
                    temperatureUnits={this.state.temperatureUnits}
                    speedUnits={this.state.speedUnits}
                    onSignOut={this._signOut}
                    onSignIn={this._signInWithSilentFail}
                    onChangeLocale={this._onMenuItemClick}
                    onChangeCurrency={this._onChangeCurrency}
                    onChangeTemperatureUnits={this._onChangeTemperatureUnits}
                    onChangeSpeedUnits={this._onChangeSpeedUnits}
                />
            );
    }

    _renderAppLicensing() {
        return this.state.isAppLicensingVisible && (
            <SlidePanel
                className="app-licensing-drawer"
                anchor="right"
                open={true}
                title={t('licensing.caption')}
                variant="temporary"
                onClose={() => this.setState({ isAppLicensingVisible: false })}
            >
                <AppLicensing 
                    company={AppConfig.company}
                />
            </SlidePanel>
        );
    }

    render() {
        // All available views
        const views = [
            SpatialAwareness.ViewKey, 
            SupplyAndDemand.ViewKey, 
            PriceHistory.ViewKey, 
            PriceForecast.ViewKey,
            CompanyFinancials.ViewKey,
            Weather.ViewKey,
        ];

        // Don't render the UI in an iFrame
        if (this._isInPopupOrIFrame) {
            return null;
        }

        return (
            <Router>
                <Switch>
                    <Route
                        path={'/:view(' + views.join('|') + ')'}
                        render={(routeProps) => {
                            let currentView = routeProps.match.params.view;

                            return (
                                <div className="de-app" style={{ marginRight: this._getMarginRight(currentView) }}>
                                    {this._renderFault()}
                                    {this._renderLoadIndicator()}
                                    {this._renderAppLicensing()}
                    
                                    {/* App header/stripe */}
                                    <JEMIStripe />
                
                                    {/* Tabs */}
                                    {this._renderTabs(currentView, routeProps)}
                
                                    {/* Render header controls: identity, locale, currency */}
                                    {this._renderHeaderControls()}
                
                                    {/* App footer */}
                                    <div className="app-footer">
                                        <span className="app-analysis">{t('footer.analysisDerivedFrom')}</span>
                                        <span 
                                            className="app-attribution de-link-button"
                                            onClick={() => this.setState({ isAppLicensingVisible: true })}
                                        >
                                            &#169;{' Copyright ' + new Date().getFullYear() + ' ' + AppConfig.company.name}
                                        </span>
                                    </div>
                                </div>
                            );
                        }}
                    />

                    <Route path="*">
                        <Redirect to={'/' + SpatialAwareness.ViewKey} />
                    </Route>
                </Switch>
            </Router>
        );
    }
}

App._downloads = {};
App._downloads[SpatialAwareness.ViewKey] = (SpatialAwareness.Downloads != null) ? SpatialAwareness.Downloads : [];
App._downloads[SupplyAndDemand.ViewKey] = (SupplyAndDemand.Downloads != null) ? SupplyAndDemand.Downloads : [];
App._downloads[PriceHistory.ViewKey] = (PriceHistory.Downloads != null) ? PriceHistory.Downloads : [];
App._downloads[PriceForecast.ViewKey] = (PriceForecast.Downloads != null) ? PriceForecast.Downloads : [];
App._downloads[CompanyFinancials.ViewKey] = (CompanyFinancials.Downloads != null) ? CompanyFinancials.Downloads : [];
App._downloads[Weather.ViewKey] = (Weather.Downloads != null) ? Weather.Downloads : [];

export default App;
