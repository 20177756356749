import AppConfig from 'AppConfig';
import AppTrace from 'AppTrace';
import Authentication from 'Authentication';
import BusyState from 'BusyState';
import ErrorState from 'ErrorState';
import Locale from 'locale/Locale';

const axios = require('axios');

class CSVDownload {
    get(route, fileName) {
        // Set busy state
        let isBusy = BusyState.isBusy;
        BusyState.isBusy = true;

        // Get access token
        Authentication.getAccessToken()
            // Get API data
            .then(accessToken => {
                return this._downloadCSV(accessToken, route, fileName);
            })
            .finally(() => {
                BusyState.isBusy = isBusy;
            });
    }

    _downloadCSV(accessToken, route, fileName) {
        // Trace the download request
        AppTrace.traceInfo(route, AppTrace.categories.download);

        // Build authorization header and request download
        let authString = 'Bearer '.concat(accessToken);
        let url = AppConfig.dataService.baseUrl + route;

        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                    headers: {
                        Authorization: authString,
                        Accept: "application/json",
                        "cache-control": "no-cache",
                        "accept-language": Locale.localeName,
                    },
                    responseType: 'blob', // Gets data as a blob for which we can create a dummy URL below
                })
                .then((response) => {
                    // Support IE10+
                    if (response.data != null && navigator.msSaveBlob) {
                        navigator.msSaveBlob(
                            new Blob([response.data]),
                            fileName
                        );
                    }
                    // All other modern browsers...
                    else {
                        // Save the downloaded content locally
                        var a = document.createElement('a');
                        var dummyUrl = window.URL.createObjectURL(
                            response.data
                        );
                        a.href = dummyUrl;
                        a.download = fileName;
                        a.click();
                        window.URL.revokeObjectURL(dummyUrl);
                        a.remove();
                    }

                    // Done loading
                    resolve(true);
                })
                .catch((error) => {
                    AppTrace.traceError('CSVDownload._downloadCSV[' + fileName + ']: ' + error);
                    ErrorState.setFault('Unable to download "' + fileName + '"');
                    resolve(false);
                });
        });
    }
}

const _instance = new CSVDownload();
Object.seal(_instance);

export default _instance;
