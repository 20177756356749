import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import { ResponsiveContainer, ComposedChart, Area, XAxis, YAxis, Legend, Tooltip, Label } from 'recharts';

import ErrorBoundary from 'ErrorBoundary';
import Locale from 'locale/Locale';
import Palettes from 'common/Palettes';
import SessionState from 'SessionState';

import 'common/MuiSlider.scss';
import 'common/ReCharts.scss';
import './WeatherChart.scss';

const t = Locale.getResourceString.bind(Locale);

/////////////////////////////////////////////////////////////////////////////
// This class encapsulates the sunshine duration chart shown on the weather
// tab.
/////////////////////////////////////////////////////////////////////////////
class SunshineDurationChart extends Component {
    constructor(props, context) {
        super(props);

        // Set a maximum number of chart points/entries of 336, which is 1 week of hourly data
        SunshineDurationChart._MaxChartEntries = 168;

        // Configure initial state
        this._sessionStateKey = 'emi-japan.weather.SunshineDurationChart';
        this._defaultInitialState = {
            sliderValue: Number.MAX_SAFE_INTEGER,
        }
        this._stateOverrides = {
        }
        this.state = SessionState.get(this._sessionStateKey, this._defaultInitialState, this._stateOverrides);

        // Bind event handlers
        this._onTooltipFormatLabel = this._onTooltipFormatLabel.bind(this);
        this._onFormatValue = this._onFormatValue.bind(this);
        this._onTooltipFormatValue = this._onTooltipFormatValue.bind(this);
        this._onChangeSlider = this._onChangeSlider.bind(this);
    }

    get _series() {
        const palette = Palettes.DefaultColors;
        let series = [];        

        if (this.props.data != null) {
            series = this.props.seriesKeys.map((s, index) => {
                let color = (this.props.seriesKeys.length === 1) ? 
                    Palettes.FuelColors.solar :
                    palette[index % palette.length];
                return (
                    <Area
                        key={s} 
                        dataKey={s} 
                        dot={false}
                        isAnimationActive={false}
                        legendType="rect"
                        name={this.props.seriesNames[index]} 
                        stroke={color} 
                        strokeWidth={1}
                        fill={color}
                        type='linear'
                    />
                );
            });
        }

        return series;
    }

    get _dataBounds() {
        let extraEntries = this.props.aggregated.length - SunshineDurationChart._MaxChartEntries;
        let max = Math.max(0, extraEntries);

        return {
            min: 0,
            max: max,
            sliderValue: Math.min(this.state.sliderValue, max)
        };
    }

    _getData(dataBounds) {
        // Clean up previous data
        if (this.__data != null) {
            this.__data.length = 0;
        }

        // Take a subset if needed
        this.__data = this.props.aggregated.slice(dataBounds.sliderValue, dataBounds.sliderValue + SunshineDurationChart._MaxChartEntries);
        
        return this.__data;
    }

    get _yAxisLabelText() {
        let hasUnits = (this.props.aggregation === 'stdev' || this.props.aggregation === 'count');
        let units = hasUnits ? '' : ` ${t(`units.percent`)}`;
        let labelKey = `weather.charts.sunshineDuration.axisLabels.${this.props.aggregation}`;
        return `${t(labelKey)} ${units}`;
    }



    _onChangeSlider(event, value) {
        this.setState({
            sliderValue: value,
        })
    }

    _onTooltipFormatLabel(value) {
        return (<span>{value}</span>);
    }

    _onFormatValue(value, name, props) {
        return (this.props.aggregation === 'stdev') ? Locale.formatNumber(value, 2) :
            (this.props.aggregation === 'count') ? Locale.formatNumber(value, 0) :
            Locale.formatPercent(value, 0);
    }

    _onTooltipFormatValue(value, name, props) {
        return (this.props.aggregation === 'stdev') ? Locale.formatNumber(value, 2) :
            (this.props.aggregation === 'count') ? Locale.formatNumber(value, 0) :
            Locale.formatPercent(value, 0);
    }



    componentDidUpdate() {
        // Persist current state
        SessionState.set(this._sessionStateKey, this.state);
    }

    render() {
        // Get bounds of the chart data
        let dataBounds = this._dataBounds;

        // Get chart margins and JSX for slider as needed
        let chartMarginBottom = (dataBounds.max > 0) ? 30 : 10;
        let sliderJSX = null;
        if (dataBounds.max > 0) {
            let sliderPaddingRight = 20;
            sliderJSX = (
                <div className="weather-range-slider" style={{ paddingRight: sliderPaddingRight }}>
                    <Slider
                        min={dataBounds.min}
                        max={dataBounds.max}
                        value={dataBounds.sliderValue}
                        onChange={this._onChangeSlider}
                    />
                </div>
            );
        }

        return (
            <ErrorBoundary>
                <ResponsiveContainer>
                    <ComposedChart
                        data={this._getData(dataBounds)}
                        margin={{
                            top: 60, right: 20, bottom: chartMarginBottom, left: 10,
                        }}
                    >
                        <XAxis 
                            dataKey="category" 
                        />
                        <YAxis 
                            tickFormatter={ (v) => this._onFormatValue(v) }
                            width={90}
                            yAxisId={0}>
                            <Label 
                                angle={-90} 
                                position='insideLeft'
                                value={this._yAxisLabelText}
                                style={{textAnchor: 'middle'}}
                            />
                        </YAxis>
                        <Tooltip 
                            isAnimationActive={false} 
                            labelFormatter={this._onTooltipFormatLabel}
                            formatter={this._onTooltipFormatValue} 
                        />
                        <Legend />

                        {this._series}
                    </ComposedChart>
                </ResponsiveContainer>

                {sliderJSX}
            </ErrorBoundary>
        );
    }
}

SunshineDurationChart.propTypes = {
    aggregation: PropTypes.string,
    aggregated: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.object,
    seriesKeys: PropTypes.arrayOf(PropTypes.number),
    seriesNames: PropTypes.arrayOf(PropTypes.string),
}

SunshineDurationChart.defaultProps = {
    aggregation: 'average',
    aggregated: [],
    seriesKeys: [],
    seriesNames: [],
}

export default SunshineDurationChart;
