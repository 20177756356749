import React from 'react';

import Locale from 'locale/Locale';
import StatementOfIncomeChart from './StatementOfIncomeChart';

import './StatementOfIncomeCharts.scss';

const t = Locale.getResourceString.bind(Locale);

////////////////////////////////////////////////////////////////////////////////
// This class renders a collection of statement-of-income charts based
// on user selections.
////////////////////////////////////////////////////////////////////////////////
class StatementOfIncomeCharts extends React.Component {
    constructor(props, context) {
        super(props);
    }

    render() {
        return (
            <div className="company-financials-chart-container">
                <div className="company-financials-chart-title">
                    {this.props.title}<br/>
                    {t('units.millionsOf_' + Locale.currencyName)}
                </div>
                <div className="statement-of-income-charts">
                    <div className="statement-of-income-charts-inner">
                        {(this.props.data != null && this.props.chartData.length > 0) &&
                            <>
                                {/* 
                                    Render 1 chart whose purpose is to display the financial
                                    attributes as Y-axis labels.  I'm using a chart because that
                                    helps keep the labels aligned with the bars in the chart.
                                    Also, note that the extra 2 pixels of width is to account for
                                    the 1px border that surrounds the chart.
                                */}
                                <StatementOfIncomeChart
                                    chartBy={this.props.chartBy}
                                    chartData={this.props.chartData[0]}
                                    data={this.props.data}
                                    yAxisWidth={240}
                                    width={242}
                                    formatChartTitle={(v) => (<span>&nbsp;</span>)}
                                />

                                {
                                    // The rest of the charts are rendered 1-per-element
                                    // in the chartData.  The width was chosen as the minimum
                                    // width that would easily accomodate the tooltips.
                                    this.props.chartData.map((group, index) => (
                                        <StatementOfIncomeChart
                                            key={"chart_" + index}
                                            chartBy={this.props.chartBy}
                                            chartData={group}
                                            data={this.props.data}
                                            yAxisWidth={0}
                                            width={250}
                                            formatChartTitle={this.props.formatChartTitle}
                                        />
                                    ))
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default StatementOfIncomeCharts;
