import React from 'react';
import moment from 'moment';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import ClearAllIcon from '@material-ui/icons/ClearAll';
import ForwardIcon from '@material-ui/icons/Forward';
import HistoryIcon from '@material-ui/icons/History';
import SelectAllIcon from '@material-ui/icons/SelectAll';

import Filter from 'webcore-ux/react/components/Icons/Filter';
import { Button, Checkbox, Dropdown } from 'webcore-ux/react/components';

import BusyState from '../BusyState';
import ErrorBoundary from 'ErrorBoundary';
import ErrorState from 'ErrorState';
import GlobalSessionState from 'GlobalSessionState';
import CSVDownloadItem from 'CSVDownloadItem';
import Locale from '../locale/Locale';
import SessionState from 'SessionState';
import Utils from 'Utils';

import DateRangePicker from 'common/DateRangePicker';
import HourPicker from 'common/HourPicker';
import MonthPicker from 'common/MonthPicker';
import MultiSelectDropdown from 'common/MultiSelectDropdown';
import PinnableSettings from 'common/PinnableSettings';
import TabPanel from 'common/TabPanel';
import VerticalTabNav from 'common/VerticalTabNav';
import VisualBox from 'common/VisualBox';
import YearPicker from 'common/YearPicker';

import VintagesDataFactory from 'common/VintagesDataFactory';
import SupplyAndDemandDataFactory from './data/SupplyAndDemandDataFactory';
import SupplyAndDemandStats from './SupplyAndDemandStats';
import SupplyAndDemandChart from './SupplyAndDemandChart';

import HistoricSupplyAndDemandDataFactory from './data/HistoricSupplyAndDemandDataFactory';
import HistoricSupplyAndDemandStats from './HistoricSupplyAndDemandStats';
import HistoricGenerationByFuel from './HistoricGenerationByFuel';
import HistoricDemandByTerritory from './HistoricDemandByTerritory';

import './SupplyAndDemand.scss';
import Palettes from 'common/Palettes';
import AppConfig from 'AppConfig';

const t = Locale.getResourceString.bind(Locale);

class SupplyAndDemand extends React.Component {
    static GENERATION_BY_FUEL_TYPE = 'generationByFuelType';
    static DEMAND_BY_TERRITORY = 'demandByTerritory';

    static get DownloadMinJSTime() {
        let now = moment.utc();
        return moment
            .utc([now.year(), now.month(), now.date()])
            .subtract(AppConfig.historicSupplyAndDemand.downloadMonthsBack, 'months');
    }

    static get ViewKey() {
        return 'supply-and-demand';
    }

    static get Downloads() {
        return [
            // Reference case downloads
            new CSVDownloadItem('supplyAndDemand.filters.chartBy.refCaseBaseline', '/supply-and-demand/ref-case-baseline/download', 'ref-case-baseline.csv'),
            new CSVDownloadItem('supplyAndDemand.filters.chartBy.refCaseHighFuelPrice', '/supply-and-demand/ref-case-high-fuel-price/download', 'ref-case-high-fuel-price.csv'),
            new CSVDownloadItem('supplyAndDemand.filters.chartBy.refCaseRenewableGenerationTargets', '/supply-and-demand/ref-case-renewable-generation-targets/download', 'ref-case-renewable-generation-targets.csv'),
            new CSVDownloadItem('supplyAndDemand.filters.chartBy.refCaseFullNuclearRestart', '/supply-and-demand/ref-case-full-nuclear-restart/download', 'ref-case-full-nuclear-restart.csv'),

            // New entrants downloads
            new CSVDownloadItem('supplyAndDemand.filters.chartBy.neByFuel', '/supply-and-demand/new-entrants-fuel/download', 'new-entrants-fuel.csv'),
            new CSVDownloadItem('supplyAndDemand.filters.chartBy.neByPrimeMover', '/supply-and-demand/new-entrants-prime-mover/download', 'new-entrants-prime-mover.csv'),
            new CSVDownloadItem('supplyAndDemand.filters.chartBy.neByStatus', '/supply-and-demand/new-entrants-status/download', 'new-entrants-status.csv'),
            
            // We don't know our range of available data yet.  Just take the configured number of months back from now.
            new CSVDownloadItem('supplyAndDemand.tabs.historic.caption', `/historic-supply-and-demand/download?minJSTime=${SupplyAndDemand.DownloadMinJSTime.valueOf()}`, 'historic-supply-and-demand.csv'),
        ]
    };

    constructor(props, context) {
        super(props);

        // Data
        this._vintages = null;
        
        // Bind event handlers
        this._oni18nChanged = this._oni18nChanged.bind(this);
        this._onPin = this._onPin.bind(this);
        this._onGotData = this._onGotData.bind(this);
        this._onGotForwardData = this._onGotForwardData.bind(this);
        this._onGotHistoricData = this._onGotHistoricData.bind(this);
        this._calculateStats = this._calculateStats.bind(this);
        this._calculateForwardStats = this._calculateForwardStats.bind(this);
        this._calculateHistoricStats = this._calculateHistoricStats.bind(this);
        this._renderFilters = this._renderFilters.bind(this);
        this._renderForwardFilters = this._renderForwardFilters.bind(this);
        this._renderHistoricFilters = this._renderHistoricFilters.bind(this);

        this._now = DateRangePicker.getDate(moment());
        this._availableDataRange = null;
        this._chartKey = this._chartKey ?? 'forward';  // Manage this outside of state to avoid timing issues
        this.__chartSettings = {
            forward: {
                dataFactory: new SupplyAndDemandDataFactory(),
                stats: new SupplyAndDemandStats(),
                data: null,
                filters: () => {
                    return {
                        dataSource: this._forwardDataSource,
                        supplyAndDemandServiceTerritories: GlobalSessionState.get().supplyAndDemandServiceTerritories,
                        selectedYears: this.state.selectedForwardYears,
                    };
                },
                palette: () => {
                    return (this._forwardDataSource === 'neByPrimeMover') ? Palettes.PrimeMoverColors :
                        (this._forwardDataSource === 'neByStatus') ? Palettes.OperatingStatusColors :
                        Palettes.FuelColors;
                },
                onGotData: this._onGotForwardData,
                calculateStats: this._calculateForwardStats,
                renderFilters: this._renderForwardFilters,
            },
            historic: {
                dataFactory: new HistoricSupplyAndDemandDataFactory(),
                stats: new HistoricSupplyAndDemandStats(),
                data: null,
                filters: () => {
                    return {
                        localeName: Locale.localeName,  // This will trigger new data on locale changes
                        supplyAndDemandServiceTerritories: GlobalSessionState.get().supplyAndDemandServiceTerritories,
                        granularity: this.state.granularity,
                        aggregation: this.state.aggregation,
                        start: this.state.filterStart,
                        end: this.state.filterEnd,
                        selectedHours: this.state.selectedHours,
                        selectedMonths: this.state.selectedMonths,
                        selectedYears: this.state.selectedYears,
                    };
                },
                palette: () => {
                    return Palettes.FuelColors;
                },
                onGotData: this._onGotHistoricData,
                calculateStats: this._calculateHistoricStats,
                renderFilters: this._renderHistoricFilters,
            },
        }

        // Configure initial state
        this._sessionStateKey = 'emi-japan.supply-and-demand.SupplyAndDemand';
        if (this._forwardDataSource == null) {
            this._forwardDataSource = 'refCaseBaseline';
        }

        this._defaultInitialState = {
            // Shared state
            isNavExpanded: true,

            // Forward chart state
            showPeakLoad: true,
            showReserveMargin: true,

            // Historic chart state
            historicChartType: SupplyAndDemand.GENERATION_BY_FUEL_TYPE,
            showTotalDemand: true,
            granularity: 'D',
            aggregation: 'average',
            selectedHours: [...Array(24).keys()],
            selectedMonths: [...Array(12).keys()].map(k => k + 1),
        }
        this._stateOverrides = {
            // Shared state
            filterPanelOpen: false,
            filterPanelIsPinned: false,

            // Forward chart state
            selectedForwardYears: [],

            // Historic chart state
            selectedFuels: [],
            start: this._now,
            end: this._now,
            filterStart: this._now,
            filterEnd: this._now,
            selectedYears: [],
        }
        this.state = SessionState.get(this._sessionStateKey, this._defaultInitialState, this._stateOverrides);
    }

    static get AllDataSources() {
        const allDataSources = [
            'refCaseBaseline', 
            'refCaseHighFuelPrice', 
            'refCaseRenewableGenerationTargets', 
            'refCaseFullNuclearRestart', 
            'neByFuel', 
            'neByPrimeMover', 
            'neByStatus'
        ];
        return allDataSources;
    }

    // Gets all refcase-based data sources as a mapping from source key to scenario ID
    static get RefcaseDataSources() {
        const dataSources = {
            'refCaseBaseline' : 1,
            'refCaseHighFuelPrice' : 2, 
            'refCaseRenewableGenerationTargets' : 3,
            'refCaseFullNuclearRestart' : 4, 
        };
        return dataSources;
    }
    
    static ViewDetails(utilityServiceTerritory) {
        let currentTerritoryIds = GlobalSessionState.get().supplyAndDemandServiceTerritories;
        let newId = utilityServiceTerritory.entityId;

        // If we currently have no selected territories, or just 1 then set the
        // selection to the one just clicked
        if (currentTerritoryIds.length <= 1) {
            GlobalSessionState.set({ 
                supplyAndDemandServiceTerritories: [newId],
                supplyAndDemandDataSource: 'refCaseBaseline',
            });
        }
        // Otherwise we have multiple selected values already.  Make sure
        // the territory that was just selected is represented.
        else if (currentTerritoryIds.indexOf(newId) < 0) {
            currentTerritoryIds.push(newId);
            GlobalSessionState.set({
                supplyAndDemandServiceTerritories: currentTerritoryIds,
                supplyAndDemandDataSource: 'refCaseBaseline',
            });
        }
    }

    get _chartKey() {
        return GlobalSessionState.get().supplyAndDemandChartKey;
    }
    set _chartKey(value) {
        GlobalSessionState.set({ 
            supplyAndDemandChartKey: value,
        });
    }

    get _chartSettings() {
        return this.__chartSettings[this._chartKey];
    }

    get _dataFactory() {
        return this._chartSettings.dataFactory;
    }
    set _dataFactory(value) {
        this._chartSettings.dataFactory = value;
    }

    get _data() {
        return this._chartSettings.data;
    }
    set _data(value) {
        this._chartSettings.data = value;
    }

    get _stats() {
        return this._chartSettings.stats;
    }
    set _stats(value) {
        this._chartSettings.stats = value;
    }
    
    get _filters() {
        return this._chartSettings.filters();
    }

    get _chartPalette() {
        return this._chartSettings.palette();
    }

    _onGotData(data) {
        return this._chartSettings.onGotData(data);
    }

    _calculateStats() {
        return this._chartSettings.calculateStats();
    }

    _renderFilters() {
        return this._chartSettings.renderFilters();
    }

    get _ustsOptions() {
        let serviceTerritoryOptions = [];
        if (this._data != null) {
            let idIndex = this._data.usts.metadata.columns.id.index;
            let nameIndex = this._data.usts.metadata.columns.name.index;
            serviceTerritoryOptions = this._data.usts.rows.map(row => { 
                return {
                    value: row[idIndex].toString(), 
                    label: Utils.getShortServiceTerritoryName(Locale.getJSONFieldValue(row[nameIndex]))
                } 
            });
        }
        return serviceTerritoryOptions;
    }

    get _fuels() {
        let fuels = this._data?.fuels ?? [];
        fuels = fuels.slice();
        fuels.sort((a, b) => Locale.getJSONFieldValue(a.fuelName).localeCompare(Locale.getJSONFieldValue(b.fuelName)));
        return fuels;
    }

    get _fuelsOptions() {
        let options = [];
        let fuels = this._fuels;
        options = fuels.map(f => { 
            return {
                value: f.fuelCode, 
                label: Locale.getJSONFieldValue(f.fuelName)
            } 
        });
        return options;
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gets selected territories (shared between forward and historic)
    ////////////////////////////////////////////////////////////////////////////
    get _selectedServiceTerritoryNames() {
        let names = [];
        if (this._data != null) {
            names = GlobalSessionState.get().supplyAndDemandServiceTerritories.map(id => 
                Utils.getShortServiceTerritoryName(Locale.getJSONFieldValue(this._data.ustsLookup[id]))
            );
        }

        return names.join(t('general.listSeparator'));
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gets or sets the data source for the forward supply and demand chart
    ////////////////////////////////////////////////////////////////////////////
    get _forwardDataSource() {
        return GlobalSessionState.get().supplyAndDemandDataSource;
    }
    set _forwardDataSource(value) {
        GlobalSessionState.set({
            supplyAndDemandDataSource: value,
        });
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gets or sets the available years for the forward chart
    ////////////////////////////////////////////////////////////////////////////
    get _forwardYears() {
        return (this.__forwardYears != null) ? this.__forwardYears : [];
    }
    set _forwardYears(value) {
        this.__forwardYears = value;
    }

    _onGotForwardData(data) {
        // Save the forward data
        this._data = data;

        // Derive all years
        let allYears = {};
        SupplyAndDemand.AllDataSources.forEach(key => {
            let ds = this._data[key];
            let yearIndex = ds.metadata.columns.year.index;
            for (let i = 0; i < ds.rows.length; ++i) {
                allYears[ds.rows[i][yearIndex]] = true;
            }
        });
        let temp = Object.keys(allYears).map(y => parseInt(y, 10));
        temp.sort((a, b) => { return a - b });
        this._forwardYears = temp;

        this.setState({ 
            selectedForwardYears: this._forwardYears,
        });
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gets the min and max available date for the historic data
    ////////////////////////////////////////////////////////////////////////////
    get _minDate() {
        let min = this._data?.availableDataRange?.min ?? this._now;
        return DateRangePicker.getDate(min);
    }
    get _maxDate() {
        let max = this._data?.availableDataRange?.max ?? this._now;
        return DateRangePicker.getDate(max);
    }
    
    ////////////////////////////////////////////////////////////////////////////
    // Gets or sets the available historic years
    ////////////////////////////////////////////////////////////////////////////
    get _historicYears() {
        return this.__historicYears ?? [];
    }
    set _historicYears(value) {
        this.__historicYears = value;
    }

    _onGotHistoricData(data) {
        // Save the historic data
        this._data = data;

        // Derive all years
        let historicYears = [];
        for (let y = data.availableDataRange.min.year(); y <= data.availableDataRange.max.year(); ++y) {
            historicYears.push(y);
        }
        this._historicYears = historicYears;

        this.setState({
            selectedFuels: this._fuels.map(f => f.fuelCode),
            start: this._minDate,
            end: this._maxDate,
            filterStart: this._minDate,
            filterEnd: this._maxDate,
            selectedYears: historicYears.slice(),
        });
    }

    _calculateForwardStats() {
        if (this._data != null) {
            let filters = this._filters;
            if (JSON.stringify(filters) !== JSON.stringify(this._stats.filters)) {
                this._stats.calculate(this._data[filters.dataSource], filters);
            }
        }
    }

    _calculateHistoricStats() {
        if (this._data != null) {
            let filters = this._filters;
            if (JSON.stringify(filters) !== JSON.stringify(this._stats.filters)) {
                this._stats.calculate(this._data, filters);
            }
        }
    }

    _getSeriesName(key) {
        let seriesName = '';
        if (this._data != null) {
            // In JEMI 3.0, refcase series data was replaced.  One of the required changes
            // is to show 'Generation' instead of 'Peak Load' for these series.  So,
            // in this case, override the peak load series name from the server.
            if (key === 'peakload' && this._isRefcaseDataSource) {
                seriesName = Locale.getResourceString('supplyAndDemand.chart.totalEnergy');
            }
            else {
                seriesName = Locale.getJSONFieldValue(this._data.seriesLookup[key]);
            }
        }
        return seriesName;
    }

    _oni18nChanged() {
        this.forceUpdate();
    }

    _onPin(args) {
        this.setState({ filterPanelIsPinned: args.isPinned });
        if (this.props.onPin != null) {
            this.props.onPin(args);
        }
    }

    _getData() {
        return new Promise((resolve, reject) => {
            // Check whether or not we already have data
            if (this._data != null) {
                resolve(this._data);
            }
            else {
                // Set busy state
                BusyState.isBusy = true;

                // First, get vintages
                VintagesDataFactory
                    .get()
                    .then(vintages => {
                        // Save a reference to the vintages
                        this._vintages = vintages;

                        // Now get supply and demand data
                        return this._dataFactory.get();
                    })
                    .then(data => {
                        this._onGotData(data);
                        resolve(this._data);
                    })
                    .finally(() => {
                        BusyState.isBusy = false;
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    }

    _onChangeChartTab(chartKey) {
        this._chartKey = chartKey;

        // Load and show data for the selected chart
        this._getData()
            .then(data => {
                this.forceUpdate();
            })
            .catch((error) => {
                ErrorState.setFault(error.message);
            });
    }

    // Gets whether or not to show the forward charts' source/vintage
    get _isRefcaseDataSource() {
        return (this._chartKey === 'forward') &&
            (SupplyAndDemand.RefcaseDataSources.hasOwnProperty(this._forwardDataSource));
    }



    componentDidMount() {
        // Listen for locale and currency-changed events.  We need to recalculate stats 
        // and update because we don't recalculate stats on every render.
        Locale.addLocaleHandler("SupplyAndDemand", this._oni18nChanged);
        Locale.addCurrencyHandler("SupplyAndDemand", this._oni18nChanged);

        // Get initial chart data
        this._getData()
            .then(data => {
                this.forceUpdate();
            })
            .catch((error) => {
                ErrorState.setFault(error.message);
            });
    }

    componentDidUpdate() {
        // Persist current state
        SessionState.set(this._sessionStateKey, this.state);
    }

    componentWillUnmount() {
        // Clean up handlers
        Locale.removeCurrencyHandler("SupplyAndDemand");
        Locale.removeLocaleHandler("SupplyAndDemand");
    }

    _renderForwardFilters() {
        let serviceTerritoryOptions = this._ustsOptions;

        return (
            <div className="supply-and-demand-filters">
                <VisualBox caption={t('supplyAndDemand.filters.chartSeries')}>
                    <div className="button-group-filter">
                        <div 
                            className="select-all" 
                            title={t('general.selectAll')} 
                            onClick={() => {
                                GlobalSessionState.set({ supplyAndDemandServiceTerritories: Object.keys(this._data.ustsLookup).map(id => parseInt(id, 10)) });
                                this.forceUpdate();
                            }}
                        >
                            <SelectAllIcon />
                        </div>
                        <div 
                            className="select-none" 
                            title={t('general.clearAll')} 
                            onClick={() => {
                                GlobalSessionState.set({ supplyAndDemandServiceTerritories: [] });
                                this.forceUpdate();
                            }}
                        >
                            <ClearAllIcon />
                        </div>

                        <MultiSelectDropdown 
                            label={t('supplyAndDemand.filters.utilityServiceTerritories.caption')}
                            multipleSelectionsPlaceholder={t('general.multipleSelectedValues')}
                            placeholder={t('general.select')}
                            options={serviceTerritoryOptions}
                            value={GlobalSessionState.get().supplyAndDemandServiceTerritories.map(id => id.toString())}
                            onChange={(value) => {
                                value && GlobalSessionState.set({ supplyAndDemandServiceTerritories: value.map(id => parseInt(id, 10)) });
                                this.forceUpdate();
                            }}
                        />

                        <Checkbox 
                            checked={this.state.showPeakLoad}
                            label={this._getSeriesName('peakload')} 
                            onChange={(obj) => this.setState({ showPeakLoad: obj.isChecked })}
                        />

                        <Checkbox 
                            checked={this.state.showReserveMargin}
                            label={t('supplyAndDemand.chart.reserveMargin')} 
                            onChange={(obj) => this.setState({ showReserveMargin: obj.isChecked })}
                        />
                    </div>
                </VisualBox>

                <VisualBox caption={t('supplyAndDemand.filters.view')}>
                    <Dropdown
                        label={t('supplyAndDemand.filters.chartBy.caption')}
                        options={SupplyAndDemand.AllDataSources.map(ds => {
                            return { 
                                value: ds, 
                                label: t('supplyAndDemand.filters.chartBy.' + ds)
                            };
                        })}
                        isClearable={false}
                        value={this._forwardDataSource}
                        onChange={obj => {
                            this._forwardDataSource = obj.value;
                            this.forceUpdate();
                        }}
                    />
                </VisualBox>

                <VisualBox caption={t('supplyAndDemand.filters.year')}>
                    <div className="button-group-filter">
                        <div 
                            className="select-all" 
                            title={t('general.selectAll')} 
                            onClick={() => this.setState({ selectedForwardYears: this._forwardYears })}
                        >
                            <SelectAllIcon />
                        </div>
                        <div 
                            className="select-none" 
                            title={t('general.clearAll')} 
                            onClick={() => this.setState({ selectedForwardYears: [] })}
                        >
                            <ClearAllIcon />
                        </div>
                        <YearPicker
                            allValues={this._forwardYears}
                            value={this.state.selectedForwardYears}
                            onChange={(value) => this.setState({ selectedForwardYears: value }) }
                        />
                    </div>
                </VisualBox>
            </div>
        );
    }

    _renderHistoricFilters() {
        const granularities = [
            { value: 'A', },
            { value: 'M', },
            { value: 'D', },
            { value: 'H', },
            { value: 'MoY', },
            { value: 'HoD', }
        ];

        const aggregations = [
            { value: 'min', },
            { value: 'max', },
            { value: 'average', },
            { value: 'sum', },
            // Stdev and Count don't seem particularly useful in the historic supply and demand
            // data.  Got PO buy-in to omit them.  But I'm leaving them here so they can be 
            // easily re-added if needed.
            // { value: 'stdev', },
            // { value: 'count', }
        ];

        const serviceTerritoryOptions = this._ustsOptions;

        return (
            <div className="supply-and-demand-filters">
                <VisualBox caption={t('supplyAndDemand.filters.chartType.caption')}>
                    <Dropdown
                        options={[
                            { value: SupplyAndDemand.GENERATION_BY_FUEL_TYPE, label: t(`supplyAndDemand.filters.chartType.${SupplyAndDemand.GENERATION_BY_FUEL_TYPE}`) },
                            { value: SupplyAndDemand.DEMAND_BY_TERRITORY, label: t(`supplyAndDemand.filters.chartType.${SupplyAndDemand.DEMAND_BY_TERRITORY}`) },
                        ]}
                        isClearable={false}
                        value={this.state.historicChartType}
                        onChange={obj => this.setState({ historicChartType: obj.value })}
                    />
                </VisualBox>

                { 
                    (this.state.historicChartType === SupplyAndDemand.GENERATION_BY_FUEL_TYPE) &&
                    (
                        <VisualBox caption={t('supplyAndDemand.filters.chartSeries')}>
                            <div className="button-group-filter">
                                <div 
                                    className="select-all" 
                                    title={t('general.selectAll')} 
                                    onClick={() => this.setState({ selectedFuels: this._fuels.map(f => f.fuelCode), showTotalDemand: true })}
                                >
                                    <SelectAllIcon />
                                </div>
                                <div 
                                    className="select-none" 
                                    title={t('general.clearAll')} 
                                    onClick={() => this.setState({ selectedFuels: [], showTotalDemand: false })}
                                >
                                    <ClearAllIcon />
                                </div>
                                <MultiSelectDropdown
                                    label={t('supplyAndDemand.filters.fuelType.caption')}
                                    multipleSelectionsPlaceholder={t('general.multipleSelectedValues')}
                                    placeholder={t('general.select')}
                                    options={this._fuelsOptions}
                                    value={this.state.selectedFuels}
                                    onChange={fuels => this.setState({ selectedFuels: fuels })}
                                />
        
                                <Checkbox 
                                    checked={this.state.showTotalDemand}
                                    label={t('supplyAndDemand.filters.totalDemand.caption')} 
                                    onChange={(obj) => this.setState({ showTotalDemand: obj.isChecked })}
                                />
                            </div>
                        </VisualBox>
                    )
                }

                <VisualBox caption={t('supplyAndDemand.filters.utilityServiceTerritories.caption')}>
                    <div className="button-group-filter">
                        <div 
                            className="select-all" 
                            title={t('general.selectAll')} 
                            onClick={() => {
                                GlobalSessionState.set({ supplyAndDemandServiceTerritories: Object.keys(this._data.ustsLookup).map(id => parseInt(id, 10)) });
                                this.forceUpdate();
                            }}
                        >
                            <SelectAllIcon />
                        </div>
                        <div 
                            className="select-none" 
                            title={t('general.clearAll')} 
                            onClick={() => {
                                GlobalSessionState.set({ supplyAndDemandServiceTerritories: [] });
                                this.forceUpdate();
                            }}
                        >
                            <ClearAllIcon />
                        </div>

                        <MultiSelectDropdown 
                            multipleSelectionsPlaceholder={t('general.multipleSelectedValues')}
                            placeholder={t('general.select')}
                            options={serviceTerritoryOptions}
                            value={GlobalSessionState.get().supplyAndDemandServiceTerritories.map(id => id.toString())}
                            onChange={(value) => {
                                value && GlobalSessionState.set({ supplyAndDemandServiceTerritories: value.map(id => parseInt(id, 10)) });
                                this.forceUpdate();
                            }}
                        />
                    </div>
                </VisualBox>

                <VisualBox caption={t('supplyAndDemand.filters.view')}>
                    <Dropdown
                        label={t('supplyAndDemand.filters.granularity.caption')}
                        options={granularities.map(g => Object.assign(g, { label: t('supplyAndDemand.filters.granularity.' + g.value) }))}
                        isClearable={false}
                        value={this.state.granularity}
                        onChange={obj => this.setState({ granularity: obj.value})}
                    />
                    <Dropdown
                        label={t('supplyAndDemand.filters.aggregation.caption')}
                        options={aggregations.map(a => Object.assign(a, { label: t('supplyAndDemand.filters.aggregation.' + a.value) }))}
                        isClearable={false}
                        value={this.state.aggregation}
                        onChange={obj => this.setState({ aggregation: obj.value})}
                    />
                </VisualBox>

                <VisualBox caption={t('supplyAndDemand.filters.dateRange')}>
                    <DateRangePicker
                        minDate={this._minDate}
                        maxDate={this._maxDate}
                        start={this.state.start}
                        end={this.state.end}
                        onChanging={(start, end) => this.setState({ start: start, end: end })}
                        onChange={(start, end) => this.setState({ start: start, end: end, filterStart: start, filterEnd: end })}
                    />
                </VisualBox>

                <VisualBox caption={t('supplyAndDemand.filters.hour')}>
                    <div className="button-group-filter">
                        <div 
                            className="select-all" 
                            title={t('general.selectAll')} 
                            onClick={() => this.setState({ selectedHours: [...Array(24).keys()] })}>
                            <SelectAllIcon />
                        </div>
                        <div 
                            className="select-none" 
                            title={t('general.clearAll')} 
                            onClick={() => this.setState({ selectedHours: [] })}>
                            <ClearAllIcon />
                        </div>
                        <HourPicker
                            value={this.state.selectedHours}
                            onChange={(value) => this.setState({ selectedHours: value })}
                        />
                    </div>
                </VisualBox>

                <VisualBox caption={t('supplyAndDemand.filters.month')}>
                    <div className="button-group-filter">
                        <div 
                            className="select-all" 
                            title={t('general.selectAll')} 
                            onClick={() => this.setState({ selectedMonths: [...Array(12).keys()].map(k => k + 1) })}>
                            <SelectAllIcon />
                        </div>
                        <div 
                            className="select-none" 
                            title={t('general.clearAll')} 
                            onClick={() => this.setState({ selectedMonths: [] })}>
                            <ClearAllIcon />
                        </div>
                        <MonthPicker
                            value={this.state.selectedMonths}
                            onChange={(value) => this.setState({ selectedMonths: value })}
                        />
                    </div>
                </VisualBox>

                <VisualBox caption={t('supplyAndDemand.filters.year')}>
                    <div className="button-group-filter">
                        <div 
                            className="select-all" 
                            title={t('general.selectAll')} 
                            onClick={() => this.setState({ selectedYears: this._historicYears.slice() })}>
                            <SelectAllIcon />
                        </div>
                        <div 
                            className="select-none" 
                            title={t('general.clearAll')} 
                            onClick={() => this.setState({ selectedYears: [] })}>
                            <ClearAllIcon />
                        </div>
                        <YearPicker
                            allValues={this._historicYears.slice()}
                            value={this.state.selectedYears}
                            onChange={(value) => this.setState({ selectedYears: value })}
                        />
                    </div>
                </VisualBox>
            </div>  
        );      
    }

    _renderTabs() {
        return (
            <Tabs
                className="supply-and-demand-nav"
                value={this._chartKey}
                onChange={(e, value) => this._onChangeChartTab(value)}
                orientation="vertical"
            >
                <Tab 
                    icon={<ForwardIcon fontSize="default" />} 
                    value="forward" 
                    label={this.state.isNavExpanded && t('supplyAndDemand.tabs.forward.caption')}
                    title={t('supplyAndDemand.tabs.forward.caption')} 
                />
                <Tab 
                    icon={<HistoryIcon fontSize="default" />} 
                    value="historic" 
                    label={this.state.isNavExpanded && t('supplyAndDemand.tabs.historic.caption')}
                    title={t('supplyAndDemand.tabs.historic.caption')} 
                />
            </Tabs>
        );
    }

    _renderTabPanels() {
        return (
            <>
                <TabPanel isRenderedWhenHidden={false} valuekey="forward" value={this._chartKey}>
                    <SupplyAndDemandChart
                        data={this._stats.aggregated ?? []}
                        barCategoryColumns={this._stats.barCategoryColumns}
                        palette={this._chartPalette}
                        serviceTerritoryIds={GlobalSessionState.get().supplyAndDemandServiceTerritories}
                        showPeakLoad={this.state.showPeakLoad}
                        showReserveMargin={this.state.showReserveMargin}
                        ustsLookup={(this._data != null) ? this._data.ustsLookup : {}}
                        seriesLookup={(this._data != null) ? this._data.seriesLookup : {}}
                        isRefcaseDataSource={this._isRefcaseDataSource}
                        peakLoadSeriesName={this._getSeriesName('peakload')}
                    />
                </TabPanel>
                <TabPanel isRenderedWhenHidden={false} valuekey="historic" value={this._chartKey}>
                    {
                        (this.state.historicChartType === SupplyAndDemand.GENERATION_BY_FUEL_TYPE) ?
                        (<HistoricGenerationByFuel
                            aggregation={this.state.aggregation}
                            aggregated={this._stats.generationByFuel ?? []}
                            fuels={this._fuels.filter(f => this.state.selectedFuels.includes(f.fuelCode))}
                            showTotalDemand={this.state.showTotalDemand}
                            totalDemandCaption={Locale.getJSONFieldValue(this._data?.chartData?.metadata?.columns?.load_val?.caption)}
                        />) :
                        (<HistoricDemandByTerritory
                            aggregation={this.state.aggregation}
                            aggregated={this._stats.demandByTerritory ?? []}
                            supplyAndDemandServiceTerritories={GlobalSessionState.get().supplyAndDemandServiceTerritories ?? []}
                            ustsLookup={this._data?.ustsLookup ?? {}}
                        />)
                    }
                </TabPanel>
            </>
        );
    }

    render() {
        // Update stats as needed
        this._calculateStats();

        return (
            <ErrorBoundary>
                <div className="supply-and-demand">
                    <VisualBox caption={t('supplyAndDemand.caption')}>
                        <div className="supply-and-demand-chart-container">
                            <div className="supply-and-demand-chart-title">
                                {this._selectedServiceTerritoryNames}
                            </div>
                            <div className="jemi-view-filter-button" title={t('supplyAndDemand.filters.caption')}>
                                <Button 
                                    disabled={this.state.filterPanelIsPinned}
                                    variant="primary"
                                    onClick={ () => this.setState({ filterPanelOpen: !this.state.filterPanelOpen }) } >
                                    <Filter fontSize="small" />
                                </Button>
                            </div>
                            <PinnableSettings
                                contentClassName="jemi-filters-container"
                                open={this.state.filterPanelOpen}
                                title={t('supplyAndDemand.filters.caption')}
                                viewKey={SupplyAndDemand.ViewKey}
                                onClose={() => this.setState({ filterPanelOpen: false })}
                                onPin={(args) => this._onPin(args)}
                            >
                                {this._renderFilters()}
                            </PinnableSettings>

                            <VerticalTabNav 
                                className={"vertical-tab-nav-" + Locale.localeName}
                                isExpanded={this.state.isNavExpanded}
                                expandedWidth={150}
                                tabs={this._renderTabs()}
                                tabPanels={this._renderTabPanels()}
                                onChangeIsExpanded={(isExpanded) => this.setState({ isNavExpanded: isExpanded })}
                            />
                            {(this._isRefcaseDataSource) && (
                                <div className="chart-source-annotation">
                                    {Locale.formatVintageAndScenario(this._vintages, SupplyAndDemand.RefcaseDataSources[this._forwardDataSource])}
                                </div>
                            )}
                        </div>
                    </VisualBox>
                </div>
            </ErrorBoundary>
        );
    }
}

export default SupplyAndDemand;
