import React from 'react';
import { LoadingIndicator } from 'webcore-ux/react/components';

class PopupLoadIndicator extends React.Component {
    constructor(props, context) {
        super(props);
    }

    render() {
        return (
            <div className="popup-load-indicator-container">
                <LoadingIndicator />
            </div>
        );
    }
}

export default PopupLoadIndicator;
