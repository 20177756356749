import CommonStyles from 'common/Common.scss';

import ForwardMarketChart from './ForwardMarketChart';

/////////////////////////////////////////////////////////////////////////////
// This class encapsulates the forward-market prices chart palette.
// Two types of palettes are available:
// * Detailed - for getting a specific color for any of the known 
//              forward market volume series, e.g. "kn_wk_24_total_volume",
//              "kn_wk_dt_total_volume", etc.
//
// * Summary - for getting a more general color for a category of 
//             volumne series, e.g. "Kansai", "System", "Tokyo", or
//             "Week", "Month", "Year".
//
// Both types of palettes are requested with a "colorBy" option which 
// specifies how the colors are assigned.  For example, "colorBy"
// "interconnect" assigns similar colors (e.g. blue colors) to 
// series with the same interconnect.
// 
// Further, both types of palettes then provide a "getSeriesColor(s)"
// function which accepts the full name of a series 
// (e.g. "kn_wk_24_total_volume") and returns the color for that series.
/////////////////////////////////////////////////////////////////////////////
class ForwardMarketChartPalette {
    getDetailedPalette(colorBy) {
        const detailedInterconnectColorMap = {
            // Kansai colors
            kn_wk_24_total_volume: CommonStyles.abbBlue80,
            kn_wk_dt_total_volume: CommonStyles.abbBlue60,
            kn_mth_24_total_volume: CommonStyles.abbBlue50,
            kn_mth_dt_total_volume: CommonStyles.abbBlue40,

            // System colors
            sys_wk_24_total_volume: CommonStyles.abbGold80,
            sys_wk_dt_total_volume: CommonStyles.abbGold60,
            sys_mth_24_total_volume: CommonStyles.abbGold50,
            sys_mth_dt_total_volume: CommonStyles.abbGold40,
            sys_yr_24_total_volume: CommonStyles.abbGold20,

            // Tokyo colors
            tk_wk_24_total_volume: CommonStyles.abbGreen80,
            tk_wk_dt_total_volume: CommonStyles.abbGreen60,
            tk_mth_24_total_volume: CommonStyles.abbGreen50,
            tk_mth_dt_total_volume: CommonStyles.abbGreen40,
            tk_yr_24_total_volume: CommonStyles.abbGreen20,
        };

        const detailedPeakTypeColorMap = {
            // 24-hour colors
            kn_wk_24_total_volume: CommonStyles.abbBlue90,
            kn_mth_24_total_volume: CommonStyles.abbBlue80,
            sys_wk_24_total_volume: CommonStyles.abbBlue70,
            sys_mth_24_total_volume: CommonStyles.abbBlue60,
            sys_yr_24_total_volume: CommonStyles.abbBlue50,
            tk_wk_24_total_volume: CommonStyles.abbBlue40,
            tk_mth_24_total_volume: CommonStyles.abbBlue30,
            tk_yr_24_total_volume: CommonStyles.abbBlue20,

            // Daytime colors
            kn_wk_dt_total_volume: CommonStyles.abbGreen90,
            kn_mth_dt_total_volume: CommonStyles.abbGreen80,
            sys_wk_dt_total_volume: CommonStyles.abbGreen60,
            sys_mth_dt_total_volume: CommonStyles.abbGreen50,
            tk_wk_dt_total_volume: CommonStyles.abbGreen40,
            tk_mth_dt_total_volume: CommonStyles.abbGreen20,
        };

        const detailedContractLengthColorMap = {
            // Weekly colors
            kn_wk_24_total_volume: CommonStyles.abbBlue90,
            kn_wk_dt_total_volume: CommonStyles.abbBlue80,
            sys_wk_24_total_volume: CommonStyles.abbBlue60,
            sys_wk_dt_total_volume: CommonStyles.abbBlue50,
            tk_wk_24_total_volume: CommonStyles.abbBlue40,
            tk_wk_dt_total_volume: CommonStyles.abbBlue20,

            // Monthly colors
            kn_mth_24_total_volume: CommonStyles.abbGreen90,
            kn_mth_dt_total_volume: CommonStyles.abbGreen80,
            sys_mth_24_total_volume: CommonStyles.abbGreen60,
            sys_mth_dt_total_volume: CommonStyles.abbGreen50,
            tk_mth_24_total_volume: CommonStyles.abbGreen40,
            tk_mth_dt_total_volume: CommonStyles.abbGreen20,

            // Yearly colors
            sys_yr_24_total_volume: CommonStyles.abbGold80,
            tk_yr_24_total_volume: CommonStyles.abbGold60,
        };

        const detailedPaletteFactories = {
            interconnect: {
                getSeriesColor(seriesName) {
                    return detailedInterconnectColorMap[seriesName];
                }
            },

            peakType: {
                getSeriesColor(seriesName) {
                    return detailedPeakTypeColorMap[seriesName];
                }
            },
            
            contractLength: {
                getSeriesColor(seriesName) {
                    return detailedContractLengthColorMap[seriesName];
                }
            },
        };

        return detailedPaletteFactories[colorBy];
    }

    getSummaryPalette(colorBy) {
        const summaryInterconnectColorMap = {
            kn: CommonStyles.appPrimaryColor,
            sys: CommonStyles.abbGold60,
            tk: CommonStyles.abbGreen60,
        };
        const summaryPeakTypeColorMap = {
            '24': CommonStyles.appPrimaryColor,
            dt: CommonStyles.abbGreen60,
        };
        const summaryContractLengthColorMap = {
            wk: CommonStyles.appPrimaryColor,
            mth: CommonStyles.abbGreen60,
            yr: CommonStyles.abbGold60,
        };

        const summaryPaletteFactories = {
            interconnect: { 
                getSeriesColor(seriesName) {
                    let temp = ForwardMarketChart.SeriesPatternRegex.exec(seriesName);
                    let interconnect = (temp != null) ? temp[2] : null;
                    return (interconnect != null && summaryInterconnectColorMap.hasOwnProperty(interconnect)) ? 
                        summaryInterconnectColorMap[interconnect] :
                        CommonStyles.abbBlue80;
                }
            },

            peakType: { 
                getSeriesColor(seriesName) {
                    let temp = ForwardMarketChart.SeriesPatternRegex.exec(seriesName);
                    let peakType = (temp != null) ? temp[4] : null;
                    return (peakType != null && summaryPeakTypeColorMap.hasOwnProperty(peakType)) ? 
                        summaryPeakTypeColorMap[peakType] :
                        CommonStyles.abbBlue80;
                }
            },

            contractLength: { 
                getSeriesColor(seriesName) {
                    let temp = ForwardMarketChart.SeriesPatternRegex.exec(seriesName);
                    let contractLength = (temp != null) ? temp[3] : null;
                    return (contractLength != null && summaryContractLengthColorMap.hasOwnProperty(contractLength)) ? 
                        summaryContractLengthColorMap[contractLength] :
                        CommonStyles.abbBlue80;
                }
            },
        };

        return summaryPaletteFactories[colorBy];
    }
}

const _instance = new ForwardMarketChartPalette();
Object.freeze(_instance);

export default _instance;