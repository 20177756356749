import moment from 'moment';

import MarketStats from './MarketStats';

export default class SpotMarketStats extends MarketStats {
    get _granularityField() {
        return 'spotMarketGranularity'
    }

    _beforeFilter(data, filters, valueColumns) {
        // Transform selection arrays into objects for easy lookup without searches
        filters.selectedHours = filters.selectedHours.reduce((obj, h) => { obj[h] = true; return obj; }, {});
        filters.selectedMonths = filters.selectedMonths.reduce((obj, m) => { obj[m] = true; return obj; }, {});
        filters.selectedYears = filters.selectedYears.reduce((obj, y) => { obj[y] = true; return obj; }, {});

        // Change the end date so that we look up to midnight of the next day
        filters.end = moment.utc([filters.end.year(), filters.end.month(), filters.end.date()]).add(1, 'days');
    }

    _getMomentCategory(theMoment, filters) {
        // Default: hourly
        let momentCategory = theMoment;
        let granularity = filters.spotMarketGranularity;

        // Annual
        if (granularity === 'A') {
            momentCategory = moment.utc([theMoment.year(), 1, 1]);
        }
        // Monthly
        else if (granularity === 'M') {
            momentCategory = moment.utc([theMoment.year(), theMoment.month()]);
        }
        // Daily
        else if (granularity === 'D') {
            momentCategory = moment.utc([theMoment.year(), theMoment.month(), theMoment.date()]);
        }
        // Month of Year
        else if (granularity === 'MoY') {
            // Use an arbitrary year to group by the month
            momentCategory = moment.utc([2020, theMoment.month()]);
        }
        // Hour of Day
        else if (granularity === 'HoD') {
            // Use an arbitrary year, month, and day to group by the hour only
            momentCategory = moment.utc([2020, 0, 1, theMoment.hour()]);
        }

        return momentCategory;
    }

    _rowMeetsFilterCriteria(theMoment, filters) {
        let rowMeetsFilterCriteria = false;

        // Filter for start/end date
        if (filters.start.isSameOrBefore(theMoment) && theMoment.isBefore(filters.end)) {
            // Filter for hour/month/year selections
            if (filters.selectedHours[theMoment.hour()] &&
                filters.selectedMonths[theMoment.month() + 1] &&
                filters.selectedYears[theMoment.year()]) {
                    rowMeetsFilterCriteria = true;
            }
        }

        return rowMeetsFilterCriteria;
    }
}
