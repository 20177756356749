import AppConfig from 'AppConfig';
import WeatherDataFactory from './WeatherDataFactory';

export default class WindDataFactory extends WeatherDataFactory {
    ////////////////////////////////////////////////////////////////////////////
    // "Protected" interface
    ////////////////////////////////////////////////////////////////////////////
    get _key() {
        return 'wind'
    }

    _getUrl(ids) {
        let idQuery = ids.map(id => `id=${id}`).join('&');
        return `${AppConfig.dataService.baseUrl}/weather/wind?${idQuery}`
    }
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
}