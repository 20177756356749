import SessionState from './SessionState';

/////////////////////////////////////////////////////////////////////////////
// GlobalSessionState facilitates getting/setting global UI state via the
// browser's sessionStorage object.
/////////////////////////////////////////////////////////////////////////////
class GlobalSessionState {
    constructor() {
        this._sessionStateKey = 'emi-japan.GlobalSessionState';
    }

    get() {
        try {
            return SessionState.get(this._sessionStateKey);
        } catch (err) {
            console.error(err);
        }
        return {};
    }

    set(sessionState: Object) {
        SessionState.set(this._sessionStateKey, sessionState);
    }
}

const _instance = new GlobalSessionState();
Object.freeze(_instance);

export default _instance;
