import React from 'react';
import { withRouter } from "react-router";
import { SlidePanel } from 'webcore-ux/react/components';
import ErrorBoundary from 'ErrorBoundary';
import Locale from '../../locale/Locale';
import PopupDataCache from './PopupDataCache';
import Pagination from '@material-ui/lab/Pagination';

import './FeaturePopup.scss';
import '../../common/MuiPagination.scss';

const t = Locale.getResourceString.bind(Locale);

class FeaturePopup extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {
            featureIndex: 0,
        };

        this._onChangePage = this._onChangePage.bind(this);
    }

    _onClose(isVisible) {
        // Reset feature index to 0
        this.setState({
            featureIndex: 0,
        });

        // Clear PopupDataCache
        PopupDataCache.clear();

        // Notify parent that we've closed
        if (this.props.onClose != null) {
            this.props.onClose();
        }
    }

    _onChangePage(event, page) {
        this.setState({
            featureIndex: page - 1,
        });
    }



    componentDidUpdate(previousProps) {
        if (this.props.location.pathname !== previousProps.location.pathname) {
            this._onClose();
        }
    }

    render() {
        let title = t("spatialAwareness.features.title");
        let popupContent = null
        if (this.props.selectedFeatures != null && this.props.selectedFeatures.length > 0 && this.state.featureIndex < this.props.selectedFeatures.length) {
            let feature = this.props.selectedFeatures[this.state.featureIndex];
            let layer = this.props.layerLookup[feature.getProperties().layerKey];
            popupContent = layer.renderPopupContent(feature);
        }

        return (
            <ErrorBoundary>
                <SlidePanel
                    anchor="left"
                    title={title}
                    open={this.props.selectedFeatures != null && this.props.selectedFeatures.length > 0}
                    variant="temporary"
                    onClose={() => this._onClose(false)}
                >
                    <div className="feature-popup-content-container">
                        {popupContent}
                    </div>
                    <div className="feature-popup-footer">
                        <div className="pagination-container">
                            <Pagination 
                                count={this.props.selectedFeatures.length} 
                                page={this.state.featureIndex + 1}
                                onChange={this._onChangePage}
                            />
                        </div>
                    </div>
                </SlidePanel>
            </ErrorBoundary>
        );
    }
}

export default withRouter(FeaturePopup);
