import Locale from 'locale/Locale';
import WeatherStats from './WeatherStats';

export default class WindStats extends WeatherStats {
    _getFields(row) {
        return this._valueColumns.reduce((obj, col) => { 
            if (col.name.startsWith('speed')) {
                obj[col.name] = Locale.convertSpeed(row[col.index], 'metersPerSecond');
            }
            else if (col.name.startsWith('direction')) {
                obj[col.name] = row[col.index];
            }
            return obj; 
        }, {});
    }
}
