import AppTrace from 'AppTrace';
import Authentication from 'Authentication';
import DataFormatting from 'DataFormatting';

const axios = require('axios');

export default class WeatherDataFactory {
    constructor() {
        this._previousIds = [];
        this._cache = {};

        // This is the hard-coded result for "empty" temperature requests
        this.__emptyResult = {
            // Post-processed fields
            maxDate: null,
            minDate: null,
            allYears: [],

            // Actual API result
            metadata: {
                columns: {
                    "datetime": {
                        "name": "datetime",
                        "caption": "datetime",
                        "type": "datetime",
                        "format": null,
                        "index": 0,
                        "visibility": null,
                        "tag": null
                    }
                }
            },
            rows: [],
        };
    }

    ////////////////////////////////////////////////////////////////////////////
    // "Protected" interface
    ////////////////////////////////////////////////////////////////////////////
    get _emptyResult() {
        return this.__emptyResult;
    }

    get _key() {
        throw new Error('not implemented');
    }

    _getUrl(ids) {
        throw new Error('not implemented');
    }
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////
    // Public interface
    ////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////
    // Gets weather data given a set of weather station IDs
    ////////////////////////////////////////////////////////////////////////////
    get(ids) {
        return new Promise((resolve, reject) => {
            // Optmize away requests for no IDs
            if (ids.length === 0) {
                resolve(this._emptyResult);
            }
            // Optimize requests for data we already have
            else if (ids.filter(id => this._previousIds.includes(id)).length === ids.length) {
                resolve(this._cache);
            }
            // Otherwise we need to go get the data from the service
            else {
                // Get access token
                Authentication.getAccessToken()
                    // Get API data
                    .then(accessToken => {
                        let authString = 'Bearer '.concat(accessToken);
                        let url = this._getUrl(ids);

                        return axios.get(url, {
                            headers: {
                                Authorization: authString,
                                Accept: "application/json",
                                "cache-control": "no-cache",
                            }
                        });
                    })
                    .then(response => {
                        let data = response.data;

                        // Convert result fields
                        DataFormatting.convertResultFields(data);

                        // Derive date range and allYears
                        let minDate = null;
                        let maxDate = null;
                        let allYears = [];
                        if (data.rows.length > 0) {
                            let datetimeIndex = data.metadata.columns.datetime.index;

                            minDate = data.rows[0][datetimeIndex];
                            maxDate = data.rows[data.rows.length - 1][datetimeIndex];
                            for (let y = minDate.year(); y <= maxDate.year(); ++y) {
                                allYears.push(y);
                            }
                        }
                        data.minDate = minDate;
                        data.maxDate = maxDate;
                        data.allYears = allYears;
                        data.allYears.sort((a, b) => a - b);

                        // Update empty result
                        this.__emptyResult.minDate = minDate;
                        this.__emptyResult.maxDate = maxDate;
                        this.__emptyResult.allYears = allYears.slice();

                        // Clear cache and update the cached the result
                        if (this._cache?.rows?.length)
                            this._cache.rows.length = 0;
                        this._previousIds = ids.slice();
                        this._cache = data;
                        resolve(this._cache);
                    })
                    .catch((error) => {
                        AppTrace.traceError('Error in WeatherDataFactory.get: ' + error.message);
                        reject(new Error(`Error getting ${this._key} data`));
                    });
            }
        });
    }
}