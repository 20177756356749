import enResources from './en';
import XYZ from 'ol/source/XYZ';

import Impl from '../Impl';

export default class Impl_en extends Impl {
    get resources() {
        return enResources;
    }

    get jsonFieldKeys() {
        return ['en_us', 'en'];
    }

    get tileSources() {
        return [
            new XYZ({
                attributions: 'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer">ArcGIS</a>',
                url: 'https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
            }),
            new XYZ({
                attributions: 'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Reference/MapServer">ArcGIS</a>',
                url: 'https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Reference/MapServer/tile/{z}/{y}/{x}',
            })
        ];
    }

    formatDate(moment) {
        return moment.locale('en').format('MM/DD/YYYY');
    }

    formatDateTime(moment) {
        return moment.locale('en').format('MM/DD/YYYY HH:00');
    }

    formatMomentGranularity(moment, granularity) {
        const granularities = {
            // Standard granularities
            'A': 'YYYY',
            'M': 'YYYY-MM',
            'D': 'YYYY-MM-DD',
            'H': 'YYYY-MM-DD HH',
            'MoY': 'MMMM',
            'HoD': 'HH',

            // Additional options
            'MoY-short': 'MM',
        };

        // Ensure we have a format mapping for the input granularity
        if (!granularities.hasOwnProperty(granularity)) {
            granularity = 'H';
        }

        return moment.locale('en').format(granularities[granularity]);
    }

    formatNumber(value, digits) {
        return value.toLocaleString('en-US', { minimumFractionDigits: digits, maximumFractionDigits: digits });
    }

    formatPercent(value, digits) {
        return value.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: digits, maximumFractionDigits: digits });
    }

    formatTemperature(value, digits, units) {
        // English locale units are used for the unit key in Locale so we can format them into the temperature
        return `${this.formatNumber(value, digits)} ${this.resources.units[units]}`;
    }

    formatSpeed(value, digits, units) {
        // English locale units are used for the unit key in Locale so we can format them into the speed result
        return `${this.formatNumber(value, digits)} ${this.resources.units[units]}`;
    }

    formatVintage(year, seasonName, scenarioName) {
        return `${year} ${seasonName} Reference Case`;
    }

    formatVintageAndScenario(year, seasonName, scenarioName) {
        return `${year} ${seasonName} ${scenarioName} Scenario`;
    }
}
