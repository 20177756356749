import jaResources from './ja';
import XYZ from 'ol/source/XYZ';

import 'moment/locale/ja';

import Impl from '../Impl';

export default class Impl_en extends Impl {
    get resources() {
        return jaResources;
    }

    get jsonFieldKeys() {
        return ['ja_jp', 'ja'];
    }

    
    get tileSources() {
        return [
            new XYZ({
                attributions: 'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer">ArcGIS</a>',
                url: 'https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
            }),
            new XYZ({
                attributions: 'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Reference/MapServer">ArcGIS</a>',
                url: 'https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Reference/MapServer/tile/{z}/{y}/{x}',
            })
        ];
    }

    formatDate(moment) {
        return moment.locale('ja').format('YYYY年MM月DD日 (dd)');
    }

    formatDateTime(moment) {
        return moment.locale('ja').format('YYYY年MM月DD日 HH時00分');
    }

    formatMomentGranularity(moment, granularity) {
        const granularities = {
            // Standard granularities
            'A': 'YYYY年',
            'M': 'YYYY年MM月',
            'D': 'YYYY年MM月DD日',
            'H': 'YYYY年MM月DD日 HH時',
            'MoY': 'MMMM',
            'HoD': 'HH時',

            // Additional options
            'MoY-short': 'MM月',
        };

        // Ensure we have a format mapping for the input granularity
        if (!granularities.hasOwnProperty(granularity)) {
            granularity = 'H';
        }

        return moment.locale('ja').format(granularities[granularity]);
    }

    formatNumber(value, digits) {
        return value.toLocaleString('ja-JP', { minimumFractionDigits: digits, maximumFractionDigits: digits });
    }

    formatPercent(value, digits) {
        return value.toLocaleString('ja-JP', { style: 'percent', minimumFractionDigits: digits, maximumFractionDigits: digits });
    }

    formatTemperature(value, digits, units) {
        if (units === 'celsius') { return `摂氏${this.formatNumber(value, digits)}度`; }
        else if (units === 'fahrenheit') { return `華氏${this.formatNumber(value, digits)}度`; }
        else { throw new Error('Unsupported units'); }
    }

    formatSpeed(value, digits, units) {
        if (units === 'metersPerSecond') { return `毎秒${this.formatNumber(value, digits)}メートル`; }
        else if (units === 'milesPerHour') { return `時速${this.formatNumber(value, digits)}マイル`; }
        else { throw new Error('Unsupported units'); }
    }

    formatVintage(year, seasonName) {
        return `${year}年${seasonName}の参考例`;
    }

    formatVintageAndScenario(year, seasonName, scenarioName) {
        return `${year}年${seasonName}の${scenarioName}の例`;
    }
}
