export default class Impl {
    get resources() {
        throw new Error('Not Implemented');
    }

    get jsonFieldKeys() {
        throw new Error('Not Implemented');
    }

    get tileSources() {
        throw new Error('Not Implemented');
    }

    formatDate(date) {
        throw new Error('Not Implemented');
    }

    formatDateTime(dateTime) {
        throw new Error('Not Implemented');
    }

    formatMomentGranularity(moment, granularity) {
        throw new Error('Not Implemented');
    }

    formatNumber(value, digits) {
        throw new Error('Not Implemented');
    }

    formatPercent(value, digits) {
        throw new Error('Not Implemented');
    }

    formatTemperature(value, digits, units) {
        throw new Error('Not Implemented');
    }

    formatSpeed(value, digits, units) {
        throw new Error('Not Implemented');
    }
}
