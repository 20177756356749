import Animation from './Animation';

export default class NoneAnimation extends Animation {
    ////
    // Public interface
    ////

    // Gets a string key that identifies this animation in collections, localization resource, etc.
    get key() {
        return 'none';
    }

    // Gets whether or not the theme is loaded with data and ready to use
    get isLoaded() {
        return true;
    }

    // Gets whether or not the theme is enabled, which currently 
    // defers to whether or not the theme is enabled
    get isEnabled() {
        return true;
    }

    // Loads theme data for this theme
    // Returns a Promise that resolves when the theme data is loaded and the theme
    // is ready to be rendered
    load(view) {
        // No data to load in this theme
        return Promise.resolve(this);
    }

    // Renders this theme.  By default, no rendering is needed.  But,
    // for animations, animation controls may be desired, etc.
    render() {
        return null;
    }
}