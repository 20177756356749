import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TimelineIcon from '@material-ui/icons/Timeline';
import BarChartIcon from '@material-ui/icons/BarChart';
import Slider from '@material-ui/core/Slider';
import { ResponsiveContainer, ComposedChart, Line, Bar, XAxis, YAxis, Legend, Tooltip, Label } from 'recharts';
import { ToggleButton, ToggleButtonGroup } from 'webcore-ux/react/components';

import Locale from 'locale/Locale';
import ErrorBoundary from 'ErrorBoundary';
import Palettes from 'common/Palettes';
import SessionState from 'SessionState';

import 'common/MuiSlider.scss';
import 'common/ReCharts.scss';
import './SpotMarketChart.scss';

const t = Locale.getResourceString.bind(Locale);

/////////////////////////////////////////////////////////////////////////////
// This class encapsulates the spot-market prices chart
/////////////////////////////////////////////////////////////////////////////
class SpotMarketChart extends Component {
    constructor(props, context) {
        super(props);

        // Set a maximum number of chart points/entries of 336, which is 1 week of hourly data
        SpotMarketChart._MaxChartEntries = 168;

        // Configure initial state
        this._sessionStateKey = 'emi-japan.price-history.SpotMarketChart';
        this._defaultInitialState = {
            seriesType: 'line',
        }
        this._stateOverrides = {
            sliderValue: Number.MAX_SAFE_INTEGER,
        }
        this.state = SessionState.get(this._sessionStateKey, this._defaultInitialState, this._stateOverrides);

        // Bind event handlers
        this._onTooltipFormatLabel = this._onTooltipFormatLabel.bind(this);
        this._onFormatValue = this._onFormatValue.bind(this);
        this._onChangeSlider = this._onChangeSlider.bind(this);
    }

    get _chartHasVolumeSeries() {
        return this.props.series.filter(s => this._isVolumeSeries(s)).length > 0;
    }

    get _series() {
        const palette = Palettes.DefaultColors;
        let series = [];        

        if (this.props.data != null) {
            series = (this.state.seriesType === 'line') ?
                this.props.series.map((s, index) => {
                    return (
                        <Line
                            key={s} 
                            dataKey={s} 
                            dot={false}
                            isAnimationActive={false}
                            name={Locale.getJSONFieldValue(this.props.data.metadata.columns[s].caption)} 
                            stroke={palette[index % palette.length]} 
                            yAxisId={this._isVolumeSeries(s) ? 1 : 0}
                            strokeWidth={2} 
                        />
                    );
                }) :
                this.props.series.map((s, index) => {
                    return (
                        <Bar
                            key={s} 
                            dataKey={s} 
                            isAnimationActive={false}
                            name={Locale.getJSONFieldValue(this.props.data.metadata.columns[s].caption)} 
                            fill={palette[index % palette.length]} 
                            yAxisId={this._isVolumeSeries(s) ? 1 : 0}
                        />
                    );
                });
        }

        return series;
    }

    get _dataBounds() {
        let extraEntries = this.props.aggregated.length - SpotMarketChart._MaxChartEntries;
        let max = Math.max(0, extraEntries);

        return {
            min: 0,
            max: max,
            sliderValue: Math.min(this.state.sliderValue, max)
        };
    }

    _getData(dataBounds) {
        // Clean up previous data
        if (this.__data != null) {
            this.__data.length = 0;
        }

        // Take a subset if needed
        this.__data = this.props.aggregated.slice(dataBounds.sliderValue, dataBounds.sliderValue + SpotMarketChart._MaxChartEntries);
        
        return this.__data;
    }

    _isVolumeSeries(s) {
        const volumeSeries = {
            index_volume: true,
            intraday_volume: true,
        };

        return volumeSeries[s] === true;
    }

    get _yAxisLabelText() {
        let units = (this.props.aggregation === 'stdev' || this.props.aggregation === 'count') ? 
            '' : 
            ' ' + Locale.getCurrencySymbol() + '/' + t('units.kiloWattHour');

        return t('priceHistory.charts.spotMarket.axisLabels.price.' + this.props.aggregation) + units;
    }

    get _secondaryYAxisLabelText() {
        let units = (this.props.aggregation === 'stdev' || this.props.aggregation === 'count') ? 
            '' : 
            ' ' + t('units.kiloWatts');

        return t('priceHistory.charts.spotMarket.axisLabels.volume.' + this.props.aggregation) + units;
    }



    _onChangeSlider(event, value) {
        this.setState({
            sliderValue: value,
        })
    }

    _onTooltipFormatLabel(value) {
        return (<span>{value}</span>);
    }

    _onFormatValue(value, name, props) {
        return (this.props.aggregation === 'stdev') ? Locale.formatNumber(value, 2) :
            (this.props.aggregation === 'count') ? Locale.formatNumber(value, 0) :
            (props != null && props.dataKey != null && this._isVolumeSeries(props.dataKey)) ? Locale.formatNumber(value, 0) :
            Locale.formatCurrency(value);
    }



    componentDidUpdate() {
        // Persist current state
        SessionState.set(this._sessionStateKey, this.state);
    }

    render() {
        // Get bounds of the chart data
        let dataBounds = this._dataBounds;

        // Get JSX for secondary axis as needed
        let secondaryYAxis = (this._chartHasVolumeSeries) ? (
            <YAxis 
                orientation="right"
                tickFormatter={ (v) => Locale.formatNumber(v, 0) }
                width={100}
                yAxisId={1}>
                <Label 
                    angle={90} 
                    position='insideRight'
                    value={this._secondaryYAxisLabelText}
                    style={{textAnchor: 'middle'}}
                />
            </YAxis>) :
            null;

        // Get chart margins and JSX for slider as needed
        let chartMarginBottom = (dataBounds.max > 0) ? 30 : 10;
        let sliderJSX = null;
        if (dataBounds.max > 0) {
            let sliderPaddingRight = (this._chartHasVolumeSeries) ? 120 : 20;
            sliderJSX = (
                <div className="spot-market-range-slider" style={{ paddingRight: sliderPaddingRight }}>
                    <Slider
                        min={dataBounds.min}
                        max={dataBounds.max}
                        value={dataBounds.sliderValue}
                        onChange={this._onChangeSlider}
                    />
                </div>
            );
        }

        return (
            <ErrorBoundary>
                <div className="spot-market-options">
                    <ToggleButtonGroup value={this.state.seriesType} onChange={(e, value) => { value && this.setState({ seriesType: value }) }}>
                        <ToggleButton size="small" value="line" title={t('general.lineChart')}><TimelineIcon /></ToggleButton>
                        <ToggleButton size="small" value="bar" title={t('general.barChart')}><BarChartIcon /></ToggleButton>
                    </ToggleButtonGroup>
                </div>

                <ResponsiveContainer>
                    <ComposedChart
                        data={this._getData(dataBounds)}
                        margin={{
                            top: 60, right: 20, bottom: chartMarginBottom, left: 10,
                        }}
                    >
                        <XAxis 
                            dataKey="category" 
                        />
                        <YAxis 
                            tickFormatter={ (v) => this._onFormatValue(v) }
                            width={90}
                            yAxisId={0}>
                            <Label 
                                angle={-90} 
                                position='insideLeft'
                                value={this._yAxisLabelText}
                                style={{textAnchor: 'middle'}}
                            />
                        </YAxis>
                        {secondaryYAxis}
                        <Tooltip 
                            isAnimationActive={false} 
                            labelFormatter={this._onTooltipFormatLabel}
                            formatter={this._onFormatValue} 
                        />
                        <Legend />

                        {this._series}
                    </ComposedChart>
                </ResponsiveContainer>

                {sliderJSX}
            </ErrorBoundary>
        );
    }
}

SpotMarketChart.propTypes = {
    aggregation: PropTypes.string,
    aggregated: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.object,
    series: PropTypes.arrayOf(PropTypes.string),
}

SpotMarketChart.defaultProps = {
    aggregation: 'average',
    aggregated: [],
    series: [],
}

export default SpotMarketChart;
