import Locale from 'locale/Locale';

export default class CashFlowStats {
    constructor() {
        this._filtered = [];
        this._aggregated = [];

        this.dl = require('@hitachi.energy.digitalforce.node/datalib').dl;
    }

    get filtered() {
        return this._filtered;
    }

    get aggregated() {
        return this._aggregated;
    }

    calculate(data, filters) {
        const NonValueColumns = {
            year: true,
            company_id: true,
            conv_usd: true,
            conv_eur: true,
        };

        // Clean up old calculated values
        this._cleanup();

        // Filter the data based on state
        let valueColumns = Object.values(data.metadata.columns).filter(c => !NonValueColumns.hasOwnProperty(c.name));
        this._filtered = this._filter(data, filters, valueColumns);
        this._aggregated = this._aggregate(data, filters, valueColumns);
    }



    _cleanup() {
        if (this._aggregated != null) {
            this._aggregated.length = 0;
        }

        if (this._filtered != null) {
            this._filtered.length = 0;
        }

        if (this._barCategoryColumns != null) {
            this._barCategoryColumns.length = 0;
        }
    }

    _filter(data, filters, valueColumns) {
        let filtered = [];

        if (data != null) {
            // Get property indexes
            let yearIndex = data.metadata.columns.year.index;
            let companyIdIndex = data.metadata.columns.company_id.index;

            for (let i = 0; i < data.rows.length; ++i) {
                let row = data.rows[i];
                let year = row[yearIndex];
                let companyId = row[companyIdIndex];

                if (this._rowMeetsFilterCriteria(year, companyId, filters)) {
                    let fields = valueColumns.reduce((obj, col) => { 
                        obj[col.name] = (col.tag != null && col.tag.isCurrencyField) ? 
                            Locale.getJSONCurrencyValue(row[col.index]) :
                            row[col.index];
                        return obj; 
                    }, {})
                    Object.assign(fields, { company_id: companyId, year: year });
                    filtered.push(fields);
                }
            }
        }

        //console.log(filtered);

        return filtered;
    }

    _rowMeetsFilterCriteria(year, companyId, filters) {
        let rowMeetsFilterCriteria = false;

        // Filter for hour/month/year selections
        if (filters.selectedCompanies[companyId] &&
            filters.selectedYears[year]) {
            rowMeetsFilterCriteria = true;
        }

        return rowMeetsFilterCriteria;
    }

    _aggregate(data, filters, valueColumns) {
        let aggregated = [];

        // Aggregate the data
        aggregated = this.dl.groupby(filters.chartBy).summarize(
            valueColumns.map(col => { 
                return {
                    name: col.name,
                    ops: ['sum'],
                    as: [col.name]
                };
            })
        ).execute(this._filtered);

        // Order by the categorizied moment
        aggregated.sort(this.dl.comparator(filters.chartBy));

        // Postprocess...
        for (let i = 0; i < aggregated.length; ++i) {
            let row = aggregated[i];

            // Clean up unchartable values resulting from aggregation
            for (let j = 0; j < valueColumns.length; ++j) {
                let value = row[valueColumns[j].name];
                if (Number.isNaN(value) || !Number.isFinite(value)) {
                    row[valueColumns[j].name] = null;
                }
            }
        }

        //console.log(aggregated);

        return aggregated;
    }
}