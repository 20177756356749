import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Locale from '../locale/Locale';

import { ToggleButton, ToggleButtonGroup } from 'webcore-ux/react/components';

import './HourPicker.scss';

////////////////////////////////////////////////////////////////////////////////
// HourPicker renders a WebCore UX button group in order to facilitate the 
// selection of hours of the day.
//
// Hours are represented a the integers [0..24].  The expected type of the
// value property is an array of integers representing the currently-selected
// hours.
// 
// Some WebCore-ish styling is applied, etc.
////////////////////////////////////////////////////////////////////////////////
class HourPicker extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {
        };

        this._onChange = this._onChange.bind(this);
    }

    _onChange(value) {
        if (this.props.onChange != null) {
            this.props.onChange(value);
        }
    }

    render() {
        return (
            <ToggleButtonGroup 
                className="de-filter-button-group de-hour-picker"
                multiselect={true}
                value={this.props.value}
                onChange={(e, value) => { this._onChange(value) }}
            >
                {/* Render a toggle button for each hour */}
                {[...Array(24).keys()].map(n => (
                    <ToggleButton 
                        key={n} 
                        value={n} 
                        size="small" 
                        selected={this.props.value.indexOf(n) >= 0}>
                            {Locale.formatMomentGranularity(moment.utc([2020, 0, 1, n]), 'HoD')}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        );
    }

}

HourPicker.propTypes = {
    value: PropTypes.arrayOf(PropTypes.number),
}

HourPicker.defaultProps = {
    value: [],
}

export default HourPicker;