import React from 'react';
import moment from 'moment';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TimelineIcon from '@material-ui/icons/Timeline';
import ForwardIcon from '@material-ui/icons/Forward';
import WhatshotIcon from '@material-ui/icons/Whatshot';

import Filter from 'webcore-ux/react/components/Icons/Filter';
import { Button } from 'webcore-ux/react/components'

import AppConfig from 'AppConfig';
import AppTrace from 'AppTrace';
import Authentication from 'Authentication';
import BusyState from 'BusyState';
import ErrorBoundary from 'ErrorBoundary';
import ErrorState from 'ErrorState';
import GlobalSessionState from 'GlobalSessionState';
import CSVDownloadItem from 'CSVDownloadItem';
import Locale from 'locale/Locale';
import SessionState from 'SessionState';

import DateRangePicker from 'common/DateRangePicker';
import PinnableSettings from 'common/PinnableSettings';
import TabPanel from 'common/TabPanel';
import VerticalTabNav from 'common/VerticalTabNav';
import VisualBox from 'common/VisualBox';

import ForwardMarketDataFactory from './data/ForwardMarketDataFactory';
import LNGMarketDataFactory from './data/LNGMarketDataFactory';
import SpotMarketPriceFactory from './data/SpotMarketDataFactory';
import ForwardMarketStats from './stats/ForwardMarketStats';
import LNGMarketStats from './stats/LNGMarketStats';
import SpotMarketStats from './stats/SpotMarketStats';
import ForwardMarketChart from './ForwardMarketChart';
import LNGMarketChart from './LNGMarketChart';
import SpotMarketChart from './SpotMarketChart';

import SpotMarketFilters from './SpotMarketFilters';
import ForwardMarketFilters from './ForwardMarketFilters';
import LNGMarketFilters from './LNGMarketFilters';

import './PriceHistory.scss';

const t = Locale.getResourceString.bind(Locale);
const axios = require('axios');

class PriceHistory extends React.Component {
    static get ViewKey() { return 'price-history'; }

    static get Downloads() {
        return [
            new CSVDownloadItem('priceHistory.charts.spotMarket.caption', '/historic-prices/spot-market/download', 'spot-market.csv'),
            new CSVDownloadItem('priceHistory.charts.forwardMarket.caption', '/historic-prices/forward-market/download', 'forward-market.csv'),
            new CSVDownloadItem('priceHistory.charts.lngMarket.caption', '/historic-prices/lng-market/download', 'lng-market.csv'),
        ]
    };

    static get _SessionStateKey() {
        return 'emi-japan.price-history.PriceHistory';
    }

    constructor(props, context) {
        super(props);

        this._now = DateRangePicker.getDate(moment());
        this._chartKey = 'spotMarket';  // Manage this outside of state to avoid timing issues
        this._availableDataRange = null;
        this._chartSettings = {
            spotMarket: {
                dataFactory: new SpotMarketPriceFactory(),
                data: null,
                stats: new SpotMarketStats(),
            },
            forwardMarket: {
                dataFactory: new ForwardMarketDataFactory(),
                data: null,
                stats: new ForwardMarketStats(),
            },
            lngMarket: {
                dataFactory: new LNGMarketDataFactory(),
                data: null,
                stats: new LNGMarketStats(),
            },
        }

        // Configure initial state
        this._defaultInitialState = {
            sessionStateChartKey: this._chartKey,

            isNavExpanded: true,

            spotMarketGranularity: 'D',

            forwardMarketSelectedSeries: ForwardMarketFilters.AllSeries,

            lngMarketGranularity: 'M',

            selectedHours: [...Array(24).keys()],
            selectedMonths: [...Array(12).keys()].map(k => k + 1),
            aggregation: 'average',
        }
        this._stateOverrides = {
            filterPanelOpen: false,
            filterPanelIsPinned: false,

            start: this._now,
            end: this._now,
            selectedYears: [],
        }
        this.state = SessionState.get(PriceHistory._SessionStateKey, this._defaultInitialState, this._stateOverrides);

        // Restore saved chartKey
        this._chartKey = this.state.sessionStateChartKey;

        // Bind event handlers
        this._oni18nChanged = this._oni18nChanged.bind(this);
        this._onPin = this._onPin.bind(this);
        this._onChangeChartTab = this._onChangeChartTab.bind(this);
    }

    static ViewDetails(utilityServiceTerritory) {
        let nameKey = Locale.getJSONFieldValue(utilityServiceTerritory.name, "en");
        let series = nameKey.substr(0, nameKey.indexOf(' ')).toLowerCase() + "_areaprice";
        let currentSeries = GlobalSessionState.get().spotMarketSelectedSeries;

        // Patch up session state
        SessionState.set(
            PriceHistory._SessionStateKey,
            SessionState.get(PriceHistory._SessionStateKey, {}, { sessionStateChartKey: 'spotMarket' })
        );

        // If we currently have no selected spot market series, or just 1
        // then set the selected series to the one corresponding to the
        // territory
        if (currentSeries.length <= 1) {
            GlobalSessionState.set({ 
                spotMarketSelectedSeries: [series],
                spotMarketChartKey: 'spotMarket',
            });
        }
        // Otherwise we have multiple selected values already.  Make sure
        // the territory that was just selected is represented.
        else if (currentSeries.indexOf(series) < 0) {
            currentSeries.push(series);
            GlobalSessionState.set({ 
                spotMarketSelectedSeries: currentSeries,
                spotMarketChartKey: 'spotMarket',
            });
        }
    }

    get _chartKey() {
        return GlobalSessionState.get().spotMarketChartKey;
    }
    set _chartKey(value) {
        GlobalSessionState.set({ 
            spotMarketChartKey: value,
        });
    }

    get _dataFactory() {
        return this._chartSettings[this._chartKey].dataFactory;
    }
    set _dataFactory(value) {
        this._chartSettings[this._chartKey].dataFactory = value;
    }

    get _data() {
        return this._chartSettings[this._chartKey].data;
    }
    set _data(value) {
        this._chartSettings[this._chartKey].data = value;
    }

    get _stats() {
        return this._chartSettings[this._chartKey].stats;
    }
    set _stats(value) {
        this._chartSettings[this._chartKey].stats = value;
    }

    _calculateStatsAndRender(state) {
        state = (state != null) ? state : {};
        this.setState(state);
        this._stats.calculate(this._data, Object.assign(this._filters, state));
        this.forceUpdate();
    }

    _getAvailableDataRange() {
        return new Promise((resolve, reject) => {
            Authentication.getAccessToken()
                .then(accessToken => {
                    let authString = 'Bearer '.concat(accessToken);
                    let url = AppConfig.dataService.baseUrl + '/historic-prices/available-data-range';

                    return axios.get(url, {
                        headers: {
                            Authorization: authString,
                            Accept: "application/json",
                            "cache-control": "no-cache",
                        }
                    });
                })
                .then(response => {
                    let data = response.data;
                    data.min = Locale.parseMoment(data.min);
                    data.max = Locale.parseMoment(data.max);

                    resolve(data);
                })
                .catch((error) => {
                    AppTrace.traceError('Error in _getAvailableDataRange: ' + error.message);
                    reject(new Error("Error getting available date range"));
                });
        });
    }

    _getData() {
        return new Promise((resolve, reject) => {
            // If we already have data, then resolve
            if (this._data != null) {
                resolve(this._data);
            }
            else {
                // Set busy state
                let isBusy = BusyState.isBusy;
                BusyState.isBusy = true;

                // Get data
                this._dataFactory.get()
                    .then(data => {
                        this._data = data;
                        resolve(this._data);
                    })
                    .finally(() => {
                        BusyState.isBusy = isBusy;
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    }

    get _minDate() {
        let min = (this._availableDataRange != null) ? 
            this._availableDataRange.min : 
            this._now;
        return DateRangePicker.getDate(min);
    }
    get _maxDate() {
        let max = (this._availableDataRange != null) ? 
            this._availableDataRange.max : 
            this._now;
        return DateRangePicker.getDate(max);
    }

    get _allYears() {
        return (this.__allYears != null) ? this.__allYears : [];
    }
    set _allYears(value) {
        this.__allYears = value;
    }

    get _filters() {
        return {
            spotMarketGranularity: this.state.spotMarketGranularity,
            lngMarketGranularity: this.state.lngMarketGranularity,

            start: this.state.start,
            end: this.state.end,
            selectedHours: this.state.selectedHours,
            selectedMonths: this.state.selectedMonths,
            selectedYears: this.state.selectedYears,
            aggregation: this.state.aggregation,
        };
    }

    _oni18nChanged() {
        this._calculateStatsAndRender();
    }

    _onPin(args) {
        this.setState({ filterPanelIsPinned: args.isPinned });
        if (this.props.onPin != null) {
            this.props.onPin(args);
        }
    }

    _onChangeChartTab(chartKey) {
        this._chartKey = chartKey;
        this.setState({ sessionStateChartKey: this._chartKey })

        // Load and show data for the selected chart
        this._getData()
            .then(data => {
                this._calculateStatsAndRender();
            })
            .catch((error) => {
                ErrorState.setFault(error.message);
            });
    }



    componentDidMount() {
        // Listen for locale and currency-changed events.  We need to recalculate stats 
        // and update because we don't recalculate stats on every render.
        Locale.addLocaleHandler("PriceHistory", this._oni18nChanged);
        Locale.addCurrencyHandler("PriceHistory", this._oni18nChanged);

        BusyState.isBusy = true;

        this._getAvailableDataRange()
            .then(availableDataRange => {
                // Save available date range
                this._availableDataRange = availableDataRange;

                // Derive all years
                let allYears = [];
                for (let y = this._availableDataRange.min.year(); y <= this._availableDataRange.max.year(); ++y) {
                    allYears.push(y);
                }
                this._allYears = allYears;

                // Load and show data for the selected chart
                return this._getData();
            })
            .then(data => {
                if (data.rows != null && data.rows.length > 0) {
                    this._calculateStatsAndRender({ 
                        start: this._minDate,
                        end: this._maxDate, 
                        selectedYears: this._allYears,
                    });
                }
            })
            .catch((error) => {
                ErrorState.setFault(error.message);
            })
            .finally(() => {
                BusyState.isBusy = false;
            });
    }

    componentDidUpdate() {
        // Persist current state
        SessionState.set(PriceHistory._SessionStateKey, this.state);
    }

    componentWillUnmount() {
        // Clean up handlers
        Locale.removeCurrencyHandler("PriceHistory");
        Locale.removeLocaleHandler("PriceHistory");
    }

    renderFilters() {
        let renderers = {
            spotMarket: () => {
                return (
                    <SpotMarketFilters
                        data={this._data}
                        selectedSeries={GlobalSessionState.get().spotMarketSelectedSeries}
                        onChangeSeries={(value) => { 
                            GlobalSessionState.set({ spotMarketSelectedSeries: value }); 
                            this.forceUpdate(); 
                        }}
                        granularity={this.state.spotMarketGranularity}
                        onChangeGranularity={(value) => this._calculateStatsAndRender({ spotMarketGranularity: value })}
                        aggregation={this.state.aggregation}
                        onChangeAggregation={(value) => this._calculateStatsAndRender({ aggregation: value })}

                        minDate={this._minDate}
                        maxDate={this._maxDate}
                        start={this.state.start}
                        end={this.state.end}
                        // NOTE: Do not recalculate stats and update the chart for onChanging because
                        // too many of these will fire.  Wait for the onChange event.
                        onChangingDateRange={(start, end) => this.setState({ start, end })}
                        onChangeDateRange={(start, end) => this._calculateStatsAndRender({ start, end })}

                        selectedHours={this.state.selectedHours}
                        onChangeSelectedHours={(value) => this._calculateStatsAndRender({ selectedHours: value })}
                        selectedMonths={this.state.selectedMonths}
                        onChangeSelectedMonths={(value) => { this._calculateStatsAndRender({ selectedMonths: value }) }}
                        allYears={this._allYears}
                        selectedYears={this.state.selectedYears}
                        onChangeSelectedYears={(value) => { this._calculateStatsAndRender({ selectedYears: value }) }}
                    />
                );
            },

            forwardMarket: () => {
                return (
                    <ForwardMarketFilters
                        selectedSeries={this.state.forwardMarketSelectedSeries}
                        onChangeSeries={(value) => this.setState({ forwardMarketSelectedSeries: value })}

                        minDate={this._minDate}
                        maxDate={this._maxDate}
                        start={this.state.start}
                        end={this.state.end}
                        // NOTE: Do not recalculate stats and update the chart for onChanging because
                        // too many of these will fire.  Wait for the onChange event.
                        onChangingDateRange={(start, end) => this.setState({ start, end })}
                        onChangeDateRange={(start, end) => this._calculateStatsAndRender({ start, end })}

                        selectedMonths={this.state.selectedMonths}
                        onChangeSelectedMonths={(value) => { this._calculateStatsAndRender({ selectedMonths: value }) }}
                        allYears={this._allYears}
                        selectedYears={this.state.selectedYears}
                        onChangeSelectedYears={(value) => { this._calculateStatsAndRender({ selectedYears: value }) }}
                    />
                );
            },

            lngMarket: () => {
                return (
                    <LNGMarketFilters
                        granularity={this.state.lngMarketGranularity}
                        onChangeGranularity={(value) => this._calculateStatsAndRender({ lngMarketGranularity: value })}
                        aggregation={this.state.aggregation}
                        onChangeAggregation={(value) => this._calculateStatsAndRender({ aggregation: value })}

                        minDate={this._minDate}
                        maxDate={this._maxDate}
                        start={this.state.start}
                        end={this.state.end}
                        // NOTE: Do not recalculate stats and update the chart for onChanging because
                        // too many of these will fire.  Wait for the onChange event.
                        onChangingDateRange={(start, end) => this.setState({ start, end })}
                        onChangeDateRange={(start, end) => this._calculateStatsAndRender({ start, end })}

                        selectedMonths={this.state.selectedMonths}
                        onChangeSelectedMonths={(value) => { this._calculateStatsAndRender({ selectedMonths: value }) }}
                        allYears={this._allYears}
                        selectedYears={this.state.selectedYears}
                        onChangeSelectedYears={(value) => { this._calculateStatsAndRender({ selectedYears: value }) }}
                    />
                );
            }
        };

        return renderers[this._chartKey]();
    }

    _renderTabs() {
        return (
            <Tabs
                className="price-history-nav"
                value={this._chartKey}
                onChange={(e, value) => this._onChangeChartTab(value)}
                orientation="vertical"
            >
                <Tab 
                    icon={<TimelineIcon fontSize="default" />} 
                    value="spotMarket" 
                    label={this.state.isNavExpanded && t('priceHistory.charts.spotMarket.caption')}
                    title={t('priceHistory.charts.spotMarket.caption')} 
                />
                <Tab 
                    icon={<ForwardIcon fontSize="default" />} 
                    value="forwardMarket" 
                    label={this.state.isNavExpanded && t('priceHistory.charts.forwardMarket.caption')}
                    title={t('priceHistory.charts.forwardMarket.caption')} 
                />
                <Tab 
                    icon={<WhatshotIcon fontSize="default" />} 
                    value="lngMarket" 
                    label={this.state.isNavExpanded && t('priceHistory.charts.lngMarket.caption')}
                    title={t('priceHistory.charts.lngMarket.caption')} 
                />
            </Tabs>
        );
    }

    _renderTabPanels() {
        return (
            <>
                <TabPanel isRenderedWhenHidden={false} valuekey="spotMarket" value={this._chartKey}>
                    <SpotMarketChart
                        aggregation={this.state.aggregation}
                        aggregated={this._stats.aggregated}
                        data={this._data}
                        series={GlobalSessionState.get().spotMarketSelectedSeries}
                    />
                </TabPanel>
                <TabPanel isRenderedWhenHidden={false} valuekey="forwardMarket" value={this._chartKey}>
                    <ForwardMarketChart
                        aggregated={this._stats.aggregated}
                        series={this.state.forwardMarketSelectedSeries}
                    />
                </TabPanel>
                <TabPanel isRenderedWhenHidden={false} valuekey="lngMarket" value={this._chartKey}>
                    <LNGMarketChart
                        aggregation={this.state.aggregation}
                        aggregated={this._stats.aggregated}
                        data={this._data}
                    />
                </TabPanel>
            </>
        );
    }

    render() {
        return (
            <ErrorBoundary>
                <div className="price-history">
                    <VisualBox caption={t('priceHistory.caption') + ' : ' + t('priceHistory.charts.' + this._chartKey + '.caption')}>
                        <div className="price-history-charts">
                            <div className="jemi-view-filter-button" title={t('priceHistory.filters.caption')}>
                                <Button 
                                    disabled={this.state.filterPanelIsPinned}
                                    variant="primary"
                                    onClick={ () => this.setState({ filterPanelOpen: !this.state.filterPanelOpen }) } >
                                    <Filter fontSize="small" />
                                </Button>
                            </div>
                            <PinnableSettings
                                contentClassName="jemi-filters-container"
                                open={this.state.filterPanelOpen}
                                title={t('priceHistory.filters.caption')}
                                viewKey={PriceHistory.ViewKey}
                                onClose={() => this.setState({ filterPanelOpen: false })}
                                onPin={(args) => this._onPin(args)}
                            >
                                {this.renderFilters()}
                            </PinnableSettings>

                            <VerticalTabNav 
                                className={"vertical-tab-nav-" + Locale.localeName}
                                isExpanded={this.state.isNavExpanded}
                                expandedWidth={150}
                                tabs={this._renderTabs()}
                                tabPanels={this._renderTabPanels()}
                                onChangeIsExpanded={(isExpanded) => this.setState({ isNavExpanded: isExpanded })}
                            />
                        </div>
                    </VisualBox>
                </div>
            </ErrorBoundary>
        );
    }
}

export default PriceHistory;
