import * as Msal from 'msal';
import AppConfig from 'AppConfig';
import ErrorState from 'ErrorState';
import JWT from 'Jwt'

// The Authentication class facilitates signing in and out of the authentication
// provider service.
class Authentication {
    constructor() {
        this.PollTimeoutSeconds = 240; // 4 minutes

        // Create MSAL instance
        this.__msalInstance = new Msal.UserAgentApplication(this._msalConfig);

        // Create an interval that will keep the access token current
        this._refreshAccessToken = this._refreshAccessToken.bind(this);
        this._accessTokenInterval = setInterval(this._refreshAccessToken, this.PollTimeoutSeconds * 1000);

        // Enable - disable the download behavior if the token has the property Roles and that array has an item Purchased 
        this._properties = { isPurchased: false };
    }

    get _msalConfig() {
        // TODO: Could this go in AppConfig?  Or should it stay here to avoid coupling app config settings
        // with authentication service-specific code?
        const msalConfig = {
            auth: {
                clientId: AppConfig.authentication.uiClientId, // App registration
                authority: 'https://login.microsoftonline.com/' + AppConfig.authentication.uiTenantId, 
                postLogoutRedirectUri: window.location.origin + '/',
            },
        };

        return msalConfig;
    }

    get _msalInstance() {
        return this.__msalInstance;
    }

    get _tokenRequest() {
        const tokenRequest = {
            scopes: ['api://{0}/data-api'.replace('{0}', AppConfig.authentication.apiClientId)],
            redirectUri: window.location.origin + '/',
        };
        
        return tokenRequest;
    }

    // Gets whether or not a user is currently signed into the application
    get isSignedIn() {
        return this._msalInstance.getAccount() != null;
    }

    get isPurchased() {
        return this._properties.isPurchased;
    }
 
    set isPurchased(value) {
        this._properties.isPurchased = value;
    }

    // Sign in signs the user into the authentication provider and returns
    // a promise that resolves with the claims associated with the logged-in
    // user's id_token
    signIn() {
        const loginRequest = {
            scopes: ['user.read'],
        };

        let account = this._msalInstance.getAccount();
        if (account != null && account.idToken != null) {
            // TODO: How to get the original ID token here?  Do we need it for AppTrace calls?
            // OR, do we want to set up a new API that is authenticated and get an access token for it?
            return Promise.resolve(account.idToken);
        } else {
            return new Promise((resolve, reject) => {
                this._msalInstance
                    .loginPopup(loginRequest)
                    .then((response) => {
                        // Resolve with claims
                        resolve(response.idToken.claims);
                    })
                    .catch((error) => {
                        console.error('Error in Authentication.signIn: ' + error.message);
                        reject(error);
                    });
            });
        }
    }

    // getAccessToken gets an access_token object that can be used as a
    // bearer token to call an API associated with the application
    getAccessToken() {
        return new Promise((resolve, reject) => {
            this._msalInstance
                .acquireTokenSilent(this._tokenRequest)
                .then((response) => {
                    let jwt = new JWT(response.accessToken)
                    this.isPurchased = jwt.hasRole(AppConfig.roles.purchased)

                    resolve(response.accessToken);
                })
                .catch((error) => {
                    console.error('Error in Authentication.getAccessToken: ' + error.message);
                    reject(error);
                });
        });
    }

    // signOut signs the current user out of the application and returns
    // a promise that resolves with the signout is complete
    signOut() {
        if (!this.isSignedIn && ErrorState.faultCategory !== ErrorState.faultCategories.SignIn) {
            return Promise.resolve();
        } else {
            return new Promise((resolve, reject) => {
                try {
                    this._msalInstance.logout();
                    clearInterval(this._accessTokenInterval);
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        }
    }


    // Refreshes the access token on an interval
    _refreshAccessToken() {
        this._msalInstance
            .acquireTokenSilent(this._tokenRequest)
            .then((response) => {
                // TODO: No-op
            });
    }
}

const _instance = new Authentication();
Object.freeze(_instance);

export default _instance;
