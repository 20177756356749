/////////////////////////////////////////////////////////////////////////////
// Common utility functions for JEMI
/////////////////////////////////////////////////////////////////////////////
class Utils {
    getShortServiceTerritoryName(name) {
        return name.replace(/\sElectric.+/, '');
    }
}

const _instance = new Utils();
Object.freeze(_instance);

export default _instance;
