import React from 'react';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { GeoJSON } from 'ol/format';
import { Style, Stroke, Fill } from 'ol/style';
import AppConfig from '../../AppConfig';
import AppTrace from '../../AppTrace';
import ErrorState from '../../ErrorState';
import Layer from './Layer';
import Locale from '../../locale/Locale';
import Legend from '../legend/Legend';
import UtilityServiceTerritoryPopup from '../popup/utility-service-territories/UtilityServiceTerritoryPopup';

const t = Locale.getResourceString.bind(Locale);
const wkx = require('wkx');

export default class UtilityServiceTerritories extends Layer {
    // Theming is based on unique entities
    // Using "Flat Design Color Chart", row 6, from:
    // https://htmlcolorcodes.com/color-chart/
    static Palette = [
        [183, 28, 28 ], // Red (from row 10 for better difference from dark orange)
        [255, 87, 34], // Dark orange
        [255, 152, 0], // Orange
        [255, 235, 59], // Yellow
        [76, 175, 80], // Non-putrescent green
        [0, 150, 136], // Blue-green
        [3, 169, 244], // Nice blue
        [63, 81, 181], // Indigo
        [156, 39, 176], // Purple
        [121, 85, 72], // Brown
        [233, 30, 99], // Pink-ish
        [96, 125, 139], // Dark gray
        [158, 158, 158], // Light gray
    ];

    constructor() {
        super();

        this._allFeatures = [];
    }

    // Gets a string key that identifies this layer in collections, localization resource, etc.
    get key() {
        return "utilityServiceTerritories";
    }

    // Gets the stack order of the layer.  Lower zIndex layers are stacked at the bottom
    get zIndex() {
        // I'm keeping polygon layers on the bottom: 0-10
        return 1;
    }

    // Gets the underlying layer object
    get vectorLayer() {
        return this._vectorLayer;
    }

    // Renders popup content for a feature in this layer
    renderPopupContent(feature) {
        return (
            <UtilityServiceTerritoryPopup
                feature={feature}
            />
        );
    }

    // Zooms to a selected feature
    zoomToFeature(feature, view) {
        let extent = feature.getGeometry().getExtent();
        view.fit(extent);
    }



    _getUrl(view) {
        return AppConfig.dataService.baseUrl + '/spatial-awareness/utility-service-territories';
    }

    _createLayer(view) {
        try {
            // Derive all graphics
            this._paletteLookup = {};
            this._allFeatures = this._getAllFeatures();

            this._vectorLayer = new VectorLayer({
                key: this.key,
                source: new VectorSource({
                    features: this._allFeatures,
                }),
                style: this._getStyle,
                opacity: 0.5,
                visible: true,
            });
        }
        catch (err) {
            AppTrace.traceError("UtilityServiceTerritories.ctor: " + err)
            ErrorState.setFault("Error mapping utility service territories");
        }
    }

    // Gets the default style for the selected feature
    _getDefaultStyle(feature, resolution) {
        let paletteIndex = feature.getId() % UtilityServiceTerritories.Palette.length;
        return new Style({
            stroke: new Stroke({
                color: [128, 128, 128, 0.5],
                width: 0.5
            }),
            fill: new Fill({
                color: UtilityServiceTerritories.Palette[paletteIndex]
            })
        });
    }

    // Renders legend content for this layer
    _renderDefaultLegendContent() {
        const items = this._allFeatures.map(ust =>
            <div key={ust.getId()}>
                <span className="ev-legend-symbol" style={{ color: Legend.rgbToHex(UtilityServiceTerritories.Palette[ust.getId() % UtilityServiceTerritories.Palette.length]) }}>&#9648;</span>
                <span className="ev-legend-theme-value" title={Locale.getJSONFieldValue(ust.getProperties().name)}>{Locale.getJSONFieldValue(ust.getProperties().name)}</span>
                <br />
            </div>
        );

        if (this.isVisible) {
            return (
                <div key={this.key + '-legend-content'} className="ev-legend-layer-content">
                    <span className="ev-legend-layer-name">{t('spatialAwareness.layers.utilityServiceTerritories.title')}</span><br />
                    {items}
                </div>
            );
        }
    }

    // Generate all of the features that will be added to the map.
    _getAllFeatures() {
        let features = [];
        let olGeoJSON = new GeoJSON();

        // Iterate over all entities in the API data
        let idIndex = this._apiData.metadata.columns.id.index;
        let nameIndex = this._apiData.metadata.columns.companyName.index;
        let geographyIndex = this._apiData.metadata.columns.geography.index;

        for (let i = 0; i < this._apiData.rows.length; ++i) {
            let row = this._apiData.rows[i];

            // Parse wkb format
            let wkbBuffer = new Buffer(row[geographyIndex], 'hex');
            let geoJSON = wkx.Geometry.parse(wkbBuffer).toGeoJSON();

            // Create the feature - tell GeoJSON that the data is in EPSG:4326, and to project it to EPSG:3857
            let feature = new Feature({
                layerKey: this.key,
                entityId: row[idIndex],
                name: row[nameIndex],

                geometry: olGeoJSON.readGeometry(geoJSON, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                })
            });
            feature.setId(i);

            // Add a feature
            features.push(feature);
        }

        return features;
    }
}