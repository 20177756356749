import Locale from 'locale/Locale';

export default class MarketStats {
    constructor() {
        this._filtered = [];

        this._aggregated = [];

        this.dl = require('@hitachi.energy.digitalforce.node/datalib').dl;

        this._aggregationLookup = {
            min: this.dl.min,
            max: this.dl.max,
            average: this.dl.mean,
            sum: this.dl.sum,
            stdev: this.dl.stdev,
            count: this.dl.count
        }
    }

    get filtered() {
        return this._filtered;
    }

    get aggregated() {
        return this._aggregated;
    }

    calculate(data, filters) {
        const NonValueColumns = {
            datetime: true,
            timezone: true,
            conv_eur: true,
            conv_usd: true,
        };

        // Clean up old calculated values
        this._cleanup();

        // Filter the data based on state
        let valueColumns = Object.values(data.metadata.columns).filter(c => !NonValueColumns.hasOwnProperty(c.name));
        this._filtered = this._filter(data, filters, valueColumns);
        this._aggregated = this._aggregate(data, filters, valueColumns);
    }



    get _granularityField() {
        throw new Error('Not implemented');
    }

    _cleanup() {
        if (this._aggregated != null) {
            this._aggregated.length = 0;
        }

        if (this._filtered != null) {
            this._filtered.length = 0;
        }
    }

    _beforeFilter(data, filters, valueColumns) {
        throw new Error('Not implemented');
    }

    _filter(data, filters, valueColumns) {
        let filtered = [];

        // Prepare filters for use
        this._beforeFilter(data, filters, valueColumns);

        if (data != null) {
            // Get property indexes
            let dateTimeIndex = data.metadata.columns.datetime.index;

            for (let i = 0; i < data.rows.length; ++i) {
                let row = data.rows[i];
                let theMoment = row[dateTimeIndex];

                if (this._rowMeetsFilterCriteria(theMoment, filters)) {
                    // Get date category
                    let momentCategory = this._getMomentCategory(theMoment, filters);
                        
                    let fields = valueColumns.reduce((obj, col) => { 
                        obj[col.name] = (col.tag != null && col.tag.isCurrencyField) ? 
                            Locale.getJSONCurrencyValue(row[col.index]) :
                            row[col.index];
                        return obj; 
                    }, {})
                    Object.assign(fields, { momentCategory: momentCategory });
                    filtered.push(fields);
                }
            }
        }

        //console.log(filtered.length);

        return filtered;
    }

    _getMomentCategory(theMoment, filters) {
        throw new Error('Not implemented');
    }

    _rowMeetsFilterCriteria(theMoment, filters) {
        throw new Error('Not implemented');
    }

    _aggregate(data, filters, valueColumns) {
        let aggregated = [];

        // Aggregate the data
        aggregated = this.dl.groupby('momentCategory').summarize(
            valueColumns.map(col => { 
                return {
                    name: col.name,
                    ops: [filters.aggregation],
                    as: [col.name]
                };
            })
        ).execute(this._filtered);

        // Order by the categorizied moment
        aggregated.sort(this.dl.comparator('momentCategory'));

        // Assign a human-readable category
        for (let i = 0; i < aggregated.length; ++i) {
            let row = aggregated[i];
            row.category = Locale.formatMomentGranularity(row.momentCategory, filters[this._granularityField]);
            for (let j = 0; j < valueColumns.length; ++j) {
                let value = row[valueColumns[j].name];
                if (Number.isNaN(value) || !Number.isFinite(value)) {
                    row[valueColumns[j].name] = null;
                }
            }
        }

        //console.log(aggregated);

        return aggregated;
    }
}