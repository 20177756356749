import AppConfig from 'AppConfig';
import AppTrace from 'AppTrace';
import Authentication from 'Authentication';
import DataFormatting from 'DataFormatting';

import PriceHistoryDataFactory from './PriceHistoryDataFactory';

const axios = require('axios');

export default class SpotMarketDataFactory extends PriceHistoryDataFactory {
    get() {
        return new Promise((resolve, reject) => {
            // Get access token
            Authentication.getAccessToken()
                // Get API data
                .then(accessToken => {
                    let authString = 'Bearer '.concat(accessToken);
                    let url = AppConfig.dataService.baseUrl + '/historic-prices/spot-market-chart-data';

                    return axios.get(url, {
                        headers: {
                            Authorization: authString,
                            Accept: "application/json",
                            "cache-control": "no-cache",
                        }
                    });
                })
                .then(response => {
                    let data = response.data;
                    this._dateTimeCol = data.metadata.columns.datetime;
                    DataFormatting.convertResultFields(data);
                    this._postProcessData(data);

                    resolve(data);
                })
                .catch((error) => {
                    AppTrace.traceError('Error in SpotMarketDataFactory.get: ' + error.message);
                    reject(new Error("Error getting spot market prices"));
                });
        });
    }
}