import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EyeHide from 'webcore-ux/react/components/Icons/EyeHide';
import EyeView from 'webcore-ux/react/components/Icons/EyeView';

import ErrorBoundary from 'ErrorBoundary';
import Locale from 'locale/Locale';

import 'common/MuiTreeView.scss';

const t = Locale.getResourceString.bind(Locale);

class GeneratingUnits extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {
            expandedNodeIds: [],
        }
    }

    _createDetailNodes(unit, unitNodeKey) {
        return (
            <ErrorBoundary>
                <TreeItem
                    nodeId={unitNodeKey + '-primeMover'} 
                    label={
                        <span>
                            <span className="tree-title-field-name">
                                {t('spatialAwareness.layers.powerPlants.popup.primeMover')}
                            </span>
                            &nbsp;{Locale.getJSONFieldValue(unit.primeMover)}
                        </span>
                    }
                />
                <TreeItem
                    nodeId={unitNodeKey + '-fuel'} 
                    label={
                        <span>
                            <span className="tree-title-field-name">
                                {t('spatialAwareness.layers.powerPlants.popup.primaryFuel')}
                            </span>
                            &nbsp;{Locale.getJSONFieldValue(unit.fuel)}
                        </span>
                    }
                />
                <TreeItem
                    nodeId={unitNodeKey + '-capacity'} 
                    label={
                        <span>
                            <span className="tree-title-field-name">
                                {t('spatialAwareness.layers.powerPlants.popup.capacity')}
                            </span>
                            &nbsp;{unit.capacity}&nbsp;{t('units.megaWatts')}
                        </span>
                    }
                />
                <TreeItem
                    nodeId={unitNodeKey + '-onlineDate'} 
                    label={
                        <span>
                            <span className="tree-title-field-name">
                                {t('spatialAwareness.layers.powerPlants.popup.onlineDate')}
                            </span>
                            &nbsp;{unit.onlineDate != null ? Locale.formatDate(Locale.parseMoment(unit.onlineDate)) : t('general.noDataAvailable')}
                        </span>
                    }
                />
                <TreeItem
                    nodeId={unitNodeKey + '-retirementDate'} 
                    label={
                        <span>
                            <span className="tree-title-field-name">
                                {t('spatialAwareness.layers.powerPlants.popup.retirementDate')}
                            </span>
                            &nbsp;{unit.retirementDate != null ? Locale.formatDate(Locale.parseMoment(unit.retirementDate)) : t('general.noDataAvailable')}
                        </span>
                    }
                />
            </ErrorBoundary>
        );
    }

    get _allExpandableNodeIds() {
        let nodeIds = Object.keys(this.props.allStati);
        nodeIds.push.apply(nodeIds, this.props.unitsNow.map((unit) => this._getUnitNodeKey(unit)));
        return nodeIds;
    }

    _getUnitNodeKey(unit) {
        return 'unit-id-' + unit.id;
    }

    render() {
        if (this.props.unitsNow == null || this.props.unitsNow.length === 0) {
            return (
                <>
                    <span className="feature-popup-property-name">
                        {t('spatialAwareness.layers.powerPlants.popup.generatingUnits')}
                    </span>
                    <span className="feature-popup-property-value">{t('general.noDataAvailable')}</span>
                    <br />
                </>
            );
        } else {
            return (
                <>
                    <span className="feature-popup-property-name property-tree-name">
                        {t('spatialAwareness.layers.powerPlants.popup.generatingUnits')}
                    </span>
                    <div className="property-tree-icon" title={t('general.expandAll')} onClick={() => this.setState({ expandedNodeIds: this._allExpandableNodeIds }) }>
                        <EyeView fontSize="small" />
                    </div>
                    <div className="property-tree-icon" title={t('general.collapseAll')} onClick={() => this.setState({ expandedNodeIds: [] }) }>
                        <EyeHide fontSize="small" />
                    </div>
                    <br />
                    <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        disableSelection={true}
                        expanded={this.state.expandedNodeIds}
                        onNodeToggle={(e, nodeIds) => { this.setState({ expandedNodeIds: nodeIds }) }}
                    >
                        {Object.keys(this.props.allStati).map(status =>
                            <TreeItem key={status} nodeId={status} label={<span className="tree-title-field-name">{this.props.allStati[status]}</span>}>
                                {this.props.unitsNow.filter((u) => u.statusKey === status).map((unit) => {
                                    let unitNodeKey = this._getUnitNodeKey(unit);
                                    // The unit node is displayed as "<Unit Name> (<Prime Mover> - <Primary Fuel>")
                                    return (
                                        <TreeItem key={unitNodeKey} nodeId={unitNodeKey} label={
                                            <span>
                                                {Locale.getJSONFieldValue(unit.name)}&nbsp;({Locale.getJSONFieldValue(unit.primeMover)}&nbsp;&#8208;&nbsp;
                                                {Locale.getJSONFieldValue(unit.fuel)})
                                            </span>
                                        }>
                                            {this._createDetailNodes(unit, unitNodeKey)}
                                        </TreeItem>
                                    );
                                })}
                            </TreeItem>
                        )}
                    </TreeView>
                </>
            );
        }
    }
}

export default GeneratingUnits;
