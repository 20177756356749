import Locale from 'locale/Locale';
import moment from 'moment';

export default class WeatherStats {
    constructor() {
        this.dl = require('@hitachi.energy.digitalforce.node/datalib').dl;

        this._aggregationLookup = {
            min: this.dl.min,
            max: this.dl.max,
            average: this.dl.mean,
            sum: this.dl.sum,
            stdev: this.dl.stdev,
            count: this.dl.count
        }

        this._valueColumns = [];

        this._filtered = [];
        this._aggregated = [];
    }

    get filtered() {
        return this._filtered;
    }

    get aggregated() {
        return this._aggregated;
    }

    calculate(data, filters) {
        // Bail on invalid data
        if (data == null || data.rows.length === 0) {
            return;
        }

        // Clean up old calculated values
        this._cleanup();

        // Extract column indexes
        this._datetimeIndex = data.metadata.columns.datetime.index;

        // Get value columns from data source
        this._valueColumns = Object.values(data.metadata.columns).filter(col => col.index > 0);

        // Perform other pre-filtering steps
        this._beforeFilter(data, filters);

        // Now filter and aggregate
        this._filtered = this._filter(data, filters);
        this._aggregated = this._aggregate(data, filters);
    }



    _cleanup() {
        if (this._aggregated != null) {
            this._aggregated.length = 0;
        }

        if (this._filtered != null) {
            this._filtered.length = 0;
        }
    }

    _beforeFilter(data, filters) {
        // Copy a reference to start so we look in the same place for start as end
        this._start = filters.start;
        // Change the end date so that we look up to midnight of the next day
        this._end = moment.utc([filters.end.year(), filters.end.month(), filters.end.date()]).add(1, 'days');

        // Create lookups for selected time components
        this._selectedHours = filters.selectedHours.reduce((obj, h) => { obj[h] = true; return obj; }, {});
        this._selectedMonths = filters.selectedMonths.reduce((obj, m) => { obj[m] = true; return obj; }, {});
        this._selectedYears = filters.selectedYears.reduce((obj, y) => { obj[y] = true; return obj; }, {});
    }

    _filter(data, filters) {
        let filtered = [];

        for (let i = 0; i < data.rows.length; ++i) {
            let row = data.rows[i];
            let theMoment = row[this._datetimeIndex];

            if (this._rowMeetsFilterCriteria(theMoment)) {
                // Get date category
                let momentCategory = this._getMomentCategory(theMoment, filters);

                // Get filtered row fields using template-hook pattern to sub-classes
                let fields = this._getFields(row);

                // Patch in a moment category
                Object.assign(fields, { momentCategory: momentCategory });

                filtered.push(fields);
            }
        }

        //console.log(filtered.length);

        return filtered;
    }

    _getMomentCategory(theMoment, filters) {
        // Default: hourly
        let momentCategory = theMoment;
        let granularity = filters.granularity;

        // Annual
        if (granularity === 'A') {
            momentCategory = moment.utc([theMoment.year(), 1, 1]);
        }
        // Monthly
        else if (granularity === 'M') {
            momentCategory = moment.utc([theMoment.year(), theMoment.month()]);
        }
        // Daily
        else if (granularity === 'D') {
            momentCategory = moment.utc([theMoment.year(), theMoment.month(), theMoment.date()]);
        }
        // Month of Year
        else if (granularity === 'MoY') {
            // Use an arbitrary year to group by the month
            momentCategory = moment.utc([2020, theMoment.month()]);
        }
        // Hour of Day
        else if (granularity === 'HoD') {
            // Use an arbitrary year, month, and day to group by the hour only
            momentCategory = moment.utc([2020, 0, 1, theMoment.hour()]);
        }

        return momentCategory;
    }

    _rowMeetsFilterCriteria(theMoment) {
        let rowMeetsFilterCriteria = false;

        // Filter for start/end date
        if (this._start.isSameOrBefore(theMoment) && theMoment.isBefore(this._end)) {
            // Filter for hour/month/year selections
            if (this._selectedHours[theMoment.hour()] &&
                this._selectedMonths[theMoment.month() + 1] &&
                this._selectedYears[theMoment.year()]) {
                    rowMeetsFilterCriteria = true;
            }
        }

        return rowMeetsFilterCriteria;
    }

    _aggregate(data, filters) {
        let aggregated = [];

        // Aggregate the data
        aggregated = this.dl.groupby('momentCategory').summarize(
            this._valueColumns.map(col => { 
                return {
                    name: col.name,
                    ops: [filters.aggregation],
                    as: [col.name]
                };
            })
        ).execute(this._filtered);

        // Order by the categorizied moment
        aggregated.sort(this.dl.comparator('momentCategory'));

        // Assign a human-readable category
        for (let i = 0; i < aggregated.length; ++i) {
            let row = aggregated[i];
            row.category = Locale.formatMomentGranularity(row.momentCategory, filters.granularity);
            for (let j = 0; j < this._valueColumns.length; ++j) {
                let value = row[this._valueColumns[j].name];
                if (Number.isNaN(value) || !Number.isFinite(value)) {
                    row[this._valueColumns[j].name] = null;
                }
            }
        }

        // console.log(aggregated);

        return aggregated;
    }



    ////////////////////////////////////////////////////////////////////////////
    // "Protected" methods
    ////////////////////////////////////////////////////////////////////////////
    _getFields(row) {
        throw new Error('not implemented');
    }
}
