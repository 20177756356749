import React from 'react';
import PropTypes from 'prop-types';

import './TabPanel.scss';

////////////////////////////////////////////////////////////////////////////////
// TabPanel encapsulates the content of a Mui/WebCore UX tab "page".
////////////////////////////////////////////////////////////////////////////////
class TabPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            isCurrentTab: false,
        };
    }

    static getDerivedStateFromProps(props, state) {
        let isCurrentTab = props.valuekey === props.value;
        return {
            isLoaded: state.isLoaded || isCurrentTab,
            isCurrentTab: isCurrentTab,
        };
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gets whether or not to render the tab's children.
    ////////////////////////////////////////////////////////////////////////////
    get _shouldRenderChildren() {
        // The tab must be loaded AND it must either be configured 
        // to render when hidden or be the current tab
        return ((this.state.isLoaded) && (this.props.isRenderedWhenHidden || this.state.isCurrentTab));
    }

    render() {
        const activeTabStyle = { position: 'inherit' };
        const inactiveTabStyle = { position: 'absolute', transform: 'scale(0)' };
        let isHidden = this.props.valuekey !== this.props.value;

        // Simply hiding the tab panel content is great when we don't want
        // to render the children.  However, there are situations when we
        // do want to keep children rendered (e.g. we don't want to lose
        // split-panel sizes, or we don't want charts to re-animate when 
        // re-shown).  So provide a different means of hiding inactive tabs
        // when we want inactive children to be rendered.
        return (this._shouldRenderChildren) ?
        (
            <div 
                key={'tab-panel-' + this.props.valuekey}
                className="de-tab-panel" 
                role="tabpanel" 
                style={ isHidden ? inactiveTabStyle : activeTabStyle }
            >
                {this.props.children}
            </div>
        ) :
        (
            <div 
                key={'tab-panel-' + this.props.valuekey}
                className="de-tab-panel" 
                role="tabpanel" 
                hidden={isHidden}
            >
            </div>
        );
    }
}

TabPanel.propTypes = {
    isRenderedWhenHidden: PropTypes.bool,
}

TabPanel.defaultProps = {
    isRenderedWhenHidden: true,
}

export default TabPanel;
