export default class MarketClearingStats {
    constructor() {
        this._aggregated = [];

        this.dl = require('@hitachi.energy.digitalforce.node/datalib').dl;

        this._aggregationLookup = {
            min: this.dl.min,
            max: this.dl.max,
            average: this.dl.mean,
            sum: this.dl.sum,
            stdev: this.dl.stdev,
            count: this.dl.count
        }
    }

    get aggregated() {
        return this._aggregated;
    }

    _cleanup() {
        if (this._aggregated != null) {
            this._aggregated.length = 0;
        }
    }


    calculate(series, data, filters) {
        this._cleanup()

        let aggregated = []

        aggregated = this.dl.groupby('category')
            .summarize(
                series.map(s => { 
                    return {
                        name: s,
                        ops: [filters.aggregation],
                        as: [s]
                    };
                }))
            .execute(data)
        

        // Replace invalid numbers with nulls
        for (let i = 0; i < aggregated.length; ++i) {
            let row = aggregated[i];

            for (let j = 0; j < series.length; ++j) {
                let value = row[series[j]];
                if (Number.isNaN(value) || !Number.isFinite(value)) {
                    row[series[j]] = null;
                }
            }
        }

        return aggregated;
    }
}