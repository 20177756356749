import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ResponsiveContainer, ComposedChart, Bar, Line, Scatter, XAxis, YAxis, Legend, Tooltip, Label } from 'recharts';

import Locale from 'locale/Locale';
import ErrorBoundary from 'ErrorBoundary';

import SupplyAndDemandChartTooltip from './SupplyAndDemandChartTooltip';

import 'common/ReCharts.scss';

const t = Locale.getResourceString.bind(Locale);

/////////////////////////////////////////////////////////////////////////////
// This class encapsulates the spot-market prices chart
/////////////////////////////////////////////////////////////////////////////
class SupplyAndDemandChart extends Component {
    static _LocalPalette = {
        reserveMargin: '#000',
        peakload: '#525252', // grey 70
        total_supply: '#CFD8DC',
    };

    constructor(props, context) {
        super(props);

        this.state = {
        };

        // Bind event handlers
        this._onTooltipFormatLabel = this._onTooltipFormatLabel.bind(this);
        this._onTooltipFormatValue = this._onTooltipFormatValue.bind(this);
    }

    get _series() {
        let series = this.props.barCategoryColumns.map(col => {
            let key = col.name;
            let color = this.props.palette.getColor(key);
            return (
                <Bar
                    key={key} 
                    dataKey={key} 
                    fill={color} 
                    stroke={color} 
                    isAnimationActive={false}
                    name={Locale.getJSONFieldValue(this.props.seriesLookup[key])} 
                    stackId={0}
                    yAxisId={0}
                />
            );
        });

        // Add total supply scatter series
        let key = 'total_supply';
        series.push(
            <Scatter 
                key={key} 
                dataKey={key} 
                fill={SupplyAndDemandChart._LocalPalette[key]}
                stroke={SupplyAndDemandChart._LocalPalette[key]}
                legendType="square" 
                name={t('supplyAndDemand.chart.totalSupply')} 
            />
        );

        // Add the peakload series if needed
        if (this.props.showPeakLoad) {
            let key = 'peakload';
            series.push(
                <Line
                    key={key} 
                    dataKey={key} 
                    dot={false}
                    isAnimationActive={false}
                    name={this.props.peakLoadSeriesName} 
                    stroke={SupplyAndDemandChart._LocalPalette.peakload} 
                    yAxisId={0}
                    strokeWidth={2} 
                />
            );
        }

        // Add the reserve margin series if needed
        if (this.props.showReserveMargin) {
            let key = 'reserveMargin';
            series.push(
                <Line
                    key={key} 
                    dataKey={key} 
                    dot={false}
                    isAnimationActive={false}
                    name={t('supplyAndDemand.chart.reserveMargin')} 
                    stroke={SupplyAndDemandChart._LocalPalette[key]}
                    strokeWidth={2} 
                    yAxisId={1}
                />
            );
        }

        return series;
    }

    get _yAxisLabelText() {
        return (this.props.isRefcaseDataSource) ?
            t('supplyAndDemand.chart.generation') + ' ' + t('units.gigaWattHour') :
            t('supplyAndDemand.chart.capacity') + ' ' + t('units.megaWatts');
    }

    get _secondaryYAxisLabelText() {
        return t('supplyAndDemand.chart.reserveMargin') + ' ' + t('units.percent');
    }



    _formatCapacity(v) {
        return (v != null) ? 
            Locale.formatNumber(v, 0) + ' ' + t('units.megaWatts') :
            t('general.noDataAvaialble');
    }

    _formatPercent(v) {
        return (v != null) ? 
            Locale.formatPercent(v) :
            t('general.noDataAvaialble');
    }

    _onTooltipFormatLabel(value) {
        return (<span>{Locale.formatMomentGranularity(moment.utc([value, 1, 1]), 'A')}</span>);
    }

    _onTooltipFormatValue(value, name, props) {
        return (props.dataKey === 'reserveMargin') ?
            this._formatPercent(value) :
            this._formatCapacity(value);
    }



    render() {
        // Locals
        let chartMarginBottom = this.props.isRefcaseDataSource ? 28 : 10;

        // Get JSX for secondary axis as needed
        let secondaryYAxis = (this.props.showReserveMargin) ? (
            <YAxis 
                orientation="right"
                width={60}
                yAxisId={1}
                tickFormatter={ (v) => this._formatPercent(v) }
            >
                <Label 
                    angle={90} 
                    position='insideRight'
                    value={this._secondaryYAxisLabelText}
                    style={{textAnchor: 'middle'}}
                />
            </YAxis>) :
            null;

        return (
            <ErrorBoundary>
                <ResponsiveContainer>
                    <ComposedChart
                        data={this.props.data}
                        margin={{
                            top: 60, right: 20, bottom: chartMarginBottom, left: 10,
                        }}
                    >
                        <XAxis 
                            dataKey="year" 
                            height={42}
                            tickFormatter={(v) => Locale.formatMomentGranularity(moment.utc([v, 1, 1]), 'A')} 
                        >
                            <Label 
                                position='insideBottom'
                                value={Locale.getJSONFieldValue(this.props.seriesLookup['year'])}
                            />
                        </XAxis>
                        <YAxis 
                            width={90}
                            yAxisId={0}
                            tickFormatter={ (v) => Locale.formatNumber(v, 0) }
                        >
                            <Label 
                                angle={-90} 
                                position='insideLeft'
                                value={this._yAxisLabelText}
                                style={{textAnchor: 'middle'}}
                            />
                        </YAxis>
                        {secondaryYAxis}
                        <Tooltip 
                            isAnimationActive={false} 
                            labelFormatter={this._onTooltipFormatLabel}
                            formatter={this._onTooltipFormatValue} 
                            content={<SupplyAndDemandChartTooltip barCategoryColumns={this.props.barCategoryColumns} />}
                        />
                        <Legend />

                        {this._series}
                    </ComposedChart>
                </ResponsiveContainer>
            </ErrorBoundary>
        );
    }
}

SupplyAndDemandChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    barCategoryColumns: PropTypes.arrayOf(PropTypes.object),
    palette: PropTypes.object,
    serviceTerritoryIds: PropTypes.arrayOf(PropTypes.number),
    showPeakLoad: PropTypes.bool,
    showReserveMargin: PropTypes.bool,
    ustsLookup: PropTypes.object,
    seriesLookup: PropTypes.object,
    isRefcaseDataSource: PropTypes.bool,
    peakLoadSeriesName: PropTypes.string,
}

SupplyAndDemandChart.defaultProps = {
    data: [],
    barCategoryColumns: [],
    serviceTerritoryIds: [],
    showPeakLoad: true,
    showReserveMargin: true,
    ustsLookup: {},
    seriesLookup: {},
    isRefcaseDataSource: false,
    peakLoadSeriesName: 'Peak Load',
}

export default SupplyAndDemandChart;
