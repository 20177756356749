export default {
    header: {
        anonymous: 'ゲスト',

        english: 'English',
        japanese: '日本語',

        signIn: 'サインイン',
        signOut: 'サインアウト',

        currency: '通貨',
        language: '言語',
        downloads: 'ダウンロード',
        downloadsTooltip: '無償トライアル版にはデータとシェープファイルのダウンロードサービスは含まれていません。製品版にアップデートしてください。',

        temperature: '温度',
        speed: '速度',
    },

    footer: {
        analysisDerivedFrom: 'Velocity Suiteに基づく分析',
    },

    licensing: {
        caption: '著作権とライセンス',
        copyright: '著作権 {0}-{1} {2}。無断複製禁止。',
        thirdParty: 'サードパーティの通知と情報',
        thisProductMayInclude: '本ソフトウェアには、以下のファイル（以下、第三者製フリー/オープンソースソフトウェア「FOSS」と総称します）の素材が含まれていたり、組み込まれていたりする場合があります。注意：{0}はFOSSの原著作者ではありません。オリジナルのファイルソースのURL、オリジナルの著作権表示、および{0}がFOSSを利用する際のライセンスは、ライセンスの全文とともに以下に記載されています。これらの通知とライセンスは、お客様への情報提供のみを目的として提供されます。第三者ではなく{0}は、お客様の{0}のソフトウェアまたはサービスの受領規約に基づき、このFOSSをお客様にライセンスしています。適用される法律がお客様にさらなる権利を与えない限り、{0}は暗示、禁反言、またはその他の方法であるかどうかにかかわらず、そのような契約の下で明示的に付与されていない他のすべての権利を留保します。適用されるFOSSライセンス（GNU Lesser/General Public Licenseなど）に基づき、お客様が権利を有するソースコードへのアクセスを希望される場合、またはFOSSに関するご質問がある場合は、書面にて以下までご要望をお送りください。',
    },

    fault: {
        applicationName: '日本エネルギー市場インテリジェンス',

        caption: 'アプリケーションエラー',
        buttonRefresh: 'ページの更新',
        buttonOkay: 'はい',

        contentOverview: '次のエラーが発生しました',
        contentInstructions: 'ページを更新するか、後でもう一度やり直してください。それでも問題が解決しない場合は、カスタマーサポートに連絡してください。',
        contentSupport: 'サポート問い合わせ先',

        signInFailed: 'ログインに失敗しました',
        signInInstructions: 'サインアウトしてから、もう一度サインインしてください。それでも問題が解決しない場合は、カスタマーサポートにお問い合わせください。',
        
        popupBlocked: 'ポップアップがブロックされました',
        popupInstructions: 'アプリはログインポップアップを開くことができません。このサイトからのポップアップを許可し、ページを更新してください。それでも問題が解決しない場合は、カスタマーサポートにお問い合わせください。',
    },

    loadIndicator: {
        caption: '読み込み中です。お待ち下さい。',
    },

    tabs: {
        spatialAwareness: '地理的全体像',
        supplyAndDemand: '需要と供給',
        priceHistory: '価格履歴',
        priceForecast: '価格予測',
        companyFinancials: '企業財務',
        weather: "天気",
    },

    grids: {
        previous: '前へ',
        page: 'ページ',
        of: '/',
        next: '次へ',
    },

    navigation: {
        viewDetails: '詳細を見る',
    },

    units: {
        megaWatts: 'MW',
        megaWattHour: 'MWh',
        kiloLiters: 'kl',
        kiloWatts: 'kW',
        kiloWattHour: 'kWh',
        kiloVolts: 'kV',
        sqkilometers: 'Km2',
        mmBtu: 'MMBtu',
        gigajoule: 'GJ',
        percent: '％',
        millionsOf_JPY: '百万円',
        millionsOf_USD: '百万ドル',
        millionsOf_EUR: '百万ユーロ',
        celsius: '摂氏',
        fahrenheit: '華氏度',
        metersPerSecond: 'メートル/秒',
        milesPerHour: 'マイル/時',
        gigaWattHour: 'GWh',
    },

    general: {
        noDataAvailable: 'データなし',
        undetermined: '未定',
        selectAll: 'すべて選択',
        clearAll: 'すべてクリア',
        select: '選択する',
        multipleSelectedValues: '選択済みの複数値',
        showData: 'データを表示する',
        hideData: 'データを隠す',

        lineChart: '折れ線グラフ',
        barChart: '棒グラフ',
        areaChart: '面グラフ',

        listSeparator: '、',

        expandAll: "すべて表示",
        collapseAll: "すべて非表示",
    },

    spatialAwareness: {
        caption: '電気インフラと地政学的地域',

        search: {
            title: '探す',
        },

        legend: {
            showLegend: '地図の凡例を表示',
            hideLegend: 'マップの凡例を非表示',

            noLegend: '凡例なし',
            undetermined: '未定',
            under55: '55以下',
            '56-77': '56-77',
            '78-154': '78-154',
            187: '187',
            220: '220',
            '250-275': '250-275',
            above500: '500以上',
        },

        features: {
            title: '選択された地図フィーチャ',
        },

        layers: {
            title: '地図レイヤー',
            download: 'ダウンロード',

            powerPlants: {
                title: '発電所',
                fuel: '燃料の種類',

                popup: {
                    title: '発電所',
                    name: '名称',
                    primaryFuel: '主要燃料',
                    operatingCapacity: '発電能力',
                    operatingUnits: '稼働ユニット',
                    plantStatus: '稼働状況',
                    owner: '所有者',
                    firstOnlineDate: '稼働開始日',

                    unitsByFuelAndStatus: '燃料と稼働状況',
                    generatingUnits: '発電ユニット',
                    unitName: '名称',
                    primeMover: '原動機',
                    fuel: '始動燃料',
                    capacity: '発電能力',
                    onlineDate: '稼働開始日',
                    retirementDate: '稼働終了日',
                    status: '稼働状況',
                },
            },

            substations: {
                title: '変電所',

                popup: {
                    title: '変電所',
                    name: '名称',
                    minKV: '最小電圧',
                    maxKV: '最大電圧',
                },
            },

            weatherStations: {
                title: '気象観測所',

                popup: {
                    title: '気象台',
                    name: '名称',
                },
            },

            lngFacilities: {
                title: '液体天然ガス施設',

                popup: {
                    title: '液体天然ガス施設',
                    name: '名称',
                    storageCapacity: '貯蔵容量',
                    owners: '所有者',
                    status: '稼働状況',
                    type: '施設タイプ',
                    onlineYear: '稼働開始年',
                    facilityId: '施設識別子',
                },
            },

            transmissionLines: {
                title: '送電線',

                popup: {
                    title: '送電線',
                    name: '名称',
                    voltage: '電圧',
                    voltageClass: '電圧クラス',
                },
            },

            utilityServiceTerritories: {
                title: 'サービス提供地域',

                popup: {
                    title: 'サービス提供領域',
                    name: '企業名',

                    supplyAndDemand: '需要と供給',
                    totalSupply: '総供給',
                    demand: '需要',
                    reserveMargin: '供給予備力',
                    capacityByFuel: '燃料別容量',

                    priceHistory: '価格履歴',
                    priceForecast: '価格予測',
                    financialHighlights: '財務ハイライト',
                    fiscalYear: '2018財務年度',

                    assets: '資産',
                    liabilities: '負債',
                    cashStart: '現金（年始）',
                    cashEnd: '現金（年末）',
                    operatingRevenue: '営業収益',
                    nonOperatingRevenue: '営業外収益',
                    totalRevenue: '総収益',
                    operatingExpenses: '営業経費',
                    nonOperatingExpenses: '営業外費用',
                    totalExpenses: '総費用',
                    netIncome: '株主の純利益',

                    localDateTime: '現地日時',
                    year: '年',
                },

                columnCaptions: {
                    offPeak: 'オフピーク',
                    onPeak: 'ピーク',
                    average: '平均',

                    areaPrice: '地域価格',
                },
            },

            prefectures: {
                title: '都道府県',

                popup: {
                    title: '都道府県',
                    name: '都道府県名',
                    type: 'タイプ',
                    source: '情報源',
                    populationUrban: '都市人口',
                    populationRural: '農村人口',
                    populationTotal: '総人口',
                    areaUrban: '都市面積',
                    areaRural: '農村面積',
                    areaTotal: '総面積',
                    densityUrban: '都市人口密度',
                    densityRural: '農村人口密度',
                    densityTotal: '総人口密度',
                },
            },
        },

        animations: {
            title: 'アニメーション',

            none: {
                title: 'なし',
            },

            averagePriceForecast: {
                title: '平均価格予測',
            },

            onPeakPriceForecast: {
                title: 'ピーク価格予測',
            },

            offPeakPriceForecast: {
                title: 'オフピーク価格予測',
            },

            areaPrice: {
                title: '地域価格',
            },

            windSpeedAndDirection: {
                title: '風速と風向',
            },
        },
    },

    supplyAndDemand: {
        caption: '需要と供給',

        filters: {
            caption: 'グラフ設定',

            chartSeries: 'グラフ',

            chartBy: {
                caption: "グラフの内容",

                neByFuel: '主要燃料別の新規参入者',
                neByPrimeMover: '原動機別の新規参入者',
                neByStatus: '稼働状況別の新規参入者',

                refCaseBaseline: 'ベースライン参照例',
                refCaseHighFuelPrice: '燃料価格の高い参考例',
                refCaseRenewableGenerationTargets: '再生可能エネルギー発電目標参考例',
                refCaseFullNuclearRestart: '完全な原子力再起動の参照例',
            },

            utilityServiceTerritories: {
                caption: 'サービス提供地域',
            },

            chartType: {
                caption: 'グラフ',
                generationByFuelType: '燃料による発電',
                demandByTerritory: 'サービス提供地域別の需要',
            },

            fuelType: {
                caption: '燃料タイプ',
            },

            totalDemand: {
                caption: '総需要',
            },

            view: '表示する',
            granularity: {
                caption: '粒度',
                A: '年',
                M: '月',
                D: '日',
                H: '時',
                MoY: '月（1-12）',
                HoD: '時刻（24時間）',
            },
            aggregation: {
                caption: '集約',
                min: '最小',
                max: '最大',
                average: '平均',
                sum: '合計',
                stdev: '標準偏差',
                count: 'カウント'
            },

            dateRange: '日付範囲',
            hour: '時刻（24時間）',
            month: '月（1-12）',
            year: '年',
        },

        tabs: {
            forward: {
                caption: '需要と供給の予測',
            },
            historic: {
                caption: '過去の需要と供給',
            },
        },

        chart: {
            capacity: '発電能力',
            generation: '発電',
            totalEnergy: '総発電量',
            reserveMargin: '供給予備力',
            totalSupply: '総供給',

            supply: {
                axisLabels: {
                    min: '最小供給',
                    max: '最大供給',
                    average: '平均供給',
                    sum: '合計供給',
                    stdev: '供給の標準偏差',
                    count: '供給のカウント',
                },
            },

            demand: {
                axisLabels: {
                    min: '最小',
                    max: '最大',
                    average: '平均',
                    sum: '合計',
                    stdev: '標準偏差',
                    count: 'カウント',
                },
            },
        },
    },

    companyFinancials: {
        caption: '企業財務',

        filters: {
            caption: 'グラフ設定',

            view: '表示する',
            chartBy: 'グラフの内容',

            utilityServiceTerritories: 'サービス提供地域',

            year: '年',
        },

        charts: {
            balanceSheet: {
                caption: 'バランスシート',
                yAxisLabel: '資産及び負債',
            },

            cashFlow: {
                caption: 'キャッシュフロー',
            },

            statementOfIncome: {
                caption: '損益計算書',
            },
        },
    },

    priceHistory: {
        caption: '価格履歴',

        filters: {
            caption: 'フィルター・集計',

            chartSeries: 'グラフ',
            view: '表示する',
            granularity: {
                caption: '粒度',
                A: '年',
                M: '月',
                D: '日',
                H: '時',
                MoY: '月（1-12）',
                HoD: '時刻（24時間）',
            },
            aggregation: {
                caption: '集約',
                min: '最小',
                max: '最大',
                average: '平均',
                sum: '合計',
                stdev: '標準偏差',
                count: 'カウント'
            },

            dateRange: '日付範囲',
            hour: '時刻（24時間）',
            month: '月（1-12）',
            year: '年',
        },

        charts: {
            spotMarket: {
                caption: 'JEPX スポット市場 時間前市場',

                axisLabels: {
                    price: {
                        min: '最低価格',
                        max: '最高価格',
                        average: '平均価格',
                        sum: '合計価格',
                        stdev: '価格の標準偏差',
                        count: '価格のカウント',
                    },

                    volume: {
                        min: '最小取引量',
                        max: '最大取引量',
                        average: '平均取引量',
                        sum: '合計取引量',
                        stdev: '取引量の標準偏差',
                        count: '取引量のカウント',
                    }
                },

                seriesGroups: {
                    indexPrices: 'インデックス価格',
                    areaPrices: '地域価格',
                    intradayPrices: '日中価格',
                    volumes: '取引量',
                },
            },

            forwardMarket: {
                caption: 'JEPX 先渡市場',

                buttonTitles: {
                    colorByInterconnect: '相互接続による色分け',
                    colorByPeakType: 'ピークの種類による色分け',
                    colorByContractLength: '契約期間による色分け',
                    detailedTheme: '詳細',
                },

                seriesGroups: {
                    contractLength: '契約期間',
                    peakType: 'ピークの種類',
                    interconnect: '相互接続',
                },

                seriesTypes: {
                    week: '週',
                    month: '月',
                    year: '年',
                    allHours: '24時間',
                    daytime: '日中',
                    tokyo: '東京',
                    kansai: '関西',
                    system: 'システム',
                },

                series: {
                    kn_mth_24_total_volume: '関西月間全時間帯',
                    kn_mth_dt_total_volume: '関西月間日中',
                    kn_wk_24_total_volume: '関西週間全時間帯',
                    kn_wk_dt_total_volume: '関西週間日中',

                    sys_mth_24_total_volume: 'システム月間全時間帯',
                    sys_mth_dt_total_volume: 'システム月間日中',
                    sys_wk_24_total_volume: 'システム週間全時間帯',
                    sys_wk_dt_total_volume: 'システム週間日中',
                    sys_yr_24_total_volume: 'システム年間全時間帯',

                    tk_mth_24_total_volume: '東京月間全時間帯',
                    tk_mth_dt_total_volume: '東京月間日中',
                    tk_wk_24_total_volume: '東京週間全時間帯',
                    tk_wk_dt_total_volume: '東京週間日中',
                    tk_yr_24_total_volume: '東京年間全時間帯',
                },

                axisLabels: {
                    y: '総契約数',
                },

                tooltips: {
                    category: 'カテゴリー',
                    volume: '取引量',
                    minValue: '最低価格',
                    maxValue: '最高価格',
                    avgValue: '平均価格',
                },
            },

            lngMarket: {
                caption: '液体天然ガス市場',

                axisLabels: {
                    price: {
                        min: '最低価格',
                        max: '最高価格',
                        average: '平均価格',
                        sum: '合計価格',
                        stdev: '価格の標準偏差',
                        count: '価格のカウント',
                    },
                },
            },
        },
    },

    priceForecast: {
        caption: '市場清算価格',
        marketChart: {
            caption: '市場清算価格',
        },
        fuelChart : {
            caption: '燃料価格の予測',
        },
        download: {
            marketClearingPricesMonthly: '月間市場清算価格',
            marketClearingPricesAnnual: '年間市場清算価格',
        },
        series: {
            avg_val: '平均値',
            off_peak_val: 'オフピーク値',
            on_peak_val: 'ピーク値',
        },
        panel: {
            caption: '選択して集計',
            compareBy: {
                caption: '比較要素',
                region: '地方',
                peakType: 'ピークの種類',
                scenario: '選択した例',
            },
            chartSeries: {
                caption: 'サービス提供地域',
            },
            fuelType: {
                caption: '燃料タイプ',
            },
            scenario: {
                caption: '選択した例',
            },
            datePicker: {
                caption: '日付範囲',
            },
            peakType: {
                caption: 'ピークの種類',
                average: '平均',
                offPeak: 'オフピーク',
                onPeak: 'ピーク',
                all: 'すべて',
            },
            granularity: {
                caption: '粒度',
                A: '年',
                M: '月',
                MoY: '月（1-12）',
            },
            aggregation: {
                caption: '集計',
                min: '最小',
                max: '最大',
                average: '平均',
                sum: '合計',
                stdev: '標準偏差',
                count: 'カウント',
            },
            view: {
                caption: '表示する',
            },
            month: {
                caption: '月（1-12）',
            },
            year: {
                caption: '年',
            }
        },
    },

    weather: {
        caption: "天気",
        localDateTime: '現地日時',
        weatherStation: '気象台',
        download: '毎時の天気',

        temperature: {
            caption: '温度',
        },
        
        windSpeed: {
            caption: '風速',
        },
        
        windDirection: {
            caption: '風向き',
        },
        
        wind: {
            caption: '風',
        },
        
        sunshineDuration: {
            caption: '日照時間',
        },

        filters: {
            caption: 'フィルター・集計',

            stations: {
                upToNStations: "最大{0}の気象観測所を選択できます",
            },
            granularity: {
                caption: '粒度',
                A: '年',
                M: '月',
                D: '日',
                H: '時',
                MoY: '月（1-12）',
                HoD: '時刻（24時間）',
            },
            aggregation: {
                caption: '集計',
                min: '最小',
                max: '最大',
                average: '平均',
                sum: '合計',
                stdev: '標準偏差',
                count: 'カウント',
            },
            view: {
                caption: '表示する',
            },
            dateRange: {
                caption: '日付範囲',
            },
            hour: {
                caption: '時刻（24時間）',
            },
            month: {
                caption: '月（1-12）',
            },
            year: {
                caption: '年',
            },
        },

        charts: {
            temperature: {
                axisLabels: {
                    min: '最低気温',
                    max: '最高温度',
                    average: '平均温度',
                    sum: '温度の合計',
                    stdev: '温度の標準偏差',
                    count: '温度のカウント',
                },
            },

            wind: {
                showWindDirection: '風向を表示',
                hideWindDirection: '風向を隠す',

                axisLabels: {
                    min: '最小風速',
                    max: '最大風速',
                    average: '平均風速',
                    sum: '風速の合計',
                    stdev: '風速の標準偏差',
                    count: '風速のカウント',
                },
            },
  
            sunshineDuration: {
                axisLabels: {
                    min: '最小日照時間',
                    max: '最大日照時間',
                    average: '平均日照時間',
                    sum: '日照時間の合計',
                    stdev: '日照時間の標準偏差',
                    count: '日照時間のカウント',
                },
            },
        },
    },
};