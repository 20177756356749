import React from 'react';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { GeoJSON } from 'ol/format';
import { Style, Stroke } from 'ol/style';
import { intersects } from 'ol/extent';
import AppConfig from '../../AppConfig';
import AppTrace from '../../AppTrace';
import ErrorState from '../../ErrorState';
import Layer from './Layer';
import Locale from '../../locale/Locale';

const t = Locale.getResourceString.bind(Locale);
const Projection = require('ol/proj');
const wkx = require('wkx');

export default class TransmissionLines extends Layer {
    // Zoom threshold at which this layer becomes visible
    static _ViewThreshold = 9;

    // Assign colors to voltage classes. 
    // Standalone integers must have space added to be treated as a string and maintain proper order in legend.
    static VoltageClassColors = {
        'Undetermined': '#595959',
        'Under 55': '#FFFFBC',
        '56-77': '#b0b000',
        '78-154': '#0095e0',
        '187 ': '#00b000',
        '220 ': '#FF8000',
        '250-275': '#FF67B3',
        '500 and above': '#E03800'
    }

    // Use this map to find the key in the Locale files to the text for each voltage class.
    // Standalone integers must have space added to be treated as a string and maintain proper order in legend.
    static VoltageClassLocaleMap = {
        'Undetermined': 'spatialAwareness.legend.undetermined',
        'Under 55': 'spatialAwareness.legend.under55',
        '56-77': 'spatialAwareness.legend.56-77',
        '78-154': 'spatialAwareness.legend.78-154',
        '187 ': 'spatialAwareness.legend.187',
        '220 ': 'spatialAwareness.legend.220',
        '250-275': 'spatialAwareness.legend.250-275',
        '500 and above': 'spatialAwareness.legend.above500'
    }

    // Gets a string key that identifies this layer in collections, localization resource, etc.
    get key() {
        return "transmissionLines";
    }

    // Gets the stack order of the layer.  Lower zIndex layers are stacked at the bottom
    get zIndex() {
        // Polyline layers in middle: between 10 and 20
        return 10;
    }

    // Gets the underlying layer object
    get vectorLayer() {
        return this._vectorLayer;
    }

    // Renders popup content for a feature in this layer
    renderPopupContent(feature) {
        let voltage = feature.getProperties().voltage;
        if (voltage === -99)
            voltage = t('general.undetermined')
        return (
            <>
                <span className="feature-popup-title">{t('spatialAwareness.layers.transmissionLines.popup.title')}</span><br />
                <br />

                <span className="feature-popup-property-name">{t('spatialAwareness.layers.transmissionLines.popup.name')}</span>
                <span className="feature-popup-property-value">{Locale.getJSONFieldValue(feature.getProperties().name)}</span><br />
                <span className="feature-popup-property-name">{t('spatialAwareness.layers.transmissionLines.popup.voltage')}</span>
                <span className="feature-popup-property-value">{voltage}&nbsp;{t('units.kiloVolts')}</span><br />
                <span className="feature-popup-property-name">
                    {t('spatialAwareness.layers.transmissionLines.popup.voltageClass')}&nbsp;({t('units.kiloVolts')})</span>
                <span className="feature-popup-property-value">{Locale.getJSONFieldValue(feature.getProperties().voltageClass)}</span><br />
            </>
        );
    }

    // Notifies the layer that the map view is now stationary.  This
    // is a good time to do things like retrieving dynamic content.
    notifyMoveEnd(view) {
        // Enable the layer based on zoom level
        this.isEnabled = (view.getZoom() >= TransmissionLines._ViewThreshold);

        // If the layer is enabled and visible then get new transmission lines for the current view
        if (this.isEnabled && this.isVisible) {
            this._replaceLayerData(view);
        }
    }

    // Finds and returns a feature by it's ID/entity ID
    findFeatureByEntityId(entityId) {
        return this._allFeatures.find(f => f.getProperties().entityId === entityId);
    }

    // Zooms to a selected feature
    zoomToFeature(feature, view) {
        let extent = feature.getGeometry().getExtent();
        view.fit(extent);
        view.setZoom(Math.min(15, view.getZoom()));
    }


    _getUrl(view) {
        return `${AppConfig.dataService.baseUrl}/spatial-awareness/transmission-lines`;
    }

    _createLayer(view) {
        // Derive all features
        this._allFeatures = this._getAllFeatures();

        try {
            // Create the layer
            this._vectorLayer = new VectorLayer({
                key: this.key,
                source: new VectorSource({
                    features: [],
                }),
                style: this._getStyle,
                opacity: 1.0,
                visible: false,
            });

            // Replace features with visible features
            this._replaceLayerData(view);
        }
        catch (err) {
            AppTrace.traceError("TransmissionLines.ctor: " + err)
            ErrorState.setFault("Error mapping transmission lines");
        }
    }

    // Gets the default style for the selected feature
    _getDefaultStyle(feature, resolution) {
                // Always use en as locale here, so that voltageClass for colors/width will match with the static VoltageClassColors (in English)
        let voltageClass = Locale.getJSONFieldValue(feature.getProperties().voltageClass, "en");

        return new Style({
            stroke: new Stroke({
                color: this._getVoltageClassColor(voltageClass),
                width: (voltageClass === "500 and above") ? 3 : 2
            }),
        });
    }

    // Renders legend content for this layer
    _renderDefaultLegendContent() {
        const items = Object.keys(TransmissionLines.VoltageClassColors).map(voltageClass =>
            <div key={voltageClass}>
                <span className="ev-legend-symbol" style={{ color: this._getVoltageClassColor(voltageClass) }}>&#x25AC;</span>
                <span className="ev-legend-theme-value">{this._getVoltageClassText(voltageClass)}</span>
                <br />
            </div>
        );

        if (this.isVisible) {
            return (
                <div key={this.key + '-legend-content'} className="ev-legend-layer-content">
                    <span className="ev-legend-layer-name">
                        {t('spatialAwareness.layers.transmissionLines.title')}&nbsp;({t('units.kiloVolts')})</span><br />
                    {items}
                </div>
            );
        }
    }

    // Generate all of the features that will be added to the map.
    _getAllFeatures() {
        let features = [];
        let olGeoJSON = new GeoJSON();

        // Iterate over all plants in the API data
        let idIndex = this._apiData.metadata.columns.id.index;
        let nameIndex = this._apiData.metadata.columns.name.index;
        let voltageIndex = this._apiData.metadata.columns.voltage.index;
        let voltageClassIndex = this._apiData.metadata.columns.voltageClass.index;
        let geographyIndex = this._apiData.metadata.columns.geography.index;

        for (let i = 0; i < this._apiData.rows.length; ++i) {
            let row = this._apiData.rows[i];

            // Parse the geometry
            let wkbBuffer = new Buffer(row[geographyIndex], 'hex');
            let geoJSON = wkx.Geometry.parse(wkbBuffer).toGeoJSON();

            // Create the feature - tell GeoJSON that the data is in EPSG:4326, and to project it to EPSG:3857
            features.push(new Feature({
                layerKey: this.key,
                entityId: row[idIndex],
                name: row[nameIndex],
                voltage: row[voltageIndex],
                voltageClass: row[voltageClassIndex],

                geometry: olGeoJSON.readGeometry(geoJSON, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                })
            }));
        }

        return features;
    }

    _getViewExtentWkt(view) {
        let ext = Projection.transformExtent(view.calculateExtent(), view.getProjection(), "EPSG:4326");
        let wktExtent = wkx.Geometry.parseGeoJSON({
            "type": "Polygon",
            "coordinates": [[
                [ext[0], ext[1]],  // Min x, min y
                [ext[2], ext[1]],  // Max x, min y
                [ext[2], ext[3]],  // Max x, max y
                [ext[0], ext[3]],  // Min x, max y
                [ext[0], ext[1]]   // Close polygon
            ]]
        }).toWkt();

        return wktExtent;
    }

    _replaceLayerData(view) {
        // Clean up old transmission line graphics
        if (this._visibleFeatures != null) {
            this._visibleFeatures.length = 0;
        }

        // Apply only the features that are currently visible within the layer extent
        let extent = view.calculateExtent();
        this._visibleFeatures = this._allFeatures.filter(f => intersects(extent, f.getGeometry().getExtent()));

        // Update graphics collection - use brut force and clear/re-add features
        this.vectorLayer.getSource().clear(true);
        this.vectorLayer.getSource().addFeatures(this._visibleFeatures);
    }

    _getVoltageClassColor(voltageClass) {
        const vcColors = TransmissionLines.VoltageClassColors;
        let color = (vcColors[voltageClass] != null) ? vcColors[voltageClass] : vcColors['Undetermined'];

        return color;
    }

    _getVoltageClassText(voltageClass) {
        const vcMap = TransmissionLines.VoltageClassLocaleMap;
        let text = t(vcMap[voltageClass])

        return text;
    }
}