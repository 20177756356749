import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Locale from '../locale/Locale';

import { ToggleButton, ToggleButtonGroup } from 'webcore-ux/react/components';

import './YearPicker.scss';

////////////////////////////////////////////////////////////////////////////////
// YearPicker renders a WebCore UX button group in order to facilitate the 
// selection from a set of available years.
//
// Available years are provided via the "allYears" property, which is an 
// array of integers.  The selected years are provided via the "value" 
// property, which is also an array of integers.
// 
// Some WebCore-ish styling is applied, etc.
////////////////////////////////////////////////////////////////////////////////
class YearPicker extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {
        };

        this._onChange = this._onChange.bind(this);
    }

    _onChange(value) {
        if (this.props.onChange != null) {
            this.props.onChange(value);
        }
    }

    render() {
        return (
            <ToggleButtonGroup 
                className="de-filter-button-group de-year-picker"
                multiselect={true}
                value={this.props.value}
                onChange={(e, value) => { this._onChange(value) }}
            >
                {this.props.allValues.map(n => (
                    <ToggleButton 
                        key={n} 
                        value={n} 
                        size="small" 
                        selected={this.props.value.indexOf(n) >= 0}>
                            {Locale.formatMomentGranularity(moment.utc([n, 1, 1]), 'A')}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        );
    }

}

YearPicker.propTypes = {
    value: PropTypes.arrayOf(PropTypes.number),
    allValues: PropTypes.arrayOf(PropTypes.number),
}

YearPicker.defaultProps = {
    value: [],
    allValues: [],
}

export default YearPicker;