import React from 'react';
import Locale from '../../../locale/Locale';
import Theme from '../Theme';

const t = Locale.getResourceString.bind(Locale);

export default class Animation extends Theme {
    ////
    // Object creation
    ////
    constructor(layer) {
        super(layer);

        this._animationControlsRef = React.createRef();
        this.__frameIndex = 0;

        this._onChangeFrameIndex = this._onChangeFrameIndex.bind(this);
    }

    ////
    // Public interface
    ////

    // Gets the display name of this animation
    get name() {
        return t('spatialAwareness.animations.{0}.title'.replace('{0}', this.key));
    }

    // Gets or sets whether or not the theme is currently applied to its layer
    get isActive() {
        return super.isActive;
    }
    set isActive(isActive) {
        super.isActive = isActive;
        if (!isActive) {
            this.pause();
        }
    }

    // Begins playback on the animation
    play() {
        if (this._animationControlsRef.current != null && typeof this._animationControlsRef.current.play === 'function') {
            this._animationControlsRef.current.play();
        }
    }

    // Pauses the animation
    pause() {
        if (this._animationControlsRef.current != null && typeof this._animationControlsRef.current.pause === 'function') {
            this._animationControlsRef.current.pause();
        }
    }

    ////
    // Protected interface
    ////
    get _frameIndex() {
        return this.__frameIndex;
    }
    set _frameIndex(frameIndex) {
        this.__frameIndex = frameIndex;
        this._onChange();
    }

    _onChangeFrameIndex(value) {
        this._frameIndex = value;
    }
}