import React from 'react';
import { withRouter } from "react-router";
import { Button } from 'webcore-ux/react/components';
import AvTimerIcon from '@material-ui/icons/AvTimer';

import ErrorBoundary from 'ErrorBoundary';
import Locale from '../locale/Locale';

import PinnableSettings from 'common/PinnableSettings';

import SpatialAwareness from './SpatialAwareness';
import './AnimationChooser.scss';

const t = Locale.getResourceString.bind(Locale);

class AnimationChooser extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {
            isAnimationsPanelVisible: false,
            isAnimationsPanelPinned: false,
        };

        this._onChangeAnimation = this._onChangeAnimation.bind(this);
        this._onPin = this._onPin.bind(this);
    }

    _onChangeAnimation(e) {
        e.stopPropagation();
        if (this.props.onChangeAnimation != null) {
            this.props.onChangeAnimation(e.target.value);
        }
    }

    _onPin(args) {
        this.setState({ isAnimationsPanelPinned: args.isPinned });
        if (this.props.onPin != null) {
            this.props.onPin(args);
        }
    }



    render() {
        let title = t("spatialAwareness.animations.title");
        let animations = [];

        // Add additional animations for all others
        if (this.props.animations != null) {
            this.props.animations.forEach(a => {
                animations.push(
                    <div key={a.key}>
                        <label className="animation-radio">
                            <input type="radio" value={a.key} checked={a.key === this.props.selectedAnimationKey} onChange={this._onChangeAnimation} />
                            <span>{a.name}</span>
                        </label>
                        <br/>
                    </div>
                );
            });
        }

        return (
            <ErrorBoundary>
                <div className="spatial-awareness-map-buttons">
                    <div className="spatial-awareness-animations-button" title={title}>
                        <Button 
                            disabled={this.state.isAnimationsPanelPinned || this.props.disabled}
                            variant="primary"
                            onClick={ () => this.setState({ isAnimationsPanelVisible: !this.state.isAnimationsPanelVisible }) }>
                            <AvTimerIcon />
                        </Button>
                    </div>

                    <PinnableSettings
                        contentClassName="layerchooser-layers"
                        open={this.state.isAnimationsPanelVisible}
                        title={title}
                        viewKey={SpatialAwareness.ViewKey}
                        onClose={() => this.setState({ isAnimationsPanelVisible: false })}
                        onPin={(args) => this._onPin(args)}
                    >
                        {animations}
                    </PinnableSettings>
                </div>
            </ErrorBoundary>
        );
    }
}

export default withRouter(AnimationChooser);
