import React from 'react';
import moment from 'moment';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import BarChartIcon from '@material-ui/icons/BarChart';
import DescriptionIcon from '@material-ui/icons/Description';
import GridOffIcon from '@material-ui/icons/GridOff';
import GridOnIcon from '@material-ui/icons/GridOn';

import Filter from 'webcore-ux/react/components/Icons/Filter';
import { Button, Dropdown } from 'webcore-ux/react/components'

import BusyState from 'BusyState';
import CSVDownloadItem from 'CSVDownloadItem';
import ErrorBoundary from 'ErrorBoundary';
import ErrorState from 'ErrorState';
import GlobalSessionState from 'GlobalSessionState';
import Locale from 'locale/Locale';
import SessionState from 'SessionState';
import Utils from 'Utils';

import HorizontalSplitContainer from 'common/HorizontalSplitContainer';
import MultiSelectDropdown from 'common/MultiSelectDropdown';
import PinnableSettings from 'common/PinnableSettings';
import TabPanel from 'common/TabPanel';
import VerticalTabNav from 'common/VerticalTabNav';
import VisualBox from 'common/VisualBox';
import YearPicker from 'common/YearPicker';

import CompanyFinancialsDataFactory from './data/CompanyFinancialsDataFactory';
import BalanceSheetStats from './stats/BalanceSheetStats';
import CashFlowStats from './stats/CashFlowStats';
import StatementOfIncomeStats from './stats/StatementOfIncomeStats';
import BalanceSheetChart from './BalanceSheetChart';
import BalanceSheetGrid from './BalanceSheetGrid';
import StatementOfIncomeCharts from './StatementOfIncomeCharts';
import StatementOfIncomeGrid from './StatementOfIncomeGrid';
import CashFlowChart from './CashFlowChart';
import CashFlowGrid from './CashFlowGrid';

import './CompanyFinancials.scss';

const t = Locale.getResourceString.bind(Locale);

class CompanyFinancials extends React.Component {
    static get ViewKey() { return 'company-financials'; }

    static get Downloads() { return [
        new CSVDownloadItem('companyFinancials.charts.balanceSheet.caption', '/company-financials/balance-sheet/download', 'balance-sheet.csv'),
        new CSVDownloadItem('companyFinancials.charts.statementOfIncome.caption', '/company-financials/statement-of-income/download', 'statement-of-income.csv'),
        new CSVDownloadItem('companyFinancials.charts.cashFlow.caption', '/company-financials/cash-flow/download', 'cash-flow.csv'),
    ]};

    static get _SessionStateKey() {
        return 'emi-japan.company-financials.CompanyFinancials';
    }

    constructor(props, context) {
        super(props);

        this._chartKey = 'balanceSheet';  // Manage this outside of state to avoid timing issues
        this._stats = {
            balanceSheet: new BalanceSheetStats(),
            cashFlow: new CashFlowStats(),
            statementOfIncome: new StatementOfIncomeStats(),
        };

        // Data factory and data
        this._dataFactory = new CompanyFinancialsDataFactory();
        this._data = null;
        this._previousFilters = {};

        // Configure initial state
        this._defaultInitialState = {
            sessionStateChartKey: this._chartKey,

            isNavExpanded: true,

            chartBy: 'year',

            isSplitBalanceSheet: false,
            isSplitCashFlow: false,
            isSplitStatementOfIncome: false,
        }
        this._stateOverrides = {
            filterPanelOpen: false,
            filterPanelIsPinned: false,

            selectedYears: [],
        }
        this.state = SessionState.get(CompanyFinancials._SessionStateKey, this._defaultInitialState, this._stateOverrides);

        // Restore saved chartKey
        this._chartKey = this.state.sessionStateChartKey;

        // Bind event handlers
        this._onFormatYear = this._onFormatYear.bind(this);
        this._onFormatCompanyId = this._onFormatCompanyId.bind(this);
        this._oni18nChanged = this._oni18nChanged.bind(this);
        this._onPin = this._onPin.bind(this);
        this._onChangeChartTab = this._onChangeChartTab.bind(this);
        this._onChangeChartBy = this._onChangeChartBy.bind(this);
    }

    static ViewDetails(utilityServiceTerritory) {
        let currentCompanyIds = GlobalSessionState.get().companyFinancialsCompanyIds;
        let newId = utilityServiceTerritory.entityId;

        // If we currently have no selected territories, or just 1 then set the
        // selection to the one just clicked
        if (currentCompanyIds.length <= 1) {
            GlobalSessionState.set({ 
                companyFinancialsCompanyIds: [newId]
            });
        }
        // Otherwise we have multiple selected values already.  Make sure
        // the territory that was just selected is represented.
        else if (currentCompanyIds.indexOf(newId) < 0) {
            currentCompanyIds.push(newId);
            GlobalSessionState.set({ 
                companyFinancialsCompanyIds: currentCompanyIds
            });
        }
    }

    _calculateStats(state) {
        if (this._data != null) {
            let filters = this._filters;
            if (JSON.stringify(filters) !== JSON.stringify(this._previousFilters)) {
                this._previousFilters = filters;
                Object.keys(this._stats).forEach(chartKey => this._stats[chartKey].calculate(this._data[chartKey], filters));
            }
        }
    }

    _getData() {
        return new Promise((resolve, reject) => {
            // If we already have data, then resolve
            if (this._data != null) {
                resolve(this._data);
            }
            else {
                // Set busy state
                let isBusy = BusyState.isBusy;
                BusyState.isBusy = true;

                // Get data
                this._dataFactory.get()
                    .then(data => {
                        this._data = data;
                        resolve(this._data);
                    })
                    .finally(() => {
                        BusyState.isBusy = isBusy;
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    }

    get _chartKey() {
        return GlobalSessionState.get().companyFinancialsChartKey;
    }
    set _chartKey(value) {
        GlobalSessionState.set({ 
            companyFinancialsChartKey: value,
        });
    }

    get _allYears() {
        return (this._data != null && this._data.allYears != null) ? this._data.allYears : [];
    }

    get _allCompanies() {
        let allCompanies = [];
        if (this._data != null) {
            let idIndex = this._data.usts.metadata.columns.id.index;
            let nameIndex = this._data.usts.metadata.columns.name.index;
            allCompanies = this._data.usts.rows.map(row => { 
                return {
                    value: row[idIndex].toString(), 
                    label: Utils.getShortServiceTerritoryName(Locale.getJSONFieldValue(row[nameIndex]))
                } 
            });
        }

        return allCompanies;
    }

    get _filters() {
        return {
            chartBy: this.state.chartBy,
            // Needed to ensure we calculate stats when the currency changes
            selectedCurrency: Locale.currencyName,
            selectedYears: this.state.selectedYears.reduce((obj, y) => { obj[y] = true; return obj; }, {}),
            selectedCompanies: GlobalSessionState.get().companyFinancialsCompanyIds.reduce((obj, t) => { obj[t] = true; return obj; }, {}),
        };
    }

    get _selectedServiceTerritoryNames() {
        let names = [];
        if (this._data != null) {
            names = GlobalSessionState.get().companyFinancialsCompanyIds.map(id => 
                Utils.getShortServiceTerritoryName(Locale.getJSONFieldValue(this._data.ustsLookup[id]))
            );
        }

        return names.join(t('general.listSeparator'));
    }

    get _selectedYears() {
        let years = [];
        if (this._data != null) {
            years = this.state.selectedYears.map(y => Locale.formatMomentGranularity(moment.utc([y, 1, 1]), 'A'));
        }
        return years.join(t('general.listSeparator'));
    }

    get _chartTitle() {
        return (this.state.chartBy === 'year') ?
            this._selectedServiceTerritoryNames :
            this._selectedYears;
    }

    get _financialsUnits() {
        return t('units.millionsOf_' + Locale.currencyName);
    }

    _onFormatYear(value) {
        return Locale.formatMomentGranularity(moment.utc([value, 1, 1]), 'A');
    }

    _onFormatCompanyId(id) {
        return (this._data != null && this._data.ustsLookup.hasOwnProperty(id)) ?
            Utils.getShortServiceTerritoryName(Locale.getJSONFieldValue(this._data.ustsLookup[id])) :
            id.toString();
    }

    _oni18nChanged() {
        this.forceUpdate();
    }

    _onPin(args) {
        this.setState({ filterPanelIsPinned: args.isPinned });
        if (this.props.onPin != null) {
            this.props.onPin(args);
        }
    }

    _onChangeChartTab(chartKey) {
        this._chartKey = chartKey;
        this.setState({ sessionStateChartKey: this._chartKey })
    }

    _onChangeChartBy(chartBy) {
        if (chartBy === 'year') {
            // Chart by year and make sure all years are selected
            this.setState({
                chartBy: chartBy,
                selectedYears: this._allYears,
            });
        }
        else if (chartBy === 'company_id') {
            // Chart by company_id and make sure all companies are selected
            GlobalSessionState.set({ companyFinancialsCompanyIds: Object.keys(this._data.ustsLookup).map(id => parseInt(id, 10)) });
            this.setState({
                chartBy: chartBy,
            });
        }
        else {
            // Ruh-roh...
            this.setState({
                chartBy: chartBy,
            });
        }
    }



    componentDidMount() {
        // Listen for locale and currency-changed events.  We need to recalculate stats 
        // and update because we don't recalculate stats on every render.
        Locale.addLocaleHandler("CompanyFinancials", this._oni18nChanged);
        Locale.addCurrencyHandler("CompanyFinancials", this._oni18nChanged);

        BusyState.isBusy = true;

        this._getData()
            .then(data => {
                this.setState({ 
                    //selectedYears: [Math.max.apply(Math, this._allYears)],
                    selectedYears: this._allYears,
                });
            })
            .catch((error) => {
                ErrorState.setFault(error.message);
            })
            .finally(() => {
                BusyState.isBusy = false;
            });
    }

    componentDidUpdate() {
        // Persist current state
        SessionState.set(CompanyFinancials._SessionStateKey, this.state);
    }

    componentWillUnmount() {
        // Clean up handlers
        Locale.removeCurrencyHandler("CompanyFinancials");
        Locale.removeLocaleHandler("CompanyFinancials");
    }

    renderFilters() {
        return (
            <div className="company-financials-filters">
                <VisualBox caption={t('companyFinancials.filters.view')}>
                    <Dropdown
                        label={t('companyFinancials.filters.chartBy')}
                        options={[
                            { value: 'company_id', label: t('companyFinancials.filters.utilityServiceTerritories') },
                            { value: 'year', label: t('companyFinancials.filters.year') },
                        ]}
                        isClearable={false}
                        value={this.state.chartBy}
                        onChange={obj => this._onChangeChartBy(obj.value)}
                    />
                </VisualBox>

                <VisualBox caption={t('companyFinancials.filters.utilityServiceTerritories')}>
                    <div className="button-group-filter">
                        <div 
                            className="select-all" 
                            title={t('general.selectAll')} 
                            onClick={() => {
                                GlobalSessionState.set({ companyFinancialsCompanyIds: Object.keys(this._data.ustsLookup).map(id => parseInt(id, 10)) });
                                this.forceUpdate();
                            }}
                        >
                            <SelectAllIcon />
                        </div>
                        <div 
                            className="select-none" 
                            title={t('general.clearAll')} 
                            onClick={() => {
                                GlobalSessionState.set({ companyFinancialsCompanyIds: [] });
                                this.forceUpdate();
                            }}
                        >
                            <ClearAllIcon />
                        </div>

                        <MultiSelectDropdown 
                            multipleSelectionsPlaceholder={t('general.multipleSelectedValues')}
                            placeholder={t('general.select')}
                            options={this._allCompanies}
                            value={GlobalSessionState.get().companyFinancialsCompanyIds.map(id => id.toString())}
                            onChange={(value) => {
                                value && GlobalSessionState.set({ companyFinancialsCompanyIds: value.map(id => parseInt(id, 10)) });
                                this.forceUpdate();
                            }}
                        />
                    </div>
                </VisualBox>

                <VisualBox caption={t('companyFinancials.filters.year')}>
                    <div className="button-group-filter">
                        <div 
                            className="select-all" 
                            title={t('general.selectAll')} 
                            onClick={() => this.setState({ selectedYears: this._allYears })}
                        >
                            <SelectAllIcon />
                        </div>
                        <div 
                            className="select-none" 
                            title={t('general.clearAll')} 
                            onClick={() => this.setState({ selectedYears: [] })}
                        >
                            <ClearAllIcon />
                        </div>
                        <YearPicker
                            allValues={this._allYears}
                            value={this.state.selectedYears}
                            onChange={(value) => this.setState({ selectedYears: value }) }
                        />
                    </div>
                </VisualBox>
            </div>
        );
    }

    _renderTabs() {
        return (
            <Tabs
                value={this._chartKey}
                onChange={(e, value) => this._onChangeChartTab(value)}
                orientation="vertical"
            >
                <Tab
                    icon={<DescriptionIcon fontSize="default" />}
                    value="balanceSheet"
                    label={this.state.isNavExpanded && t('companyFinancials.charts.balanceSheet.caption')}
                    title={t('companyFinancials.charts.balanceSheet.caption')}
                />
                <Tab 
                    icon={<BarChartIcon fontSize="default" style={{ transform: 'rotate(90deg)' }} />} 
                    value="statementOfIncome" 
                    label={this.state.isNavExpanded && t('companyFinancials.charts.statementOfIncome.caption')}
                    title={t('companyFinancials.charts.statementOfIncome.caption')}
                />
                <Tab 
                    icon={<BarChartIcon fontSize="default" />} 
                    value="cashFlow" 
                    label={this.state.isNavExpanded && t('companyFinancials.charts.cashFlow.caption')}
                    title={t('companyFinancials.charts.cashFlow.caption')} 
                />
            </Tabs>
        );
    }

    _renderTabPanels() {
        return (
            <>
                <TabPanel isRenderedWhenHidden={true} valuekey="balanceSheet" value={this._chartKey}>
                    {(this._data != null) && (
                        <HorizontalSplitContainer
                            buttonContainerClassName="company-financials-data-buttons"
                            isSplit={this.state.isSplitBalanceSheet}
                            joinIcon={<GridOffIcon fontSize="small" />}
                            splitIcon={<GridOnIcon fontSize="small" />}
                            topPanelMinHeight={220}
                            topPanelContent={
                                <BalanceSheetChart
                                    chartBy={this.state.chartBy}
                                    chartData={this._stats.balanceSheet.aggregated}
                                    data={this._data.balanceSheet}
                                    title={this._chartTitle}
                                    units={this._financialsUnits}
                                    formatXAxisTick={(this.state.chartBy === 'year') ? 
                                        this._onFormatYear :
                                        this._onFormatCompanyId
                                    }
                                />
                            }
                            bottomPanelMinHeight={220}
                            bottomPanelContent={
                                <BalanceSheetGrid
                                    chartBy={this.state.chartBy}
                                    gridData={this._stats.balanceSheet.aggregated}
                                    data={this._data.balanceSheet}
                                    ustsLookup={this._data.ustsLookup}
                                    groupColumnWidth={220}
                                    yearColumnWidth={100}
                                    ustsColumnWidth={220}
                                    pageSize={20}
                                />
                            }
                            onChange={(isSplit) => this.setState({ isSplitBalanceSheet: isSplit })}
                        />
                    )}
                </TabPanel>
                <TabPanel isRenderedWhenHidden={true} valuekey="statementOfIncome" value={this._chartKey}>
                    {(this._data != null) && (
                        <HorizontalSplitContainer
                            buttonContainerClassName="company-financials-data-buttons"
                            isSplit={this.state.isSplitStatementOfIncome}
                            joinIcon={<GridOffIcon fontSize="small" />}
                            splitIcon={<GridOnIcon fontSize="small" />}
                            topPanelMinHeight={410}
                            topPanelContent={
                                <StatementOfIncomeCharts
                                    chartBy={this.state.chartBy}
                                    chartData={this._stats.statementOfIncome.aggregated}
                                    data={this._data.statementOfIncome}
                                    title={this._chartTitle}
                                    formatChartTitle={(this.state.chartBy === 'year') ? 
                                        this._onFormatYear :
                                        this._onFormatCompanyId
                                    }
                                />
                            }
                            bottomPanelMinHeight={220}
                            bottomPanelContent={
                                <StatementOfIncomeGrid
                                    chartBy={this.state.chartBy}
                                    gridData={this._stats.statementOfIncome.aggregated}
                                    data={this._data.statementOfIncome}
                                    ustsLookup={this._data.ustsLookup}
                                    groupColumnWidth={250}
                                    yearColumnWidth={100}
                                    ustsColumnWidth={220}
                                    pageSize={20}
                                />
                            }
                            onChange={(isSplit) => this.setState({ isSplitStatementOfIncome: isSplit })}
                        />
                    )}
                </TabPanel>
                <TabPanel isRenderedWhenHidden={true} valuekey="cashFlow" value={this._chartKey}>
                    {(this._data != null) && (
                        <HorizontalSplitContainer
                            buttonContainerClassName="company-financials-data-buttons"
                            isSplit={this.state.isSplitCashFlow}
                            joinIcon={<GridOffIcon fontSize="small" />}
                            splitIcon={<GridOnIcon fontSize="small" />}
                            topPanelMinHeight={220}
                            topPanelContent={
                                <CashFlowChart
                                    chartBy={this.state.chartBy}
                                    chartData={this._stats.cashFlow.aggregated}
                                    data={this._data.cashFlow}
                                    title={this._chartTitle}
                                    units={this._financialsUnits}
                                    formatXAxisTick={(this.state.chartBy === 'year') ? 
                                        this._onFormatYear :
                                        this._onFormatCompanyId
                                    }
                                />
                            }
                            bottomPanelMinHeight={220}
                            bottomPanelContent={
                                <CashFlowGrid
                                    chartBy={this.state.chartBy}
                                    gridData={this._stats.cashFlow.aggregated}
                                    data={this._data.cashFlow}
                                    ustsLookup={this._data.ustsLookup}
                                    groupColumnWidth={260}
                                    yearColumnWidth={100}
                                    ustsColumnWidth={220}
                                    pageSize={20}
                                />
                            }
                            onChange={(isSplit) => this.setState({ isSplitCashFlow: isSplit })}
                        />
                    )}
                </TabPanel>
            </>
        );
    }

    render() {
        // Update stats as needed
        this._calculateStats();

        return (
            <ErrorBoundary>
                <div className="company-financials">
                    <VisualBox caption={t('companyFinancials.caption') + ' : ' + t('companyFinancials.charts.' + this._chartKey + '.caption')}>
                        <div className="company-financials-charts">
                            <div className="jemi-view-filter-button" title={t('companyFinancials.filters.caption')}>
                                <Button 
                                    disabled={this.state.filterPanelIsPinned}
                                    variant="primary"
                                    onClick={ () => this.setState({ filterPanelOpen: !this.state.filterPanelOpen }) } >
                                    <Filter fontSize="small" />
                                </Button>
                            </div>
                            <PinnableSettings
                                contentClassName="jemi-filters-container"
                                open={this.state.filterPanelOpen}
                                title={t('companyFinancials.filters.caption')}
                                viewKey={CompanyFinancials.ViewKey}
                                onClose={() => this.setState({ filterPanelOpen: false })}
                                onPin={(args) => this._onPin(args)}
                            >
                                {this.renderFilters()}
                            </PinnableSettings>

                            <VerticalTabNav 
                                isExpanded={this.state.isNavExpanded}
                                expandedWidth={150}
                                tabs={this._renderTabs()}
                                tabPanels={this._renderTabPanels()}
                                onChangeIsExpanded={(isExpanded) => this.setState({ isNavExpanded: isExpanded })}
                            />
                        </div>
                    </VisualBox>
                </div>
            </ErrorBoundary>
        );
    }
}

export default CompanyFinancials;
