import React from 'react';

import SelectAllIcon from '@material-ui/icons/SelectAll';
import ClearAllIcon from '@material-ui/icons/ClearAll';

import { Dropdown } from 'webcore-ux/react/components';

import ErrorBoundary from 'ErrorBoundary';
import Locale from 'locale/Locale';

import DateRangePicker from 'common/DateRangePicker';
import HourPicker from 'common/HourPicker';
import MonthPicker from 'common/MonthPicker';
import MultiSelectDropdown from 'common/MultiSelectDropdown';
import YearPicker from 'common/YearPicker';
import VisualBox from 'common/VisualBox';

import './PriceHistory.scss';

const t = Locale.getResourceString.bind(Locale);

////////////////////////////////////////////////////////////////////////////////
// These are the filters to be shown for the spot market chart
////////////////////////////////////////////////////////////////////////////////
class SpotMarketFilters extends React.Component {
    constructor(props, context) {
        super(props);
    }

    get _spotMarketSeriesOptions() {
        const options = [
            {
                label: 'indexPrices',
                options: [
                    { value: 'index_24hr' }, 
                    { value: 'index_daytime' }, 
                    { value: 'index_peaktime' }, 
                ],
            },
            {
                label: 'areaPrices',
                options: [
                    { value: 'hokkaido_areaprice' }, 
                    { value: 'tohoku_areaprice' }, 
                    { value: 'tokyo_areaprice' }, 
                    { value: 'chubu_areaprice' }, 
                    { value: 'kansai_areaprice' }, 
                    { value: 'chugoku_areaprice' }, 
                    { value: 'shikoku_areaprice' }, 
                    { value: 'kyushu_areaprice' }, 
                    { value: 'okinawa_areaprice' }, 
                    { value: 'hokuriku_areaprice' }, 
                ],
            },
            {
                label: 'intradayPrices',
                options: [
                    { value: 'intraday_open' }, 
                    { value: 'intraday_high' }, 
                    { value: 'intraday_low' }, 
                    { value: 'intraday_closing' }, 
                    { value: 'intraday_average' }, 
                ],
            },
            {
                label: 'volumes',
                options: [
                    { value: 'index_volume' }, 
                    { value: 'intraday_volume' }, 
                ],
            },
        ];

        let translatedOptions = options.map(o => { 
            return {
                label: t('priceHistory.charts.spotMarket.seriesGroups.' + o.label),
                options: o.options.map(o2 => { 
                    return {
                        value: o2.value,
                        label: Locale.getJSONFieldValue(this.props.data.metadata.columns[o2.value].caption),
                    };
                }),
            };
        });

        return translatedOptions;
    }

    render() {
        const granularities = [
            { value: 'A', },
            { value: 'M', },
            { value: 'D', },
            { value: 'H', },
            { value: 'MoY', },
            { value: 'HoD', }
        ];

        const aggregations = [
            { value: 'min', },
            { value: 'max', },
            { value: 'average', },
            { value: 'sum', },
            { value: 'stdev', },
            { value: 'count', }
        ];

        return (
            <ErrorBoundary>
                <div>
                    <VisualBox className="chart-series-box" caption={t('priceHistory.filters.chartSeries')}>
                        <MultiSelectDropdown
                            multipleSelectionsPlaceholder={t('general.multipleSelectedValues')}
                            placeholder={t('general.select')}
                            options={this._spotMarketSeriesOptions}
                            value={this.props.selectedSeries}
                            onChange={(value) => this.props.onChangeSeries(value)}
                        />
                    </VisualBox>

                    <VisualBox caption={t('priceHistory.filters.view')}>
                        <Dropdown
                            label={t('priceHistory.filters.granularity.caption')}
                            options={granularities.map(g => Object.assign(g, { label: t('priceHistory.filters.granularity.' + g.value) }))}
                            isClearable={false}
                            value={this.props.granularity}
                            onChange={obj => this.props.onChangeGranularity(obj.value)}
                        />
                        <Dropdown
                            label={t('priceHistory.filters.aggregation.caption')}
                            options={aggregations.map(a => Object.assign(a, { label: t('priceHistory.filters.aggregation.' + a.value) }))}
                            isClearable={false}
                            value={this.props.aggregation}
                            onChange={obj => this.props.onChangeAggregation(obj.value)}
                        />
                    </VisualBox>

                    <VisualBox caption={t('priceHistory.filters.dateRange')}>
                        <DateRangePicker
                            minDate={this.props.minDate}
                            maxDate={this.props.maxDate}
                            start={this.props.start}
                            end={this.props.end}
                            onChanging={(start, end) => this.props.onChangingDateRange(start, end)}
                            onChange={(start, end) => this.props.onChangeDateRange(start, end)}
                        />
                    </VisualBox>

                    <VisualBox caption={t('priceHistory.filters.hour')}>
                        <div className="button-group-filter">
                            <div 
                                className="select-all" 
                                title={t('general.selectAll')} 
                                onClick={() => this.props.onChangeSelectedHours([...Array(24).keys()])}>
                                <SelectAllIcon />
                            </div>
                            <div 
                                className="select-none" 
                                title={t('general.clearAll')} 
                                onClick={() => this.props.onChangeSelectedHours([])}>
                                <ClearAllIcon />
                            </div>
                            <HourPicker
                                value={this.props.selectedHours}
                                onChange={(value) => this.props.onChangeSelectedHours(value)}
                            />
                        </div>
                    </VisualBox>

                    <VisualBox caption={t('priceHistory.filters.month')}>
                        <div className="button-group-filter">
                            <div 
                                className="select-all" 
                                title={t('general.selectAll')} 
                                onClick={() => this.props.onChangeSelectedMonths([...Array(12).keys()].map(k => k + 1))}>
                                <SelectAllIcon />
                            </div>
                            <div 
                                className="select-none" 
                                title={t('general.clearAll')} 
                                onClick={() => this.props.onChangeSelectedMonths([])}>
                                <ClearAllIcon />
                            </div>
                            <MonthPicker
                                value={this.props.selectedMonths}
                                onChange={(value) => { this.props.onChangeSelectedMonths(value) }}
                            />
                        </div>
                    </VisualBox>

                    <VisualBox caption={t('priceHistory.filters.year')}>
                        <div className="button-group-filter">
                            <div 
                                className="select-all" 
                                title={t('general.selectAll')} 
                                onClick={() => this.props.onChangeSelectedYears(this.props.allYears)}>
                                <SelectAllIcon />
                            </div>
                            <div 
                                className="select-none" 
                                title={t('general.clearAll')} 
                                onClick={() => this.props.onChangeSelectedYears([])}>
                                <ClearAllIcon />
                            </div>
                            <YearPicker
                                allValues={this.props.allYears}
                                value={this.props.selectedYears}
                                onChange={(value) => { this.props.onChangeSelectedYears(value) }}
                            />
                        </div>
                    </VisualBox>
                </div>
            </ErrorBoundary>
        );
    }
}

export default SpotMarketFilters;
