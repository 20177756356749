import AppConfig from '../../../AppConfig';
import PriceForecastAnination from './PriceForecastAnimation';

export default class OnPeakPriceForecast extends PriceForecastAnination {
    ////
    // Public interface
    ////

    // Gets a string key that identifies this animation in collections, localization resource, etc.
    get key() {
        return 'onPeakPriceForecast';
    }



    ////
    // "Protected" interface
    ////

    // Gets the URL from which API data is obtained
    _getUrl(view) {
        return AppConfig.dataService.baseUrl + '/spatial-awareness/utility-service-territories/refcase-by-territory/on-peak';
    }
}