import CompanyFinancialsPivotGrid from './CompanyFinancialsPivotGrid';

/////////////////////////////////////////////////////////////////////////////
// This class encapsulates the cash flow grid
/////////////////////////////////////////////////////////////////////////////
class CashFlowGrid extends CompanyFinancialsPivotGrid {
    constructor(props, context) {
        super(props);
    }

    get _numericFields() {
        const numericFields = [
            'cash_from_oper_activities_val', 
            'cash_from_invest_activities_val', 
            'cash_from_finance_activities_val', 
            'net_cash_increase_and_equiv_val', 
            'cash_equiv_begin_of_year_val', 
            'cash_equiv_end_of_year_val', 
        ];

        return numericFields;
    }
}

export default CashFlowGrid;
