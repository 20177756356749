import React from 'react';
import { ResponsiveContainer, ComposedChart, Bar, Cell, XAxis, YAxis, ReferenceLine, Tooltip } from 'recharts';

import ErrorBoundary from 'ErrorBoundary';
import Locale from 'locale/Locale';

import CommonStyles from 'common/Common.scss';

const t = Locale.getResourceString.bind(Locale);

////////////////////////////////////////////////////////////////////////////////
// Encapsulates a statement-of-income chart.
////////////////////////////////////////////////////////////////////////////////
class StatementOfIncomeChart extends React.Component {
    constructor(props, context) {
        super(props);

        this._getFieldName = this._getFieldName.bind(this);
    }

    // Gets a localized field name given a key into the chart data
    _getFieldName(key) {
        return Locale.getJSONFieldValue(this.props.data.metadata.columns[key].caption);
    }

    render() {
        // This is a local palette chosen based on ABB colors and a sample
        // provided from the PO.
        const Palette = {
            total: '#bababa', // Grey 40
            revenue: CommonStyles.abbGreen60, // Green 60
            expense: '#968D0B', // Yellow 50
            tax: '#EDE56F', // Yellow 20
            extra: CommonStyles.appPrimaryColor, // Primary color
        };

        // If the chartData is null, return now
        if (this.props.chartData == null) {
            return null;
        }
        else {
            // The first bar is for electric operating revenue.  Start at 0.
            let data = [{
                name: 'electric_oper_revenue_val',
                value: [0, this.props.chartData['electric_oper_revenue_val']],
                category: 'revenue',
            }];
            // The next bar starts where the last left off, and adds other operating revenue
            data.push({
                name: 'other_oper_revenue_val',
                value: [data[data.length - 1].value[1], data[data.length - 1].value[1] + this.props.chartData['other_oper_revenue_val']],
                category: 'revenue',
            });
            // The next bar starts where the last left off, and adds non-operating revenue
            data.push({
                name: 'non_oper_revenue_val',
                value: [data[data.length - 1].value[1], data[data.length - 1].value[1] + this.props.chartData['non_oper_revenue_val']],
                category: 'revenue',
            });

            // Remember the total revenue value.  It's used more than once.
            let totalRevenue = this.props.chartData['tot_ordinary_revenue_val'];
            // The total revenue bar starts at 0 and goes up to the total revenue.
            // NOTE: This value may not exactly match the sum of the last 3 due to
            // roundoff error, but it should be close.
            data.push({
                name: 'tot_ordinary_revenue_val',
                value: [0, totalRevenue],
                category: 'total',
            });
            // The electric expenses bar ends/right-aligns with the total revenue value, and goes backwards
            data.push({
                name: 'electric_oper_expenses_val',
                value: [totalRevenue - this.props.chartData['electric_oper_expenses_val'], totalRevenue],
                category: 'expense',
            });
            // The next expense bar ends/right-aligns with the previous bar's left, and goes backwards
            data.push({
                name: 'other_oper_expenses_val',
                value: [data[data.length - 1].value[0] - this.props.chartData['other_oper_expenses_val'], data[data.length - 1].value[0]],
                category: 'expense',
            });
            // The next expense bar also ends/right-aligns with the previous bar's left, and goes backwards
            data.push({
                name: 'non_oper_expenses_val',
                value: [data[data.length - 1].value[0] - this.props.chartData['non_oper_expenses_val'], data[data.length - 1].value[0]],
                category: 'expense',
            });
            
            // Remember total ordinary income - we'll use it more than once
            let totalOrdinaryIncome = this.props.chartData['total_ordinary_income_val'];
            // Total ordinary income starts at 0 and goes up/down to the value above.
            // It should look like its right aligns with the left of the last expense bar,
            // but roundoff error might affect the actual value a bit.
            data.push({
                name: 'total_ordinary_income_val',
                value: [0, totalOrdinaryIncome],
                category: 'total',
            });
            // Extraordinary income/loss is based at the end/right of total ordinary income,
            // and goes to the right or left depending on the sign.
            data.push({
                name: 'extraordinary_income_loss_val',
                value: [totalOrdinaryIncome, totalOrdinaryIncome + this.props.chartData['extraordinary_income_loss_val']],
                category: 'extra',
            });

            // Remember income before taxes - we'll use it more than once
            let incomeBeforeTaxes = this.props.chartData['income_before_taxes_val'];
            // Income before taxes starts at zero and goes up or down to the value
            // extracted above.  It should appear to be left-aligned with the left 
            // of the extraordinary expenses bar.
            data.push({
                name: 'income_before_taxes_val',
                value: [0, incomeBeforeTaxes],
                category: 'total',
            });
            // The first tax item is right aligned at income before taxes, and backs
            // up to the left by the amount.
            data.push({
                name: 'income_taxes_current_val',
                value: [incomeBeforeTaxes - this.props.chartData['income_taxes_current_val'], incomeBeforeTaxes],
                category: 'tax',
            });
            // The next tax item left aligns with the previous and also backs up to the left
            data.push({
                name: 'income_taxes_deferred_val',
                value: [data[data.length - 1].value[0] - this.props.chartData['income_taxes_deferred_val'], data[data.length - 1].value[0]],
                category: 'tax',
            });

            // Remember net income
            let netIncome = this.props.chartData['net_inc_attrib_to_owners_val'];
            // Net income starts at 0 and goes left or right (depending on sign).
            // It the end of the bar should align with the left of the last taxes
            // bar.
            data.push({
                name: 'net_inc_attrib_to_owners_val',
                value: [0, netIncome],
                category: 'total',
            });

            // Determine the minimum expected X value
            let xMin = Math.min(0, netIncome);

            return (
                <ErrorBoundary>
                    <div className="statement-of-income-chart" style={{ width: this.props.width }}>
                        <div style={{ paddingLeft: this.props.yAxisWidth }}>
                            {this.props.formatChartTitle(this.props.chartData[this.props.chartBy])}
                        </div>
                        <div>
                            <ResponsiveContainer>
                                <ComposedChart
                                    data={data}
                                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                                    layout="vertical"
                                >
                                    <XAxis
                                        type="number"
                                        hide={true}
                                        domain={[xMin, totalRevenue]}
                                    />
                                    <YAxis
                                        type="category"
                                        dataKey="name"
                                        width={this.props.yAxisWidth}
                                        tickFormatter={(v) => this._getFieldName(v)}
                                    />
                                    <Bar dataKey="value">
                                        {
                                            data.map((entry, index) => (
                                                <Cell key={entry.name} fill={Palette[entry.category]} />
                                            ))
                                        }
                                    </Bar>
                                    <Tooltip
                                        isAnimationActive={false}
                                        labelFormatter={(v) => this._getFieldName(v)}
                                        formatter={(v, name, props) => [Locale.formatNumber(this.props.chartData[props.payload.name], 0), t('units.millionsOf_' + Locale.currencyName)]}
                                    />
                                    {/* Add a reference line at zero if we have negative values to help highlight 0, which is the break-even point */}
                                    {(xMin !== 0) && <ReferenceLine x="0" stroke={CommonStyles.abbGreen60} strokeWidth={0.8} />}
                                </ComposedChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </ErrorBoundary>
            );
        }
    }
}

export default StatementOfIncomeChart;
