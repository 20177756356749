import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ResponsiveContainer, ComposedChart, Line, XAxis, YAxis, Legend, Tooltip, Label } from 'recharts';
import { ToggleButton } from 'webcore-ux/react/components';

import ErrorBoundary from 'ErrorBoundary';
import Locale from 'locale/Locale';
import Palettes from 'common/Palettes';
import SessionState from 'SessionState';

import 'common/MuiSlider.scss';
import 'common/ReCharts.scss';
import './WeatherChart.scss';

const t = Locale.getResourceString.bind(Locale);

/////////////////////////////////////////////////////////////////////////////
// This class encapsulates the wind chart shown on the weather tab
/////////////////////////////////////////////////////////////////////////////
class WindChart extends Component {
    constructor(props, context) {
        super(props);

        // Set a maximum number of chart points/entries of 336, which is 1 week of hourly data
        WindChart._MaxChartEntries = 168;

        // Configure initial state
        this._sessionStateKey = 'emi-japan.weather.WindChart';
        this._defaultInitialState = {
            sliderValue: Number.MAX_SAFE_INTEGER,
            showWindDirection: false,
        }
        this._stateOverrides = {
        }
        this.state = SessionState.get(this._sessionStateKey, this._defaultInitialState, this._stateOverrides);

        // Bind event handlers
        this._onTooltipFormatLabel = this._onTooltipFormatLabel.bind(this);
        this._onFormatValue = this._onFormatValue.bind(this);
        this._onRenderTooltipContent = this._onRenderTooltipContent.bind(this);
        this._onChangeSlider = this._onChangeSlider.bind(this);
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gets whether or not wind direction can be shown on the chart.  By spec,
    // we only allow wind direction for hourly grandularity, and any aggregation
    // other than standard deviation.
    ////////////////////////////////////////////////////////////////////////////
    get _canShowWindDirection() {
        return (this.props.granularity === 'H' && this.props.aggregation !== 'stdev');
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gets whether or not wind direction is currently shown on the chart.
    ////////////////////////////////////////////////////////////////////////////
    get _showWindDirection() {
        return this._canShowWindDirection && this.state.showWindDirection;
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gets chart series - this will be 1 Line series per selected weather
    // station ID.
    ////////////////////////////////////////////////////////////////////////////
    get _series() {
        const palette = Palettes.DefaultColors;

        ////////////////////////////////////////////////////////////////////////
        // This component renders the chart point markers, or "dot"s as 
        // a wind direction arrow, using the corresponding station's 
        // direction value, received as part of the payload.
        ////////////////////////////////////////////////////////////////////////
        const WindSpeedDot = (props) => {
            const { cx, cy, dataKey, payload } = props;
            const size = 24;
            const rotationAngle = payload[dataKey.replace('speed', 'direction')];

            return (
                <svg width={size} height={size} x={cx - size / 2} y={cy - size / 2} viewBox="0 0 24 24">
                    <g fill="#3366ff" transform={`rotate(${rotationAngle} 12, 12)`} >
                        <polygon points="12,1 5,23 12,15 19,23"/>
                    </g>
                </svg>
            );
        };

        // Start with no series
        let series = [];
        if (this.props.aggregated != null && this.props.aggregated.length !== 0 && this.props.seriesKeys?.length) {
            // Map the series keys (e.g. ['speed_1', 'speed_40']) to Line series objects
            series = this.props.seriesKeys.map((s, index) => {
                // Use a subtle gray line color for 1 series; otherwise use the default palette
                let color = (this._showWindDirection && this.props.seriesKeys.length === 1) ? 
                    "#a0a0a0" :
                    palette[index % palette.length];

                return (
                    <Line
                        key={s} 
                        dataKey={s} 
                        dot={(this._showWindDirection) ? <WindSpeedDot /> : false}
                        isAnimationActive={false}
                        name={this.props.seriesNames[index]} 
                        stroke={color}
                        strokeWidth={2} 
                    />
                );
            });
        }

        return series;
    }

    get _dataBounds() {
        let extraEntries = this.props.aggregated.length - WindChart._MaxChartEntries;
        let max = Math.max(0, extraEntries);

        return {
            min: 0,
            max: max,
            sliderValue: Math.min(this.state.sliderValue, max)
        };
    }

    _getData(dataBounds) {
        // Clean up previous data
        if (this.__data != null) {
            this.__data.length = 0;
        }

        // Take a subset if needed
        this.__data = this.props.aggregated.slice(dataBounds.sliderValue, dataBounds.sliderValue + WindChart._MaxChartEntries);
        
        return this.__data;
    }

    get _yAxisLabelText() {
        let hasUnits = (this.props.aggregation === 'stdev' || this.props.aggregation === 'count');
        let units = hasUnits ? '' : ` ${t(`units.${Locale.speedUnits}`)}`;
        let labelKey = `weather.charts.wind.axisLabels.${this.props.aggregation}`;
        return `${t(labelKey)} ${units}`;
    }



    _onChangeSlider(event, value) {
        this.setState({
            sliderValue: value,
        })
    }

    _onTooltipFormatLabel(value) {
        return (<span>{value}</span>);
    }

    _onFormatValue(value, name, props) {
        return (this.props.aggregation === 'stdev') ? Locale.formatNumber(value, 2) :
            (this.props.aggregation === 'count') ? Locale.formatNumber(value, 0) :
            Locale.formatNumber(value, 0);
    }

    _onRenderTooltipContent(props) {
        const { active, label, payload } = props;

        if (!active) {
            return null;
        }

        // Render a tooltip item for each series in the chart.  Each item will 
        // show the wind speed and direction on 1 line.
        let items = payload.map(p => {
            return (
                <ul key={p.dataKey} className="recharts-tooltip-item-list" style={{ padding: 0, margin: 0 }}>
                    <li className="recharts-tooltip-item" style={{display: 'block', paddingTop: 4, paddingBottom: 4, color: p.color }}>
                        <span className="recharts-tooltip-item-name">{p.name}</span>
                        <span className="recharts-tooltip-item-separator">{props.separator}</span>
                        <span className="recharts-tooltip-item-value">{
                            Locale.formatSpeed(p.value, 0) + 
                            t('general.listSeparator') + 
                            Locale.formatNumber(p.payload[p.dataKey.replace('speed', 'direction')], 0)}&#176;
                        </span>
                        <span className="recharts-tooltip-item-unit"></span>
                    </li>
                </ul>
            );
        });

        return (
            <div
                className="recharts-default-tooltip"
                style={{ margin: 0, padding: 10, backgroundColor: '#fff', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}
            >
                <p
                    className="recharts-tooltip-label"
                    style={{ margin: 0 }}
                >
                    <span>{label}</span>
                </p>
                {items}
            </div>
        );
    }



    componentDidUpdate() {
        // Persist current state
        SessionState.set(this._sessionStateKey, this.state);
    }

    render() {
        ////////////////////////////////////////////////////////////////////////
        // This component renders a wind-direction icon using an SVG path and
        // the SvgIcon component.
        ////////////////////////////////////////////////////////////////////////
        const WindDirectionIcon = (props) => {
            return (
                <SvgIcon {...props}>
                    <path d="M12 1 L5 23 L12 15 L19 23z" />
                </SvgIcon>
            );
        }

        // Get bounds of the chart data
        let dataBounds = this._dataBounds;

        // Get chart margins and JSX for slider as needed
        let chartMarginBottom = (dataBounds.max > 0) ? 30 : 10;
        let sliderJSX = null;
        if (dataBounds.max > 0) {
            let sliderPaddingRight = 20;
            sliderJSX = (
                <div className="weather-range-slider" style={{ paddingRight: sliderPaddingRight }}>
                    <Slider
                        min={dataBounds.min}
                        max={dataBounds.max}
                        value={dataBounds.sliderValue}
                        onChange={this._onChangeSlider}
                    />
                </div>
            );
        }

        return (
            <ErrorBoundary>
                {/* Render a toggle button to turn wind-direction arrows on/off - these can get pretty busy-looking with multiple series */}
                <div className="weather-chart-options weather-chart-show-direction">
                    <ToggleButton 
                        size="small" 
                        disabled={!this._canShowWindDirection}
                        selected={this.state.showWindDirection} 
                        title={this.state.showWindDirection ? t('weather.charts.wind.hideWindDirection') : t('weather.charts.wind.showWindDirection')}
                        value="showWindDirection"
                        onClick={() => this.setState({ showWindDirection: !this.state.showWindDirection })}
                    >
                        <WindDirectionIcon />
                    </ToggleButton>
                </div>

                <ResponsiveContainer>
                    <ComposedChart
                        data={this._getData(dataBounds)}
                        margin={{
                            top: 60, right: 20, bottom: chartMarginBottom, left: 10,
                        }}
                    >
                        <XAxis 
                            dataKey="category" 
                        />
                        <YAxis 
                            tickFormatter={ (v) => this._onFormatValue(v) }
                            width={90}
                            yAxisId={0}>
                            <Label 
                                angle={-90} 
                                position='insideLeft'
                                value={this._yAxisLabelText}
                                style={{textAnchor: 'middle'}}
                            />
                        </YAxis>
                        <Tooltip 
                            isAnimationActive={false} 
                            labelFormatter={this._onTooltipFormatLabel}
                            content={this._onRenderTooltipContent}
                        />
                        <Legend />

                        {this._series}
                    </ComposedChart>
                </ResponsiveContainer>

                {sliderJSX}
            </ErrorBoundary>
        );
    }
}

WindChart.propTypes = {
    granularity: PropTypes.string,
    aggregation: PropTypes.string,
    aggregated: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.object,
    seriesKeys: PropTypes.arrayOf(PropTypes.string),
    seriesNames: PropTypes.arrayOf(PropTypes.string),
}

WindChart.defaultProps = {
    granularity: 'D',
    aggregation: 'average',
    aggregated: [],
    seriesKeys: [],
    seriesNames: [],
}

export default WindChart;
