import moment from 'moment';
import Locale from 'locale/Locale';

export default class HistoricSupplyAndDemandStats {
    constructor() {
        // These two fields are used for tracking the filters to be applied to data
        this._filters = {};
        this._statsFilters = {};
        // These two fields are used for the generation-by-fuel data
        this._filteredGenerationByFuel = [];
        this._generationByFuel = [];
        // These two fields are used for the demand-by-territory data
        this._filteredDemandByTerritory = [];
        this._demandByTerritory = [];

        this.dl = require('@hitachi.energy.digitalforce.node/datalib').dl;
    }

    get filters() {
        return this._filters;
    }

    get filteredGenerationByFuel() {
        return this._filteredGenerationByFuel;
    }

    get filteredDemandByTerritory() {
        return this._filteredDemandByTerritory;
    }

    get generationByFuel() {
        return this._generationByFuel;
    }

    get demandByTerritory() {
        return this._demandByTerritory;
    }

    calculate(data, filters) {
        // Save a copy of the original filters sent to us
        this._filters = JSON.parse(JSON.stringify(filters));
        this._statsFilters = JSON.parse(JSON.stringify(filters));
        
        // Patch up the start and end fields
        this._filters.start = this._statsFilters.start = moment(filters.start);
        this._filters.end = this._statsFilters.end = moment(filters.end);

        // The following columns are NOT to be treated as value columns when aggregating
        const NonValueColumns = {
            datetime: true,
            srvc_territory_id: true,
            is_load_derived: true,
        };
        
        // Clean up old calculated values
        this._cleanup();

        // Filter the data based on state
        let chartData = data.chartData;
        let valueColumns = Object.values(chartData.metadata.columns).filter(c => !NonValueColumns.hasOwnProperty(c.name));
        this._filter(chartData, valueColumns);
        this._aggregate(chartData, valueColumns);
    }



    _cleanup() {
        if (this._generationByFuel != null) {
            this._generationByFuel.length = 0;
        }

        if (this._demandByTerritory != null) {
            this._demandByTerritory.length = 0;
        }

        if (this._filteredGenerationByFuel != null) {
            this._filteredGenerationByFuel.length = 0;
        }

        if (this._filteredDemandByTerritory != null) {
            this._filteredDemandByTerritory.length = 0;
        }
    }

    _beforeFilter(data, valueColumns) {
        // Transform selection arrays into objects for easy lookup without searches
        this._statsFilters.supplyAndDemandServiceTerritories = this._statsFilters.supplyAndDemandServiceTerritories.reduce((obj, h) => { obj[h] = true; return obj; }, {});
        this._statsFilters.selectedHours = this._statsFilters.selectedHours.reduce((obj, h) => { obj[h] = true; return obj; }, {});
        this._statsFilters.selectedMonths = this._statsFilters.selectedMonths.reduce((obj, m) => { obj[m] = true; return obj; }, {});
        this._statsFilters.selectedYears = this._statsFilters.selectedYears.reduce((obj, y) => { obj[y] = true; return obj; }, {});

        // Change the end date so that we look up to midnight of the next day
        let originalEnd = this._statsFilters.end;
        this._statsFilters.end = moment.utc([originalEnd.year(), originalEnd.month(), originalEnd.date()]).add(1, 'days');
    }

    _getMomentCategory(dateTime) {
        // Default: hourly
        let momentCategory = dateTime;
        let granularity = this._statsFilters.granularity;

        // Annual
        if (granularity === 'A') {
            momentCategory = moment.utc([dateTime.year(), 1, 1]);
        }
        // Monthly
        else if (granularity === 'M') {
            momentCategory = moment.utc([dateTime.year(), dateTime.month()]);
        }
        // Daily
        else if (granularity === 'D') {
            momentCategory = moment.utc([dateTime.year(), dateTime.month(), dateTime.date()]);
        }
        // Month of Year
        else if (granularity === 'MoY') {
            // Use an arbitrary year to group by the month
            momentCategory = moment.utc([2020, dateTime.month()]);
        }
        // Hour of Day
        else if (granularity === 'HoD') {
            // Use an arbitrary year, month, and day to group by the hour only
            momentCategory = moment.utc([2020, 0, 1, dateTime.hour()]);
        }

        return momentCategory;
    }

    ////////////////////////////////////////////////////////////////////////////
    // Filters the input data, returning only the rows that meet the
    // filter criteria.
    // 
    // As a side-effect, also generates filtered demand-by-service territory 
    // data
    ////////////////////////////////////////////////////////////////////////////
    _filter(data, valueColumns) {
        if (data != null) {
            // Prepare filters for use
            this._beforeFilter(data, valueColumns);

            // Get property indexes
            let dateTimeIndex = data.metadata.columns.datetime.index;
            let serviceTerritoryIdIndex = data.metadata.columns.srvc_territory_id.index;
            let loadValIndex = data.metadata.columns.load_val.index;

            // Track the current timestamp
            let lastDateTime = moment(0);
            let demandFields = {};

            for (let i = 0; i < data.rows.length; ++i) {
                let row = data.rows[i];
                let dateTime = row[dateTimeIndex];
                let serviceTerritoryId = row[serviceTerritoryIdIndex];
                let loadVal = row[loadValIndex];
                let dateTimeCategory = this._getMomentCategory(dateTime);

                if (this._rowMeetsFilterCriteria(dateTime, serviceTerritoryId)) {
                    // First, add a row to the filtered generation data
                    let fields = valueColumns.reduce((obj, col) => { 
                        obj[col.name] = row[col.index];
                        return obj; 
                    }, {})
                    Object.assign(fields, { 
                        dateTime: dateTime,
                        dateTimeCategory: dateTimeCategory.valueOf(),
                        serviceTerritoryId: serviceTerritoryId,
                    });

                    this._filteredGenerationByFuel.push(fields);

                    // Next, look at how to handle this row's data for the pivoted-out demand data
                    // If the timestamp has changed, ensure that the current fields are added to the filtered result
                    if (!dateTime.isSame(lastDateTime)) {
                        lastDateTime = moment(dateTime);
                        demandFields = { dateTime: dateTime, dateTimeCategory: dateTimeCategory };
                        this._filteredDemandByTerritory.push(demandFields);
                    }
                    // Always assign the load to the existing fields object
                    demandFields[serviceTerritoryId] = loadVal;
                }
            }
        }

        //console.log(this._filteredGenerationByFuel);
    }

    _rowMeetsFilterCriteria(dateTime, serviceTerritoryId) {
        let rowMeetsFilterCriteria = false;

        // Do the "easy" service territory ID filter
        if (this._statsFilters.supplyAndDemandServiceTerritories[serviceTerritoryId])
        {
            // Filter for start/end date
            if (this._statsFilters.start.isSameOrBefore(dateTime) && dateTime.isBefore(this._statsFilters.end)) {
                // Filter for hour/month/year selections
                if (this._statsFilters.selectedHours[dateTime.hour()] &&
                    this._statsFilters.selectedMonths[dateTime.month() + 1] &&
                    this._statsFilters.selectedYears[dateTime.year()]) {
                    rowMeetsFilterCriteria = true;
                }
            }
        }

        return rowMeetsFilterCriteria;
    }

    _aggregate(data, valueColumns) {
        // Aggregate the generation data.  This is a 2-step process:
        // 1. Roll up the data, grouping by the original hourly timestamp using SUM.
        //    This will aggregate up all the service territories together at each hour.
        // 2. Next, roll up the data, grouping by the dateTime category using the 
        //    user-specified aggregation.  This will roll up the data over time.

        // 1. Group by dateTime to roll up over all territories (sum all numerics, take min dateTime - they should all be the same)
        let territoryAggregates = valueColumns.map(col => { 
            return { name: col.name, ops: ['sum'], as: [col.name] };
        });
        territoryAggregates = [...territoryAggregates, { name: "dateTimeCategory", ops: ['min'], as: ["dateTimeCategory"] }];
        let temp = this.dl
            .groupby('dateTime')
            .summarize(territoryAggregates)
            .execute(this._filteredGenerationByFuel);

        // 2. Group by dateTimeCategory to roll up over time
        let dateTimeAggregates = valueColumns.map(col => { 
            return { name: col.name, ops: [this._statsFilters.aggregation], as: [col.name] };
        });
        this._generationByFuel = this.dl
            .groupby('dateTimeCategory')
            .summarize(dateTimeAggregates)
            .execute(temp);

        // 3. Postprocess...
        for (let i = 0; i < this._generationByFuel.length; ++i) {
            let row = this._generationByFuel[i];
            // Convert dateTimeCategory to a human readable string for display on the chart
            row['dateTimeCategory'] = Locale.formatMomentGranularity(moment.utc(row['dateTimeCategory']), this._statsFilters.granularity);
            // Clean up unchartable values resulting from aggregation
            for (let j = 0; j < valueColumns.length; ++j) {
                let value = row[valueColumns[j].name];
                if (Number.isNaN(value) || !Number.isFinite(value)) {
                    row[valueColumns[j].name] = null;
                }
            }
        }

        // 4. Clean up
        temp.length = 0;



        // Next, aggregate the demand data.  All we need to do here is:
        // 1. Roll up the data, grouping by the dateTime category using the 
        //    user-specified aggregation.  This will roll up the data over time.
        let idFields = Object.keys(this._statsFilters.supplyAndDemandServiceTerritories);
        dateTimeAggregates = idFields.map(id => {
            return { name: id, ops: [this._statsFilters.aggregation], as: [id] };
        });
        this._demandByTerritory = this.dl
            .groupby('dateTimeCategory')
            .summarize(dateTimeAggregates)
            .execute(this._filteredDemandByTerritory);

        // Postprocess...
        for (let i = 0; i < this._demandByTerritory.length; ++i) {
            let row = this._demandByTerritory[i];
            // Convert dateTimeCategory to a human readable string for display on the chart
            row['dateTimeCategory'] = Locale.formatMomentGranularity(moment.utc(row['dateTimeCategory']), this._statsFilters.granularity);
            // Clean up unchartable values resulting from aggregation
            for (let j = 0; j < idFields.length; ++j) {
                let value = row[idFields[j]];
                if (Number.isNaN(value) || !Number.isFinite(value)) {
                    row[idFields[j]] = null;
                }
            }
        }
    }
}