import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import GlobalSessionState from './GlobalSessionState';

// Configure global session state for the app
GlobalSessionState.set({
    // Default value for historic-prices' spot market price chart series
    // This can be set in the chart, or alternatively the utility service
    // territory popout on the map.
    spotMarketSelectedSeries: ['tokyo_areaprice'],

    // Default value for supply and demand selected utility service territories
    supplyAndDemandServiceTerritories: [3],  // Tokyo is ID 3 at this time

    // Default value for company financials selected companies
    companyFinancialsCompanyIds: [3],  // Tokyo is ID 3 at this time

    // Default value for price forecast selected territory
    priceForecastElectricServiceTerritoryId: [3], // Tokyo is ID 3 at this time
});

ReactDOM.render(<App />, document.getElementById('root'));
