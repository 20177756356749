import WeatherStats from './WeatherStats';

export default class SunshineDurationStats extends WeatherStats {
    _getFields(row) {
        return this._valueColumns.reduce((obj, col) => { 
            obj[col.name] = row[col.index];
            return obj; 
        }, {});
    }
}
