import AppTrace from 'AppTrace';
import Locale from 'locale/Locale';

export default class DataFormatting {
    //
    // Converts all field values in the input query results to the intended type
    // based on metadata-reported column types.
    //
    static convertResultFields(queryResult) {
        // Get the columns that are returned from the service
        let columns = Object.values(queryResult.metadata.columns);

        // Iterate over all rows...
        for (let i = 0; i < queryResult.rows.length; ++i) {
            let r = queryResult.rows[i];

            // Convert all fields as needed
            for (let j = 0; j < columns.length; ++j) {
                let col = columns[j];
                let fieldValue = r[col.index];
                if (fieldValue != null) {
                    let isEmpty = typeof fieldValue === 'string' && fieldValue.trim().length === 0;

                    if (col.type === 'datetime') {
                        r[col.index] = isEmpty ? null : Locale.parseMoment(fieldValue);
                    }
                    else if (col.type === 'integer') {
                        r[col.index] = isEmpty ? null : parseInt(fieldValue, 10);
                    }
                    else if (col.type === 'number') {
                        r[col.index] = isEmpty ? null : parseFloat(fieldValue);
                    }
                    else if (col.type === 'json') {
                        r[col.index] = isEmpty ? null : DataFormatting.parseJSON(fieldValue);
                    }
                }
            }
        }
    }

    ////////////////////////////////////////////////////////////////////////////
    // The purpose of this method is to provide error handling when using
    // JSON.parse with JSON field values, column captions, etc. from the API.
    // In the event that something unexpected is received from the API, we
    // will have an error log record to help diagnose the issue.
    ////////////////////////////////////////////////////////////////////////////
    static parseJSON(json) {
        let result = json;
        try {
            result = JSON.parse(json);
        }
        catch (err) {;
            AppTrace.traceError("DataFormatting.parseJSON: " + err);
        }
        return result;
    }
}
