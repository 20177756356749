import React from 'react';
import { withRouter } from 'react-router';

import AppConfig from 'AppConfig';
import AppTrace from 'AppTrace';
import Authentication from 'Authentication';
import ErrorBoundary from 'ErrorBoundary';
import Locale from 'locale/Locale';
import PopupDataCache from '../PopupDataCache';
import PopupLoadIndicator from '../PopupLoadIndicator';
import GeneratingUnits from './GeneratingUnits';
import FuelAndStatusChart from './FuelAndStatusChart';

import 'common/MuiTreeView.scss';

const t = Locale.getResourceString.bind(Locale);
const axios = require('axios');

class PowerPlantPopup extends React.Component {
    constructor(props, context) {
        super(props);
    }

    get _url() {
        let entityId = this.props.feature.getProperties().entityId;
        return AppConfig.dataService.baseUrl + '/spatial-awareness/power-plants/{0}/popup'.replace('{0}', entityId);
    }

    _getData() {
        return new Promise((resolve, reject) => {
            let url = this._url;

            // Check the cache for this popup data
            if (PopupDataCache.contains(url)) {
                // It's there so resolve with the cached data
                resolve(PopupDataCache.get(url));
            } else {
                // Otherwise get access token
                Authentication.getAccessToken()
                    .then((accessToken) => {
                        // Then use the access token to get data for popup
                        let authString = 'Bearer '.concat(accessToken);
                        return axios.get(url, {
                            headers: {
                                Authorization: authString,
                                Accept: 'application/json',
                                'cache-control': 'no-cache',
                            },
                        });
                    })
                    .then((response) => {
                        // Get result data
                        let apiData = response.data;

                        // Set a delete function to clean up the data
                        apiData.delete = function () {
                            if (this.operatingUnits != null) {
                                this.operatingUnits.length = 0;
                            }
                            if (this.units != null) {
                                this.units.length = 0;
                            }
                        };

                        // Now use the data to render the popup
                        resolve(apiData);
                    })
                    .catch((error) => {
                        AppTrace.traceError('Error in PowerPlantPopup._getData: ' + error.message);
                        reject(new Error("Error getting popup data"));
                    });
            }
        });
    }

    _getDataAndRender() {
        this._getData()
            .then((apiData) => {
                // Cache the API data
                PopupDataCache.add(this._url, apiData);
                // Force an update
                this.forceUpdate();
            })
            .catch((error) => {
                // Don't hammer the server with a URL that is failing
                PopupDataCache.add(this._url, { error: error });
            });
    }

    _getDynamicContent() {
        // Dynamic content is build based on availability of data
        let dynamicContent = null;

        // If we have already queried the data for the current entity, render the full content
        if (PopupDataCache.contains(this._url)) {
            // Get API data
            let apiData = PopupDataCache.get(this._url);

            // Get just the rows that apply to the current state of the plant
            let unitsNow = apiData.units.filter((u) => u.startDate != null && u.endDate == null);

            // Get distinct fuels and operating stati
            let allFuels = {};
            let allStati = {};
            unitsNow.forEach((u) => {
                allFuels[u.fuelKey] = Locale.getJSONFieldValue(u.fuel);
                allStati[u.statusKey] = Locale.getJSONFieldValue(u.statusFull);
            });

            // Operating unit names...
            let operatingUnitNames = apiData.operatingUnits
                .map((name) => Locale.getJSONFieldValue(name))
                .join(',')
                .trim();
            if (operatingUnitNames == null || operatingUnitNames.length === 0) {
                operatingUnitNames = t('general.noDataAvailable');
            }

            // Status...
            let status = Locale.getJSONFieldValue(this.props.feature.getProperties().plantStatus);
            if (status == null || status.length === 0) {
                status = t('general.noDataAvailable');
            }

            // Owner...
            let owner = Locale.getJSONFieldValue(this.props.feature.getProperties().majOwner);
            if (owner == null || owner.length === 0) {
                owner = t('general.noDataAvailable');
            }

            dynamicContent = (
                <>
                    <span className="feature-popup-property-name">
                        {t('spatialAwareness.layers.powerPlants.popup.operatingCapacity')}
                    </span>
                    <span className="feature-popup-property-value">
                        {apiData.operatingCapacity}&nbsp;{t('units.megaWatts')}
                    </span>
                    <br />

                    <span className="feature-popup-property-name">
                        {t('spatialAwareness.layers.powerPlants.popup.operatingUnits')}
                    </span>
                    <span className="feature-popup-property-value" title={operatingUnitNames}>{operatingUnitNames}</span>
                    <br />

                    <span className="feature-popup-property-name">
                        {t('spatialAwareness.layers.powerPlants.popup.plantStatus')}
                    </span>
                    <span className="feature-popup-property-value">{status}</span>
                    <br />

                    <span className="feature-popup-property-name">
                        {t('spatialAwareness.layers.powerPlants.popup.owner')}
                    </span>
                    <span className="feature-popup-property-value" title={owner}>{owner}</span>
                    <br />

                    <span className="feature-popup-property-name">
                        {t('spatialAwareness.layers.powerPlants.popup.firstOnlineDate')}
                    </span>
                    <span className="feature-popup-property-value">
                        {apiData.firstOnlineDate != null
                            ? Locale.formatDate(Locale.parseMoment(apiData.firstOnlineDate))
                            : t('general.noDataAvailable')}
                    </span>
                    <br />

                    <span className="feature-popup-property-name">
                        {t('spatialAwareness.layers.powerPlants.popup.unitsByFuelAndStatus')}
                    </span>
                    <FuelAndStatusChart
                        units={unitsNow}
                        fuels={allFuels}
                        stati={allStati}
                    />

                    <GeneratingUnits 
                        unitsNow={unitsNow}
                        allStati={allStati}
                    />
                </>
            );
        } else {
            // Otherwise, show a load indicator and query for the current data
            dynamicContent = <PopupLoadIndicator />;
        }

        return dynamicContent;
    }



    componentDidMount() {
        this._getDataAndRender();
    }

    render() {
        // Dynamic content is build based on availability of data
        let dynamicContent = this._getDynamicContent();
        if (!PopupDataCache.contains(this._url)) {
            this._getDataAndRender();
        }

        return (
            <ErrorBoundary>
                <span className="feature-popup-title">{t('spatialAwareness.layers.powerPlants.popup.title')}</span>
                <br />
                <br />
                <span className="feature-popup-property-name">
                    {t('spatialAwareness.layers.powerPlants.popup.name')}
                </span>
                <span className="feature-popup-property-value" title={Locale.getJSONFieldValue(this.props.feature.getProperties().name)}>
                    {Locale.getJSONFieldValue(this.props.feature.getProperties().name)}
                </span>
                <br />
                <span className="feature-popup-property-name">
                    {t('spatialAwareness.layers.powerPlants.popup.primaryFuel')}
                </span>
                <span className="feature-popup-property-value">{Locale.getJSONFieldValue(this.props.feature.getProperties().fuelName)}</span>
                <br />

                {dynamicContent}
            </ErrorBoundary>
        );
    }
}

export default withRouter(PowerPlantPopup);
