import AppConfig from 'AppConfig';
import Authentication from 'Authentication';
import AppTrace from 'AppTrace';
import DataFormatting from 'DataFormatting';

const axios = require('axios');

////////////////////////////////////////////////////////////////////////////////
// Gets and caches the searchable/mappable entities to support JEMI search.
////////////////////////////////////////////////////////////////////////////////
export default class SearchDataFactory {
    static __cache = null;

    static get _cache() {
        return SearchDataFactory.__cache;
    }
    static set _cache(value) {
        SearchDataFactory.__cache = value;
    }    

    static get() {
        return new Promise((resolve, reject) => {
            if (SearchDataFactory._cache != null) {
                resolve(SearchDataFactory._cache);
            }
            else {
                // Get access token
                Authentication.getAccessToken()
                    // Get API data
                    .then((accessToken) => {
                        let authString = `Bearer `.concat(accessToken);
                        let url = `${AppConfig.dataService.baseUrl}/spatial-awareness/search`;

                        return axios.get(url, {
                            headers: {
                                Authorization: authString,
                                Accept: 'application/json',
                                'cache-control': 'no-cache',
                            },
                        });
                    })
                    .then((response) => {
                        let data = response.data;
                        DataFormatting.convertResultFields(data);
                        SearchDataFactory._cache = data;
                        resolve(SearchDataFactory._cache);
                    })
                    .catch((error) => {
                        AppTrace.traceError('Error in SearchDataFactory.get: ' + error.message);
                        reject(new Error('Error getting search data'));
                    });
            }
        });
    }
}
