class BusyState {
    _handlers = [];
    _isBusy = false; // Whether or not the application is busy

    // Adds a function to be called when the state changes
    addHandler(handler) {
        this._handlers.push(handler);
    }

    // Gets whether or not the app is busy
    get isBusy() {
        return this._isBusy;
    }

    // Sets whether or not the app is busy
    set isBusy(isBusy) {
        if (this.isBusy !== isBusy) {
            this._isBusy = isBusy;
            this._notify();
        }
    }

    _notify() {
        let stopNotifying = false;
        for (let i = 0; i < this._handlers.length && !stopNotifying; ++i) {
            stopNotifying = stopNotifying || this._handlers[i](this.isBusy);
        }
    }
}

const _instance = new BusyState();
Object.seal(_instance);

export default _instance;
