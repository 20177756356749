class ErrorState {
    _faultHandlers = [];
    _fault = null;
    _faultCategory = this.faultCategories.Default;
    _errorContinuationHandlers = [];

    // Gets the available fault categories
    get faultCategories() {
        const FaultCategories = {
            Default: 0,
            Fatal: 1,
            SignIn: 2,
            PopupBlocked: 3,
        };

        return FaultCategories;
    }

    // Adds a function to be called when a fault is set or cleared
    addFaultHandler(faultHandler) {
        this._faultHandlers.push(faultHandler);
    }

    // Gets whether or not the app is faulted
    get isFaulted() {
        return this._fault !== null;
    }

    // Gets the current fault; null if there is no current fault
    get fault() {
        return this._fault;
    }

    // Gets the fault category
    get faultCategory() {
        return this._faultCategory;
    }

    // Sets an application fault
    setFault(fault, faultCategory, errorContinuationCallback) {
        this._fault = fault;
        this._faultCategory = (faultCategory != null && Object.values(this.faultCategories).indexOf(faultCategory) !== -1) ? 
            faultCategory : 
            this.faultCategories.Default;
        if (errorContinuationCallback != null) {
            this._errorContinuationHandlers.push(errorContinuationCallback);
        }

        this._notify();
    }

    clearFault() {
        let isFatal = this.faultCategory === this.faultCategories.Fatal;

        // Clear fault information
        this._fault = null;
        this._faultCategory = this.faultCategories.Default;

        // Notify fault handlers
        this._notify();

        // Invoke error-continuation callbacks if the error is non-fatal
        if (isFatal === false) {
            for (let i = 0; i < this._errorContinuationHandlers.length; ++i) {
                this._errorContinuationHandlers[i]();
            }
        }

        // Clear error-continuation handlers
        this._errorContinuationHandlers.length = 0;
    }

    _notify() {
        let stopNotifying = false;
        for (let i = 0; i < this._faultHandlers.length && !stopNotifying; ++i) {
            this._faultHandlers[i](this.isFaulted);
        }
    }
}

const _instance = new ErrorState();
Object.seal(_instance);

export default _instance;
