import moment from 'moment';
import Locale from 'locale/Locale';

export default class FuelPriceForecastStats {
    constructor() {
        this._filtered = [];

        this._aggregated = [];

        this.dl = require('@hitachi.energy.digitalforce.node/datalib').dl;

        this._aggregationLookup = {
            min: this.dl.min,
            max: this.dl.max,
            average: this.dl.mean,
            sum: this.dl.sum,
            stdev: this.dl.stdev,
            count: this.dl.count
        }
    }

    get filtered() {
        return this._filtered;
    }

    get aggregated() {
        return this._aggregated;
    }

    calculate(data, filters) {
        // Clean up old calculated values
        this._cleanup();

        // Filter the data based on state
        let currencyColumns = data.getCurrencyColumns();
        this._filtered = this._filter(data, filters, currencyColumns);
        this._aggregated = this._aggregate(data, filters, currencyColumns);
    }



    _cleanup() {
        if (this._aggregated != null) {
            this._aggregated.length = 0;
        }

        if (this._filtered != null) {
            this._filtered.length = 0;
        }
    }

    _beforeFilter(data, filters, currencyColumns) {
        // Transform selection arrays into objects for easy lookup without searches
        filters.selectedMonths = filters.selectedMonths.reduce((obj, m) => { obj[m] = true; return obj; }, {});
        filters.selectedYears = filters.selectedYears.reduce((obj, y) => { obj[y] = true; return obj; }, {});

        // Change the start and end date for our monthly data
        filters.start = moment.utc([filters.start.year(), filters.start.month(), 1]);
        filters.end = moment.utc([filters.end.year(), filters.end.month(), 1]).add(1, 'months');
    }

    _filter(data, filters, currencyColumns) {
        let filtered = [];

        // Prepare filters for use
        this._beforeFilter(data, filters, currencyColumns);

        if (data != null) {
            // Get property indexes
            let dateTimeIndex = data.metadata.columns.datetime.index;
            let scenarioIdIndex = data.metadata.columns.scenario_id.index;

            for (let i = 0; i < data.rows.length; ++i) {
                let row = data.rows[i];
                let theMoment = row[dateTimeIndex];
                let scenario_id = row[scenarioIdIndex];

                if (this._rowMeetsFilterCriteria(theMoment, scenario_id, filters)) {
                    // Get date category
                    let momentCategory = this._getMomentCategory(theMoment, filters);
                        
                    let fields = currencyColumns.reduce((obj, col) => { 
                        obj[col.name] = (col.tag != null && col.tag.isCurrencyField) ? 
                            Locale.getJSONCurrencyValue(row[col.index]) :
                            row[col.index];
                        return obj; 
                    }, {})
                    Object.assign(fields, { momentCategory: momentCategory, scenario_id: scenario_id });
                    filtered.push(fields);
                }
            }
        }

        //console.log(filtered.length);

        return filtered;
    }

    _getMomentCategory(theMoment, filters) {
        // Default: monthly
        let momentCategory = moment.utc([theMoment.year(), theMoment.month()]);
        let granularity = filters.granularity;

        // Annual
        if (granularity === 'A') {
            momentCategory = moment.utc([theMoment.year(), 1, 1]);
        }
        // Month of Year
        else if (granularity === 'MoY') {
            // Use an arbitrary year to group by the month
            momentCategory = moment.utc([2020, theMoment.month()]);
        }

        return momentCategory;
    }

    _rowMeetsFilterCriteria(theMoment, scenario_id, filters) {
        let rowMeetsFilterCriteria = false;

        // Filter for scenario ID
        if (filters.selectedScenarioId === scenario_id) {
            // Filter for start/end date
            if (filters.start.isSameOrBefore(theMoment) && theMoment.isBefore(filters.end)) {
                // Filter for hour/month/year selections
                if (filters.selectedMonths[theMoment.month() + 1] &&
                    filters.selectedYears[theMoment.year()]) {
                        rowMeetsFilterCriteria = true;
                }
            }
        }

        return rowMeetsFilterCriteria;
    }

    _aggregate(data, filters, currencyColumns) {
        let aggregated = [];

        // Aggregate the data
        aggregated = this.dl.groupby('momentCategory').summarize(
            currencyColumns.map(col => { 
                return {
                    name: col.name,
                    ops: [filters.aggregation],
                    as: [col.name]
                };
            })
        ).execute(this._filtered);

        // Order by the categorizied moment
        aggregated.sort(this.dl.comparator('momentCategory'));

        // Assign a human-readable category
        for (let i = 0; i < aggregated.length; ++i) {
            let row = aggregated[i];
            row.category = Locale.formatMomentGranularity(row.momentCategory, filters.granularity);
            for (let j = 0; j < currencyColumns.length; ++j) {
                let value = row[currencyColumns[j].name];
                if (Number.isNaN(value) || !Number.isFinite(value)) {
                    row[currencyColumns[j].name] = null;
                }
            }
        }

        //console.log(aggregated);

        return aggregated;
    }
}