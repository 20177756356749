import { Fill, Stroke, Style } from 'ol/style';
import React from 'react';
import Locale from '../../../locale/Locale';
import Animation from './Animation';
import AnimationControls from './AnimationControls';

const t = Locale.getResourceString.bind(Locale);
const d3Interpolate = require('d3-interpolate');

export default class PriceAnimation extends Animation {
    static _Fall = ['#3d5941', '#778868', '#b5b991', '#f6edbd', '#edbb8a', '#de8a5a', '#ca562c'];
    static _Geyser = ['#008080', '#70a494', '#b4c8a8', '#f6edbd', '#edbb8a', '#de8a5a', '#ca562c'];
    static _Temps = ['#009392', '#39b185', '#9ccb86', '#e9e29c', '#eeb479', '#e88471', '#cf597e'];
    static _Earth = ['#A16928', '#bd925a', '#d6bd8d', '#edeac2', '#b5c8b8', '#79a7ac', '#2887a1'];

    static _ColorPalette = PriceAnimation._Earth;

    ////
    // Object creation
    ////
    constructor(layer) {
        super(layer);

        this._interpolateColor = d3Interpolate.piecewise(d3Interpolate.interpolateRgb, PriceAnimation._ColorPalette);

        this._min = null;
        this._5thPercentile = null;
        this._95thPercentile = null;
        this._max = null;

        this._minYen = null;
        this._5thPercentileYen = null;
        this._95thPercentileYen = null;
        this._maxYen = null;
    }

    ////
    // Public interface
    ////

    // Renders this theme.  By default, no rendering is needed.  But,
    // for animations, animation controls may be desired, etc.
    render() {
        // Don't render any content if the theme is not active and loaded
        if (!this.isActive || !this.isLoaded) {
            return null;
        }

        return (
            <AnimationControls
                ref={this._animationControlsRef}
                data={this._apiData.rows}
                formatFrameTip={(value) => this._formatFrameTip(value)}
                onChange={(value) => this._onChangeFrameIndex(value)}
            />
        );
    }

    // Gets the style for the current feature as styled by this theme
    getStyle(feature, resolution) {
        // Default to transparent if we have no data
        let color = 'transparent';

        if (this.isLoaded) {
            let entityId = feature.getProperties().entityId;
            if (this._apiData.metadata.columns[entityId.toString()] != null) {
                let fieldValue = this._apiData.rows[this._frameIndex][this._apiData.metadata.columns[entityId.toString()].index];
                if (fieldValue != null) {
                    let yen = Locale.getJSONCurrencyValue(fieldValue, 'JPY');
                    color = (yen < this._5thPercentileYen) ? PriceAnimation._ColorPalette[0] :
                        (yen > this._95thPercentileYen) ? PriceAnimation._ColorPalette[PriceAnimation._ColorPalette.length - 1] :
                            color = this._interpolateColor((yen - this._5thPercentileYen) / (this._95thPercentileYen - this._5thPercentileYen));
                }
            }
        }

        return new Style({
            stroke: new Stroke({
                color: [128, 128, 128, 0.5],
                width: 0.5
            }),
            fill: new Fill({
                color: color
            })
        });
    }

    // Renders legend content for this layer
    renderLegendContent() {
        let gradientStyle = 'linear-gradient(to right, ' + PriceAnimation._ColorPalette.join(',') + ')';
        let subtitleResource = 'spatialAwareness.animations.{0}.title'.replace('{0}', this.key);

        return (
            <div key={this.key + '-legend-content'} className="ev-legend-layer-content">
                <span className="ev-legend-layer-name">{t('spatialAwareness.layers.utilityServiceTerritories.title')}</span><br />
                <span className="ev-legend-theme-variable">{t(subtitleResource)}</span><br />

                <div className="ev-legend-gradient-labels">
                    <span className="ev-legend-gradient-label-low">{this._formatCurrency(this._min)}</span>
                    <span className="ev-legend-gradient-label-high">{this._formatCurrency(this._max)}</span>
                </div>
                <div className="ev-legend-pre-gradient" style={{ backgroundColor: PriceAnimation._ColorPalette[0] }} />
                <div className="ev-legend-gradient" style={{ backgroundImage: gradientStyle }} />
                <div className="ev-legend-post-gradient" style={{ backgroundColor: PriceAnimation._ColorPalette[PriceAnimation._ColorPalette.length - 1] }} />

                <div className="ev-legend-gradient-labels">
                    <span className="ev-legend-gradient-label-5th">{">" + this._formatCurrency(this._5thPercentile)}</span>
                    <span className="ev-legend-gradient-label-95th">{"<" + this._formatCurrency(this._95thPercentile)}</span>
                </div>
            </div>
        );
    }



    ////
    // "Protected" interface
    ////

    _createTheme() {
        const YenCurrencyName = 'JPY';

        this._dateTimeIndex = this._apiData.metadata.columns.dateTime.index;

        // Find min, max, and key percentiles in the data
        if (this._apiData.rows.length > 0) {
            let columns = Object.values(this._apiData.metadata.columns);
            let temp = [];
            for (let i = 0; i < this._apiData.rows.length; ++i) {
                let row = this._apiData.rows[i];
                for (let j = 0; j < columns.length; ++j) {
                    let col = columns[j];
                    if (col.index !== this._dateTimeIndex) {
                        let fieldValue = row[col.index];
                        if (fieldValue != null && Locale.getJSONCurrencyValue(fieldValue, YenCurrencyName) != null) {
                            temp.push(fieldValue);
                        }
                    }
                }
            }

            // Sort values in ascending order
            temp.sort((a, b) => {
                return (Locale.getJSONCurrencyValue(a, YenCurrencyName) - Locale.getJSONCurrencyValue(b, YenCurrencyName));
            });

            // Get the limits
            let fivePercent = Math.round(temp.length / 20);
            this._min = temp[0];
            this._max = temp[temp.length - 1];
            this._5thPercentile = temp[fivePercent];
            this._95thPercentile = temp[temp.length - 1 - fivePercent];

            // Extract the yen for each of the limits; we'll use yen for assinging theme colors
            this._minYen = Locale.getJSONCurrencyValue(this._min, YenCurrencyName);
            this._maxYen = Locale.getJSONCurrencyValue(this._max, YenCurrencyName);
            this._5thPercentileYen = Locale.getJSONCurrencyValue(this._5thPercentile, YenCurrencyName);
            this._95thPercentileYen = Locale.getJSONCurrencyValue(this._95thPercentile, YenCurrencyName);
        }
    }

    _formatFrameTip(value) {
        throw new Error('not implemented');
    }

    _formatCurrency(value) {
        throw new Error('not implemented');
    }
}