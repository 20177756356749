import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PowerIcon from '@material-ui/icons/Power';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DetailsIcon from '@material-ui/icons/Details';
import Slider from '@material-ui/core/Slider';
import { ResponsiveContainer, ComposedChart, Bar, XAxis, YAxis, Legend, Tooltip, Label } from 'recharts';
import { ToggleButton, ToggleButtonGroup } from 'webcore-ux/react/components';

import Locale from 'locale/Locale';
import ErrorBoundary from 'ErrorBoundary';

import ForwardMarketChartPalette from './ForwardMarketChartPalette';
import ForwardMarketChartTooltip from './ForwardMarketChartTooltip';
import SessionState from 'SessionState';

import 'common/MuiSlider.scss';
import 'common/ReCharts.scss';
import './ForwardMarketChart.scss';

const t = Locale.getResourceString.bind(Locale);

/////////////////////////////////////////////////////////////////////////////
// This class encapsulates the forward-market prices chart
/////////////////////////////////////////////////////////////////////////////
class ForwardMarketChart extends Component {
    constructor(props, context) {
        super(props);

        // Set a maximum number of chart points/entries
        // 168 will allow this chart to have a similar appearance/time scale
        // to the spot market chart at a daily level
        ForwardMarketChart._MaxChartEntries = 168;

        // Configure initial state
        this._sessionStateKey = 'emi-japan.price-history.ForwardMarketChart';
        this._defaultInitialState = {
            colorBy: 'interconnect',
            isDetailedTheme: false,
        }
        this._stateOverrides = {
            sliderValue: Number.MAX_SAFE_INTEGER,
        }
        this.state = SessionState.get(this._sessionStateKey, this._defaultInitialState, this._stateOverrides);

        this._onChangeSlider = this._onChangeSlider.bind(this);
        this._onTooltipFormatLabel = this._onTooltipFormatLabel.bind(this);
        this._onTooltipFormatValue = this._onTooltipFormatValue.bind(this);
        this._onFormatValue = this._onFormatValue.bind(this);
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gets the palette to be used to theme chart series.
    // See ForwardMarketChartPalette for more information.
    ////////////////////////////////////////////////////////////////////////////
    get _palette() {
        return (this.state.isDetailedTheme) ?
            ForwardMarketChartPalette.getDetailedPalette(this.state.colorBy) :
            ForwardMarketChartPalette.getSummaryPalette(this.state.colorBy);
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gets the interconnects that are currently selected by the user.
    // The key field represents the selected values reported by the UI select
    // control.  The value field represents the corresponding piece of the 
    // series names that we use in the chart.
    // (e.g. "kansai" maps to "kn" in "kn_wk_24_total_volume")
    ////////////////////////////////////////////////////////////////////////////
    get _interconnects() {
        const interconnects = [
            { key: 'kansai', value: 'kn' },
            { key: 'system', value: 'sys' },
            { key: 'tokyo', value: 'tk' },
        ];

        return interconnects.filter(obj => this.props.series.indexOf(obj.key) >= 0);
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gets the peak types that are currently selected by the user.
    // The key field represents the selected values reported by the UI select
    // control.  The value field represents the corresponding piece of the 
    // series names that we use in the chart.
    // (e.g. "allHours" maps to "24" in "kn_wk_24_total_volume")
    ////////////////////////////////////////////////////////////////////////////
    get _peakTypes() {
        const peakTypes = [
            { key: 'allHours', value: '24' },
            { key: 'daytime', value: 'dt' },
        ];

        return peakTypes.filter(obj => this.props.series.indexOf(obj.key) >= 0);
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gets the contract lengths that are currently selected by the user.
    // The key field represents the selected values reported by the UI select
    // control.  The value field represents the corresponding piece of the 
    // series names that we use in the chart.
    // (e.g. "week" maps to "wk" in "kn_wk_24_total_volume")
    ////////////////////////////////////////////////////////////////////////////
    get _contractLengths() {
        const contractLengths = [
            { key: 'week', value: 'wk' },
            { key: 'month', value: 'mth' },
            { key: 'year', value: 'yr' },
        ];

        return contractLengths.filter(obj => this.props.series.indexOf(obj.key) >= 0);
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gets the series keys (which are data field names, that end up being
    // "dataKey" attributes of the chart series) that are currently selected
    // by the user.
    //
    // This is done by deriving the series names based on the categories that
    // are currently selected by the user.  The collection is specifically
    // ordered to keep similar series together in the stack order based on
    // the currently selected colorBy theme.
    ////////////////////////////////////////////////////////////////////////////
    get _selectedSeriesKeys() {
        const interconnects = this._interconnects.map(obj => obj.value);
        const peakTypes = this._peakTypes.map(obj => obj.value);
        const contractLengths = this._contractLengths.map(obj => obj.value);

        const seriesKeysFactories = {
            'interconnect': () => {
                // Keep series ordered primarily by interconnect name so that 
                // interconnect series stack next to each other
                let selectedSeriesKeys = [];
                interconnects.forEach(ic => {
                    contractLengths.forEach(cl => {
                        peakTypes.forEach(pt => {
                            selectedSeriesKeys.push(ic + '_' + cl + '_' + pt + '_total_volume');
                        })
                    })
                });
                return selectedSeriesKeys;
            },
            'peakType': () => {
                // Keep series ordered primarily by peak type so that 
                // same-peak-type series stack next to each other
                let selectedSeriesKeys = [];
                peakTypes.forEach(pt => {
                    interconnects.forEach(ic => {
                        contractLengths.forEach(cl => {
                            selectedSeriesKeys.push(ic + '_' + cl + '_' + pt + '_total_volume');
                        })
                    })
                });
                return selectedSeriesKeys;
            },
            'contractLength': () => {
                // Keep series ordered primarily by contract length name so that 
                // same-contract-length series stack next to each other
                let selectedSeriesKeys = [];
                contractLengths.forEach(cl => {
                    interconnects.forEach(ic => {
                        peakTypes.forEach(pt => {
                            selectedSeriesKeys.push(ic + '_' + cl + '_' + pt + '_total_volume');
                        })
                    })
                });
                return selectedSeriesKeys;
            },
        };

        return seriesKeysFactories[this.state.colorBy]()
            .filter(key => ForwardMarketChart.KnownDataKeys.hasOwnProperty(key));
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gets the chart series that are to be included in the chart.
    ////////////////////////////////////////////////////////////////////////////
    _getSeries(data) {
        let series = [];
        let selectedSeriesKeys = this._selectedSeriesKeys;
        let seriesKeysWithData = this._getSeriesKeysWithData(data);
        let palette = this._palette;
        
        series = selectedSeriesKeys.map((key, index) => {
            return (
                <Bar
                    key={key} 
                    dataKey={key} 
                    stroke={palette.getSeriesColor(key)} 
                    fill={palette.getSeriesColor(key)} 
                    hide={seriesKeysWithData[key] === false}
                    isAnimationActive={false}
                    name={t('priceHistory.charts.forwardMarket.series.' + key)} 
                    stackId={0}
                    yAxisId={0}
                />
            )
        });

        return series;
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gets the legend "payload" based on our current UI settings.
    // The legend payload is then used by recharts to render the legend.
    ////////////////////////////////////////////////////////////////////////////
    get _legendPayload() {
        let payload = null;
        let palette = this._palette;

        // For the detailed theme, we want a legend item for every series in the 
        // chart.  To make the large number of series and colors easier to interpret,
        // also include a "heading" legend item at the top of each "category" of 
        // series/legend items.
        if (this.state.isDetailedTheme) {
            payload = [];
            let selectedSeriesKeys = this._selectedSeriesKeys;
            let categories = (this.state.colorBy === 'interconnect') ? this._interconnects :
                (this.state.colorBy === 'peakType') ? this._peakTypes :
                (this.state.colorBy === 'contractLength') ? this._contractLengths :
                [];

            // For each "category" of series items (based on colorBy selection)...
            categories.forEach(cat => {
                let categoryLegendPayload = selectedSeriesKeys
                    // Get the series for the current category
                    .filter(s => s.indexOf(cat.value + '_') >= 0)
                    // Map the series to an object with the fields required for the recharts legend
                    .map(s => { return { color: palette.getSeriesColor(s), type: 'rect', value: t('priceHistory.charts.forwardMarket.series.' + s) }});

                if (categoryLegendPayload.length > 0) {
                    // Add a heading
                    payload.push({ key: 'heading', color: '#fff', type: 'rect', value: t('priceHistory.charts.forwardMarket.seriesTypes.' + cat.key)})
                    // Add the payload items
                    payload.push.apply(payload, categoryLegendPayload);
                }
            });
        }
        else {
            // Interconnect items
            payload = (this.state.colorBy === 'interconnect') ? [
                { key: 'kansai', color: palette.getSeriesColor('kn_wk_24_total_volume'), type: 'rect', value: t('priceHistory.charts.forwardMarket.seriesTypes.kansai') },
                { key: 'system', color: palette.getSeriesColor('sys_wk_24_total_volume'), type: 'rect', value: t('priceHistory.charts.forwardMarket.seriesTypes.system') },
                { key: 'tokyo', color: palette.getSeriesColor('tk_wk_24_total_volume'), type: 'rect', value: t('priceHistory.charts.forwardMarket.seriesTypes.tokyo') },
            ].filter(p => this.props.series.indexOf(p.key) >= 0) :
            // Peak type items
            (this.state.colorBy === 'peakType') ? [
                { key: 'allHours', color: palette.getSeriesColor('kn_wk_24_total_volume'), type: 'rect', value: t('priceHistory.charts.forwardMarket.seriesTypes.allHours') },
                { key: 'daytime', color: palette.getSeriesColor('kn_wk_dt_total_volume'), type: 'rect', value: t('priceHistory.charts.forwardMarket.seriesTypes.daytime') },
            ].filter(p => this.props.series.indexOf(p.key) >= 0) :
            // Contract length items
            [
                { key: 'week', color: palette.getSeriesColor('kn_wk_24_total_volume'), type: 'rect', value: t('priceHistory.charts.forwardMarket.seriesTypes.week') },
                { key: 'month', color: palette.getSeriesColor('kn_mth_24_total_volume'), type: 'rect', value: t('priceHistory.charts.forwardMarket.seriesTypes.month') },
                { key: 'year', color: palette.getSeriesColor('kn_yr_24_total_volume'), type: 'rect', value: t('priceHistory.charts.forwardMarket.seriesTypes.year') },
            ].filter(p => this.props.series.indexOf(p.key) >= 0);
        }

        return payload;
    }

    ////////////////////////////////////////////////////////////////////////////
    // Get the current data bounds for the chart
    ////////////////////////////////////////////////////////////////////////////
    get _dataBounds() {
        let extraEntries = this.props.aggregated.length - ForwardMarketChart._MaxChartEntries;
        let max = Math.max(0, extraEntries);

        return {
            min: 0,
            max: max,
            sliderValue: Math.min(this.state.sliderValue, max)
        };
    }

    _getData(dataBounds) {
        // Clean up previous data
        if (this.__data != null) {
            this.__data.length = 0;
        }

        // Take a subset if needed
        this.__data = this.props.aggregated.slice(dataBounds.sliderValue, dataBounds.sliderValue + ForwardMarketChart._MaxChartEntries);

        return this.__data;
    }

    _getSeriesKeysWithData(data) {
        // Make a note of which series have non-null data over the current window
        // to optimize out lots of nulls which will slow down drawing
        let selectedSeriesKeys = this._selectedSeriesKeys;
        return selectedSeriesKeys.reduce((accumulator, seriesKey) => ({
            ...accumulator, 
            [seriesKey]: data.findIndex(row => row[seriesKey] != null) >= 0
        }), {});
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gets the y-axis label text for the chart
    ////////////////////////////////////////////////////////////////////////////
    get _yAxisLabelText() {
        return t('priceHistory.charts.forwardMarket.axisLabels.y') + ' ' + t('units.kiloWatts');
    }



    _onChangeSlider(event, value) {
        this.setState({
            sliderValue: value,
        })
    }

    _onTooltipFormatLabel(value) {
        return (<span>{value}</span>);
    }

    _onTooltipFormatValue(value, name, props) {
        return Locale.formatNumber(value, 0);
    }

    _onFormatValue(value, name, props) {
        return Locale.formatNumber(value, 0);
    }



    componentDidUpdate() {
        // Persist current state
        SessionState.set(this._sessionStateKey, this.state);
    }
    
    render() {
        // Get bounds of the chart data
        let dataBounds = this._dataBounds;

        // Get chart margins and JSX for slider as needed
        let chartMarginBottom = (dataBounds.max > 0) ? 30 : 10;
        let sliderJSX = null;
        if (dataBounds.max > 0) {
            let sliderPaddingRight = 20;
            sliderJSX = (
                <div className="forward-market-range-slider" style={{ paddingRight: sliderPaddingRight }}>
                    <Slider
                        min={dataBounds.min}
                        max={dataBounds.max}
                        value={dataBounds.sliderValue}
                        onChange={this._onChangeSlider}
                    />
                </div>
            );
        }

        // Get data and series
        let data = this._getData(dataBounds);
        let series = this._getSeries(data);

        return (
            <ErrorBoundary>
                <div className="forward-market-options forward-market-theme-type">
                    <ToggleButton 
                        size="small" 
                        selected={this.state.isDetailedTheme} 
                        title={t('priceHistory.charts.forwardMarket.buttonTitles.detailedTheme')}
                        value="isDetailedTheme"
                        onClick={() => this.setState({ isDetailedTheme: !this.state.isDetailedTheme })}
                    >
                            <DetailsIcon />
                    </ToggleButton>
                </div>

                <div className="forward-market-options forward-market-color-by">
                    <ToggleButtonGroup value={this.state.colorBy} onChange={(e, value) => { value && this.setState({ colorBy: value }) }}>
                        <ToggleButton size="small" value="interconnect" title={t('priceHistory.charts.forwardMarket.buttonTitles.colorByInterconnect')}><PowerIcon /></ToggleButton>
                        <ToggleButton size="small" value="peakType" title={t('priceHistory.charts.forwardMarket.buttonTitles.colorByPeakType')}><ScheduleIcon /></ToggleButton>
                        <ToggleButton size="small" value="contractLength" title={t('priceHistory.charts.forwardMarket.buttonTitles.colorByContractLength')}><DateRangeIcon /></ToggleButton>
                    </ToggleButtonGroup>
                </div>

                <ResponsiveContainer className="forward-market-chart">
                    <ComposedChart
                        data={data}
                        margin={{
                            top: 60, right: 0, bottom: chartMarginBottom, left: 10,
                        }}
                    >
                        <XAxis 
                            dataKey="category" 
                        />
                        <YAxis 
                            tickFormatter={ (v) => this._onFormatValue(v) }
                            width={90}
                            yAxisId={0}>
                            <Label 
                                angle={-90} 
                                position='insideLeft'
                                value={this._yAxisLabelText}
                                style={{textAnchor: 'middle'}}
                            />
                        </YAxis>

                        {series}

                        <Legend
                            layout="vertical"
                            align="right"
                            verticalAlign="top"
                            payload={this._legendPayload}
                            formatter={
                                (value, entry, index) => { 
                                    return (entry.key === 'heading') ?
                                        (<span className="forward-market-legend-group">{value}</span>) :
                                        value;
                                }
                            }
                        />

                        <Tooltip 
                            isAnimationActive={false} 
                            labelFormatter={this._onTooltipFormatLabel}
                            formatter={this._onTooltipFormatValue} 
                            content={<ForwardMarketChartTooltip colorBy={this.state.colorBy} isDetailedTheme={this.state.isDetailedTheme} />}
                        />
                    </ComposedChart>
                </ResponsiveContainer>

                {sliderJSX}
            </ErrorBoundary>
        );
    }
}

// Set class-level known series keys
ForwardMarketChart.KnownDataKeys = {
    kn_mth_24_total_volume: true,
    kn_mth_dt_total_volume: true,
    kn_wk_24_total_volume: true,
    kn_wk_dt_total_volume: true,

    sys_mth_24_total_volume: true,
    sys_mth_dt_total_volume: true,
    sys_wk_24_total_volume: true,
    sys_wk_dt_total_volume: true,
    sys_yr_24_total_volume: true,

    tk_mth_24_total_volume: true,
    tk_mth_dt_total_volume: true,
    tk_wk_24_total_volume: true,
    tk_wk_dt_total_volume: true,
    tk_yr_24_total_volume: true,
};

// Set class-level series pattern regex, which helps us parse out
// pieces of chart series names
ForwardMarketChart.SeriesPatternRegex = /((kn|sys|tk)_(wk|mth|yr)_(24|dt))_total_volume/;

ForwardMarketChart.propTypes = {
    aggregated: PropTypes.arrayOf(PropTypes.object),
    series: PropTypes.arrayOf(PropTypes.string),
}

ForwardMarketChart.defaultProps = {
    aggregated: [],
    series: [],
}

export default ForwardMarketChart;
