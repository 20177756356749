import React, { useState } from 'react';
import { withRouter } from "react-router";
import { Button } from 'webcore-ux/react/components';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import Locale from '../locale/Locale';
import ErrorBoundary from 'ErrorBoundary';
import PinnableSettings from 'common/PinnableSettings';
import SpatialAwareness from './SpatialAwareness';

import './EntitySearch.scss';

const t = Locale.getResourceString.bind(Locale);

const EntitySearch = (props) => {
    const [isVisible, setIsVisible] = useState(false);

    const title = t("spatialAwareness.search.title");

    // Use this string to test Japanese search
    //'海洋博変電所'

    // These filter options limit the number of results to 100 to improve performance
    // and uses the combined English and Japanese entity names for searching to
    // allow searching in both languages regardless of the selected locale.
    const filterOptions = createFilterOptions({
        limit: 100,
        stringify: option => option.searchString,
    });

    return (
        <ErrorBoundary>
            <div className="spatial-awareness-map-buttons">
                <div className="spatial-awareness-search-button" title={title}>
                    <Button 
                        variant="primary"
                        onClick={ () => setIsVisible(true) }>
                        <SearchIcon />
                    </Button>
                </div>

                <PinnableSettings
                    contentClassName="search-content"
                    open={isVisible}
                    title={title}
                    showPinButton={false}
                    viewKey={SpatialAwareness.ViewKey}
                    onClose={() => setIsVisible(false) }
                >
                    {isVisible && (
                        <Autocomplete
                            options={props.entities[Locale.localeName]}
                            groupBy={(option) => Locale.getJSONFieldValue(option.type)}
                            getOptionLabel={(option) => Locale.getJSONFieldValue(option.name)}
                            renderInput={(params) => <TextField {...params} label={title} variant="outlined" />}
                            filterOptions={filterOptions}
                            style={{ width: 400 }}
                            onChange={(e, value) => {
                                props.onSearch(value);
                                setIsVisible(false);
                            }}
                        />
                    )}
                </PinnableSettings>
            </div>
        </ErrorBoundary>
    );
}

export default withRouter(EntitySearch);
