import CommonStyles from 'common/Common.scss'

export default class Palettes {
    static DefaultColors = [
        CommonStyles.appPrimaryColor, // Blue 60
        '#477A43', // Green 60
        '#A65503', // Gold 60
        '#A13FA1', // Purple 60
        '#3B786E', // Teal 60
        '#E12E2E', // Red 60
        '#004480', // Blue 80
        '#1B4718', // Green 80
        '#542C04', // Gold 80
        '#7A097A', // Purple 80
        '#16453D', // Teal 80
        '#A30005', // Red 80
        '#00284A', // Blue 90
        '#10290E', // Green 90
        '#331B02', // Gold 90
        '#4A014A', // Purple 90
        '#092923', // Teal 90
        '#5C0006', // Red 90
        '#031324', // Blue 100
        '#011A00', // Green 100
    ];

    ////////////////////////////////////////////////////////////////////////////
    // Fuel colors for JEMI
    ////////////////////////////////////////////////////////////////////////////
    static FuelColors = {
        // Default color for fuels that are not found
        default: '#bababa',

        'bio': '#1B4718', // Green 80

        'coal': '#F03040', // Status Red

        // Coal crude
        'ccr': '#A30005', // Red 80

        'coke': '#3DA190', // Teal 50
    
        // Add 'ng' and 'lng' as the same as Gas
        'gas': '#FF7300', // Status Orange
        'ng': '#FF7300',
        'lng': '#FF7300',
    
        // Geo
        'geo': '#75BA70', // Green 40

        // Light oil
        'loil': '#FFD3A8', // Gold 20

        // Oil
        'oil': '#D98F45', // Gold 40

        // Crude oil
        'co': '#A65503', // Gold 60

        // Heavy oil
        'ho': '#542C04',   // Gold 80
        'hoil': '#542C04', // Gold 80

        // Heavy oil crude oil mix
        'hoco': '#1A0E00', // Gold 100

        // Coal heavy oil
        'cho': '#751A08', // Orange 80
    
        // These all apply to uranium
        'nuc': '#EDE56F', // Yellow 20
        'ura': '#EDE56F',
        'uranium': '#EDE56F',
    
        // Other
        'other': '#C8C8C8',

        // Renewable
        'renew': '#5CBE85',
    
        // Solar and PV (Photovoltaic are the same)
        'solar': '#FFD800', // Status yellow
        'pv': '#FFD800',
    
        'unkn': '#ECA88F',

        // Water
        'water': '#2FC8F7', // Blue 40

        // Wind
        'wind': '#CC50CC', // Purple 50

        // Diesel
        'dsl': '#7A097A', // Purple 80

        // Kerosene
        'ker': '#820D48', // Magenta 80

        // Thermal
        'thm': '#D64550', // Taken from PowerPoint mockup

        // Pump Storage
        'pst': '#F5C4AF', // Taken from PowerPoint mockup

        // Imports
        'imp': '#B200FF', // Modified from PowerPoint mockup

        // Exports
        'exp': '#1AAB40', // Taken from PowerPoint mockup

        ////////////////////////////////////////////////////////////////////////
        // Gets the color associated with the specified fuel, or .default
        // if the fuel is not found in the pallette definition.
        ////////////////////////////////////////////////////////////////////////
        getColor(fuel) {
            fuel = fuel.toLowerCase();
            return (this.hasOwnProperty(fuel)) ? this[fuel] : this.default;
        }
    };


    ////////////////////////////////////////////////////////////////////////////
    // Prime mover colors for JEMI
    // Taken from "Color Formats.xlsx" provided to us for Digital Force
    ////////////////////////////////////////////////////////////////////////////
    static PrimeMoverColors = {
        // Default color for fuels that are not found
        default: '#bababa',

        ca: '#DE8262',
        cc: '#FF7300',
        ce: '#A288CE',
        ct: '#C8C8C8',
        es: '#5CBE85',
        fc: '#F1DD8E',
        ge: '#7A641B',
        gt: '#80A0A6',
        hy: '#0C74DA',
        ic: '#F03040',
        nuc: '#CBAF49',
        ps: '#73B7FF',
        pv: '#FFD800',
        st: '#462D6E',
        wt: '#6C45AA',

        ////////////////////////////////////////////////////////////////////////
        // Gets the color associated with the specified prime mover, or .default
        // if the prime mover is not found in the pallette definition.
        ////////////////////////////////////////////////////////////////////////
        getColor(primeMover) {
            primeMover = primeMover.toLowerCase();
            return (this.hasOwnProperty(primeMover)) ? this[primeMover] : this.default;
        }
    };

    ////////////////////////////////////////////////////////////////////////////
    // Grayscales for plant operating stati, e.g. plant fuel and status 
    // chart on the power plant map popup.
    ////////////////////////////////////////////////////////////////////////////
    static OperatingStatusGrayscales = {
        // Default color for stati that are not found
        default: '#bababa',

        op: '#263238',
        operating: '#263238',
        mb: '#37474F',
        ts: '#455A64',
        re: '#546E7A',
        retired: '#546E7A',
        pl: '#607D8B',
        planned: '#607D8B',
        cn: '#78909C',
        canceled: '#78909C',
        fe: '#90A4AE',
        sb: '#B0BEC5',
        uc: '#CFD8DC',
        underconstruction: '#CFD8DC',
        rs: '#ECEFF1',

        ////////////////////////////////////////////////////////////////////////
        // Gets the grayscale associated with the specified status, or .default
        // if the status is not found in the pallette definition.
        ////////////////////////////////////////////////////////////////////////
        getColor(status) {
            status = status.toLowerCase();
            return (this.hasOwnProperty(status)) ? this[status] : this.default;
        }
    };

    ////////////////////////////////////////////////////////////////////////////
    // Colors for plant operating stati
    ////////////////////////////////////////////////////////////////////////////
    static OperatingStatusColors = {
        // Default color for stati that are not found
        default: '#bababa',

        op: CommonStyles.appPrimaryColor, // Blue 60
        re: '#A13FA1', // Purple 60
        pl: '#477A43', // Green 60
        cn: '#3B786E', // Teal 60
        uc: '#A65503', // Gold 60

        ////////////////////////////////////////////////////////////////////////
        // Gets the color associated with the specified status, or .default
        // if the status is not found in the pallette definition.
        ////////////////////////////////////////////////////////////////////////
        getColor(status) {
            status = status.toLowerCase();
            return (this.hasOwnProperty(status)) ? this[status] : this.default;
        }
    };
}