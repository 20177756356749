import React, { Component } from 'react';
import './VisualBox.scss';

////////////////////////////////////////////////////////////////////////////////
// VisualBox renders a bordered "box" with a caption to help group
// content together.
////////////////////////////////////////////////////////////////////////////////
class VisualBox extends Component {
    render() {
        let className = 'visual-box';
        if (this.props.className != null) {
            className += ' ' + this.props.className;
        }

        return (
            <div className={className}>
                <div className="visual-box-header">
                    <div className="visual-box-caption" title={this.props.caption}>
                        {this.props.caption}
                    </div>
                </div>
                <div className={this.props.heightClass}>{this.props.children}</div>
            </div>
        );
    }
}

export default VisualBox;
