// Local debugging settings
const local = {
    name: 'Local',
    isRemote: false,
    authentication: {
        uiClientId: 'c653f971-21ef-4b12-81e6-dc0fa4549393',
        uiTenantId: 'cd5d51b4-c42d-4054-b6b6-872922126d9a', // DigitalForce-Dev AAD tenant ID
        apiClientId: '4b99a177-eb04-4e70-8b40-8841e6f6684d',
    },
    dataService: {
        //baseUrl: 'https://localhost:44383',
        //baseUrl: 'https://localhost:5001',
        baseUrl: 'https://jemiapi.emidev.hasops.com',
    },
};

// Managed service settings
const azureDev = {
    name: 'AzureDev',
    isRemote: true,
    authentication: {
        uiClientId: 'c653f971-21ef-4b12-81e6-dc0fa4549393',
        uiTenantId: 'cd5d51b4-c42d-4054-b6b6-872922126d9a', // DigitalForce-Dev AAD tenant ID
        apiClientId: '4b99a177-eb04-4e70-8b40-8841e6f6684d',
    },
    dataService: {
        baseUrl: 'https://denetcoreapi.azurewebsites.net',
    },
};

// Epiphany dev settings
const epiphanyDev = {
    name: 'EpiphanyDev',
    isRemote: true,
    authentication: {
        uiClientId: 'c653f971-21ef-4b12-81e6-dc0fa4549393',
        uiTenantId: 'cd5d51b4-c42d-4054-b6b6-872922126d9a', // DigitalForce-Dev AAD tenant ID
        apiClientId: '4b99a177-eb04-4e70-8b40-8841e6f6684d',
    },
    dataService: {
        baseUrl: 'https://jemiapi.emidev.hasops.com',
    },
};

// Epiphany prod settings
const epiphanyProd = {
    name: 'EpiphanyProd',
    isRemote: true,
    authentication: {
        uiClientId: '1e8aeaf7-1820-4c4e-a262-a9e7659e13b1',
        uiTenantId: '372ee9e0-9ce0-4033-a64a-c07073a91ecd', // My ABB tenant ID
        apiClientId: '4c258ccc-ec24-4ba9-8a54-10c8cfd8afa4',
    },
    dataService: {
        baseUrl: 'https://api.jp.emi.enterprisesoftware.abb',
    },
};

// Determine API url based on hostname
const config = (window.location.hostname === 'localhost') ? local : 
    (window.location.hostname.includes('jp.emi.enterprisesoftware.abb')) ? epiphanyProd : 
    (window.location.hostname.includes('jemiui.emidev.hasops.com')) ? epiphanyDev : 
    (window.location.hostname.includes('de-emi-japan-ui')) ? azureDev : 
    epiphanyDev;

export default {
    /////////////////////////////////////////////////////////////////////////
    // Common config values
    /////////////////////////////////////////////////////////////////////////

    // Sign out - set to null to return to the sign in page
    signOutRedirectUri: null,

    // Company Info
    company: {
        name: 'Hitachi Energy USA Inc.',
        address: '901 Main Campus Drive Raleigh, North Carolina 27606'
    },

    // Support contact info
    support: {
        name: 'Insights Support',
        email: 'Insights-Support.PGES@hitachienergy.com',
        phone: '1.888.968.8860',
    },

    // The role inside the token
    roles: {
        purchased: 'Purchased'
    },

    historicSupplyAndDemand: {
        //monthsBack: 2,
        monthsBack: 24,
    },

    weather: {
        // Maximum number of weather stations for comparison
        maxWeatherStations: 20,
    },

    // Append environment-specific configrations
    ...config,
};
