import React from 'react'

import Locale from '../../locale/Locale'

import MonthPicker from 'common/MonthPicker'
import YearPicker from 'common/YearPicker'
import MultiSelectDropdown from 'common/MultiSelectDropdown'
import { Dropdown } from 'webcore-ux/react/components'
import DateRangePicker from 'common/DateRangePicker'
import VisualBox from '../../common/VisualBox'

import SelectAllIcon from '@material-ui/icons/SelectAll'
import ClearAllIcon from '@material-ui/icons/ClearAll'

const t = Locale.getResourceString.bind(Locale)

export const FuelFilters = (props) => {
    // Aggregation
    const aggregationOptions = [
        {
            label: t('priceForecast.panel.aggregation.min'),
            value: 'min'
        },
        {
            label: t('priceForecast.panel.aggregation.max'),
            value: 'max'
        },
        {
            label: t('priceForecast.panel.aggregation.average'),
            value: 'average'
        },
        {
            label: t('priceForecast.panel.aggregation.sum'),
            value: 'sum'
        },
        {   
            label: t('priceForecast.panel.aggregation.stdev'),
            value: 'stdev' 
        },
        { 
            label: t('priceForecast.panel.aggregation.count'),
            value: 'count' 
        }
    ]
    // Dropdowns
    //// Granularity dropdown
    const granularityOpts = [
        {
            label: t('priceForecast.panel.granularity.M'),
            value: 'M',
        },
        {
            label: t('priceForecast.panel.granularity.A'),
            value: 'A',
        },
        {
            label: t('priceForecast.panel.granularity.MoY'),
            value: 'MoY',
        },

    ]
    // Date Picker / slider
    // Multiselect Dropdown
    let fuelMultiSelectDropdownOnChange = (selected) => {
        props.fuelMultiSelectDropdownOnChange(selected)
    }
    let fuelMultiSelectDropdownALL = (value) => {
        return value === 'all'
            ? props.fuelMultiSelectDropdownOnChange(props.allFuels)
            : props.fuelMultiSelectDropdownOnChange([])
    }
    // granularity dropdown
    let handleGranularity = (selected) => {
        props.handleGranularity(selected.value)
    }
    let handleAggregation = (selected) => {
        props.handleAggregation(selected.value)
    }
    // multi buttons
    let onChangeSelectedMonths = (value) => {
        props.onChangeSelectedMonths(value)
    }
    let onChangeSelectedYears = (value) => {
        props.onChangeSelectedYears(value)
    }

    return (
        <>
            <VisualBox className="chart-series-box" caption={t('priceForecast.panel.fuelType.caption')} title={t('priceForecast.panel.fuelType.caption')}>
                <div className="button-group-filter">
                    <div 
                        className="select-all" 
                        title={t('general.selectAll')} 
                        onClick={() => fuelMultiSelectDropdownALL('all')}
                    >
                        <SelectAllIcon />
                    </div>
                    <div 
                        className="select-none" 
                        title={t('general.clearAll')} 
                        onClick={() => fuelMultiSelectDropdownALL('none')}
                    >
                        <ClearAllIcon />
                    </div>
                    <MultiSelectDropdown
                        multipleSelectionsPlaceholder={t('general.multipleSelectedValues')}
                        placeholder={t('general.select')}
                        options={props.fuelOptions}
                        value={props.selectedFuels}
                        onChange={(selected) => fuelMultiSelectDropdownOnChange(selected)}
                    />
                </div>
            </VisualBox>

            <VisualBox caption={t('priceForecast.panel.scenario.caption')} title={t('priceForecast.panel.scenario.caption')}>
                <Dropdown
                    id="scenario"
                    options={props.scenarioOptions}
                    value={props.selectedScenarioId}
                    isClearable={false}
                    onChange={(e) => props.onChangeSelectedScenarioId && props.onChangeSelectedScenarioId(e.value)}
                />
            </VisualBox>

            <VisualBox caption={t('priceForecast.panel.datePicker.caption')} title={t('priceForecast.panel.datePicker.caption')}>
                <DateRangePicker
                    minDate={props.minDateRange}
                    maxDate={props.maxDateRange}
                    start={props.startDateRange}
                    end={props.endDateRange}
                    onChanging={(start, end) => props.onChangingDateRange(start, end)}
                    onChange={(start, end) => props.onChangeDateRange(start, end)}
                />
            </VisualBox>

            <VisualBox caption={t('priceForecast.panel.view.caption')} title={t('priceForecast.panel.view.caption')}>
                <Dropdown
                    label={t('priceForecast.panel.granularity.caption')}
                    id="granularity"
                    options={granularityOpts}
                    value={props.granularity}
                    placeholder={t('general.select')}
                    isClearable={false}
                    onChange={(selected) => handleGranularity(selected)}
                />
                <Dropdown
                    label={t('priceForecast.panel.aggregation.caption')}
                    id="aggregationDropdown"
                    options={aggregationOptions}
                    value={props.aggregationSelected}
                    placeholder={t('general.select')}
                    isClearable={false}
                    onChange={(selected) => handleAggregation(selected)}
                />
            </VisualBox>

            {
                props.granularity === 'M' || props.granularity === 'MoY'
                    ? (
                        <VisualBox caption={t('priceForecast.panel.month.caption')} title={t('priceForecast.panel.month.caption')}>
                            <div className="button-group-filter">
                                <div
                                    className="select-all"
                                    title={t('general.selectAll')}
                                    onClick={() => onChangeSelectedMonths([...Array(12).keys()].map(k => k + 1))}
                                >
                                    <SelectAllIcon />
                                </div>
                                <div
                                    className="select-none"
                                    title={t('general.clearAll')}
                                    onClick={() => onChangeSelectedMonths([])}
                                >
                                    <ClearAllIcon />
                                </div>
                                <MonthPicker
                                    value={props.selectedMonths}
                                    onChange={(value) => { onChangeSelectedMonths(value) }}
                                />
                            </div>
                        </VisualBox>
                    )
                    : <></>
            }

            <VisualBox caption={t('priceForecast.panel.year.caption')} title={t('priceForecast.panel.year.caption')}>
                <div className="button-group-filter">
                    <div
                        className="select-all"
                        title={t('general.selectAll')}
                        onClick={() => onChangeSelectedYears(props.allYears)}
                    >
                        <SelectAllIcon />
                    </div>
                    <div className="select-none" title={t('general.clearAll')} onClick={() => onChangeSelectedYears([])}>
                        <ClearAllIcon />
                    </div>
                    <YearPicker
                        allValues={props.allYears}
                        value={props.selectedYears}
                        onChange={(value) => {onChangeSelectedYears(value)}}
                    />
                </div>
            </VisualBox>
        </>
    )
}
