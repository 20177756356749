import CompanyFinancialsPivotGrid from './CompanyFinancialsPivotGrid';

/////////////////////////////////////////////////////////////////////////////
// This class encapsulates the balance sheet grid
/////////////////////////////////////////////////////////////////////////////
class BalanceSheetGrid extends CompanyFinancialsPivotGrid {
    constructor(props, context) {
        super(props);
    }

    get _numericFields() {
        const numericFields = [
            'non_current_assets_val', 
            'current_assets_val', 
            'tot_assets_val', 
            'non_current_liabilities_val', 
            'current_liabilities_val', 
            'reserve_provisions_val', 
            'tot_liabilities_val', 
            'shareholders_equity_val', 
            'accum_other_income_val', 
            'non_controlling_interests_val', 
            'tot_net_assets_val', 
            'tot_liabilities_and_net_assets_val'
        ];

        return numericFields;
    }
}

export default BalanceSheetGrid;
