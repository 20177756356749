import React from 'react';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { GeoJSON } from 'ol/format';
import { Style, Stroke, Fill } from 'ol/style';
import AppConfig from '../../AppConfig';
import AppTrace from '../../AppTrace';
import ErrorState from '../../ErrorState';
import Layer from './Layer';
import Locale from '../../locale/Locale';
import Legend from '../legend/Legend';

const t = Locale.getResourceString.bind(Locale);
const wkx = require('wkx');

export default class Prefectures extends Layer {
    // Theming is based on unique entities
    // Using "Material Design" colors, rows 8, 10, and 5 from:
    // https://htmlcolorcodes.com/color-chart/
    static Palette = [
        // Row 8
        [183, 28, 28], // Red
        [191, 54, 12], // Orange through...
        [230, 81, 0],
        [255, 111, 0],
        [245, 127, 23],
        [130, 119, 23],
        [51, 105, 30],
        [27, 94, 32],
        [0, 77, 64],
        [0, 96, 100],
        [1, 87, 155],
        [13, 71, 161],
        [26, 35, 126],
        [49, 27, 146],
        [74, 20, 140],
        [136, 14, 79], // Pink-ish
        [38, 50, 56], // Last gray
        [33, 33, 33], // Other gray
        [62, 39, 35], // Brown-ish

        // Row 10
        [211, 47, 47], // Red
        [230, 74, 25], // Orange through...
        [245, 124, 0],
        [255, 160, 0],
        [251, 192, 45],
        [175, 180, 43],
        [104, 159, 56],
        [56, 142, 60],
        [0, 121, 107],
        [0, 151, 167],
        [2, 136, 209],
        [25, 118, 210],
        [48, 63, 159],
        [81, 45, 168],
        [81, 45, 168],
        [194, 24, 91], // Pink-ish
        [69, 90, 100], // Last gray
        [97, 97, 97], // Other gray
        [97, 97, 97], // Brown-ish

        // Row 5
        [239, 83, 80], // Red
        [255, 112, 67], // Orange through...
        [255, 167, 38],
        [255, 202, 40],
        [255, 238, 88],
        [212, 225, 87],
        [156, 204, 101],
        [102, 187, 106],
        [38, 166, 154],
        [38, 198, 218],
        [41, 182, 246],
        [66, 165, 245],
        [92, 107, 192],
        [126, 87, 194],
        [171, 71, 188],
        [236, 64, 122], // Pink-ish
        [120, 144, 156], // Last gray
        [189, 189, 189], // Other gray
        [141, 110, 99], // Brown-ish
    ];

    constructor(apiData) {
        super();

        this._allFeatures = [];
    }

    // Gets a string key that identifies this layer in collections, localization resource, etc.
    get key() {
        return "prefectures";
    }

    // Gets the stack order of the layer.  Lower zIndex layers are stacked at the bottom
    get zIndex() {
        // I'm keeping polygon layers on the bottom: 0-10
        return 3;
    }

    // Gets the underlying layer object
    get vectorLayer() {
        return this._vectorLayer;
    }

    // Renders popup content for a feature in this layer
    renderPopupContent(feature) {
        return (
            <>
                <span className="feature-popup-title">{t('spatialAwareness.layers.prefectures.popup.title')}</span><br />
                <br />
                <span className="feature-popup-property-name feature-popup-property-name-wide">{t('spatialAwareness.layers.prefectures.popup.name')}</span>
                <span className="feature-popup-property-value feature-popup-property-value-narrow">{Locale.getJSONFieldValue(feature.getProperties().name)}</span><br />

                <span className="feature-popup-property-name feature-popup-property-name-wide">{t('spatialAwareness.layers.prefectures.popup.populationUrban')}</span>
                <span className="feature-popup-property-value feature-popup-property-value-narrow">{(feature.getProperties().populationUrban == null) ? t('general.noDataAvailable') : Locale.formatNumber(feature.getProperties().populationUrban, 0)}</span><br />

                <span className="feature-popup-property-name feature-popup-property-name-wide">{t('spatialAwareness.layers.prefectures.popup.populationRural')}</span>
                <span className="feature-popup-property-value feature-popup-property-value-narrow">{(feature.getProperties().populationRural == null) ? t('general.noDataAvailable') : Locale.formatNumber(feature.getProperties().populationRural, 0)}</span><br />

                <span className="feature-popup-property-name feature-popup-property-name-wide">{t('spatialAwareness.layers.prefectures.popup.populationTotal')}</span>
                <span className="feature-popup-property-value feature-popup-property-value-narrow">{(feature.getProperties().populationTot == null) ? t('general.noDataAvailable') : Locale.formatNumber(feature.getProperties().populationTot, 0)}</span><br />

                <span className="feature-popup-property-name feature-popup-property-name-wide">{t('spatialAwareness.layers.prefectures.popup.areaUrban')}</span>
                <span className="feature-popup-property-value feature-popup-property-value-narrow">{(feature.getProperties().areaUrban == null) ? t('general.noDataAvailable') : (Locale.formatNumber(feature.getProperties().areaUrban, 0) + ' ' + t('units.sqkilometers'))}</span><br />

                <span className="feature-popup-property-name feature-popup-property-name-wide">{t('spatialAwareness.layers.prefectures.popup.areaRural')}</span>
                <span className="feature-popup-property-value feature-popup-property-value-narrow">{(feature.getProperties().areaRural == null) ? t('general.noDataAvailable') : (Locale.formatNumber(feature.getProperties().areaRural, 0) + ' ' + t('units.sqkilometers'))}</span><br />

                <span className="feature-popup-property-name feature-popup-property-name-wide">{t('spatialAwareness.layers.prefectures.popup.areaTotal')}</span>
                <span className="feature-popup-property-value feature-popup-property-value-narrow">{(feature.getProperties().areaTotal == null) ? t('general.noDataAvailable') : (Locale.formatNumber(feature.getProperties().areaTotal, 0) + ' ' + t('units.sqkilometers'))}</span><br />

                <span className="feature-popup-property-name feature-popup-property-name-wide">{t('spatialAwareness.layers.prefectures.popup.densityUrban')}</span>
                <span className="feature-popup-property-value feature-popup-property-value-narrow">{(feature.getProperties().densityUrban == null) ? t('general.noDataAvailable') : Locale.formatNumber(feature.getProperties().densityUrban, 0)}</span><br />

                <span className="feature-popup-property-name feature-popup-property-name-wide">{t('spatialAwareness.layers.prefectures.popup.densityRural')}</span>
                <span className="feature-popup-property-value feature-popup-property-value-narrow">{(feature.getProperties().densityRural == null) ? t('general.noDataAvailable') : Locale.formatNumber(feature.getProperties().densityRural, 0)}</span><br />

                <span className="feature-popup-property-name feature-popup-property-name-wide">{t('spatialAwareness.layers.prefectures.popup.densityTotal')}</span>
                <span className="feature-popup-property-value feature-popup-property-value-narrow">{(feature.getProperties().densityTot == null) ? t('general.noDataAvailable') : Locale.formatNumber(feature.getProperties().densityTot, 0)}</span><br />
            </>
        );
    }

    // Zooms to a selected feature
    zoomToFeature(feature, view) {
        let extent = feature.getGeometry().getExtent();
        view.fit(extent);
    }



    _getUrl(view) {
        return AppConfig.dataService.baseUrl + '/spatial-awareness/prefectures';
    }

    _postProcessData(apiData) {
        // Sort the prefectures by the English name
        let nameIndex = apiData.metadata.columns.name.index;
        apiData.rows.sort((a, b) => {
            return Locale.getJSONFieldValue(a[nameIndex], 'en').localeCompare(Locale.getJSONFieldValue(b[nameIndex], 'en'));
        });
    }

    _createLayer(view) {
        try {
            // Derive all features
            this._paletteLookup = {};
            this._allFeatures = this._getAllFeatures();

            this._vectorLayer = new VectorLayer({
                key: this.key,
                source: new VectorSource({
                    features: this._allFeatures,
                }),
                style: this._getStyle,
                opacity: 0.5,
                visible: true,
            });
        }
        catch (err) {
            AppTrace.traceError("Prefectures.ctor: " + err)
            ErrorState.setFault("Error mapping Prefectures");
        }
    }

    // Gets the default style for the selected feature
    _getDefaultStyle(feature, resolution) {
        let paletteIndex = feature.getId() % Prefectures.Palette.length;
        return new Style({
            stroke: new Stroke({
                color: [128, 128, 128, 0.5],
                width: 0.5
            }),
            fill: new Fill({
                color: Prefectures.Palette[paletteIndex]
            })
        });
    }

    // Renders legend content for this layer
    _renderDefaultLegendContent() {
        const items = this._allFeatures.map(feature =>
            <div key={feature.getId()}>
                <span className="ev-legend-symbol" style={{ color: Legend.rgbToHex(Prefectures.Palette[feature.getId() % Prefectures.Palette.length]) }}>&#9648;</span>
                <span className="ev-legend-theme-value">{Locale.getJSONFieldValue(feature.getProperties().name)}</span>
                <br />
            </div>
        );

        if (this.isVisible) {
            return (
                <div key={this.key + '-legend-content'} className="ev-legend-layer-content">
                    <span className="ev-legend-layer-name">{t('spatialAwareness.layers.prefectures.title')}</span><br />
                    {items}
                </div>
            );
        }
    }

    // Generate all of the features that will be added to the map.
    _getAllFeatures() {
        let features = [];
        let olGeoJSON = new GeoJSON();

        // Iterate over all entities in the API data
        let idIndex = this._apiData.metadata.columns.id.index;
        let nameIndex = this._apiData.metadata.columns.name.index;
        let geographyIndex = this._apiData.metadata.columns.geography.index;
        let populationUrbanIndex = this._apiData.metadata.columns.populationUrban.index;
        let populationRuralIndex = this._apiData.metadata.columns.populationRural.index;
        let populationTotalIndex = this._apiData.metadata.columns.populationTot.index;
        let urbanAreaIndex = this._apiData.metadata.columns.areaUrban.index;
        let ruralAreaIndex = this._apiData.metadata.columns.areaRural.index;
        let totalAreaIndex = this._apiData.metadata.columns.areaTotal.index;
        let densityUrbanIndex = this._apiData.metadata.columns.densityUrban.index;
        let densityRuralIndex = this._apiData.metadata.columns.densityRural.index;
        let densityTotalIndex = this._apiData.metadata.columns.densityTot.index;

        for (let i = 0; i < this._apiData.rows.length; ++i) {
            let row = this._apiData.rows[i];

            // Parse wkb format
            let wkbBuffer = new Buffer(row[geographyIndex], 'hex');
            let geoJSON = wkx.Geometry.parse(wkbBuffer).toGeoJSON();

            // Create the feature - tell GeoJSON that the data is in EPSG:4326, and to project it to EPSG:3857
            let feature = new Feature({
                layerKey: this.key,
                entityId: row[idIndex],
                name: row[nameIndex],
                populationUrban: row[populationUrbanIndex],
                populationRural: row[populationRuralIndex],
                populationTot: row[populationTotalIndex],
                areaUrban: row[urbanAreaIndex],
                areaRural: row[ruralAreaIndex],
                areaTotal: row[totalAreaIndex],
                densityUrban: row[densityUrbanIndex],
                densityRural: row[densityRuralIndex],
                densityTot: row[densityTotalIndex],

                geometry: olGeoJSON.readGeometry(geoJSON, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                })
            });
            feature.setId(i);

            // Add a feature
            features.push(feature);
        }

        return features;
    }
}