import React from 'react'

import Locale from 'locale/Locale'
import ErrorBoundary from 'ErrorBoundary'

import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Legend, Label } from 'recharts'
import Filter from 'webcore-ux/react/components/Icons/Filter'
import { Button } from 'webcore-ux/react/components'
import Palettes from 'common/Palettes'

const t = Locale.getResourceString.bind(Locale)

export const FuelChart = (props) => {
    let handlePanel = () => {
        props.handlePanel()
    }

    let onTooltipFormatLabel = (value) => {
        return props.onTooltipFormatLabel(value)
    }

    let onFormatValue = (value) => {
        return props.onFormatValue(value)
    }
    return (
        <ErrorBoundary>
           <div className="jemi-view-filter-button" title={t('priceForecast.filters.caption')}>
                <Button
                    variant="primary"
                    onClick={() => {
                        handlePanel()
                    }}
                    disabled={props.filterPanelIsPinned}
                >
                    <Filter fontSize="small" />
                </Button>
            </div>

             <ResponsiveContainer>
                <LineChart
                    data={props.chartData}
                    margin={{
                        top: 60, right: 20, bottom: 10, left: 10,
                    }}
                >
                    <XAxis 
                        dataKey="category"
                    />
                    <YAxis 
                        tickFormatter={ (v) => onFormatValue(v) }
                        width={90}
                        yAxisId={0}>
                            <Label
                                angle={-90}
                                position="insideLeft"
                                value={`${Locale.getCurrencySymbol()}  / ${t('units.gigajoule')}`}
                                style={{ textAnchor: 'middle' }}
                            />
                    </YAxis>
                    <Tooltip isAnimationActive={false} labelFormatter={onTooltipFormatLabel} formatter={onFormatValue} />
                    <Legend />
                    {
                        props.selectedFuels.map((serie, index) => {
                            return (
                                <Line
                                    key={index}
                                    dataKey={`${serie}`}
                                    dot={false}
                                    isAnimationActive={false}
                                    stroke={Palettes.FuelColors.getColor(serie)}
                                    strokeWidth={2}
                                    name={Locale.getJSONFieldValue(props.data.metadata.columns[serie].caption)}
                                />
                            )
                        })
                    }
                </LineChart>
            </ResponsiveContainer>
        </ErrorBoundary>
    )
}