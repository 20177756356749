import AppConfig from 'AppConfig';
import AppTrace from 'AppTrace';
import Authentication from 'Authentication';
import DataFormatting from 'DataFormatting';

const axios = require('axios');

export default class SupplyAndDemandDataFactory {
    get() {
        return new Promise((resolve, reject) => {
            // Get access token
            Authentication.getAccessToken()
                // Get API data
                .then(accessToken => {
                    let authString = 'Bearer '.concat(accessToken);
                    let url = AppConfig.dataService.baseUrl + '/supply-and-demand';

                    return axios.get(url, {
                        headers: {
                            Authorization: authString,
                            Accept: "application/json",
                            "cache-control": "no-cache",
                        }
                    });
                })
                .then(response => {
                    let data = response.data;
                    this._postProcessData(data);

                    resolve(data);
                })
                .catch((error) => {
                    AppTrace.traceError('Error in SupplyAndDemandDataFactory._getData: ' + error.message);
                    reject(new Error("Error getting supply and demand data"));
                });
        });
    }

    _postProcessData(data) {
        // Postprocess all results fields
        DataFormatting.convertResultFields(data.usts);
        DataFormatting.convertResultFields(data.neByFuel);
        DataFormatting.convertResultFields(data.neByPrimeMover);
        DataFormatting.convertResultFields(data.neByStatus);

        DataFormatting.convertResultFields(data.refCaseBaseline);
        DataFormatting.convertResultFields(data.refCaseHighFuelPrice);
        DataFormatting.convertResultFields(data.refCaseRenewableGenerationTargets);
        DataFormatting.convertResultFields(data.refCaseFullNuclearRestart);

        // Postprocess all column captions
        Object.values(data.neByFuel.metadata.columns).forEach(col => col.caption = DataFormatting.parseJSON(col.caption));
        Object.values(data.neByPrimeMover.metadata.columns).forEach(col => col.caption = DataFormatting.parseJSON(col.caption));
        Object.values(data.neByStatus.metadata.columns).forEach(col => col.caption = DataFormatting.parseJSON(col.caption));

        Object.values(data.refCaseBaseline.metadata.columns).forEach(col => col.caption = DataFormatting.parseJSON(col.caption));
        Object.values(data.refCaseHighFuelPrice.metadata.columns).forEach(col => col.caption = DataFormatting.parseJSON(col.caption));
        Object.values(data.refCaseRenewableGenerationTargets.metadata.columns).forEach(col => col.caption = DataFormatting.parseJSON(col.caption));
        Object.values(data.refCaseFullNuclearRestart.metadata.columns).forEach(col => col.caption = DataFormatting.parseJSON(col.caption));

        // Create a lookup from utility service territory ID to name
        let idIndex = data.usts.metadata.columns.id.index;
        let nameIndex = data.usts.metadata.columns.name.index;
        data.ustsLookup = data.usts.rows.reduce((obj, row) => {
            obj[row[idIndex]] = row[nameIndex];
            return obj;
        }, {});
        
        // Create a lookup from series key to series names
        data.seriesLookup = {};
        [
            data.neByFuel, 
            data.neByPrimeMover, 
            data.neByStatus, 

            data.refCaseBaseline, 
            data.refCaseHighFuelPrice, 
            data.refCaseRenewableGenerationTargets, 
            data.refCaseFullNuclearRestart
        ].forEach(table => {
            Object.values(table.metadata.columns).forEach(col => {
                data.seriesLookup[col.name] = col.caption;
            });
        });
    }
}