import React from 'react';
import { withRouter } from "react-router";
import AppConfig from 'AppConfig';
import AppTrace from 'AppTrace';
import Authentication from 'Authentication';
import DataFormatting from 'DataFormatting';
import ErrorBoundary from 'ErrorBoundary';
import Locale from 'locale/Locale';
import PopupDataCache from '../PopupDataCache';
import PopupLoadIndicator from '../PopupLoadIndicator';
import SupplyAndDemandChart from './SupplyAndDemandChart';
import PopoutLineChart from 'charts/PopoutLineChart';
import SupplyAndDemand from 'supply-and-demand/SupplyAndDemand';
import PriceHistory from 'price-history/PriceHistory';
import PriceForecast from 'price-forecast/PriceForecast';
import CompanyFinancials from 'company-financials/CompanyFinancials';

const t = Locale.getResourceString.bind(Locale);
const axios = require('axios');

class UtilityServiceTerritoryPopup extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {
        }

        this._onViewDetailsSupplyAndDemand = this._onViewDetailsSupplyAndDemand.bind(this);
        this._onViewDetailsPriceHistory = this._onViewDetailsPriceHistory.bind(this);
        this._onViewDetailsPriceForecast = this._onViewDetailsPriceForecast.bind(this);
        this._onViewDetailsFinancialHighlights = this._onViewDetailsFinancialHighlights.bind(this);
    }

    get _url() {
        let entityId = this.props.feature.getProperties().entityId;
        return AppConfig.dataService.baseUrl + '/spatial-awareness/utility-service-territories/{0}/popup'
            .replace('{0}', entityId);
    }

    _getData() {
        return new Promise((resolve, reject) => {
            let url = this._url;

            // Check the cache for this popup data
            if (PopupDataCache.contains(url)) {
                // It's there so resolve with the cached data
                resolve(PopupDataCache.get(url));
            }
            else {
                // Otherwise get access token
                Authentication.getAccessToken()
                    .then(accessToken => {
                        // Then use the access token to get data for popup
                        let authString = 'Bearer '.concat(accessToken);
                        return axios.get(url, {
                            headers: {
                                Authorization: authString,
                                Accept: "application/json",
                                "cache-control": "no-cache",
                            }
                        })
                     })
                    .then(response => {
                        // Get result data
                        let apiData = response.data;

                        // Convert result fields based on type
                        DataFormatting.convertResultFields(apiData.referenceCase);
                        DataFormatting.convertResultFields(apiData.jepxSpotMarketTrading);
                        DataFormatting.convertResultFields(apiData.supplyAndDemand);

                        // Parse JSON column captions for capacity-by-fuel columns
                        this._getCapacityByFuelColumns(apiData.supplyAndDemand)
                            .forEach(c => c.caption = DataFormatting.parseJSON(c.caption));

                        // Configure non-capacity supply-and-demand columns with localized caption values
                        apiData.supplyAndDemand.metadata.columns.demand.caption = Locale.makeJSONField('spatialAwareness.layers.utilityServiceTerritories.popup.demand');
                        apiData.supplyAndDemand.metadata.columns.reserveMargin.caption = Locale.makeJSONField('spatialAwareness.layers.utilityServiceTerritories.popup.reserveMargin');

                        // Set a delete function to clean up the data
                        apiData.delete = function () {
                            if (this.referenceCase != null) {
                                this.referenceCase.rows.length = 0;
                            }
                            if (this.jepxSpotMarketTrading != null) {
                                this.jepxSpotMarketTrading.rows.length = 0;
                            }
                        };

                        // Now use the data to render the popup
                        resolve(apiData);
                    })
                    .catch((error) => {
                        AppTrace.traceError('Error in UtilityServiceTerritoryPopup._getData: ' + error.message);
                        reject(new Error("Error getting popup data"));
                    });
            }
        });
    }

    _getCapacityByFuelColumns(supplyAndDemand) {
        return Object.values(supplyAndDemand.metadata.columns)
            .filter(c => c.tag != null && c.tag.isCapacityByFuelColumn);
    }

    _getDataAndRender() {
        this._getData()
            .then(apiData => {
                // Cache the API data
                PopupDataCache.add(this._url, apiData);

                // Force an update
                this.forceUpdate();
            })
            .catch(error => {
                // Don't hammer the server with a URL that is failing
                PopupDataCache.add(this._url, { error: error });
            });
    }

    _formatFinancialHighlight(apiData, key) {
        let value = Locale.getJSONCurrencyValue(apiData.financialHighlights.attributes[key]);
        return (value != null) ?
            Locale.formatCurrency(value) :
            t('general.noDataAvailable');
    }

    _onViewDetailsSupplyAndDemand() {
        SupplyAndDemand.ViewDetails(this.props.feature.getProperties());
        this.props.history.push({ pathname: '/' + SupplyAndDemand.ViewKey });
        AppTrace.traceInfo(SupplyAndDemand.ViewKey, AppTrace.categories.navigate);
    }

    _onViewDetailsPriceHistory() {
        PriceHistory.ViewDetails(this.props.feature.getProperties());
        this.props.history.push({ pathname: '/' + PriceHistory.ViewKey });
        AppTrace.traceInfo(PriceHistory.ViewKey, AppTrace.categories.navigate);
    }

    _onViewDetailsPriceForecast() {
        PriceForecast.ViewDetails(this.props.feature.getProperties())
        this.props.history.push({ pathname: '/' + PriceForecast.ViewKey });
        AppTrace.traceInfo(PriceForecast.ViewKey, AppTrace.categories.navigate);
    }

    _onViewDetailsFinancialHighlights() {
        CompanyFinancials.ViewDetails(this.props.feature.getProperties());
        this.props.history.push({ pathname: '/' + CompanyFinancials.ViewKey });
        AppTrace.traceInfo(CompanyFinancials.ViewKey, AppTrace.categories.navigate);
    }



    render() {
        const FinancialAttributes = [
            'assets',
            'liabilities',
            'cashStart',
            'cashEnd',
            'operatingRevenue',
            'nonOperatingRevenue',
            'totalRevenue',
            'operatingExpenses',
            'nonOperatingExpenses',
            'totalExpenses',
            'netIncome',
        ];

        // Build dynamic content based on availability of data
        let dynamicContent = null;

        // If we have already queried the data for the current entity, render the dynamic content
        if (PopupDataCache.contains(this._url)) {
            let apiData = PopupDataCache.get(this._url);

            let financialAttributes = FinancialAttributes.map(fa => {
                return (<div key={fa}>
                    <span className="feature-popup-property-name feature-popup-property-name-wide">{t('spatialAwareness.layers.utilityServiceTerritories.popup.' + fa)}</span>
                    <span className="feature-popup-property-value feature-popup-property-value-narrow">{this._formatFinancialHighlight(apiData, fa)}</span><br />
                </div>);
            });

            dynamicContent = (
                <div key={this._url}>
                    <span className="feature-popup-title">{t('spatialAwareness.layers.utilityServiceTerritories.popup.supplyAndDemand')}</span>
                    <span>&nbsp;(</span><button className="de-link-button" onClick={this._onViewDetailsSupplyAndDemand}>{t('navigation.viewDetails')}</button><span>)</span>
                    <br />
                    <SupplyAndDemandChart
                        data={apiData.supplyAndDemand}

                        argumentColumn={apiData.supplyAndDemand.metadata.columns.year}
                        capacityByFuelColumns={this._getCapacityByFuelColumns(apiData.supplyAndDemand)}
                        demandColumn={apiData.supplyAndDemand.metadata.columns.demand}
                        reserveMarginColumn={apiData.supplyAndDemand.metadata.columns.reserveMargin}

                        getSeriesName={(column) => Locale.getJSONFieldValue(column.caption)}

                        getArgumentTitle={() => t('spatialAwareness.layers.utilityServiceTerritories.popup.year')}
                        getArgument={(a) => a}
                        getValue={(v) => v}

                        formatArgument={(a) => a}
                        formatAxisCapacityValue={(v) => (v != null) ? Locale.formatNumber(v, 0) : t('general.noDataAvailable')}
                        formatAxisMarginValue={(v) => (v != null) ? Locale.formatNumber(v, 2) : t('general.noDataAvailable')}
                        formatTooltipCapacityValue={(v) => (v != null) ? Locale.formatNumber(v, 0) + ' ' + t('units.megaWatts') : t('general.noDataAvailable')}
                        formatTooltipMarginValue={(v) => (v != null) ? Locale.formatPercent(v, 0) : t('general.noDataAvailable')}
                    />

                    <br />

                    <span className="feature-popup-title">{t('spatialAwareness.layers.utilityServiceTerritories.popup.priceHistory')}</span>
                    <span>&nbsp;(</span><button className="de-link-button" onClick={this._onViewDetailsPriceHistory}>{t('navigation.viewDetails')}</button><span>)</span>
                    <br />
                    <PopoutLineChart
                        data={apiData.jepxSpotMarketTrading}

                        argumentColumn={apiData.jepxSpotMarketTrading.metadata.columns.dateTime}
                        valueColumns={[
                            apiData.jepxSpotMarketTrading.metadata.columns.areaPrice,
                        ]}

                        getSeriesName={(column) => t('spatialAwareness.layers.utilityServiceTerritories.columnCaptions.' + column.caption)}

                        getArgumentTitle={() => t('spatialAwareness.layers.utilityServiceTerritories.popup.localDateTime')}
                        getArgument={(a) => a}
                        getValue={(v) => Locale.getJSONCurrencyValue(v)}

                        formatArgument={(a) => Locale.formatDateTime(a)}
                        formatAxisValue={(v) => Locale.formatCurrency(v)}
                        formatTooltipValue={(v) => Locale.formatCurrency(v) + '/' + t('units.kiloWattHour')}
                    />

                    <br />

                    <span className="feature-popup-title">{t('spatialAwareness.layers.utilityServiceTerritories.popup.priceForecast')}</span>
                    <span>&nbsp;(</span><button className="de-link-button" onClick={this._onViewDetailsPriceForecast}>{t('navigation.viewDetails')}</button><span>)</span>
                    <br />
                    <PopoutLineChart
                        data={apiData.referenceCase}

                        argumentColumn={apiData.referenceCase.metadata.columns.dateTime}
                        valueColumns={[
                            apiData.referenceCase.metadata.columns.offPeak,
                            apiData.referenceCase.metadata.columns.onPeak,
                            apiData.referenceCase.metadata.columns.average,
                        ]}

                        getSeriesName={(column) => t('spatialAwareness.layers.utilityServiceTerritories.columnCaptions.' + column.caption)}

                        getArgumentTitle={() => t('spatialAwareness.layers.utilityServiceTerritories.popup.localDateTime')}
                        getArgument={(a) => a}
                        getValue={(v) => Locale.getJSONCurrencyValue(v)}

                        formatArgument={(a) => Locale.formatDate(a)}
                        formatAxisValue={(v) => Locale.formatCurrency(v)}
                        formatTooltipValue={(v) => Locale.formatCurrency(v) + '/' + t('units.megaWattHour')}
                    />

                    <br />

                    <span className="feature-popup-title">{t('spatialAwareness.layers.utilityServiceTerritories.popup.financialHighlights')}</span>
                    <span>&nbsp;(</span><button className="de-link-button" onClick={this._onViewDetailsFinancialHighlights}>{t('navigation.viewDetails')}</button><span>)</span><br />
                    <span className="feature-popup-subtitle">
                        {t('spatialAwareness.layers.utilityServiceTerritories.popup.fiscalYear')
                            .replace("{0}", apiData.financialHighlights.attributes.year)}
                    </span>
                    <span>&nbsp;:&nbsp;(</span>
                    <span className="feature-popup-subtitle">{t('units.millionsOf_' + Locale.currencyName)}</span>
                    <span>)</span>
                    <br />
                    {financialAttributes}
                </div>
            );
        }
        else {
            // Otherwise, show a load indicator and query for the current data
            dynamicContent = (<PopupLoadIndicator />);
            this._getDataAndRender();
        }

        return (
            <ErrorBoundary>
                <span className="feature-popup-title">{t('spatialAwareness.layers.utilityServiceTerritories.popup.title')}</span><br />
                <br />
                <span className="feature-popup-property-name">{t('spatialAwareness.layers.utilityServiceTerritories.popup.name')}</span><span className="feature-popup-property-value">{Locale.getJSONFieldValue(this.props.feature.getProperties().name)}</span><br />
                <br />
                {dynamicContent}
            </ErrorBoundary>
        );
    }
}

export default withRouter(UtilityServiceTerritoryPopup);
