import React from 'react';
import moment from 'moment';

import SvgIcon from '@material-ui/core/SvgIcon';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import WbSunnyIcon from '@material-ui/icons/WbSunny';

import Filter from 'webcore-ux/react/components/Icons/Filter';
import { Button } from 'webcore-ux/react/components'

import AppConfig from 'AppConfig';
import BusyState from 'BusyState';
import CSVDownloadItem from 'CSVDownloadItem';
import ErrorBoundary from 'ErrorBoundary';
import ErrorState from 'ErrorState';
import GlobalSessionState from 'GlobalSessionState';
import Locale from 'locale/Locale';
import SessionState from 'SessionState';

import DateRangePicker from 'common/DateRangePicker';
import PinnableSettings from 'common/PinnableSettings';
import TabPanel from 'common/TabPanel';
import VerticalTabNav from 'common/VerticalTabNav';
import VisualBox from 'common/VisualBox';
import WeatherFilters from './WeatherFilters';
import WeatherStationsDataFactory from './data/WeatherStationsDataFactory';
import TemperatureDataFactory from './data/TemperatureDataFactory';
import WindDataFactory from './data/WindDataFactory';
import SunshineDurationDataFactory from './data/SunshineDurationDataFactory';
import TemperatureStats from './stats/TemperatureStats';
import WindStats from './stats/WindStats';
import SunshineDurationStats from './stats/SunshineDurationStats';
import TemperatureChart from './TemperatureChart';
import WindChart from './WindChart';
import SunshineDurationChart from './SunshineDurationChart';

import './Weather.scss';

const t = Locale.getResourceString.bind(Locale);

class Weather extends React.Component {
    static get ViewKey() { return 'weather'; }

    static get Downloads() { 
        return [
            new CSVDownloadItem('weather.download', () => `/weather/download?${Weather.SelectedWeatherStationIds.map(id => `id=${id}`).join('&')}`, 'hourly-weather.csv'),
        ]
    };

    static get _SessionStateKey() {
        return 'emi-japan.weather.Weather';
    }

    constructor(props, context) {
        super(props);

        // Default to NOT viewing details from spatial awareness
        Weather._isViewingDetails = false;
        Weather._chartKey = Weather._chartKey ?? 'temperature';
        
        this._now = DateRangePicker.getDate(moment());

        // All weather stations as reported by the API
        this._weatherStationsDataFactory = new WeatherStationsDataFactory();
        this._weatherStations = null;

        // Previously-selected filters to help determine when to recalculate stats
        this.__chartSettings = {
            temperature: {
                previousFilters: {},
                dataFactory: new TemperatureDataFactory(),
                data: null,
                timeFilters: { start: this._now, end: this._now, selectedYears: [] },
                stats: new TemperatureStats(),
            },
            wind: {
                previousFilters: {},
                dataFactory: new WindDataFactory(),
                data: null,
                timeFilters: { start: this._now, end: this._now, selectedYears: [] },
                stats: new WindStats(),
            },
            sunshineDuration: {
                previousFilters: {},
                dataFactory: new SunshineDurationDataFactory(),
                data: null,
                timeFilters: { start: this._now, end: this._now, selectedYears: [] },
                stats: new SunshineDurationStats(),
            },
        }

        // Configure initial state
        this._defaultInitialState = {
            isNavExpanded: true,

            granularity: 'D',
            aggregation: 'average',

            selectedHours: [...Array(24).keys()],
            selectedMonths: [...Array(12).keys()].map(k => k + 1),
        }
        this._stateOverrides = {
            filterPanelOpen: false,
            filterPanelIsPinned: false,
        }
        this.state = SessionState.get(Weather._SessionStateKey, this._defaultInitialState, this._stateOverrides);

        // Bind event handlers
        this._onFormatYear = this._onFormatYear.bind(this);
        this._oni18nChanged = this._oni18nChanged.bind(this);
        this._onPin = this._onPin.bind(this);
        this._onChangeChartTab = this._onChangeChartTab.bind(this);
    }

    static ViewDetails(weatherStation, chartKey) {
        let currentIds = Weather.SelectedWeatherStationIds;
        let newId = weatherStation.entityId;

        // If we currently have no selected territories, or just 1 then set the
        // selection to the one just clicked
        if (currentIds.length <= 1) {
            currentIds = [newId];
        }
        // Otherwise we have multiple selected values already.  Make sure
        // the territory that was just selected is represented.
        else if (currentIds.indexOf(newId) < 0) {
            currentIds.push(newId);

            // If we have more than the max-allowed stations here, then remove the 
            // least-recently-added one.  It might be better to prompt here, but 
            // I'm banking on this scenario not occurring with 20 stations.
            if (currentIds.length > AppConfig.weather.maxWeatherStations) {
                currentIds.shift();
            }
        }

        // Update global session state
        Weather._chartKey = chartKey;
        Weather.IsViewingDetails = true;
        Weather.SelectedWeatherStationIds = currentIds;
    }

    static get IsViewingDetails() {
        return Weather._isViewingDetails;
    }
    static set IsViewingDetails(value) {
        return Weather._isViewingDetails = value;
    }

    static get SelectedWeatherStationIds() {
        return GlobalSessionState.get().weatherStationIds ?? [];
    }
    static set SelectedWeatherStationIds(ids) {
        GlobalSessionState.set({
            weatherStationIds: ids,
        });
    }

    static get _chartKey() {
        return GlobalSessionState.get().weatherChartKey;
    }
    static set _chartKey(value) {
        GlobalSessionState.set({ 
            weatherChartKey: value,
        });
    }

    get _chartSettings() {
        return this.__chartSettings[Weather._chartKey];
    }

    // Get/set previous filters
    get _previousFilters() {
        return this._chartSettings.previousFilters;
    }
    set _previousFilters(value) {
        this._chartSettings.previousFilters = value;
    }

    // Get data factory
    get _dataFactory() {
        return this._chartSettings.dataFactory;
    }

    // Get/set current chart data
    get _data() {
        return this._chartSettings.data;
    }
    set _data(value) {
        this._chartSettings.data = value;
    }

    // Get stats object
    get _stats() {
        return this._chartSettings.stats;
    }


    _getData() {
        return new Promise((resolve, reject) => {
            // Set busy state
            let isBusy = BusyState.isBusy;
            BusyState.isBusy = true;

            // Get data
            this._dataFactory.get(Weather.SelectedWeatherStationIds)
                .then(data => {
                    this._data = data;
                    resolve(this._data);
                })
                .finally(() => {
                    BusyState.isBusy = isBusy;
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    get _minDate() {
        let min = this._data?.minDate ?? this._now;
        return DateRangePicker.getDate(min);
    }
    get _maxDate() {
        let max = this._data?.maxDate ?? this._now;
        return DateRangePicker.getDate(max);
    }

    get _start() {
        return this._chartSettings.timeFilters.start;
    }
    set _start(value) {
        this._chartSettings.timeFilters.start = value;
    }

    get _end() {
        return this._chartSettings.timeFilters.end;
    }
    set _end(value) {
        this._chartSettings.timeFilters.end = value;
    }

    get _selectedYears() {
        return this._chartSettings.timeFilters.selectedYears;
    }
    set _selectedYears(value) {
        this._chartSettings.timeFilters.selectedYears = value;
    }

    get _allYears() {
        return this._data?.allYears ?? [];
    }
   
    get _filters() {
        return {
            // Needed to ensure we calculate stats when the locale or units change
            selectedLocale: Locale.localeName,
            selectedTemperatureUnits: Locale.temperatureUnits,
            selectedSpeedUnits: Locale.speedUnits,

            selectedWeatherStationsIds: Weather.SelectedWeatherStationIds,

            granularity: this.state.granularity,
            aggregation: this.state.aggregation,

            start: this._start,
            end: this._end,
            selectedHours: this.state.selectedHours,
            selectedMonths: this.state.selectedMonths,
            selectedYears: this._selectedYears,
        };
    }

    get _selectedWeatherStationNames() {
        let names = [];
        if (this._weatherStations != null) {
            names = Weather.SelectedWeatherStationIds.map(id => 
                Locale.getJSONFieldValue(this._weatherStations.lookup[id])
            );
        }

        return names;
    }

    get _committedWeatherStationIds() {
        return this.__committedWeatherStationIds ?? [];
    }
    set _committedWeatherStationIds(value) {
        this.__committedWeatherStationIds = value;
    }

    get _committedWeatherStationNames() {
        return this.__committedWeatherStationNames ?? [];
    }
    set _committedWeatherStationNames(value) {
        this.__committedWeatherStationNames = value;
    }

    _onFormatYear(value) {
        return Locale.formatMomentGranularity(moment.utc([value, 1, 1]), 'A');
    }

    _oni18nChanged() {
        this._calculateStatsAndRender();
    }

    _onPin(args) {
        this.setState({ filterPanelIsPinned: args.isPinned });
        if (this.props.onPin != null) {
            this.props.onPin(args);
        }
    }

    _onChangeChartTab(chartKey) {
        // Update the chart key
        Weather._chartKey = chartKey;

        // Get data and update
        this._getData()
            .then(() => {
                // If this is the first time we've gotten data for a chart, then set default
                // start, end, and selected years
                if (this._start.isSame(this._now)) {
                    this._start = this._minDate;
                    this._end = this._maxDate;
                    this._selectedYears = this._allYears;
                }

                // Now we have everything we need to calclate stats and render the charts
                this._calculateStatsAndRender();
            });
    }

    _calculateStatsAndRender(filterOverrides) {
        // Make sure we have a state object
        filterOverrides = filterOverrides ?? {};

        // Check whether or not to calculate stats and render
        if (this._data != null) {
            // Merge in new state to filters
            let filters = Object.assign(this._filters, filterOverrides);

            // Compare filters to optimize away unnecessary calculations
            if (JSON.stringify(filters) !== JSON.stringify(this._previousFilters)) {
                this._previousFilters = filters;
                this._stats.calculate(this._data, filters);
            }
        }

        // Update state given the new state values
        this._committedWeatherStationIds = Weather.SelectedWeatherStationIds.slice();
        this._committedWeatherStationNames = this._selectedWeatherStationNames.slice();
        this.setState(filterOverrides);
    }



    componentDidMount() {
        // Listen for locale and currency-changed events.  We need to recalculate stats 
        // and update because we don't recalculate stats on every render.
        Locale.addLocaleHandler("Weather", this._oni18nChanged);
        Locale.addUnitsHandler("Weather", this._oni18nChanged);

        BusyState.isBusy = true;

        // Get weather stations
        this._weatherStationsDataFactory.getWeatherStations()
            .then(weatherStations => {
                this._weatherStations = weatherStations;
                Weather.SelectedWeatherStationIds = (Weather.SelectedWeatherStationIds?.length) ? Weather.SelectedWeatherStationIds :
                    (this._weatherStations?.length) ? [this._weatherStations[0].id] :
                    [];
                return this._getData();
            })
            .then(() => {
                // Now we have everything we need to calclate stats and render the charts
                this._start = this._minDate;
                this._end = this._maxDate;
                this._selectedYears = this._allYears;
                this._calculateStatsAndRender();
            })
            .catch((error) => {
                ErrorState.setFault(error.message);
            })
            .finally(() => {
                BusyState.isBusy = false;
            });
    }

    componentDidUpdate() {
        // Persist current state
        SessionState.set(Weather._SessionStateKey, this.state);

        // If viewing details from Spatial Awareness then get data and update
        if (Weather.IsViewingDetails) {
            Weather.IsViewingDetails = false;

            // Get data and update
            this._getData()
                .then(() => {
                    // Now we have everything we need to calclate stats and render the charts
                    this._calculateStatsAndRender();
                });
        }
    }

    componentWillUnmount() {
        // Clean up handlers
        Locale.addUnitsHandler("Weather");
        Locale.removeLocaleHandler("Weather");
    }

    renderFilters() {
        return (
            <WeatherFilters
                allWeatherStations={this._weatherStations}
                data={this._data}
                
                selectedWeatherStationIds={Weather.SelectedWeatherStationIds}
                onChangeWeatherStationIds={(value) => { 
                    Weather.SelectedWeatherStationIds = value;
                    this.forceUpdate();
                }}
                onCommitWeatherStationIds={() => {
                    // Get data, then calculate stats and render
                    this._getData()
                        .then(() => {
                            this._calculateStatsAndRender();
                        });
                }}         
                onClearWeatherStationIds={() => {
                    // This will update the series and charts, showing nothing on the chart
                    this._calculateStatsAndRender();
                }}       
                granularity={this.state.granularity}
                onChangeGranularity={(value) => this._calculateStatsAndRender({ granularity: value })}
                aggregation={this.state.aggregation}
                onChangeAggregation={(value) => this._calculateStatsAndRender({ aggregation: value })}

                minDate={this._minDate}
                maxDate={this._maxDate}
                start={this._start}
                end={this._end}
                // NOTE: Do not recalculate stats and update the chart for onChanging because
                // too many of these will fire.  Wait for the onChange event.
                onChangingDateRange={(start, end) => { this._start = start; this._end = end; this.forceUpdate(); }}
                onChangeDateRange={(start, end) => { this._start = start; this._end = end; this._calculateStatsAndRender(); }}

                selectedHours={this.state.selectedHours}
                onChangeSelectedHours={(value) => this._calculateStatsAndRender({ selectedHours: value })}
                selectedMonths={this.state.selectedMonths}
                onChangeSelectedMonths={(value) => { this._calculateStatsAndRender({ selectedMonths: value }) }}
                allYears={this._allYears}
                selectedYears={this._selectedYears}
                onChangeSelectedYears={(value) => { this._selectedYears = value; this._calculateStatsAndRender(); }}
            />
        );
    }

    _renderTabs() {
        const DeviceThermostatIcon = (props) => {
            return (
                <SvgIcon {...props}>
                    <path d="M15 13V5c0-1.66-1.34-3-3-3S9 3.34 9 5v8c-1.21.91-2 2.37-2 4 0 2.76 2.24 5 5 5s5-2.24 5-5c0-1.63-.79-3.09-2-4zm-4-8c0-.55.45-1 1-1s1 .45 1 1h-1v1h1v2h-1v1h1v2h-2V5z" />
                </SvgIcon>
            );
        }

        const AirIcon = (props) => {
            return (
                <SvgIcon {...props}>
                    <path d="M14.5 17c0 1.65-1.35 3-3 3s-3-1.35-3-3h2c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1H2v-2h9.5c1.65 0 3 1.35 3 3zM19 6.5C19 4.57 17.43 3 15.5 3S12 4.57 12 6.5h2c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5S16.33 8 15.5 8H2v2h13.5c1.93 0 3.5-1.57 3.5-3.5zm-.5 4.5H2v2h16.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5v2c1.93 0 3.5-1.57 3.5-3.5S20.43 11 18.5 11z" />
                </SvgIcon>
            );
        }

        return (
            <Tabs
                value={Weather._chartKey}
                onChange={(e, value) => this._onChangeChartTab(value)}
                orientation="vertical"
            >
                <Tab
                    icon={<DeviceThermostatIcon fontSize="default" />}
                    value="temperature"
                    label={this.state.isNavExpanded && t('weather.temperature.caption')}
                    title={t('weather.temperature.caption')}
                />
                <Tab 
                    icon={<AirIcon fontSize="default" />}
                    value="wind"
                    label={this.state.isNavExpanded && t('weather.wind.caption')}
                    title={t('weather.wind.caption')}
                />
                <Tab 
                    icon={<WbSunnyIcon fontSize="default" />}
                    value="sunshineDuration"
                    label={this.state.isNavExpanded && t('weather.sunshineDuration.caption')}
                    title={t('weather.sunshineDuration.caption')}
                />
            </Tabs>
        );
    }

    _renderTabPanels() {
        return (
            <>
                <TabPanel isRenderedWhenHidden={false} valuekey="temperature" value={Weather._chartKey}>
                    {(this._data != null) && (
                        <TemperatureChart
                            aggregation={this.state.aggregation}
                            aggregated={this._stats.aggregated}
                            data={this._data}
                            seriesKeys={this._committedWeatherStationIds}
                            seriesNames={this._committedWeatherStationNames}
                        />
                    )}
                </TabPanel>
                <TabPanel isRenderedWhenHidden={false} valuekey="wind" value={Weather._chartKey}>
                    {(this._data != null) && (
                        <WindChart
                            granularity={this.state.granularity}
                            aggregation={this.state.aggregation}
                            aggregated={this._stats.aggregated}
                            data={this._data}
                            seriesKeys={this._committedWeatherStationIds.map(id => `speed_${id}`)}
                            seriesNames={this._committedWeatherStationNames}
                        />
                )}
                </TabPanel>
                <TabPanel isRenderedWhenHidden={false} valuekey="sunshineDuration" value={Weather._chartKey}>
                    {(this._data != null) && (
                        <SunshineDurationChart
                            aggregation={this.state.aggregation}
                            aggregated={this._stats.aggregated}
                            data={this._data}
                            seriesKeys={this._committedWeatherStationIds}
                            seriesNames={this._committedWeatherStationNames}
                    />
                )}
                </TabPanel>
            </>
        );
    }

    render() {
        return (
            <ErrorBoundary>
                <div className="weather">
                    <VisualBox caption={t('weather.caption') + ' : ' + t('weather.' + Weather._chartKey + '.caption')}>
                        <div className="weather-charts">
                            <div className="jemi-view-filter-button" title={t('weather.filters.caption')}>
                                <Button 
                                    disabled={this.state.filterPanelIsPinned}
                                    variant="primary"
                                    onClick={ () => this.setState({ filterPanelOpen: !this.state.filterPanelOpen }) } >
                                    <Filter fontSize="small" />
                                </Button>
                            </div>
                            <PinnableSettings
                                contentClassName="jemi-filters-container"
                                open={this.state.filterPanelOpen}
                                title={t('weather.filters.caption')}
                                viewKey={Weather.ViewKey}
                                onClose={() => this.setState({ filterPanelOpen: false })}
                                onPin={(args) => this._onPin(args)}
                            >
                                {this.renderFilters()}
                            </PinnableSettings>

                            <VerticalTabNav 
                                isExpanded={this.state.isNavExpanded}
                                expandedWidth={150}
                                tabs={this._renderTabs()}
                                tabPanels={this._renderTabPanels()}
                                onChangeIsExpanded={(isExpanded) => this.setState({ isNavExpanded: isExpanded })}
                            />
                        </div>
                    </VisualBox>
                </div>
            </ErrorBoundary>
        );
    }
}

export default Weather;
