import React from 'react';

import SelectAllIcon from '@material-ui/icons/SelectAll';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { Dropdown } from 'webcore-ux/react/components';

import AppConfig from 'AppConfig';
import ErrorBoundary from 'ErrorBoundary';
import Locale from 'locale/Locale';

import DateRangePicker from 'common/DateRangePicker';
import HourPicker from 'common/HourPicker';
import MonthPicker from 'common/MonthPicker';
import MultiSelectDropdown from 'common/MultiSelectDropdown';
import YearPicker from 'common/YearPicker';
import VisualBox from 'common/VisualBox';

const t = Locale.getResourceString.bind(Locale);

////////////////////////////////////////////////////////////////////////////////
// These are the filters to be shown for the spot market chart
////////////////////////////////////////////////////////////////////////////////
class WeatherFilters extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {
            isSnackbarOpen: false,
        };

        this.onChangeWeatherStationIds = this.onChangeWeatherStationIds.bind(this);
    }

    onChangeWeatherStationIds(ids) {
        if (ids.length > AppConfig.weather.maxWeatherStations) {
            this.setState({ isSnackbarOpen: true });
        }
        else {
            this.props.onChangeWeatherStationIds(ids);
        }
    }

    render() {
        // Bail early if we don't have the data we need
        if (this.props.allWeatherStations == null ||
            this.props.selectedWeatherStationIds == null ||
            this.props.allYears == null ||
            this.props.selectedYears == null) {
            return null;
        }

        // Build weather station options, then sort by the current locale
        let weatherStationOptions = this.props.allWeatherStations.map(w => { 
            return {
                value: w.id.toString(),
                label: Locale.getJSONFieldValue(w.name),
            };
        });
        weatherStationOptions.sort((a, b) => {
            return a.label.localeCompare(b.label, Locale.localeName);
        })

        const granularities = [
            { value: 'A', },
            { value: 'M', },
            { value: 'D', },
            { value: 'H', },
            { value: 'MoY', },
            { value: 'HoD', }
        ];

        const aggregations = [
            { value: 'min', },
            { value: 'max', },
            { value: 'average', },
            { value: 'sum', },
            { value: 'stdev', },
            { value: 'count', }
        ];

        return (
            <ErrorBoundary>
                <Snackbar open={this.state.isSnackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={3000} 
                    onClose={() => this.setState({ isSnackbarOpen: false })}
                >
                    <Alert severity="warning">
                        {t('weather.filters.stations.upToNStations').replace('{0}', AppConfig.weather.maxWeatherStations)}
                    </Alert>
                </Snackbar>

                <div>
                    <VisualBox className="chart-series-box" caption={t('weather.weatherStation')}>
                        <MultiSelectDropdown 
                            multipleSelectionsPlaceholder={t('general.multipleSelectedValues')}
                            placeholder={t('general.select')}
                            options={weatherStationOptions}
                            value={this.props.selectedWeatherStationIds.map(id => id.toString())}
                            onClear={() => {this.props.onClearWeatherStationIds()}}
                            onChange={(value) => {this.onChangeWeatherStationIds(value.map(id => parseInt(id, 10)))}}
                            onMenuClose={() => this.props.onCommitWeatherStationIds()}
                        />
                    </VisualBox>

                    <VisualBox caption={t('weather.filters.view.caption')}>
                        <Dropdown
                            label={t('weather.filters.granularity.caption')}
                            options={granularities.map(g => Object.assign(g, { label: t('weather.filters.granularity.' + g.value) }))}
                            isClearable={false}
                            value={this.props.granularity}
                            onChange={obj => this.props.onChangeGranularity(obj.value)}
                        />
                        <Dropdown
                            label={t('weather.filters.aggregation.caption')}
                            options={aggregations.map(a => Object.assign(a, { label: t('weather.filters.aggregation.' + a.value) }))}
                            isClearable={false}
                            value={this.props.aggregation}
                            onChange={obj => this.props.onChangeAggregation(obj.value)}
                        />
                    </VisualBox>

                    <VisualBox caption={t('weather.filters.dateRange.caption')}>
                        <DateRangePicker
                            minDate={this.props.minDate}
                            maxDate={this.props.maxDate}
                            start={this.props.start}
                            end={this.props.end}
                            onChanging={(start, end) => this.props.onChangingDateRange(start, end)}
                            onChange={(start, end) => this.props.onChangeDateRange(start, end)}
                        />
                    </VisualBox>

                    <VisualBox caption={t('weather.filters.hour.caption')}>
                        <div className="button-group-filter">
                            <div 
                                className="select-all" 
                                title={t('general.selectAll')} 
                                onClick={() => this.props.onChangeSelectedHours([...Array(24).keys()])}>
                                <SelectAllIcon />
                            </div>
                            <div 
                                className="select-none" 
                                title={t('general.clearAll')} 
                                onClick={() => this.props.onChangeSelectedHours([])}>
                                <ClearAllIcon />
                            </div>
                            <HourPicker
                                value={this.props.selectedHours}
                                onChange={(value) => this.props.onChangeSelectedHours(value)}
                            />
                        </div>
                    </VisualBox>

                    <VisualBox caption={t('weather.filters.month.caption')}>
                        <div className="button-group-filter">
                            <div 
                                className="select-all" 
                                title={t('general.selectAll')} 
                                onClick={() => this.props.onChangeSelectedMonths([...Array(12).keys()].map(k => k + 1))}>
                                <SelectAllIcon />
                            </div>
                            <div 
                                className="select-none" 
                                title={t('general.clearAll')} 
                                onClick={() => this.props.onChangeSelectedMonths([])}>
                                <ClearAllIcon />
                            </div>
                            <MonthPicker
                                value={this.props.selectedMonths}
                                onChange={(value) => { this.props.onChangeSelectedMonths(value) }}
                            />
                        </div>
                    </VisualBox>

                    <VisualBox caption={t('weather.filters.year.caption')}>
                        <div className="button-group-filter">
                            <div 
                                className="select-all" 
                                title={t('general.selectAll')} 
                                onClick={() => this.props.onChangeSelectedYears(this.props.allYears)}>
                                <SelectAllIcon />
                            </div>
                            <div 
                                className="select-none" 
                                title={t('general.clearAll')} 
                                onClick={() => this.props.onChangeSelectedYears([])}>
                                <ClearAllIcon />
                            </div>
                            <YearPicker
                                allValues={this.props.allYears}
                                value={this.props.selectedYears}
                                onChange={(value) => { this.props.onChangeSelectedYears(value) }}
                            />
                        </div>
                    </VisualBox>
                </div>
            </ErrorBoundary>
        );
    }
}

export default WeatherFilters;
