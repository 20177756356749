import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AreaChart, Area, XAxis, YAxis, Legend, Tooltip } from 'recharts';
import ErrorBoundary from 'ErrorBoundary';
import Palettes from 'common/Palettes';
import CommonStyles from 'common/Common.scss'

import './PopoutChart.scss';

/////////////////////////////////////////////////////////////////////////////
// This class encapsulates a small area chart intended to be used in
// popouts for map entities, or other side panel popouts.
/////////////////////////////////////////////////////////////////////////////
class PopoutAreaChart extends Component {
    constructor(props, context) {
        super(props);

        // Initially, series is null
        this._series = null;
        this._data = null;

        this._onTooltipFormatLabel = this._onTooltipFormatLabel.bind(this);
        this._onTooltipFormatValue = this._onTooltipFormatValue.bind(this);
    }

    _onTooltipFormatLabel(value) {
        return (
            <>
                <span>{this.props.getArgumentTitle()}</span>
                &nbsp;:&nbsp;
                <span>{
                    (this._data != null && value >= 0 && value < this._data.length) ? 
                        this.props.formatArgument(this._data[value].argument) : 
                        null
                }</span>
            </>
        );
    }

    _onTooltipFormatValue(value, name, props) {
        return this.props.formatTooltipValue(value);
    }

    _makeSeries() {
        // Only generate series and data once
        if (this._series == null) {
            // Get argument column
            let argumentColumn = this.props.argumentColumn;

            // Build series info for each value column specified in the props
            this._data = [];
            for (let i = 0; i < this.props.data.rows.length; ++i) {
                // Start a new row object with the X-axis properties
                let row = this.props.data.rows[i];
                let obj = {
                    x: i,
                    argument: this.props.getArgument(row[argumentColumn.index]),
                };

                // Add values
                for (let seriesIndex = 0; seriesIndex < this.props.valueColumns.length; ++seriesIndex) {
                    let valueColumn = this.props.valueColumns[seriesIndex];
                    obj[valueColumn.name] = this.props.getValue(row[valueColumn.index]);
                }

                this._data.push(obj);
            }

            this._series = this.props.valueColumns.map((valueColumn, index) => {
                return {
                    dataKey: valueColumn.name,
                    name: this.props.getSeriesName(valueColumn),
                    color: this.props.palette[index],
                };             
            });
        }
    }



    componentWillUnmount() {
        this._data = null;
        this._series = null;
        this._onTooltipFormatLabel = null;
        this._onTooltipFormatValue = null;
    }

    render() {
        const chartWidth = parseInt(CommonStyles.featurePopupContentWidth);

        if (this.props.data != null && this.props.valueColumns != null) {
            // Make chart series
            this._makeSeries();

            // Create series from seriesInfos
            let series = this._series.map((info, index) =>
                <Area
                    type={this.props.interpolationType}
                    key={info.dataKey} 
                    name={info.name} 
                    dataKey={info.dataKey} 
                    stroke={info.color} 
                    fill={info.color}
                    dot={false}
                    legendType="rect"
                    strokeWidth={1}
                />
            );

            // Render the chart
            return (
                <ErrorBoundary>
                    <div className="popout-chart">
                        <AreaChart
                            margin={{ top: 5, right: 65, bottom: 5, left: 5 }}
                            width={chartWidth} 
                            height={this.props.height}
                            data={this._data}
                        >
                            {series}
                            <XAxis height={1} tick={false} />
                            <YAxis 
                                tickFormatter={(v) => this.props.formatAxisValue(v)}
                            />
                            <Tooltip 
                                isAnimationActive={false} 
                                labelFormatter={this._onTooltipFormatLabel}
                                formatter={this._onTooltipFormatValue} 
                            />
                            <Legend />
                        </AreaChart>
                    </div>
                </ErrorBoundary>
            );
        }
        else {
            return null;
        }
    }
}

PopoutAreaChart.propTypes = {
    interpolationType: PropTypes.string,
    palette: PropTypes.arrayOf(PropTypes.string),
    height: PropTypes.number,
    data: PropTypes.object,
    argumentColumn: PropTypes.object,
    valueColumn: PropTypes.arrayOf(PropTypes.object),

    getSeriesName: PropTypes.func,

    getArgumentTitle: PropTypes.func,
    getArgument: PropTypes.func,
    getValue: PropTypes.func,

    formatArgument: PropTypes.func,
    formatAxisValue: PropTypes.func,
    formatTooltipValue: PropTypes.func,
}

PopoutAreaChart.defaultProps = {
    interpolationType: 'linear',
    palette: Palettes.DefaultColors,
    height: 110,
}

export default PopoutAreaChart;
