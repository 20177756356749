import Locale from 'locale/Locale';
import WeatherStats from './WeatherStats';

export default class TemperatureStats extends WeatherStats {
    _getFields(row) {
        return this._valueColumns.reduce((obj, col) => { 
            obj[col.name] = Locale.convertTemperature(row[col.index], 'celsius');
            return obj; 
        }, {});
    }
}
