import AppConfig from 'AppConfig';
import Authentication from 'Authentication';
import AppTrace from 'AppTrace';

const axios = require('axios');

export default class VintagesDataFactory {
    static __cache = null;

    static get _cache() {
        return VintagesDataFactory.__cache;
    }
    static set _cache(value) {
        VintagesDataFactory.__cache = value;
    }    

    static get() {
        return new Promise((resolve, reject) => {
            if (VintagesDataFactory._cache != null) {
                resolve(VintagesDataFactory._cache);
            }
            else {
                // Get access token
                Authentication.getAccessToken()
                    // Get API data
                    .then((accessToken) => {
                        let authString = `Bearer `.concat(accessToken);
                        let url = `${AppConfig.dataService.baseUrl}/supply-and-demand/vintages`;

                        return axios.get(url, {
                            headers: {
                                Authorization: authString,
                                Accept: 'application/json',
                                'cache-control': 'no-cache',
                            },
                        });
                    })
                    .then((response) => {
                        let data = response.data;
                        data.forEach(e => {
                            e.SeasonAbbr = JSON.parse(e.SeasonAbbr);
                            e.ScenarioName = JSON.parse(e.ScenarioName);
                        })
                        VintagesDataFactory._cache = data;
                        resolve(VintagesDataFactory._cache);
                    })
                    .catch((error) => {
                        AppTrace.traceError('Error in VintagesDataFactory.get: ' + error.message);
                        reject(new Error('Error getting vintages data'));
                    });
            }
        });
    }
}
