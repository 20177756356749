import moment from 'moment';

import MarketStats from './MarketStats';

export default class LNGMarketStats extends MarketStats {
    get _granularityField() {
        return 'lngMarketGranularity'
    }

    _beforeFilter(data, filters, valueColumns) {
        // Transform selection arrays into objects for easy lookup without searches
        filters.selectedMonths = filters.selectedMonths.reduce((obj, m) => { obj[m] = true; return obj; }, {});
        filters.selectedYears = filters.selectedYears.reduce((obj, y) => { obj[y] = true; return obj; }, {});

        // Change the start and end date for our monthly data
        filters.start = moment.utc([filters.start.year(), filters.start.month(), 1]);
        filters.end = moment.utc([filters.end.year(), filters.end.month(), 1]).add(1, 'months');
    }


    _getMomentCategory(theMoment, filters) {
        // Default: monthly
        let momentCategory = moment.utc([theMoment.year(), theMoment.month()]);
        let granularity = filters.lngMarketGranularity;

        // Annual
        if (granularity === 'A') {
            momentCategory = moment.utc([theMoment.year(), 1, 1]);
        }
        // Month of Year
        else if (granularity === 'MoY') {
            // Use an arbitrary year to group by the month
            momentCategory = moment.utc([2020, theMoment.month()]);
        }

        return momentCategory;
    }

    _rowMeetsFilterCriteria(theMoment, filters) {
        let rowMeetsFilterCriteria = false;

        // Filter for start/end date
        if (filters.start.isSameOrBefore(theMoment) && theMoment.isBefore(filters.end)) {
            // Filter for hour/month/year selections
            if (filters.selectedMonths[theMoment.month() + 1] &&
                filters.selectedYears[theMoment.year()]) {
                    rowMeetsFilterCriteria = true;
            }
        }

        return rowMeetsFilterCriteria;
    }
}
