import React from 'react';

import SelectAllIcon from '@material-ui/icons/SelectAll';
import ClearAllIcon from '@material-ui/icons/ClearAll';

import ErrorBoundary from 'ErrorBoundary';
import Locale from 'locale/Locale';

import DateRangePicker from 'common/DateRangePicker';
import MonthPicker from 'common/MonthPicker';
import MultiSelectDropdown from 'common/MultiSelectDropdown';
import YearPicker from 'common/YearPicker';
import VisualBox from 'common/VisualBox';

import './PriceHistory.scss';

const t = Locale.getResourceString.bind(Locale);

////////////////////////////////////////////////////////////////////////////////
// These are the filters to be shown for the forward market chart
////////////////////////////////////////////////////////////////////////////////
class ForwardMarketFilters extends React.Component {
    constructor(props, context) {
        super(props);
    }

    ////////////////////////////////////////////////////////////////////////////
    // These are the heirarchical options used for the series dropdown
    ////////////////////////////////////////////////////////////////////////////
    get _forwardMarketSeriesOptions() {
        const options = [
            {
                label: 'contractLength',
                options: [
                    { value: 'year' }, 
                    { value: 'month' }, 
                    { value: 'week' }, 
                ],
            },
            {
                label: 'peakType',
                options: [
                    { value: 'allHours' }, 
                    { value: 'daytime' }, 
                ],
            },
            {
                label: 'interconnect',
                options: [
                    { value: 'kansai' }, 
                    { value: 'system' }, 
                    { value: 'tokyo' }, 
                ],
            },
        ];

        let translatedOptions = options.map(o => { 
            return {
                label: t('priceHistory.charts.forwardMarket.seriesGroups.' + o.label),
                options: o.options.map(o2 => { 
                    return {
                        value: o2.value,
                        label: t('priceHistory.charts.forwardMarket.seriesTypes.' + o2.value),
                    };
                }),
            };
        });

        return translatedOptions;
    }

    render() {
        return (
            <ErrorBoundary>
                <div>
                    <VisualBox className="chart-series-box" caption={t('priceHistory.filters.chartSeries')}>
                        <div className="button-group-filter">
                            <div 
                                className="select-all" 
                                title={t('general.selectAll')} 
                                onClick={() => this.props.onChangeSeries(ForwardMarketFilters.AllSeries)}>
                                <SelectAllIcon />
                            </div>
                            <div 
                                className="select-none" 
                                title={t('general.clearAll')} 
                                onClick={() => this.props.onChangeSeries([])}>
                                <ClearAllIcon />
                            </div>
                            <MultiSelectDropdown
                                multipleSelectionsPlaceholder={t('general.multipleSelectedValues')}
                                placeholder={t('general.select')}
                                options={this._forwardMarketSeriesOptions}
                                value={this.props.selectedSeries}
                                onChange={(value) => this.props.onChangeSeries(value)}
                            />
                        </div>
                    </VisualBox>

                    <VisualBox caption={t('priceHistory.filters.dateRange')}>
                        <DateRangePicker
                            minDate={this.props.minDate}
                            maxDate={this.props.maxDate}
                            start={this.props.start}
                            end={this.props.end}
                            onChanging={(start, end) => this.props.onChangingDateRange(start, end)}
                            onChange={(start, end) => this.props.onChangeDateRange(start, end)}
                        />
                    </VisualBox>

                    <VisualBox caption={t('priceHistory.filters.month')}>
                        <div className="button-group-filter">
                            <div 
                                className="select-all" 
                                title={t('general.selectAll')} 
                                onClick={() => this.props.onChangeSelectedMonths([...Array(12).keys()].map(k => k + 1))}>
                                <SelectAllIcon />
                            </div>
                            <div 
                                className="select-none" 
                                title={t('general.clearAll')} 
                                onClick={() => this.props.onChangeSelectedMonths([])}>
                                <ClearAllIcon />
                            </div>
                            <MonthPicker
                                value={this.props.selectedMonths}
                                onChange={(value) => { this.props.onChangeSelectedMonths(value) }}
                            />
                        </div>
                    </VisualBox>

                    <VisualBox caption={t('priceHistory.filters.year')}>
                        <div className="button-group-filter">
                            <div 
                                className="select-all" 
                                title={t('general.selectAll')} 
                                onClick={() => this.props.onChangeSelectedYears(this.props.allYears)}>
                                <SelectAllIcon />
                            </div>
                            <div 
                                className="select-none" 
                                title={t('general.clearAll')} 
                                onClick={() => this.props.onChangeSelectedYears([])}>
                                <ClearAllIcon />
                            </div>
                            <YearPicker
                                allValues={this.props.allYears}
                                value={this.props.selectedYears}
                                onChange={(value) => { this.props.onChangeSelectedYears(value) }}
                            />
                        </div>
                    </VisualBox>
                </div>
            </ErrorBoundary>
        );
    }
}

// Set a class-level field for all available series
ForwardMarketFilters.AllSeries = ['year', 'month', 'week', 'allHours', 'daytime', 'tokyo', 'kansai', 'system'];

export default ForwardMarketFilters;
