export default class PopupDataCache {
    static _cache = {};

    static add(key, value) {
        PopupDataCache._cache[key] = value;
    }

    static contains(key) {
        return PopupDataCache._cache[key] != null;
    }

    static get(key) {
        return PopupDataCache._cache[key];
    }

    static clear() {
        Object.values(PopupDataCache._cache).forEach(v => {
            if (typeof v.delete === 'function') {
                v.delete();
            }
        });

        PopupDataCache._cache = {};
    }
}
