import AppConfig from 'AppConfig';
import Authentication from 'Authentication';
import DataFormatting from 'DataFormatting';
import AppTrace from 'AppTrace';

const axios = require('axios');

export default class PriceForecastDataFactory {
    get() {
        return new Promise((resolve, reject) => {
            // Get access token
            Authentication.getAccessToken()
                // Get API data
                .then((accessToken) => {
                    let authString = `Bearer `.concat(accessToken);
                    let url = `${AppConfig.dataService.baseUrl}/price-forecast`;

                    return axios.get(url, {
                        headers: {
                            Authorization: authString,
                            Accept: 'application/json',
                            'cache-control': 'no-cache',
                        },
                    });
                })
                .then((response) => {
                    let data = response.data;
                    this._postProcessData(data);
                    this._postProcessCurrencyData(data.RefCaseMonthly)
                    this._postProcessCurrencyData(data.RefCaseAnnual)
                    this._postProcessFuelPriceForecast(data.FuelPriceForecast);
                    resolve(data);
                })
                .catch((error) => {
                    AppTrace.traceError('Error in PriceForecastDataFactory.get: ' + error.message);
                    reject(new Error('Error getting price forecast data'));
                });
        });
    }

    _postProcessData(data) {
        DataFormatting.convertResultFields(data.Entities);

        // Create a lookup from utility service territory ID to name
        let idIndex = data.Entities.metadata.columns.id.index;
        let nameIndex = data.Entities.metadata.columns.name.index;
        data.Entities = data.Entities.rows.reduce((obj, row) => {
            obj[row[idIndex]] = row[nameIndex];
            return obj;
        }, {});
    }

    _postProcessCurrencyData(data) {
        DataFormatting.convertResultFields(data)
        const regex = new RegExp('_val', 'g')
        let convUSD = data.metadata.columns.conv_usd
        let convEUR = data.metadata.columns.conv_euro
        let currencyColumns = Object.values(data.metadata.columns).filter(c => c.name.match(regex));

         for (let i = 0; i < data.rows.length; ++i) { 
             let row = data.rows[i];

            // Convert all currency columns
             for (let j = 0; j < currencyColumns.length; ++j) {
                let col = currencyColumns[j];
                 let value = row[col.index];

                if (value != null) {
                    row[col.index] = {
                        JPY: value,
                        USD: value * row[convUSD.index],
                        EUR: value * row[convEUR.index],
                    };
                }
                else {
                    row[col.index] = {
                        JPY: null,
                        USD: null,
                        EUR: null,
                    }
                }
          }

        }
    }

    _postProcessFuelPriceForecast(data) {
        // Extend data with a method to get currency columns
        data.getCurrencyColumns = function() {
            return Object.values(this.metadata.columns).filter(c => c.tag != null && c.tag.isCurrencyField === true)
        };

        // Convert result fields
        DataFormatting.convertResultFields(data);

        // Get currency columns
        let currencyColumns = data.getCurrencyColumns();

        // Parse column captions
        currencyColumns.forEach(c => c.caption = JSON.parse(c.caption));

        // Convert currencies
        let usdIndex = data.metadata.columns.conv_usd.index;
        let eurIndex = data.metadata.columns.conv_eur.index;
        for (let i = 0; i < data.rows.length; ++i) { 
            let row = data.rows[i];

            // Convert all currency columns
            for (let j = 0; j < currencyColumns.length; ++j) {
                let col = currencyColumns[j];
                let value = row[col.index];

                if (value != null) {
                    row[col.index] = {
                        JPY: value,
                        USD: value * row[usdIndex],
                        EUR: value * row[eurIndex],
                    };
                }
                else {
                    row[col.index] = {
                        JPY: null,
                        USD: null,
                        EUR: null,
                    }
                }
            }
        }
    }
}
