import React, { Component } from 'react'
import moment from 'moment'

// App
import GlobalSessionState from 'GlobalSessionState'
import CSVDownloadItem from 'CSVDownloadItem';
import SessionState from 'SessionState'
import Locale from '../locale/Locale'
import BusyState from '../BusyState'
import ErrorState from 'ErrorState'
import Utils from 'Utils'

// Data
import VintagesDataFactory from 'common/VintagesDataFactory';
import PriceForecastDataFactory from './PriceForecastDataFactory'
import FuelPriceForecastStats from './FuelPriceForecastStats';

// Components
import VerticalTabNav from 'common/VerticalTabNav'
import { MarketChart, MarketFilters, FuelChart, FuelFilters } from './components'
import DateRangePicker from 'common/DateRangePicker'
import TabPanel from '../common/TabPanel'
import VisualBox from '../common/VisualBox'
import PinnableSettings from '../common/PinnableSettings'
import { Tab, Tabs } from '@material-ui/core/'
import TooltipMui from '@material-ui/core/Tooltip'
import ShowChartIcon from '@material-ui/icons/ShowChart';
import TimelineIcon from '@material-ui/icons/Timeline'

import PriceForecastComparisonTypes from './PriceForecastComparisonTypes';

// Styles
import './PriceForecast.scss'

const t = Locale.getResourceString.bind(Locale)

class PriceForecast extends Component {
    static get ViewKey() {
        return 'price-forecast'
    }

    static get Downloads() {
        return [
            new CSVDownloadItem('priceForecast.download.marketClearingPricesMonthly', '/price-forecast/ref-case-monthly/download', 'ref-case-monthly.csv'),
            new CSVDownloadItem('priceForecast.download.marketClearingPricesAnnual', '/price-forecast/ref-case-annual/download', 'ref-case-annual.csv'),
            new CSVDownloadItem('priceForecast.fuelChart.caption', '/price-forecast/fuel-price/download', 'fuel-price.csv'),
        ]
    };

    static get _SessionStateKey() {
        return `emi-japan.price-forecast.PriceForecast`;
    }

    constructor(props, context) {
        super(props)

        this._vintages = null;
        this.__rawDataMonthly = [];
        this.__rawDataAnnual = [];
        this._dataFactory = new PriceForecastDataFactory()
        this._territories = null
        this._annuallyData = null
        this._monthlyData = null
        this._fuelPriceForecast = null;
        this._now = DateRangePicker.getDate(moment())
        this._chartKey = `marketClearing` // Manage this outside of state to avoid timing issues
        this._availableDataRange = null
        this._ismounted = false

        // Configure initial state
        this._defaultInitialState = {
            //general components
            sessionStateChartKey: this._chartKey,
            isNavExpanded: true,
            // default chart values
            peakTypesDefault: ['avg_val', 'off_peak_val', 'on_peak_val'],
            peakTypes: [],
            // compare by dropdown
            compareBy: 2,
            // Region flow
            territoryIds: [],
            multiSelectDropdownSelected:[],
            // date range / picker
            min: this._now,
            max: this._now,
            start: this._now,
            end: this._now,
            startCommitted: this._now,
            endCommitted: this._now,
            selectedYears: [],
            allYears: [],
            // granularity
            granularity: 'M',
            // months buttons
            selectedMonths: [...Array(12).keys()].map(k => k + 1),
            // USCAPEO
            // fuel price forecast
            selectedFuels: [],
            allFuels: [],
            selectedScenarioIdFuel: 1, // Baseline
            selectedScenarioIdsMCPs: [1], // Baseline
            aggregation: ['min', 'max', 'average', 'sum', 'stdev', 'count'],
            aggregationSelected: 'sum'
        }
        this._stateOverrides = {
            filterPanelOpen: false,
            filterPanelIsPinned: false,
            territoryIds: SessionState.get(`${PriceForecast.ViewKey}.line-chart`).territoryIds === undefined 
                ? GlobalSessionState.get().priceForecastElectricServiceTerritoryId 
                : SessionState.get(`${PriceForecast.ViewKey}.line-chart`).territoryIds,
        }
        this.state = SessionState.get(PriceForecast._SessionStateKey, this._defaultInitialState, this._stateOverrides)
        
        // Set peak types to an appropriate value based on the compare-by setting that was restored from session state
        this.state.peakTypes = this._defaultInitialState.compareBy === PriceForecastComparisonTypes.Region ? 
            ['avg_val'] : 
            this._defaultInitialState.peakTypesDefault;
        
        // moment objects come back out of state as strings - convert them back to moments
        this.state.min = moment(this.state.min);
        this.state.max = moment(this.state.max);
        this.state.start = moment(this.state.start);
        this.state.end = moment(this.state.end);

        // Fuel price forecast stats and filters
        this._fuelPriceForecastStats = new FuelPriceForecastStats();
        this._previousMarketFiltersJSON = '';
        this._previousFuelPriceForecastFiltersJSON = '';

        // Restore saved chartKey
        this._chartKey = this.state.sessionStateChartKey

        // Bind event handlers
        this._onPin = this._onPin.bind(this)
        this._onTooltipFormatLabel = this._onTooltipFormatLabel.bind(this)
        this._onFormatValue = this._onFormatValue.bind(this)
        this._oni18nChanged = this._oni18nChanged.bind(this)
        this._onChangeSlider = this._onChangeSlider.bind(this)
    }

    // Global functions
    static ViewDetails(priceForecastDetails) {
        // Patch up session state
        SessionState.set(
            PriceForecast._SessionStateKey,
            SessionState.get(PriceForecast._SessionStateKey, {}, { sessionStateChartKey: 'marketClearing' })
        );

        SessionState.set(`${PriceForecast.ViewKey}.line-chart`, {territoryIds: [priceForecastDetails.entityId]});
        GlobalSessionState.set({
            priceForecastChartKey: 'marketClearing',
        });
    }

    get _chartKey() {
        return GlobalSessionState.get().priceForecastChartKey;
    }
    set _chartKey(value) {
        GlobalSessionState.set({ 
            priceForecastChartKey: value,
        });
    }

    _onTooltipFormatLabel(value) {
        return (<span>{value}</span>)
    }
    _onFormatValue(value, name, props) {
        return (this.props.aggregation === 'stdev') ? Locale.formatNumber(value, 2) :
            (this.props.aggregation === 'count') ? Locale.formatNumber(value, 0) :
            (props != null && props.dataKey != null && this._isVolumeSeries(props.dataKey)) ? Locale.formatNumber(value, 0) :
            Locale.formatCurrency(value)
    }
    _isVolumeSeries(s) {
        const volumeSeries = {
            index_volume: true,
            intraday_volume: true,
        }

        return volumeSeries[s] === true
    }
    _onPin(args) {
        this.setState({ filterPanelIsPinned: args.isPinned })
        if (this.props.onPin != null) {
            this.props.onPin(args)
        }
    }
    _oni18nChanged() {
        this.forceUpdate()
    }
    _onChangeSlider(event, value) {
        this.setState({
            sliderValue: value,
        })
    }

    // Component Lifecyclye
    componentDidMount() {
        // Listen for locale and currency-changed events.  We need to recalculate stats
        // and update because we don't recalculate stats on every render.
        Locale.addLocaleHandler('PriceForecast', this._oni18nChanged)
        Locale.addCurrencyHandler('PriceForecast', this._oni18nChanged)

        BusyState.isBusy = true

        // Get data
        VintagesDataFactory
            .get()
            .then(vintages => {
                this._vintages = vintages;
                return this._dataFactory.get();
            })
            .then((data) => {
                this._territories = data.Entities
                this._annuallyData = this._dataBuilder(data.RefCaseAnnual)
                this._monthlyData = this._dataBuilder(data.RefCaseMonthly)
                this._fuelPriceForecast = data.FuelPriceForecast;

                // If we have no selected fuels, set selected fuels to all fuels
                // USCAPEO
                if (this.state.selectedFuels == null || this.state.selectedFuels.length === 0) {
                    let selectedFuels = this._fuelPriceForecast.getCurrencyColumns().map(c => c.name)
                    this.setState({
                        selectedFuels,
                        allFuels: selectedFuels
                    })
                }

                this.handleTerritories(PriceForecast.territoryIds())

                this._ismounted = true
            })
            .catch((error) => {
                ErrorState.setFault(error.message)
            })
            .finally(() => {
                BusyState.isBusy = false
            })
    }
    componentDidUpdate() {
        // Persist current state
        SessionState.set(PriceForecast._SessionStateKey, this.state)

    }
    componentWillUnmount() {
        // Clean up handlers
        Locale.removeCurrencyHandler('PriceForecast')
        Locale.removeLocaleHandler('PriceForecast')
        this._ismounted = false
    }
    componentDidCatch(error, info) {
        ErrorState.setFault(error)
        ErrorState.setFault(info)
    }

    // Data Transformations
    _dataBuilder = (collection) => {
        let result = []
        for (let i = 0; i < collection.rows.length; ++i) {
            let row = collection.rows[i]

            let newRow = Object.values(collection.metadata.columns).reduce((obj, col) => {
                obj[col.name] = row[col.index]
                return obj
            }, {})

            result.push(newRow)
        }
        return result
    }

    get _rawDataMonthly() {
        return this.__rawDataMonthly;
    }

    _updateRawDataMonthly() {
        const makeMoment = (year, month) => { return moment.utc([year, month - 1, 1]); }

        let selectedYears = this.state.selectedYears.reduce((obj, y) => { obj[y] = true; return obj; }, {});
        let selectedMonths = this.state.selectedMonths.reduce((obj, m) => { obj[m] = true; return obj; }, {});
        let source = this._monthlyData;

        // Reset previous data
        this.__rawDataMonthly.length = 0;

        // Build data rows and index on date
        let momentLookup = {};
        for (let i = 0; i < source.length; ++i) {
            let row = source[i];
            let theMoment = makeMoment(row.year, row.month);
            let start = makeMoment(this.state.start.year(), this.state.start.month() + 1);
            let end = makeMoment(this.state.end.year(), this.state.end.month() + 1);

            // Check against time filters
            if (selectedYears.hasOwnProperty(theMoment.year()) &&
                selectedMonths.hasOwnProperty(theMoment.month() + 1) &&
                theMoment.isSameOrAfter(start) && theMoment.isSameOrBefore(end)) {
                // Check if we already know about this timestamp
                if (momentLookup[theMoment] == null) {
                    let newRow = { 
                        moment: theMoment
                    };
                    momentLookup[theMoment] = newRow;
                    this.__rawDataMonthly.push(newRow);
                }
            }
        }

        // Order by year/month
        this.__rawDataMonthly.sort((a, b) => a.moment.valueOf() - b.moment.valueOf());

        // Fill in values
        for (let i = 0; i < source.length; ++i) {
            let row = source[i];
            let theMoment = makeMoment(row.year, row.month);
            if (momentLookup.hasOwnProperty(theMoment)) {
                let newRow = momentLookup[theMoment];
                let scenarioId = row.scenario_id;

                newRow.category = Locale.formatMomentGranularity(theMoment, this.state.granularity);
                newRow.year = row.year;
                newRow.month = row.month;
                newRow[`avg_val-${row.srvc_territory_id}-${scenarioId}`] = Locale.getJSONCurrencyValue(row.avg_val);
                newRow[`on_peak_val-${row.srvc_territory_id}-${scenarioId}`] = Locale.getJSONCurrencyValue(row.on_peak_val);
                newRow[`off_peak_val-${row.srvc_territory_id}-${scenarioId}`] = Locale.getJSONCurrencyValue(row.off_peak_val);
            }
        }
    }

    get _rawDataAnnual() {
        return this.__rawDataAnnual;
    }

    _updateRawDataAnnual() {
        const makeMoment = (year) => { return moment.utc([year, 0, 1]); }

        let selectedYears = this.state.selectedYears.reduce((obj, y) => { obj[y] = true; return obj; }, {});
        let source = this._annuallyData;

        // Reset previous data
        this.__rawDataAnnual.length = 0;

        // Build data rows and index on date
        let momentLookup = {};
        for (let i = 0; i < source.length; ++i) {
            let row = source[i];
            let theMoment = makeMoment(row.year_nbr);
            let start = makeMoment(this.state.start.year());
            let end = makeMoment(this.state.end.year());

            // Check against time filters
            if (selectedYears.hasOwnProperty(theMoment.year()) &&
                theMoment.isSameOrAfter(start) && theMoment.isSameOrBefore(end)) {
                // Check if we already know about this timestamp
                if (momentLookup[theMoment] == null) {
                    let newRow = { 
                        moment: theMoment
                    };
                    momentLookup[theMoment] = newRow;
                    this.__rawDataAnnual.push(newRow);
                }
            }
        }

        // Order by year/month
        this.__rawDataAnnual.sort((a, b) => a.moment.valueOf() - b.moment.valueOf());

        // Fill in values
        for (let i = 0; i < source.length; ++i) {
            let row = source[i];
            let theMoment = makeMoment(row.year_nbr);
            if (momentLookup.hasOwnProperty(theMoment)) {
                let newRow = momentLookup[theMoment];
                let scenarioId = row.scenario_id;

                newRow.category = Locale.formatMomentGranularity(theMoment, this.state.granularity);
                newRow.year = row.year_nbr;
                newRow[`avg_val-${row.srvc_territory_id}-${scenarioId}`] = Locale.getJSONCurrencyValue(row.avg_val);
                newRow[`on_peak_val-${row.srvc_territory_id}-${scenarioId}`] = Locale.getJSONCurrencyValue(row.on_peak_val);
                newRow[`off_peak_val-${row.srvc_territory_id}-${scenarioId}`] = Locale.getJSONCurrencyValue(row.off_peak_val);
            }
        }
    }

    get _fuelPriceForecastFilters() {
        return {
            localeName: Locale.localeName,
            currencyName: Locale.currencyName,
            selectedScenarioId: this.state.selectedScenarioIdFuel,
            start: this.state.startCommitted,
            end: this.state.endCommitted,
            selectedMonths: this.state.selectedMonths,
            selectedYears: this.state.selectedYears,
            granularity: this.state.granularity,
            aggregation: this.state.aggregationSelected
        };
    }

    get _marketClearingFilters() {
        return {
            localeName: Locale.localeName,
            currencyName: Locale.currencyName,
            selectedScenarioIds: this.state.selectedScenarioIdsMCPs,
            start: this.state.startCommitted,
            end: this.state.endCommitted,
            selectedMonths: this.state.selectedMonths,
            selectedYears: this.state.selectedYears,
            granularity: this.state.granularity,
            aggregation: this.state.aggregationSelected
        };
    }

    ////////////////////////////////////////////////////////////////////////////
    // Transform our vintages into an array of dropdown options for the filters
    ////////////////////////////////////////////////////////////////////////////
    get _scenarioOptions() {
        return this._vintages.map(v => {
            return {
                label: Locale.getJSONFieldValue(v.ScenarioName),
                value: v.ScenarioId
            };
        });
    }

    _calculateStatsAndRender(start, end) {
        this.setState({
            startCommitted: start,
            endCommitted: end
        })
        this.adjustMonthYearsSelected(start, end)
        this.forceUpdate();
    }

    // Session Storage
    static territoryIds() {
        return SessionState.get(`${PriceForecast.ViewKey}.line-chart`).territoryIds === undefined 
                ? GlobalSessionState.get().priceForecastElectricServiceTerritoryId 
                : SessionState.get(`${PriceForecast.ViewKey}.line-chart`).territoryIds
    }
    static updateTerritoryIds = (id) => {
        SessionState.set(`${PriceForecast.ViewKey}.line-chart`, {territoryIds: id})
    }

    // Main UI
    //// Set the caption in the VisualBox component
    get _marketChartCaption() {
        let peakTypeNameLookup = {
            avg_val: t('priceForecast.panel.peakType.average'),
            off_peak_val: t('priceForecast.panel.peakType.offPeak'),
            on_peak_val: t('priceForecast.panel.peakType.onPeak'),
        };

        let vintageNameLookup = this._vintages.reduce((obj, v) => {
            obj[v.ScenarioId] = Locale.getJSONFieldValue(v.ScenarioName);
            return obj;
        }, {});

        switch (this.state.compareBy) {
            case PriceForecastComparisonTypes.Region: 
                return `${t('priceForecast.panel.peakType.caption')} : ${peakTypeNameLookup[this.state.peakTypes[0]]}, ` + 
                    `${t('priceForecast.panel.scenario.caption')} : ${vintageNameLookup[this.state.selectedScenarioIdsMCPs[0]]}`;
            case PriceForecastComparisonTypes.PeakType: 
                return `${t('spatialAwareness.layers.utilityServiceTerritories.popup.title')} : ${Utils.getShortServiceTerritoryName(Locale.getJSONFieldValue(this._territories[parseInt(this.state.territoryIds[0], 10)]))}, ` + 
                    `${t('priceForecast.panel.scenario.caption')} : ${vintageNameLookup[this.state.selectedScenarioIdsMCPs[0]]}`;
            case PriceForecastComparisonTypes.Scenario: 
                return `${t('spatialAwareness.layers.utilityServiceTerritories.popup.title')} : ${Utils.getShortServiceTerritoryName(Locale.getJSONFieldValue(this._territories[parseInt(this.state.territoryIds[0], 10)]))}, ` + 
                    `${t('priceForecast.panel.peakType.caption')} : ${peakTypeNameLookup[this.state.peakTypes[0]]}`;
            default:
                return '';
        }
    }

    //// filters
    renderFilters() {
        let renderers = {
            marketClearing: () => {
                return (
                    <MarketFilters
                        territoryIds={this.state.territoryIds}
                        handleTerritories={this.handleTerritories}
                        peakTypeDropdown={this.peakTypeDropdown}
                        peakTypeSelected={this.state.peakTypes}

                        // Props related to scenario selection
                        scenarioOptions={this._scenarioOptions}
                        selectedScenarioIds={this.state.selectedScenarioIdsMCPs}
                        onChangeSelectedScenarioIds={ids => this.setState({ selectedScenarioIdsMCPs: ids })}

                        minDateRange={this.state.min}
                        maxDateRange={this.state.max}
                        startDateRange={this.state.start}
                        endDateRange={this.state.end}
                        onChangeDateRange={this.onChangeDateRange}
                        onChangingDateRange={(start, end) => this.setState({ start, end })}
                        handlePanel={() => this.setState({ filterPanelOpen: !this.state.filterPanelOpen})}
                        granularity={this.state.granularity}
                        handleGranularity={this.handleGranularity}
                        handleCompareBy={this.handleCompareBy}
                        compareBy={this.state.compareBy}
                        multiSelectDropdownOnChange={(options) => this.multiSelectDropdownOnChange(options)}
                        multiSelectDropdownSelected={this.state.multiSelectDropdownSelected}
                        territories={this._territories}
                        selectedMonths={this.state.selectedMonths}
                        onChangeSelectedMonths={(value) => this.onChangeSelectedMonths(value)}
                        onChangeSelectedYears={(value) => this.onChangeSelectedYears(value)}
                        allYears={this.state.allYears}
                        selectedYears={this.state.selectedYears}
                        aggregation={this.state.aggregation}
                        aggregationSelected={this.state.aggregationSelected}
                        handleAggregation={(selected) => this.setState({aggregationSelected: selected})}
                    />
                );
            },

            fuelPrice: () => {
                return (
                    <FuelFilters
                        fuelOptions= {this._fuelOptions}
                        selectedFuels={this.state.selectedFuels}
                        allFuels={this.state.allFuels}
                        fuelMultiSelectDropdownOnChange={(options) => this.fuelMultiSelectDropdownOnChange(options)}

                        // Props related to scenario selection
                        scenarioOptions={this._scenarioOptions}
                        selectedScenarioId={this.state.selectedScenarioIdFuel}
                        onChangeSelectedScenarioId={id => this.setState({ selectedScenarioIdFuel: id })}

                        minDateRange={this.state.min}
                        maxDateRange={this.state.max}
                        startDateRange={this.state.start}
                        endDateRange={this.state.end}
                        onChangeDateRange={(start, end) => this._calculateStatsAndRender(start, end)}
                        onChangingDateRange={(start, end) => this.setState({ start, end })}
                        handlePanel={() => this.setState({ filterPanelOpen: !this.state.filterPanelOpen})}
                        granularity={this.state.granularity}
                        handleGranularity={this.handleGranularity}
                        handleCompareBy={this.handleCompareBy}
                        selectedMonths={this.state.selectedMonths}
                        onChangeSelectedMonths={(value) => this.onChangeSelectedMonths(value)}
                        onChangeSelectedYears={(value) => this.onChangeSelectedYears(value)}
                        allYears={this.state.allYears}
                        selectedYears={this.state.selectedYears}
                        aggregation={this.state.aggregation}
                        aggregationSelected={this.state.aggregationSelected}
                        handleAggregation={(selected) => this.setState({aggregationSelected: selected})}
                    />
                );
            }
        };

        return renderers[this._chartKey]();
    }
    //// Nav Tabs
    _renderTabs() {
        return (
            <nav>
                <Tabs
                    value={this._chartKey}
                    onChange={(e, newValue) => {
                        this._chartKey = newValue;
                        this.setState({ sessionStateChartKey: newValue });
                    }}
                    orientation="vertical"
                >
                    <Tab
                        value="marketClearing"
                        icon={
                            <TooltipMui title={t('priceForecast.marketChart.caption')} placement="right">
                                <TimelineIcon fontSize="default" />
                            </TooltipMui>
                        } 
                        label={this.state.isNavExpanded && t('priceForecast.marketChart.caption')}
                    />

                    <Tab
                        value="fuelPrice"
                        icon={
                            <TooltipMui title={t('priceForecast.fuelChart.caption')} placement="right">
                                <ShowChartIcon />
                            </TooltipMui>
                        }
                        label={this.state.isNavExpanded && t('priceForecast.fuelChart.caption')}
                    />
                </Tabs>
            </nav>
        )
    }

    //// Panels
    _renderTabPanels() {
        return (
            <article>
                <TabPanel isRenderedWhenHidden={false} valuekey="marketClearing" value={this._chartKey}>
                    <h1>{this._marketChartCaption}</h1>
                    <MarketChart
                        rawDataMonthly={this._rawDataMonthly}
                        rawDataAnnually={this._rawDataAnnual}
                        marketClearingFilters={this._marketClearingFilters}
                        territoryIds={PriceForecast.territoryIds()}
                        granularity={this.state.granularity}
                        peakTypes={this.state.peakTypes}
                        vintages={this._vintages}
                        selectedScenarioIds={this.state.selectedScenarioIdsMCPs}
                        territories={this._territories}
                        onTooltipFormatLabel={this._onTooltipFormatLabel}
                        onFormatValue={this._onFormatValue}
                        handlePanel={() => this.setState({ filterPanelOpen: !this.state.filterPanelOpen})}
                        compareBy={this.state.compareBy}
                        filterPanelIsPinned={this.state.filterPanelIsPinned}
                    />
                </TabPanel>
                <TabPanel isRenderedWhenHidden={false} valuekey="fuelPrice" value={this._chartKey}>
                    <h1>{t('priceForecast.fuelChart.caption')}</h1>
                    <FuelChart
                        data={this._fuelPriceForecast}
                        chartData={this._fuelPriceForecastStats.aggregated}
                        selectedFuels={this.state.selectedFuels}
                        onTooltipFormatLabel={this._onTooltipFormatLabel}
                        onFormatValue={this._onFormatValue}
                        handlePanel={() => this.setState({ filterPanelOpen: !this.state.filterPanelOpen})}
                        filterPanelIsPinned={this.state.filterPanelIsPinned}
                    />
                </TabPanel>
            </article>
        )
    }

    //// First dropdown
    handleCompareBy = (selected) => {
        let territoryId = PriceForecast.territoryIds();
        let peakTypes = this.state.peakTypesDefault;
        let selectedScenarioIdsMCPs = this._vintages.map(v => v.ScenarioId);

        if (selected !== PriceForecastComparisonTypes.PeakType) {
            peakTypes = this.state.peakTypes.length === 3 ? ['avg_val'] : [this.state.peakTypes[0]]
        }

        if (selected !== PriceForecastComparisonTypes.Region) {
            territoryId = [territoryId[0]]
            this.handleTerritories(territoryId)
        }

        if (selected !== PriceForecastComparisonTypes.Scenario) {
            selectedScenarioIdsMCPs = (this.state.selectedScenarioIdsMCPs.length > 0) ? 
                [this.state.selectedScenarioIdsMCPs[0]] :
                [this._vintages[0].ScenarioId];
        }

        this.setState({ 
            compareBy: selected, 
            territoryIds: territoryId,
            multiSelectDropdownSelected: territoryId,
            peakTypes,
            selectedScenarioIdsMCPs,
        });

        this.forceUpdate()
    }
    // flow region -> 1
    //// multi-dropdown
    multiSelectDropdownOnChange = (options) => {
        let parsedOptions = []
        options.map(id => {
            return parsedOptions.push(parseInt(id))
        })
        
        this.setState({
            multiSelectDropdownSelected: options,
            territoryIds: parsedOptions
        })

        PriceForecast.updateTerritoryIds(parsedOptions)
    }
    // flow region -> 2
    //// dropdown
    handleTerritories = (id) => {
        PriceForecast.updateTerritoryIds(id)

        let minMax = []
        let selectedYears = []
        let start = undefined
        let end = undefined
        let min = undefined
        let max = undefined
        let data = []
        let dataFiltered = []

        if (this.state.granularity === 'M') {
            data = this._monthlyData
            dataFiltered = data.filter((f) => f.srvc_territory_id === id[0])
            min = dataFiltered.reduce((min, p) => (p.year < min ? p.year : min), data[0].year)
            max = dataFiltered.reduce((min, p) => (p.year > min ? p.year : min), data[0].year)
            
        } else {
            data = this._annuallyData
            dataFiltered = data.filter((f) => f.srvc_territory_id === id[0])
            min = dataFiltered.reduce((min, p) => (p.year_nbr < min ? p.year_nbr : min), data[0].year_nbr)
            max = dataFiltered.reduce((min, p) => (p.year_nbr > min ? p.year_nbr : min), data[0].year_nbr)
        }

        start = min
        end = max

        minMax.push(min)
        minMax.push(max)

        while (start <= end) {
            selectedYears.push(start)
            start++
        }

        this.setState({
            selectedYears,
            allYears: selectedYears,
            territoryIds: id,
            multiSelectDropdownSelected: id
         })
        this.setDateRange(minMax)
    }
    // fuel
    //// multi select dropdown
    fuelMultiSelectDropdownOnChange = (options) => {
        this.setState({
            selectedFuels: options
        })
    }
    // shared
    //// dropdown peak type
    peakTypeDropdown = (selected) => {
        selected === 'all'
        ? this.setState({peakTypes: this.state.peakTypesDefault}) 
        : this.setState({peakTypes: [selected]})
    }
    //// dropdown granularity
    handleGranularity = (value) => {
        this.setState({ granularity: value })
    }
    // months buttons group
    onChangeSelectedMonths = (value) => {
        this.setState({selectedMonths: value})
    }
    // years buttons groups
    onChangeSelectedYears = (value) => {
        this.setState({selectedYears: value})
    }
    // date picker
    setDateRange = (minMax) => {
        let min = moment.utc([minMax[0], 0, 1]);
        let max = moment.utc([minMax[1], 11, 31]);

        this.setState({
            min,
            max,
            start: min,
            end: max,
            startCommitted: min,
            endCommitted: max
        })
    }
    onChangeDateRange = (start, end) => {
        this.setState({ 
            start, 
            end
        })

        this.adjustMonthYearsSelected(start, end)
    }

    adjustMonthYearsSelected = (start, end) => {
        let selectedYears = []
        let startYear = start.year()
        let endYear = end.year()

        let newYears = (s,e) => {
            let y = []
            while (s <= e) {
                y.push(s)
                s++
            }
            return y
        }

        selectedYears = newYears(startYear, endYear) 

        this.setState({selectedYears})
    }

    get _fuelOptions() {
        let options = [];
        if (this._fuelPriceForecast != null) {
            options = this._fuelPriceForecast.getCurrencyColumns().map(c => { return {
                value: c.name,
                label: Locale.getJSONFieldValue(c.caption)
            }});
        }
        return options;
    }


    render() {
        if (this._ismounted && this._fuelPriceForecast != null) {
            let fuelFilters = this._fuelPriceForecastFilters;
            let fuelFiltersJSON = JSON.stringify(fuelFilters);
            if (fuelFiltersJSON !== this._previousFuelPriceForecastFiltersJSON) {
                this._previousFuelPriceForecastFiltersJSON = fuelFiltersJSON;
                this._fuelPriceForecastStats.calculate(this._fuelPriceForecast, fuelFilters);
            }

            let marketFilters = this._marketClearingFilters;
            let marketFiltersJSON = JSON.stringify(marketFilters);
            if (marketFiltersJSON !== this._previousMarketFiltersJSON) {
                this._previousMarketFiltersJSON = marketFiltersJSON;
                this._updateRawDataMonthly();
                this._updateRawDataAnnual();
            }
        }

        return (
            <>
                {this._territories !== null 
                    ? (
                        <div className="price-forecast">
                            <VisualBox caption={t('priceForecast.caption')} heightClass='price-forecast-visual-box-content'>
                                <PinnableSettings
                                    open={this.state.filterPanelOpen}
                                    title={t('priceForecast.panel.caption')}
                                    viewKey={PriceForecast.ViewKey}
                                    contentClassName="jemi-filters-container price-forecast-container"
                                    variant={'temporary'}
                                    onClose={() => this.setState({ filterPanelOpen: false })}
                                    onPin={(args) => this._onPin(args)}
                                >
                                    {this.renderFilters()}
                                </PinnableSettings>

                                <VerticalTabNav 
                                    isExpanded={this.state.isNavExpanded}
                                    expandedWidth={150}
                                    tabs={this._renderTabs()}
                                    tabPanels={this._renderTabPanels()}
                                    onChangeIsExpanded={(isExpanded) => this.setState({ isNavExpanded: isExpanded })}
                                />
    
                                <div className="chart-source-annotation">{Locale.formatVintage(this._vintages)}</div>
                            </VisualBox>
                        </div>
                    ) : <></>
                }
            </>
        )
    }
}

export default PriceForecast
