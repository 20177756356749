import React from 'react';
import { Popup, ToggleButton, ToggleButtonGroup } from 'webcore-ux/react/components';
import CaretDownArrow from 'webcore-ux/react/components/Icons/CaretDownArrow';
import CheckMark from 'webcore-ux/react/components/Icons/CheckMark';
import DownloadIcon from 'webcore-ux/react/components/Icons/Download';
import LanguageIcon from '@material-ui/icons/Language';
import SettingsIcon from '@material-ui/icons/Settings';

import Authentication from 'Authentication';
import Locale from 'locale/Locale';
import { None } from 'icons/Icons';

import './App.scss';
import TooltipMui from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const t = Locale.getResourceString.bind(Locale);

const Tooltip = withStyles(() => ({
    tooltip: {
        padding: '10px 15px',
        backgroundColor: '#3366ff',
        textAlign: 'center',
        fontSize: 13,
    },
}))(TooltipMui)

////////////////////////////////////////////////////////////////////////////////
// This component renders the top-level menu for wider display resolutions
////////////////////////////////////////////////////////////////////////////////
class AppMenuWide extends React.Component {
    constructor(props) {
        super(props);

        this.identityPopupAnchorElement = null;
        this.downloadPopupAnchorElement = null;
        this.settingsPopupAnchorElement = null;
        this.languagePopupAnchorElement = null;

        this.state = {
            isIdentityPopupOpen: false,
            isDownloadPopupOpen: false,
            isSettingsPopupOpen: false,
            isLanguagePopupOpen: false,
        };
    }

    render() {
        let userName =
            this.props.idTokenClaims != null && this.props.idTokenClaims.preferred_username != null
                ? this.props.idTokenClaims.preferred_username
                : t('header.anonymous');

        let accountMenuItem = Authentication.isSignedIn ? (
            <div className="app-menu-item" onClick={this.props.onSignOut}>
                {t('header.signOut')}
            </div>
        ) : (
            <div className="app-menu-item" onClick={this.props.onSignIn}>
                {t('header.signIn')}
            </div>
        );

        return (
            <div className="app-top-controls">
                {/* Identity popup "menu" */}
                <div
                    className="app-header-menu"
                    onClick={(event) => {
                        this.identityPopupAnchorElement = event.currentTarget;
                        this.setState({
                            isIdentityPopupOpen: !this.state.isIdentityPopupOpen,
                        });
                    }}
                >
                    <CaretDownArrow />
                    <span>{userName}</span>
                </div>
                <Popup
                    anchorEl={this.identityPopupAnchorElement}
                    isOpen={this.state.isIdentityPopupOpen}
                    placement="top-end"
                    onOutsideClick={() => this.setState({ isIdentityPopupOpen: false })}
                >
                    <div className="app-header-menu-popup-content">{accountMenuItem}</div>
                </Popup>
                
                {/* Download "menu" */}
                {
                    !Authentication.isPurchased
                    ? (
                        <Tooltip  
                            title={t('header.downloadsTooltip')} 
                            placement="bottom"
                        >
                            <div
                                className="app-header-menu"
                                disabled={!Authentication.isPurchased}
                                onClick={(event) => {
                                    this.downloadPopupAnchorElement = event.currentTarget;
                                    this.setState({
                                        isDownloadPopupOpen: !this.state.isDownloadPopupOpen,
                                    });
                                }}
                            >
                                <CaretDownArrow />
                                <DownloadIcon fontSize="small" className="app-language-icon" />
                                
                            </div>
                        </Tooltip>
                    ) : (
                        <div
                            className="app-header-menu"
                            disabled={!Authentication.isPurchased}
                            onClick={(event) => {
                                this.downloadPopupAnchorElement = event.currentTarget;
                                this.setState({
                                    isDownloadPopupOpen: !this.state.isDownloadPopupOpen,
                                });
                            }}
                        >
                            <CaretDownArrow />
                            <DownloadIcon fontSize="small" className="app-language-icon" />
                            
                        </div>
                    )
                }

                <Popup
                    anchorEl={this.downloadPopupAnchorElement}
                    isOpen={this.state.isDownloadPopupOpen}
                    placement="top-end"
                    onOutsideClick={() => this.setState({ isDownloadPopupOpen: false })}
                >
                    <div className="app-header-menu-popup-content">
                        {
                            this.props.downloads.map(download => { return (
                                <div 
                                    key={download.route}
                                    className="app-menu-item"
                                    disabled={!Authentication.isPurchased}
                                    onClick={() => { 
                                        if(Authentication.isPurchased) {
                                            download.download(); 
                                        }
                                        this.setState({ isDownloadPopupOpen: false });
                                    }}
                                >
                                    <span>{t(download.captionKey)} </span>
                                </div>
                                )
                            })
                        }
                    </div>
                </Popup>
                
                {/* Settings popup "menu" */}
                <div
                    className="app-header-menu"
                    onClick={(event) => {
                        this.settingsPopupAnchorElement = event.currentTarget;
                        this.setState({
                            isSettingsPopupOpen: !this.state.isSettingsPopupOpen,
                        });
                    }}
                >
                    <CaretDownArrow />
                    <SettingsIcon fontSize="small" className="app-settings-icon" />
                </div>
                <Popup
                    anchorEl={this.settingsPopupAnchorElement}
                    isOpen={this.state.isSettingsPopupOpen}
                    placement="top-end"
                    onOutsideClick={() => this.setState({ isSettingsPopupOpen: false })}
                >
                    <div className="app-header-menu-toggle-content">
                        <span>{t('header.currency')}</span><br />
                        <ToggleButtonGroup value={this.props.currencyName} onChange={(e, value) => this.props.onChangeCurrency(value)}>
                            <ToggleButton value="USD">$</ToggleButton>
                            <ToggleButton value="JPY">&yen;</ToggleButton>
                            <ToggleButton value="EUR">&euro;</ToggleButton>
                        </ToggleButtonGroup>
                        <br />
                        <br />
                        <span>{t('header.temperature')}</span><br />
                        <ToggleButtonGroup value={this.props.temperatureUnits} onChange={(e, value) => this.props.onChangeTemperatureUnits(value)}>
                            <ToggleButton value="celsius">{t('units.celsius')}</ToggleButton>
                            <ToggleButton value="fahrenheit">{t('units.fahrenheit')}</ToggleButton>
                        </ToggleButtonGroup>
                        <br />
                        <br />
                        <span>{t('header.speed')}</span><br />
                        <ToggleButtonGroup value={this.props.speedUnits} onChange={(e, value) => this.props.onChangeSpeedUnits(value)}>
                            <ToggleButton value="metersPerSecond">{t('units.metersPerSecond')}</ToggleButton>
                            <ToggleButton value="milesPerHour">{t('units.milesPerHour')}</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </Popup>

                {/* Language popup "menu" */}
                <div
                    className="app-header-menu"
                    onClick={(event) => {
                        this.languagePopupAnchorElement = event.currentTarget;
                        this.setState({
                            isLanguagePopupOpen: !this.state.isLanguagePopupOpen,
                        });
                    }}
                >
                    <CaretDownArrow />
                    <LanguageIcon fontSize="small" className="app-language-icon" />
                </div>
                <Popup
                    anchorEl={this.languagePopupAnchorElement}
                    isOpen={this.state.isLanguagePopupOpen}
                    placement="top-end"
                    onOutsideClick={() => this.setState({ isLanguagePopupOpen: false })}
                >
                    <div className="app-header-menu-popup-content">
                        <div className="app-menu-item" onClick={() => this.props.onChangeLocale('locale-en')}>
                            {this.props.localeName === 'en' ? <CheckMark /> : <None />}
                            <span>{t('header.english')}</span>
                        </div>
                        <div className="app-menu-item" onClick={() => this.props.onChangeLocale('locale-ja')}>
                            {this.props.localeName === 'ja' ? <CheckMark /> : <None />}
                            <span>{t('header.japanese')}</span>
                        </div>
                    </div>
                </Popup>
            </div>
        );
    }
}

export default AppMenuWide;
