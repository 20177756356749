import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import Locale from '../locale/Locale';

import './DatePicker.scss';

////////////////////////////////////////////////////////////////////////////////
// JEMIDatePicker is a thin wrapper around the DatePicker component in
// @material-ui/pickers.
// 
// Some WebCore-ish styling is applied, etc.
////////////////////////////////////////////////////////////////////////////////
class JEMIDatePicker extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {
        };

        this._onChange = this._onChange.bind(this);
    }

    get _className() {
        const className = 'de-date-picker';
        return (this.props.className != null) ?
            className + ' ' + this.props.className :
            className;
    }

    _onChange(value) {
        if (this.props.onChange != null) {
            this.props.onChange(value);
        }
    }

    render() {

        return (
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={Locale.localeName}>
                <DatePicker
                    className={this._className}
                    PopoverProps={{ className: "de-date-picker-popover" }}
                    autoOk={true}
                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                    value={this.props.value}
                    variant="inline"
                    labelFunc={(moment) => Locale.formatDate(moment) }
                    onChange={moment => this._onChange(moment)}
                />
            </MuiPickersUtilsProvider>
        );
    }

}

JEMIDatePicker.propTypes = {
    // Earliest date that can be selected
    minDate: PropTypes.instanceOf(moment),
    // Latest date that can be selected
    maxDate: PropTypes.instanceOf(moment),
    // Currently-selected date
    value: PropTypes.instanceOf(moment),
}

JEMIDatePicker.defaultProps = {
    minDate: moment(new Date("1900-01-01")),
    maxDate: moment(new Date("2100-01-01")),
    value: null,
}

export default JEMIDatePicker;