import { Icon, Style } from 'ol/style';
import React from 'react';
import AppConfig from '../../../AppConfig';
import Locale from '../../../locale/Locale';
import Animation from './Animation';
import AnimationControls from './AnimationControls';

const t = Locale.getResourceString.bind(Locale);

export default class WindSpeedAndDirectionAnimation extends Animation {
    ////
    // Object creation
    ////
    constructor(layer) {
        super(layer);

        this._dateTimeIndex = null;
    }

    ////
    // Public interface
    ////

    // Gets a string key that identifies this animation in collections, localization resource, etc.
    get key() {
        return 'windSpeedAndDirection';
    }

    // Renders this theme.  By default, no rendering is needed.  But,
    // for animations, animation controls may be desired, etc.
    render() {
        // Don't render any content if the theme is not active and loaded
        if (!this.isActive || !this.isLoaded) {
            return null;
        }

        return (
            <AnimationControls
                ref={this._animationControlsRef}
                data={this._apiData.rows}
                formatFrameTip={(value) => this._formatFrameTip(value)}
                onChange={(value) => this._onChangeFrameIndex(value)}
            />
        );
    }

    // Gets the style for the current feature as styled by this theme
    getStyle(feature, resolution) {
        // Default to transparent if we have no data
        let color = 'transparent';
        let scale = 1.0;
        let rotation = 0;

        if (this.isLoaded) {
            let entityId = feature.getProperties().entityId;
            if (this._apiData.metadata.columns.hasOwnProperty(`${entityId}`)) {
                let row = this._apiData.rows[this._frameIndex];
                let speed = row[this._apiData.metadata.columns[`${entityId}`].index];
                let direction = row[this._apiData.metadata.columns[`dir_${entityId}`].index];

                if (speed != null && direction != null) {
                    color = this._arrowColor;
                    scale = this._getScale(speed);
                    rotation = direction;
                }
            }
        }

        return new Style({
            image: new Icon({
                opacity: 1,
                src: 'data:image/svg+xml;utf8,' + this._getArrowSVG(color),
                scale: scale,
                rotation: rotation * (Math.PI / 180),
            })
        });
    }

    // Renders legend content for this layer
    renderLegendContent() {
        return (
            <div key={this.key + '-legend-content'} className="ev-legend-layer-content">
                <span className="ev-legend-layer-name">{t('spatialAwareness.layers.weatherStations.title')}</span><br />

                <div>
                    <span className="ev-legend-symbol wind-arrow-0">{this._getArrow(32 * this._getScale(0))}</span>
                    <span className="ev-legend-theme-value wind-value-0" title={t('spatialAwareness.animations.windSpeedAndDirection.legendItem0')}>{Locale.formatSpeed(Locale.convertSpeed(0, 'metersPerSecond'), 0)}</span>
                    <br />
                </div>

                <div>
                    <span className="ev-legend-symbol wind-arrow-10">{this._getArrow(32 * this._getScale(10))}</span>
                    <span className="ev-legend-theme-value wind-value-10" title={t('spatialAwareness.animations.windSpeedAndDirection.legendItem10')}>{Locale.formatSpeed(Locale.convertSpeed(10, 'metersPerSecond'), 0)}</span>
                    <br />
                </div>

                <div>
                    <span className="ev-legend-symbol wind-arrow-20">{this._getArrow(32 * this._getScale(20))}</span>
                    <span className="ev-legend-theme-value wind-value-20" title={t('spatialAwareness.animations.windSpeedAndDirection.legendItem20')}>{Locale.formatSpeed(Locale.convertSpeed(20, 'metersPerSecond'), 0)}</span>
                    <br />
                </div>
            </div>
        );
    }



    ////
    // "Protected" interface
    ////

    _createTheme() {
        this._dateTimeIndex = this._apiData.metadata.columns.datetime.index;
    }

    // Gets the URL from which API data is obtained
    _getUrl(view) {
        return AppConfig.dataService.baseUrl + '/spatial-awareness/weather-stations/animation-wind';
    }

    _formatFrameTip(value) {
        return Locale.formatDateTime(this._apiData.rows[value][this._dateTimeIndex]);
    }

    ////
    // "Private" interface
    ////
    get _arrowColor() {
        return 'rgba(51, 102, 255, 1)';
    }

    _getScale(speed) {
        // Normal speed ranges from 0 to about 20.  We want the amount added to scale to range from 0 to 0.67.
        return Math.min(1.0, (0.33 + (speed / 30)));
    }

    _getArrow(size) {
        return (
            <svg width={size} height={size} x="12px" y="12px" viewBox="0 0 24 24">
                <g fill="#3366ff" transform='rotate(0 12, 12)' >
                    <polygon points="12,1 5,23 12,15 19,23"/>
                </g>
            </svg>
        );
    }

    _getArrowSVG(color) {
        return `<svg width="32" height="32" x="12px" y="12px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill="${color}" transform='rotate(0 12, 12)' >
                <polygon points="12,1 5,23 12,15 19,23"/>
            </g>
        </svg>`;
    }
}