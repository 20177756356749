import React from 'react'

import Locale from '../../locale/Locale'
import Utils from 'Utils'

import MonthPicker from 'common/MonthPicker'
import YearPicker from 'common/YearPicker'
import MultiSelectDropdown from 'common/MultiSelectDropdown'
import { Dropdown } from 'webcore-ux/react/components'
import DateRangePicker from 'common/DateRangePicker'
import VisualBox from '../../common/VisualBox'

import SelectAllIcon from '@material-ui/icons/SelectAll'
import ClearAllIcon from '@material-ui/icons/ClearAll'

import PriceForecastComparisonTypes from '../PriceForecastComparisonTypes';

const t = Locale.getResourceString.bind(Locale)

export const MarketFilters = (props) => {
    // Dropdowns
    //// Compare By dropdown
        const mainOpts = [
            {
                label: t('priceForecast.panel.compareBy.region'),
                value: 1,
            },
            {
                label: t('priceForecast.panel.compareBy.peakType'),
                value: 2,
            },
            {
                label: t('priceForecast.panel.compareBy.scenario'),
                value: 3,
            },
        ]
    //// Territories dropdown
        let multiSelectDropdown = []
        let multiSelectDropdownSelected = []
        let multiSelectDropdownAllOptns = []

        for (const [key, value] of Object.entries(props.territories)) {
            multiSelectDropdown.push({
                value: key, 
                label: Utils.getShortServiceTerritoryName(Locale.getJSONFieldValue(value))
            })
            multiSelectDropdownAllOptns.push(key.toString())
        }

        if (props.compareBy === PriceForecastComparisonTypes.Region) {
            props.multiSelectDropdownSelected.map(item => { return multiSelectDropdownSelected.push(item.toString())})
        }
    //// Granularity dropdown
        const granularityOpts = [
            {
                label: t('priceForecast.panel.granularity.M'),
                value: 'M',
            },
            {
                label: t('priceForecast.panel.granularity.A'),
                value: 'A',
            },
            {
                label: t('priceForecast.panel.granularity.MoY'),
                value: 'MoY'
            }
        ]
    //// Peak type dropdown
        const peakTypeOpts = [
            {
                label: t('priceForecast.panel.peakType.average'),
                value: 'avg_val',
            },
            {
                label: t('priceForecast.panel.peakType.offPeak'),
                value: 'off_peak_val',
            },
            {
                label: t('priceForecast.panel.peakType.onPeak'),
                value: 'on_peak_val',
            },
        ]

        if (props.compareBy === PriceForecastComparisonTypes.PeakType) {
            peakTypeOpts.push({
                label: t('priceForecast.panel.peakType.all'),
                value: 'all',
            })
        }
    //// Aggregation
    const aggregationOptions = [
        {
            label: t('priceForecast.panel.aggregation.min'),
            value: 'min'
        },
        {
            label: t('priceForecast.panel.aggregation.max'),
            value: 'max'
        },
        {
            label: t('priceForecast.panel.aggregation.average'),
            value: 'average'
        },
        {
            label: t('priceForecast.panel.aggregation.sum'),
            value: 'sum'
        },
        {   
            label: t('priceForecast.panel.aggregation.stdev'),
            value: 'stdev' 
        },
        { 
            label: t('priceForecast.panel.aggregation.count'),
            value: 'count' 
        }
    ]
        
    // Date Picker / slider
    // main dropdown
    let handleCompareBy = (selected) => {
        props.handleCompareBy(selected.value)
    }
    // Region Flow
        let multiSelectDropdownOnChange = (selected) => {
            props.multiSelectDropdownOnChange(selected)
        }
        let multiSelectDropdownALL = (value) => {
            return value === 'all'
                ? props.multiSelectDropdownOnChange(multiSelectDropdownAllOptns)
                : props.multiSelectDropdownOnChange([])
        }
    // Peak type flow
        let handleTerritories = (selected) => {
            props.handleTerritories([parseInt(selected.value)])
        }
        let handleGranularity = (selected) => {
            props.handleGranularity(selected.value)
        }
        let handleAggregation = (selected) => {
            props.handleAggregation(selected.value)
        }
        let onChangeSelectedMonths = (value) => {
            props.onChangeSelectedMonths(value)
        }
        let onChangeSelectedYears = (value) => {
            props.onChangeSelectedYears(value)
        }
    // Scenario flow

    // shared components
        let peakTypeDropdown = (selected) => {
            props.peakTypeDropdown(selected)
        }

    return (
        <>
        {/* Main Dropdown */}
            <VisualBox caption={t('priceForecast.panel.compareBy.caption')} title={t('priceForecast.panel.compareBy.caption')}>
                <Dropdown
                    id="compare-by"
                    options={mainOpts}
                    isClearable={false}
                    value={props.compareBy}
                    placeholder={t('general.select')}
                    onChange={(selected) => handleCompareBy(selected)}
                />
            </VisualBox>

        {/* Region Flow -> multiselect dropdown */}
        {props.compareBy === PriceForecastComparisonTypes.Region
            ? (
                <VisualBox className="chart-series-box" caption={t('priceForecast.panel.chartSeries.caption')} title={t('priceForecast.panel.chartSeries.caption')}>
                    <div className="button-group-filter">
                        <div 
                            className="select-all" 
                            title={t('general.selectAll')} 
                            onClick={() => multiSelectDropdownALL('all')}
                        >
                            <SelectAllIcon />
                        </div>
                        <div 
                            className="select-none" 
                            title={t('general.clearAll')} 
                            onClick={() => multiSelectDropdownALL('none')}
                        >
                            <ClearAllIcon />
                        </div>
                        <MultiSelectDropdown
                            multipleSelectionsPlaceholder={t('general.multipleSelectedValues')}
                            placeholder={t('general.select')}
                            options={multiSelectDropdown}
                            value={multiSelectDropdownSelected}
                            onChange={(selected) => multiSelectDropdownOnChange(selected)}
                        />
                    </div>
                </VisualBox>
            ) : <></>
        }

        {/* Peak Type Flow -> dropdown only one territory at the time */}
        {props.compareBy === PriceForecastComparisonTypes.PeakType || props.compareBy === PriceForecastComparisonTypes.Scenario
            ? (
                <VisualBox caption={t('priceForecast.panel.chartSeries.caption')} title={t('priceForecast.panel.chartSeries.caption')}>
                    <Dropdown
                        id="territoriesDropdown"
                        options={multiSelectDropdown}
                        isClearable={false}
                        value={props.territoryIds[0].toString()}
                        onChange={(selected) => handleTerritories(selected)}
                    />
                </VisualBox>
            ) : <></>
        }

        {/* Dropdown to select the peak type.  Whether or not an "all" option is included is handled above. */}
        {true
            ? (
                <VisualBox caption={t('priceForecast.panel.peakType.caption')} title={t('priceForecast.panel.peakType.caption')}>
                    <Dropdown
                        id="peakTypeOpts"
                        options={peakTypeOpts}
                        placeholder={t('general.select')}
                        value={props.peakTypeSelected.length === 3 && props.compareBy === PriceForecastComparisonTypes.PeakType ? 'all' : props.peakTypeSelected[0]}
                        isClearable={false}
                        onChange={(selected) => peakTypeDropdown(selected.value)}
                    />
                </VisualBox>
            ) : <></>
        }

        {/* Dropdown to select the scenario */}
        {(props.compareBy === PriceForecastComparisonTypes.Scenario)
            ? (
                <VisualBox caption={t('priceForecast.panel.scenario.caption')} title={t('priceForecast.panel.scenario.caption')}>
                    <div className="button-group-filter">
                        <div 
                            className="select-all" 
                            title={t('general.selectAll')} 
                            onClick={() => props.onChangeSelectedScenarioIds && props.onChangeSelectedScenarioIds(props.scenarioOptions.map(s => parseInt(s.value, 10)))}
                        >
                            <SelectAllIcon />
                        </div>
                        <div 
                            className="select-none" 
                            title={t('general.clearAll')} 
                            onClick={() => props.onChangeSelectedScenarioIds && props.onChangeSelectedScenarioIds([])}
                        >
                            <ClearAllIcon />
                        </div>
                        <MultiSelectDropdown
                            multipleSelectionsPlaceholder={t('general.multipleSelectedValues')}
                            placeholder={t('general.select')}
                            options={props.scenarioOptions ? props.scenarioOptions.map(s => { return { label: s.label, value: s.value.toString() }; }) : []}
                            value={props.selectedScenarioIds.map(id => id.toString())}
                            onChange={(value) => props.onChangeSelectedScenarioIds && props.onChangeSelectedScenarioIds(value.map(id => parseInt(id, 10)))}
                        />
                    </div>
                </VisualBox>
            ) : 
            (
                <VisualBox caption={t('priceForecast.panel.scenario.caption')} title={t('priceForecast.panel.scenario.caption')}>
                    <Dropdown
                        id="scenarioOptions"
                        options={props.scenarioOptions ?? []}
                        value={props.selectedScenarioIds[0]}
                        isClearable={false}
                        onChange={e => props.onChangeSelectedScenarioIds && props.onChangeSelectedScenarioIds([e.value])}
                    />
                </VisualBox>
            ) 
        }

        {/* Date Piker */}
        {true
            ? (
                <VisualBox caption={t('priceForecast.panel.datePicker.caption')} title={t('priceForecast.panel.datePicker.caption')}>
                    <DateRangePicker
                        minDate={props.minDateRange}
                        maxDate={props.maxDateRange}
                        start={props.startDateRange}
                        end={props.endDateRange}
                        onChanging={(start, end) => props.onChangingDateRange(start, end)}
                        onChange={(start, end) => props.onChangeDateRange(start, end)}
                    />
                </VisualBox>
            ) : <></>
        }

        {/* Granularity dropdown -> month / year */}
        {true
            ? (
                <>
                    <VisualBox caption={t('priceForecast.panel.view.caption')} title={t('priceForecast.panel.view.caption')}>
                        <Dropdown
                            label={t('priceForecast.panel.granularity.caption')}
                            id="granularity"
                            options={granularityOpts}
                            value={props.granularity}
                            placeholder={t('general.select')}
                            isClearable={false}
                            onChange={(selected) => handleGranularity(selected)}
                        />
                        { 
                            props.granularity === 'MoY'
                            ? (
                                <Dropdown
                                    label={t('priceForecast.panel.aggregation.caption')}
                                    id="aggregationDropdown"
                                    options={aggregationOptions}
                                    value={props.aggregationSelected}
                                    placeholder={t('general.select')}
                                    isClearable={false}
                                    onChange={(selected) => handleAggregation(selected)}
                                />
                            )
                            : <></>
                        }
                    </VisualBox>

                    {/* Month options */}
                    { props.granularity === 'M' || props.granularity === 'MoY'
                        ? (
                            <VisualBox caption={t('priceForecast.panel.month.caption')} title={t('priceForecast.panel.month.caption')}>
                                <div className="button-group-filter">
                                    <div
                                        className="select-all"
                                        title={t('general.selectAll')}
                                        onClick={() => onChangeSelectedMonths([...Array(12).keys()].map(k => k + 1))}
                                    >
                                        <SelectAllIcon />
                                    </div>
                                    <div
                                        className="select-none"
                                        title={t('general.clearAll')}
                                        onClick={() => onChangeSelectedMonths([])}
                                    >
                                        <ClearAllIcon />
                                    </div>
                                    <MonthPicker
                                        value={props.selectedMonths}
                                        onChange={(value) => { onChangeSelectedMonths(value) }}
                                    />
                                </div>
                            </VisualBox>
                        ) : <></>
                    }

                    {/* Year options */}
                    <VisualBox caption={t('priceForecast.panel.year.caption')} title={t('priceForecast.panel.year.caption')}>
                        <div className="button-group-filter">
                            <div
                                className="select-all"
                                title={t('general.selectAll')}
                                onClick={() => onChangeSelectedYears(props.allYears)}
                            >
                                <SelectAllIcon />
                            </div>
                            <div className="select-none" title={t('general.clearAll')} onClick={() => onChangeSelectedYears([])}>
                                <ClearAllIcon />
                            </div>
                            <YearPicker
                                allValues={props.allYears}
                                value={props.selectedYears}
                                onChange={(value) => {onChangeSelectedYears(value)}}
                            />
                        </div>
                    </VisualBox>
                </>
            ) : <></>
        }

        </>
    )
}
