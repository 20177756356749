import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TimelineIcon from '@material-ui/icons/Timeline';
import BarChartIcon from '@material-ui/icons/BarChart';
import { ResponsiveContainer, ComposedChart, Line, Bar, XAxis, YAxis, Legend, Tooltip, Label } from 'recharts';
import { ToggleButton, ToggleButtonGroup } from 'webcore-ux/react/components';

import Locale from 'locale/Locale';
import ErrorBoundary from 'ErrorBoundary';
import Palettes from 'common/Palettes';
import SessionState from 'SessionState';

import 'common/ReCharts.scss';
import './LNGMarketChart.scss';

const t = Locale.getResourceString.bind(Locale);

/////////////////////////////////////////////////////////////////////////////
// This class encapsulates the LNG-market prices chart
/////////////////////////////////////////////////////////////////////////////
class LNGMarketChart extends Component {
    constructor(props, context) {
        super(props);

        // Configure initial state
        this._sessionStateKey = 'emi-japan.price-history.LNGMarketChart';
        this._defaultInitialState = {
            seriesType: 'bar',
        }
        this._stateOverrides = {
        }
        this.state = SessionState.get(this._sessionStateKey, this._defaultInitialState, this._stateOverrides);

        // Bind event handlers
        this._onTooltipFormatLabel = this._onTooltipFormatLabel.bind(this);
        this._onFormatValue = this._onFormatValue.bind(this);
    }

    get _series() {
        const palette = Palettes.DefaultColors;

        const seriesDataKeys = [
            'contract_based',
            'arrival_based',
        ];

        let series = [];

        if (this.props.data != null) {
            series = (this.state.seriesType === 'line') ?
                seriesDataKeys.map((s, index) => {
                    return (
                        <Line
                            key={s} 
                            dataKey={s} 
                            dot={false}
                            isAnimationActive={false}
                            name={Locale.getJSONFieldValue(this.props.data.metadata.columns[s].caption)} 
                            stroke={palette[index % palette.length]} 
                            yAxisId={0}
                        />
                    );
                }) :
                seriesDataKeys.map((s, index) => {
                    return (
                        <Bar
                            key={s} 
                            dataKey={s} 
                            isAnimationActive={false}
                            name={Locale.getJSONFieldValue(this.props.data.metadata.columns[s].caption)} 
                            fill={palette[index % palette.length]} 
                            yAxisId={0}
                        />
                    );
                });
        }

        return series;
    }

    get _yAxisLabelText() {
        let units = (this.props.aggregation === 'stdev' || this.props.aggregation === 'count') ? 
            '' : 
            ' ' + Locale.getCurrencySymbol() + '/' + t('units.mmBtu');

        return t('priceHistory.charts.lngMarket.axisLabels.price.' + this.props.aggregation) + units;
    }



    _onTooltipFormatLabel(value) {
        return (<span>{value}</span>);
    }

    _onFormatValue(value, name, props) {
        return (this.props.aggregation === 'stdev') ? Locale.formatNumber(value, 2) :
            (this.props.aggregation === 'count') ? Locale.formatNumber(value, 0) :
            Locale.formatCurrency(value);
    }



    componentDidUpdate() {
        // Persist current state
        SessionState.set(this._sessionStateKey, this.state);
    }

    render() {
        return (
            <ErrorBoundary>
                <div className="lng-market-options">
                    <ToggleButtonGroup value={this.state.seriesType} onChange={(e, value) => { value && this.setState({ seriesType: value }) }}>
                        <ToggleButton size="small" value="line" title={t('general.lineChart')}><TimelineIcon /></ToggleButton>
                        <ToggleButton size="small" value="bar" title={t('general.barChart')}><BarChartIcon /></ToggleButton>
                    </ToggleButtonGroup>
                </div>

                <ResponsiveContainer>
                    <ComposedChart
                        data={this.props.aggregated}
                        margin={{
                            top: 60, right: 20, bottom: 10, left: 10,
                        }}
                    >
                        <XAxis 
                            dataKey="category" 
                        />
                        <YAxis 
                            tickFormatter={ (v) => this._onFormatValue(v) }
                            width={90}
                            yAxisId={0}>
                            <Label 
                                angle={-90} 
                                position='insideLeft'
                                value={this._yAxisLabelText}
                                style={{textAnchor: 'middle'}}
                            />
                        </YAxis>
                        <Tooltip 
                            isAnimationActive={false} 
                            labelFormatter={this._onTooltipFormatLabel}
                            formatter={this._onFormatValue} 
                        />
                        <Legend />

                        {this._series}
                    </ComposedChart>
                </ResponsiveContainer>
            </ErrorBoundary>
        );
    }
}

LNGMarketChart.propTypes = {
    aggregation: PropTypes.string,
    aggregated: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.object,
}

LNGMarketChart.defaultProps = {
    aggregation: 'average',
    aggregated: [],
}

export default LNGMarketChart;
