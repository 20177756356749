import React from 'react';

import SelectAllIcon from '@material-ui/icons/SelectAll';
import ClearAllIcon from '@material-ui/icons/ClearAll';

import { Dropdown } from 'webcore-ux/react/components';

import ErrorBoundary from 'ErrorBoundary';
import Locale from 'locale/Locale';

import DateRangePicker from 'common/DateRangePicker';
import MonthPicker from 'common/MonthPicker';
import YearPicker from 'common/YearPicker';
import VisualBox from 'common/VisualBox';

import './PriceHistory.scss';

const t = Locale.getResourceString.bind(Locale);

////////////////////////////////////////////////////////////////////////////////
// These are the filters to be shown for the LNG market chart
////////////////////////////////////////////////////////////////////////////////
class LNGMarketFilters extends React.Component {
    constructor(props, context) {
        super(props);
    }

    render() {
        const granularities = [
            { value: 'A', },
            { value: 'M', },
            { value: 'MoY', },
        ];

        const aggregations = [
            { value: 'min', },
            { value: 'max', },
            { value: 'average', },
            { value: 'sum', },
            { value: 'stdev', },
            { value: 'count', }
        ];

        return (
            <ErrorBoundary>
                <div>
                    <VisualBox caption={t('priceHistory.filters.view')}>
                        <Dropdown
                            label={t('priceHistory.filters.granularity.caption')}
                            options={granularities.map(g => Object.assign(g, { label: t('priceHistory.filters.granularity.' + g.value) }))}
                            isClearable={false}
                            value={this.props.granularity}
                            onChange={obj => this.props.onChangeGranularity(obj.value)}
                        />
                        <Dropdown
                            label={t('priceHistory.filters.aggregation.caption')}
                            options={aggregations.map(a => Object.assign(a, { label: t('priceHistory.filters.aggregation.' + a.value) }))}
                            isClearable={false}
                            value={this.props.aggregation}
                            onChange={obj => this.props.onChangeAggregation(obj.value)}
                        />
                    </VisualBox>

                    <VisualBox caption={t('priceHistory.filters.dateRange')}>
                        <DateRangePicker
                            minDate={this.props.minDate}
                            maxDate={this.props.maxDate}
                            start={this.props.start}
                            end={this.props.end}
                            onChanging={(start, end) => this.props.onChangingDateRange(start, end)}
                            onChange={(start, end) => this.props.onChangeDateRange(start, end)}
                        />
                    </VisualBox>

                    <VisualBox caption={t('priceHistory.filters.month')}>
                        <div className="button-group-filter">
                            <div 
                                className="select-all" 
                                title={t('general.selectAll')} 
                                onClick={() => this.props.onChangeSelectedMonths([...Array(12).keys()].map(k => k + 1))}>
                                <SelectAllIcon />
                            </div>
                            <div 
                                className="select-none" 
                                title={t('general.clearAll')} 
                                onClick={() => this.props.onChangeSelectedMonths([])}>
                                <ClearAllIcon />
                            </div>
                            <MonthPicker
                                value={this.props.selectedMonths}
                                onChange={(value) => { this.props.onChangeSelectedMonths(value) }}
                            />
                        </div>
                    </VisualBox>

                    <VisualBox caption={t('priceHistory.filters.year')}>
                        <div className="button-group-filter">
                            <div 
                                className="select-all" 
                                title={t('general.selectAll')} 
                                onClick={() => this.props.onChangeSelectedYears(this.props.allYears)}>
                                <SelectAllIcon />
                            </div>
                            <div 
                                className="select-none" 
                                title={t('general.clearAll')} 
                                onClick={() => this.props.onChangeSelectedYears([])}>
                                <ClearAllIcon />
                            </div>
                            <YearPicker
                                allValues={this.props.allYears}
                                value={this.props.selectedYears}
                                onChange={(value) => { this.props.onChangeSelectedYears(value) }}
                            />
                        </div>
                    </VisualBox>
                </div>
            </ErrorBoundary>
        );
    }
}

export default LNGMarketFilters;
