import React from 'react';
import { PieChart, Pie, Tooltip } from 'recharts';
import Palettes from 'common/Palettes';

import ErrorBoundary from 'ErrorBoundary';
import Locale from 'locale/Locale';
import CommonStyles from 'common/Common.scss'

const t = Locale.getResourceString.bind(Locale);

class FuelAndStatusChart extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {
        }
    }

    get _legendContent() {
        // Create legend items for all fuels used at the plant
        let fuelLegendItems = Object.keys(this.props.fuels).map((fuel) => {
            return (
                <React.Fragment key={fuel}>
                    <span className="ev-legend-symbol" style={{ color: Palettes.FuelColors.getColor(fuel) }}>
                        &#9632;
                    </span>
                    <span className="ev-legend-theme-value">{this.props.fuels[fuel]}</span>
                </React.Fragment>
            );
        });

        // Create legend items for all status values at the plant
        let statusLegendItems = Object.keys(this.props.stati).map((status) => {
            return (
                <React.Fragment key={status}>
                    <span className="ev-legend-symbol" style={{ color: Palettes.OperatingStatusGrayscales.getColor(status) }}>
                        &#9632;
                    </span>
                    <span className="ev-legend-theme-value">{this.props.stati[status]}</span>
                </React.Fragment>
            );
        });

        return (
            <div className="power-plant-chart-legend-container">
                <div className="power-plant-chart-legend">
                    {fuelLegendItems}
                    {statusLegendItems}
                </div>
            </div>
        );
    }

    render() {
        const chartWidth = parseInt(CommonStyles.featurePopupContentWidth);
        const chartHeight = 180;
        const maxRadius = 85;
        const ringWidth = 17;

        if (this.props.units == null || this.props.units.length === 0) {
            return (
                <>
                    <span className="feature-popup-property-value">{t('general.noDataAvailable')}</span>
                    <br />
                </>
            );
        }
        else {
            let data01 = this.props.units.map((u, index) => { 
                let key = u.id + '|' + u.fuelKey + '|' + index;

                return {
                    key: key,
                    id: u.id,
                    name: Locale.getJSONFieldValue(u.name),
                    fuel: Locale.getJSONFieldValue(u.fuel),
                    primeMover: Locale.getJSONFieldValue(u.primeMover),
                    capacity: u.capacity,
                    status: Locale.getJSONFieldValue(u.statusFull),
                    fill: Palettes.FuelColors.getColor(u.fuelKey),
                };
            });

            let data02 = this.props.units.map((u, index) => { 
                let key = u.id + '|' + u.statusKey + '|' + index;

                return {
                    key: key,
                    id: u.id,
                    name: Locale.getJSONFieldValue(u.name),
                    fuel: Locale.getJSONFieldValue(u.fuel),
                    primeMover: Locale.getJSONFieldValue(u.primeMover),
                    capacity: u.capacity,
                    status: Locale.getJSONFieldValue(u.statusFull),
                    fill: Palettes.OperatingStatusGrayscales.getColor(u.statusKey),
                };
            });
            
            return(
                <ErrorBoundary>
                    <br />
                    <PieChart width={chartWidth} height={chartHeight}>
                        <Pie 
                            isAnimationActive={false}
                            data={data01} 
                            dataKey="capacity" 
                            cx={chartWidth / 2} 
                            cy={chartHeight / 2} 
                            innerRadius={maxRadius - 2 * ringWidth} 
                            outerRadius={maxRadius - ringWidth} 
                        />

                        <Pie 
                            isAnimationActive={false}
                            data={data02} 
                            dataKey="capacity" 
                            cx={chartWidth / 2} 
                            cy={chartHeight / 2} 
                            innerRadius={maxRadius - ringWidth} 
                            outerRadius={maxRadius} 
                            labelLine={false}
                            label={(content) => {
                                if (content != null && content.payload != null) {
                                    return (
                                        <span>{content.payload.capacity}&nbsp;{t('units.megaWatts')}</span>
                                    );
                                }
                            }} 
                        />
                        <Tooltip 
                            isAnimationActive={false}
                            content={(content) => {
                                if (content != null && 
                                    content.active &&
                                    content.payload != null && 
                                    content.payload.length > 0) {
                                    return (
                                        <div className="fuel-and-status-chart-tooltip-container">
                                            <span>
                                                <span className="tree-title-field-name">
                                                    {t('spatialAwareness.layers.powerPlants.popup.unitName')}
                                                </span>
                                                &nbsp;{content.payload[0].payload.name}
                                            </span>
                                            <br />
                                            <span>
                                                <span className="tree-title-field-name">
                                                    {t('spatialAwareness.layers.powerPlants.popup.status')}
                                                </span>
                                                &nbsp;{content.payload[0].payload.status}
                                            </span>
                                            <br />
                                            <span>
                                                <span className="tree-title-field-name">
                                                    {t('spatialAwareness.layers.powerPlants.popup.primeMover')}
                                                </span>
                                                &nbsp;{content.payload[0].payload.primeMover}
                                            </span>
                                            <br />
                                            <span>
                                                <span className="tree-title-field-name">
                                                    {t('spatialAwareness.layers.powerPlants.popup.primaryFuel')}
                                                </span>
                                                &nbsp;{content.payload[0].payload.fuel}
                                            </span>
                                            <br />
                                            <span>
                                                <span className="tree-title-field-name">
                                                    {t('spatialAwareness.layers.powerPlants.popup.capacity')}
                                                </span>
                                                &nbsp;{content.payload[0].payload.capacity}&nbsp;{t('units.megaWatts')}
                                            </span>
                                            <br />
                                        </div>
                                    );
                                }
                                else {
                                    return null;
                                }
                            }}
                        />
                    </PieChart>

                    {this._legendContent}
                </ErrorBoundary>
            );
        }
    }
}

export default FuelAndStatusChart;
