import React from 'react';
import { withRouter } from "react-router";
import AppConfig from 'AppConfig';
import AppTrace from 'AppTrace';
import Authentication from 'Authentication';
import DataFormatting from 'DataFormatting';
import ErrorBoundary from 'ErrorBoundary';
import Locale from 'locale/Locale';
import Palettes from 'common/Palettes';
import PopupDataCache from '../PopupDataCache';
import PopupLoadIndicator from '../PopupLoadIndicator';
import PopoutAreaChart from 'charts/PopoutAreaChart';
import PopoutLineChart from 'charts/PopoutLineChart';
import Weather from 'weather/Weather';

const t = Locale.getResourceString.bind(Locale);
const axios = require('axios');

class WeatherStationsPopup extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {
        }

        this._onViewDetailsTemperature = this._onViewDetailsTemperature.bind(this);
        this._onViewDetailsWind = this._onViewDetailsWind.bind(this);
        this._onViewDetailsSunshineDuration = this._onViewDetailsSunshineDuration.bind(this);
        this._onViewDetails = this._onViewDetails.bind(this);
    }

    get _url() {
        let entityId = this.props.feature.getProperties().entityId;
        return `${AppConfig.dataService.baseUrl}/spatial-awareness/weather-stations/${entityId}/popup`;
    }

    _getData() {
        return new Promise((resolve, reject) => {
            let url = this._url;

            // Check the cache for this popup data
            if (PopupDataCache.contains(url)) {
                // It's there so resolve with the cached data
                resolve(PopupDataCache.get(url));
            }
            else {
                // Otherwise get access token
                Authentication.getAccessToken()
                    .then(accessToken => {
                        // Then use the access token to get data for popup
                        let authString = 'Bearer '.concat(accessToken);
                        return axios.get(url, {
                            headers: {
                                Authorization: authString,
                                Accept: "application/json",
                                "cache-control": "no-cache",
                            }
                        })
                     })
                    .then(response => {
                        // Get result data
                        let apiData = response.data;

                        // Convert result fields based on type
                        DataFormatting.convertResultFields(apiData);

                        // Set a delete function to clean up the data
                        apiData.delete = function () {
                            this.rows.length = 0;
                        };

                        // Now use the data to render the popup
                        resolve(apiData);
                    })
                    .catch((error) => {
                        AppTrace.traceError('Error in WeatherStationsPopup._getData: ' + error.message);
                        reject(new Error("Error getting popup data"));
                    });
            }
        });
    }

    _getDataAndRender() {
        this._getData()
            .then(apiData => {
                // Cache the API data
                PopupDataCache.add(this._url, apiData);

                // Force an update
                this.forceUpdate();
            })
            .catch(error => {
                // Don't hammer the server with a URL that is failing
                PopupDataCache.add(this._url, { error: error });
            });
    }

    _onViewDetailsTemperature() {
        this._onViewDetails('temperature');
    }

    _onViewDetailsWind() {
        this._onViewDetails('wind');
    }

    _onViewDetailsSunshineDuration() {
        this._onViewDetails('sunshineDuration');
    }

    _onViewDetails(chartKey) {
        Weather.ViewDetails(this.props.feature.getProperties(), chartKey);
        this.props.history.push({ pathname: '/' + Weather.ViewKey });
        AppTrace.traceInfo(Weather.ViewKey, AppTrace.categories.navigate);
    }



    render() {
        // Build dynamic content based on availability of data
        let dynamicContent = null;

        // If we have already queried the data for the current entity, render the dynamic content
        if (PopupDataCache.contains(this._url)) {
            let apiData = PopupDataCache.get(this._url);

            dynamicContent = (
                <div key={this._url}>
                    <span className="feature-popup-title">{t('weather.temperature.caption')}</span>
                    <span>&nbsp;(</span><button className="de-link-button" onClick={this._onViewDetailsTemperature}>{t('navigation.viewDetails')}</button><span>)</span>
                    <br />
                    <PopoutLineChart
                        data={apiData}

                        palette={['#eb0023']} // Hitachi Energy red color

                        argumentColumn={apiData.metadata.columns.datetime}
                        valueColumns={[
                            apiData.metadata.columns.temperature_val,
                        ]}

                        getSeriesName={(column) => t('weather.temperature.caption')}

                        getArgumentTitle={() => t('weather.localDateTime')}
                        getArgument={(a) => a}
                        getValue={(v) => Locale.convertTemperature(v, 'celsius')}

                        formatArgument={(a) => Locale.formatDateTime(a)}
                        formatAxisValue={(v) => Locale.formatNumber(v, 0)}
                        formatTooltipValue={(v) => Locale.formatTemperature(v, 0)}
                    />

                    <br />

                    <span className="feature-popup-title">{t('weather.windSpeed.caption')}</span>
                    <span>&nbsp;(</span><button className="de-link-button" onClick={this._onViewDetailsWind}>{t('navigation.viewDetails')}</button><span>)</span>
                    <br />
                    <PopoutLineChart
                        data={apiData}

                        argumentColumn={apiData.metadata.columns.datetime}
                        valueColumns={[
                            apiData.metadata.columns.wind_speed_val,
                        ]}

                        getSeriesName={(column) => t('weather.windSpeed.caption')}

                        getArgumentTitle={() => t('weather.localDateTime')}
                        getArgument={(a) => a}
                        getValue={(v) => Locale.convertSpeed(v, 'metersPerSecond')}

                        formatArgument={(a) => Locale.formatDateTime(a)}
                        formatAxisValue={(v) => Locale.formatNumber(v, 0)}
                        formatTooltipValue={(v) => Locale.formatSpeed(v, 0)}
                    />

                    <br />

                    <span className="feature-popup-title">{t('weather.sunshineDuration.caption')}</span>
                    <span>&nbsp;(</span><button className="de-link-button" onClick={this._onViewDetailsSunshineDuration}>{t('navigation.viewDetails')}</button><span>)</span>
                    <br />
                    <PopoutAreaChart
                        data={apiData}

                        palette={[Palettes.FuelColors.solar]}

                        argumentColumn={apiData.metadata.columns.datetime}
                        valueColumns={[
                            apiData.metadata.columns.sunshine_duration_val,
                        ]}

                        getSeriesName={(column) => t('weather.sunshineDuration.caption')}

                        getArgumentTitle={() => t('weather.localDateTime')}
                        getArgument={(a) => a}
                        getValue={(v) => v}

                        formatArgument={(a) => Locale.formatDateTime(a)}
                        formatAxisValue={(v) => Locale.formatPercent(v, 0)}
                        formatTooltipValue={(v) => Locale.formatPercent(v, 0)}
                    />
                </div>
            );
        }
        else {
            // Otherwise, show a load indicator and query for the current data
            dynamicContent = (<PopupLoadIndicator />);
            this._getDataAndRender();
        }

        return (
            <ErrorBoundary>
                <span className="feature-popup-title">{t('spatialAwareness.layers.weatherStations.popup.title')}</span><br />
                <br />

                <span className="feature-popup-property-name" style={{ width: 58 }}>{t('spatialAwareness.layers.weatherStations.popup.name')}</span>
                <span className="feature-popup-property-value">{Locale.getJSONFieldValue(this.props.feature.getProperties().name)}</span>
                <br />
                <br />

                {dynamicContent}
            </ErrorBoundary>
        );
    }
}

export default withRouter(WeatherStationsPopup);
