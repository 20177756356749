import { getCenter } from 'ol/extent';
import AppTrace from 'AppTrace';
import Authentication from '../../Authentication';
import BusyState from '../../BusyState';
import DataFormatting from '../../DataFormatting';
import ErrorState from '../../ErrorState';
import Locale from '../../locale/Locale';

const t = Locale.getResourceString.bind(Locale);
const axios = require('axios');

export default class Layer {
    ////
    // Object creation
    ////
    constructor() {
        this.__isEnabled = true;
        this.__apiData = null;
        this._theme = null;

        this._getStyle = this._getStyle.bind(this);
        this._getDefaultStyle = this._getDefaultStyle.bind(this);
    }

    ////
    // Public interface
    ////

    // Occurs when the value of isEnabled changes
    onIsEnabledChanged;

    // Gets a string key that identifies this layer in collections, localization resource, etc.
    get key() {
        throw new Error('not implemented');
    }

    // Gets the stack order of the layer.  Lower zIndex layers are stacked at the bottom
    get zIndex() {
        throw new Error('not implemented');
    }

    // Gets the display name of this layer
    get name() {
        return t('spatialAwareness.layers.{0}.title'.replace('{0}', this.key));
    }

    // Gets the underlying layer object
    get vectorLayer() {
        throw new Error('not implemented');
    }

    // Gets whether or not the layer is loaded with data and ready to use
    get isLoaded() {
        return (this._apiData != null && this.vectorLayer != null);
    }

    // Gets or sets whether or not the layer is enabled for rendering.  For 
    // example, some layers may only be enabled at certain zoom levels.
    get isEnabled() {
        return this.__isEnabled;
    }
    set isEnabled(isEnabled) {
        if (this.__isEnabled !== isEnabled) {
            this.__isEnabled = isEnabled;
            if (this.onIsEnabledChanged != null) {
                this.onIsEnabledChanged(this, isEnabled);
            }
        }
    }

    // Gets or sets whether or not the layer is visible
    get isVisible() {
        return this.isLoaded ? this.vectorLayer.getVisible() : false;
    }
    set isVisible(visible) {
        if (this.vectorLayer != null) {
            this.vectorLayer.setVisible(visible);
        }
    }

    // Gets or sets a theme on this layer
    get theme() {
        return this._theme;
    }
    set theme(theme) {
        if (theme !== this._theme) {
            // Stop listening for changes on any previous theme
            if (this._theme != null) {
                this._theme.onChange = null;
            }

            // Save the new theme
            this._theme = theme;

            // Start listening for changes to the new theme
            if (this._theme != null) {
                this._theme.onChange = (theme) => {
                    if (this.isLoaded) {
                        this._vectorLayer.getSource().changed();
                    }
                };
            }

            // Update now to apply the theme, or unapply the old theme
            if (this.isLoaded) {
                this._vectorLayer.getSource().changed();
            }
        }
    }

    // Loads layer data for this layer
    // Returns a Promise that resolves when the layer data is loaded and the layer
    // is ready to be added to a map.
    load(view) {
        if (this.isLoaded) {
            return Promise.resolve(this);
        }
        else {
            // Save current busy state, and then set BusyState to true
            let busyState = BusyState.isBusy;
            BusyState.isBusy = true;

            return new Promise((resolve, reject) => {
                Authentication.getAccessToken()
                    .then(accessToken => {
                        return this._getData(accessToken, view);
                    })
                    .then(apiData => {
                        this._apiData = apiData;
                        this._createLayer(view);
                        BusyState.isBusy = busyState;
                        resolve(this);
                    })
                    .catch((error) => {
                        BusyState.isBusy = busyState;
                        ErrorState.setFault("Error getting geospatial data");
                        reject(error);
                    });
            });
        }
    }

    // Renders legend content for this layer
    renderLegendContent() {
        return (this.theme != null && this.theme.isLoaded) ?
            this.theme.renderLegendContent() :
            this._renderDefaultLegendContent();
    }

    // Renders popup content for a feature in this layer
    renderPopupContent(feature) {
        // No-op
    }

    // Notifies this layer that the application's locale has changed.
    // A layer should respond by localizing itself for the current locale.
    notifyLocaleChanged() {
        // By default, this is a no-op
    }

    // Notifies the layer that map movement has ended.  This is 
    // a good time to do things like retrieving dynamic content.
    notifyMoveEnd(view) {
        // By default, this is a no-op
    }

    // Finds and returns a feature by it's ID/entity ID
    findFeatureByEntityId(entityId) {
        let features = this.vectorLayer.getSource().getFeatures();
        return features.find(f => f.getProperties().entityId === entityId);
    }

    // Zooms to a selected feature
    // This base inplementation is for points.  Derived layers
    // with other geometries can override this behavior.
    zoomToFeature(feature, view) {
        let extent = feature.getGeometry().getExtent();
        let center = getCenter(extent);
        view.setZoom(15);
        view.setCenter(center);
    }



    ////
    // "Protected" interface
    ////

    // Gets or sets the API data for this layer
    get _apiData() {
        return this.__apiData;
    }
    set _apiData(apiData) {
        this.__apiData = apiData;
    }

    // Gets the URL from which API data is obtained
    _getUrl(view) {
        throw new Error('not implemented');
    }

    // Gets the data required to popupate the layer
    _getData(accessToken, view) {
        let authString = 'Bearer '.concat(accessToken);

        return new Promise((resolve, reject) => {
            axios.get(this._getUrl(view), {
                headers: {
                    Authorization: authString,
                    Accept: "application/json",
                    "cache-control": "no-cache",
                }
            })
                .then(response => {
                    DataFormatting.convertResultFields(response.data);
                    this._postProcessData(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    AppTrace.traceError('Error in Layer._getData: ' + error.message);
                    reject(new Error("Error getting layer data"));
                });
        });
    }

    // Performs any desired post-processing of the API data for the layer
    _postProcessData(apiData) {
        // By default, no post-processing is needed
    }

    // Creates and returns the FeatureLayer instance
    _createLayer(view) {
        throw new Error('not implemented');
    }

    // Gets the style for the selected feature, accounting for any themes
    // that are applied to the layer
    _getStyle(feature, resolution) {
        return (this.theme != null && this.theme.isLoaded) ?
            this.theme.getStyle(feature, resolution) :
            this._getDefaultStyle(feature, resolution);
    }

    // Gets the default style for the selected feature
    _getDefaultStyle(feature, resolution) {
        throw new Error('not implemented');
    }

    // Renders the default legend content for this layer
    _renderDefaultLegendContent() {
         // No-op
   }
}