import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import { ResponsiveContainer, ComposedChart, Bar, XAxis, YAxis, Legend, Tooltip, Label } from 'recharts';

import ErrorBoundary from 'ErrorBoundary';
import SessionState from 'SessionState';
import Palettes from 'common/Palettes';
import Locale from 'locale/Locale';

import 'common/MuiSlider.scss';
import 'common/ReCharts.scss';

const t = Locale.getResourceString.bind(Locale);

/////////////////////////////////////////////////////////////////////////////
// This class encapsulates the historic generation by fuel chart
/////////////////////////////////////////////////////////////////////////////
class HistoricDemandByTerritory extends Component {
    constructor(props, context) {
        super(props);

        // Set a maximum number of chart points/entries
        HistoricDemandByTerritory._MaxChartEntries = 168;

        // Configure initial state
        this._sessionStateKey = 'emi-japan.supply-and-demand.HistoricDemandByTerritory';
        this._defaultInitialState = {
        }
        this._stateOverrides = {
            sliderValue: Number.MAX_SAFE_INTEGER,
        }
        this.state = SessionState.get(this._sessionStateKey, this._defaultInitialState, this._stateOverrides);

        // Bind event handlers
        this._onTooltipFormatLabel = this._onTooltipFormatLabel.bind(this);
        this._onFormatValue = this._onFormatValue.bind(this);
        this._onChangeSlider = this._onChangeSlider.bind(this);
    }

    get _series() {
        const palette = Palettes.DefaultColors;
        let series = [];        

        if (this.props.aggregated != null && this.props.aggregated.length > 0) {
            series = this.props.supplyAndDemandServiceTerritories.map((id, index) => {
                let key = id;
                let color = palette[index % palette.length];
                return (
                    <Bar
                        key={key} 
                        dataKey={key} 
                        fill={color} 
                        stroke={color} 
                        isAnimationActive={false}
                        name={Locale.getJSONFieldValue(this.props.ustsLookup[id])} 
                        stackId={0}
                        yAxisId={0}
                    />
                );
            });
        }

        return series;
    }

    get _dataBounds() {
        let extraEntries = this.props.aggregated.length - HistoricDemandByTerritory._MaxChartEntries;
        let max = Math.max(0, extraEntries);

        return {
            min: 0,
            max: max,
            sliderValue: Math.min(this.state.sliderValue, max)
        };
    }

    _getData(dataBounds) {
        // Clean up previous data
        if (this.__data != null) {
            this.__data.length = 0;
        }

        // Take a subset if needed
        this.__data = this.props.aggregated.slice(dataBounds.sliderValue, dataBounds.sliderValue + HistoricDemandByTerritory._MaxChartEntries);
        
        return this.__data;
    }

    get _yAxisLabelText() {
        let units = (this.props.aggregation === 'stdev' || this.props.aggregation === 'count') ? 
            '' : ` ${t('units.megaWatts')}`

        return t(`supplyAndDemand.chart.demand.axisLabels.${this.props.aggregation}`) + units;
    }



    _onChangeSlider(event, value) {
        this.setState({
            sliderValue: value,
        })
    }

    _onTooltipFormatLabel(value) {
        return (<span>{value}</span>);
    }

    _onFormatValue(value, name, props) {
        return Locale.formatNumber(value, 0);
    }



    componentDidUpdate() {
        // Persist current state
        SessionState.set(this._sessionStateKey, this.state);
    }

    render() {
        // Get bounds of the chart data
        let dataBounds = this._dataBounds;

        // Get chart margins and JSX for slider as needed
        let chartMarginBottom = (dataBounds.max > 0) ? 30 : 10;
        let sliderJSX = null;
        if (dataBounds.max > 0) {
            const sliderPaddingRight = 20;
            sliderJSX = (
                <div className="spot-market-range-slider" style={{ paddingRight: sliderPaddingRight }}>
                    <Slider
                        min={dataBounds.min}
                        max={dataBounds.max}
                        value={dataBounds.sliderValue}
                        onChange={this._onChangeSlider}
                    />
                </div>
            );
        }

        return (
            <ErrorBoundary>
                <ResponsiveContainer>
                    <ComposedChart
                        data={this._getData(dataBounds)}
                        margin={{
                            top: 60, right: 50, bottom: chartMarginBottom, left: 10,
                        }}
                        stackOffset='sign'
                    >
                        <XAxis 
                            dataKey="dateTimeCategory" 
                        />
                        <YAxis 
                            tickFormatter={ (v) => this._onFormatValue(v) }
                            width={140}
                            yAxisId={0}>
                            <Label 
                                angle={-90} 
                                position='insideLeft'
                                value={this._yAxisLabelText}
                                style={{textAnchor: 'middle'}}
                            />
                        </YAxis>
                        <Tooltip 
                            isAnimationActive={false} 
                            labelFormatter={this._onTooltipFormatLabel}
                            formatter={this._onFormatValue} 
                        />
                        <Legend />

                        {this._series}
                    </ComposedChart>
                </ResponsiveContainer>

                {sliderJSX}
            </ErrorBoundary>
        );
    }
}

HistoricDemandByTerritory.propTypes = {
    aggregation: PropTypes.string,
    aggregated: PropTypes.arrayOf(PropTypes.object),
    supplyAndDemandServiceTerritories: PropTypes.arrayOf(PropTypes.number),
    ustsLookup: PropTypes.object,
}

HistoricDemandByTerritory.defaultProps = {
    aggregation: 'average',
    aggregated: [],
    supplyAndDemandServiceTerritories: [],
    ustsLookup: {},
}

export default HistoricDemandByTerritory;
