import Locale from '../../../locale/Locale';
import PriceAnimation from './PriceAnimation';

const t = Locale.getResourceString.bind(Locale);

export default class PriceForecastAnimation extends PriceAnimation {
    ////
    // "Protected" interface
    ////

    _formatFrameTip(value) {
        return Locale.formatDate(this._apiData.rows[value][this._dateTimeIndex]);
    }

    _formatCurrency(value) {
        return Locale.formatCurrency(Locale.getJSONCurrencyValue(value)) + '/' + t('units.megaWattHour');
    }
}