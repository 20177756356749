import AppConfig from '../../../AppConfig';
import Locale from '../../../locale/Locale';
import PriceAnimation from './PriceAnimation';

const t = Locale.getResourceString.bind(Locale);

export default class AreaPriceAnimation extends PriceAnimation {
    ////
    // Public interface
    ////

    // Gets a string key that identifies this animation in collections, localization resource, etc.
    get key() {
        return 'areaPrice';
    }



    ////
    // "Protected" interface
    ////

    // Gets the URL from which API data is obtained
    _getUrl(view) {
        return AppConfig.dataService.baseUrl + '/spatial-awareness/utility-service-territories/price-history-by-territory';
    }

    _formatFrameTip(value) {
        return Locale.formatDateTime(this._apiData.rows[value][this._dateTimeIndex]);
    }

    _formatCurrency(value) {
        return Locale.formatCurrency(Locale.getJSONCurrencyValue(value)) + '/' + t('units.kiloWattHour');
    }
}