import React from 'react';
import {Slider} from 'webcore-ux/react/components'
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import SkipNextIcon from '@material-ui/icons/SkipNext';

import ErrorBoundary from 'ErrorBoundary';

import './AnimationControls.scss';

class AnimationControls extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {
            isPlaying: false,
            frameIndex: 0,
        };

        this._playbackInterval = null;

        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);

        this._advanceFrameIndex = this._advanceFrameIndex.bind(this);
        this._onChangeSlider = this._onChangeSlider.bind(this);
        this._onToggleIsPlaying = this._onToggleIsPlaying.bind(this);
        this._onPrevious = this._onPrevious.bind(this);
        this._onNext = this._onNext.bind(this);
    }



    play() {
        if (!this.state.isPlaying) {
            // Create an interval to update the frame index
            this._playbackInterval = setInterval(this._advanceFrameIndex, 500);

            // Initialize starting frame index and set state
            this._frameIndex = this._isAtEnd ? 0 : this._frameIndex;
            this.setState({
                isPlaying: true,
            });
        }
    }

    pause() {
        if (this.state.isPlaying) {
            // Clear the _playbackInterval to clean up resources
            if (this._playbackInterval != null) {
                clearInterval(this._playbackInterval);
                this._playbackInterval = null;
            }

            this.setState({
                isPlaying: false,
            });
        }
    }



    get _frameCount() {
        return this.props.data.length;
    }

    get _isAtEnd() {
        return (this._frameIndex >= (this._frameCount - 1));
    }

    get _frameIndex() {
        return this.state.frameIndex;
    }
    set _frameIndex(value) {
        // Clamp value
        value = Math.max(0, Math.min(this._frameCount - 1, value));

        if (value !== this._frameIndex) {
            this.setState({
                frameIndex: value,
            });

            // Notify that we've changed
            if (this.props.onChange != null) {
                this.props.onChange(value);
            }
        }
    }

    _advanceFrameIndex() {
        if (this._isAtEnd) {
            this.pause();
        }
        else {
            this._frameIndex = this._frameIndex + 1;
        }
    }

    _onChangeSlider(e, value) {
        // Pause animation
        this.pause();

        // Update slider index
        this._frameIndex = value;
    }

    _onToggleIsPlaying(e) {
        e.preventDefault();
        if (this.state.isPlaying) {
            this.pause();
        }
        else {
            this.play();
        }
    }

    _onPrevious(e) {
        e.preventDefault();
        this.pause();
        --this._frameIndex;
    }

    _onNext(e) {
        e.preventDefault();
        this.pause();
        ++this._frameIndex;
    }

    componentWillUnmount() {
        this.pause();
    }

    render() {
        if (this.props.data == null) {
            return null;
        }

        let playPauseIcon = (this.state.isPlaying) ? 
            (<PauseIcon className="map-animation-button map-button-play-pause" fontSize="small" onClick={this._onToggleIsPlaying} />) :
            (<PlayArrowIcon className="map-animation-button map-button-play-pause" fontSize="small" onClick={this._onToggleIsPlaying} />);

        return (
            <ErrorBoundary>
                <div className="map-animation">
                    <Slider className="map-theme-slider"
                        min={0}
                        max={this._frameCount - 1}
                        value={this._frameIndex}
                        onChange={this._onChangeSlider}
                    />
                    <div className="map-animation-button-panel">
                        <SkipPreviousIcon className="map-animation-button map-button-previous" fontSize="small" onClick={this._onPrevious} />
                        {playPauseIcon}
                        <SkipNextIcon className="map-animation-button map-button-next" fontSize="small" onClick={this._onNext} />
                        <div className="map-animation-frame">{this.props.formatFrameTip(this._frameIndex)}</div>
                    </div>
                </div>
            </ErrorBoundary>
        );
    }
}

export default AnimationControls;
