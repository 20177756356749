import AppTrace from 'AppTrace';
import Authentication from '../../Authentication';
import BusyState from '../../BusyState';
import DataFormatting from '../../DataFormatting';
import ErrorState from '../../ErrorState';
import Locale from '../../locale/Locale';

const t = Locale.getResourceString.bind(Locale);
const axios = require('axios');

export default class Theme {
    ////
    // Object creation
    ////
    constructor(layer) {
        this._layer = layer;

        this._isActive = false;
        this.__apiData = null;
    }

    ////
    // Public interface
    ////

    // Occurs when the theme changes for any reason
    onChange;

    // Gets a string key that identifies this theme in collections, localization resource, etc.
    get key() {
        throw new Error('not implemented');
    }

    // Gets the display name of this theme
    get name() {
        return t('spatialAwareness.themes.{0}.title'.replace('{0}', this.key));
    }

    // Gets the layer associated with this theme
    get layer() {
        return this._layer;
    }

    // Gets whether or not the theme is loaded with data and ready to use
    get isLoaded() {
        return (this._apiData != null);
    }

    // Gets whether or not the theme is enabled, which currently 
    // defers to whether or not the layer is enabled
    get isEnabled() {
        return (this.layer != null) ? this.layer.isEnabled : false;
    }

    // Gets or sets whether or not the theme is currently applied to its layer
    get isActive() {
        return this._isActive;
    }
    set isActive(isActive) {
        this._isActive = isActive;
        if (this.layer != null) {
            this.layer.theme = (isActive) ? this : null;
        }
    }



    // Loads theme data for this theme
    // Returns a Promise that resolves when the theme data is loaded and the theme
    // is ready to be rendered
    load(view) {
        if (this.isLoaded) {
            return Promise.resolve(this);
        }
        else {
            // Save current busy state, and then set BusyState to true
            let busyState = BusyState.isBusy;
            BusyState.isBusy = true;

            return new Promise((resolve, reject) => {
                Authentication.getAccessToken()
                    .then(accessToken => {
                        return this._getData(accessToken, view);
                    })
                    .then(apiData => {
                        this._apiData = apiData;
                        this._createTheme();
                        BusyState.isBusy = busyState;
                        resolve(this);
                    })
                    .catch((error) => {
                        BusyState.isBusy = busyState;
                        ErrorState.setFault("Error getting theme data");
                        reject(error);
                    });
            });
        }
    }

    // Renders this theme.  By default, no rendering is needed.  But,
    // for animations, animation controls may be desired, etc.
    render() {
        return null;
    }

    // Gets the style for the current feature as styled by this theme
    getStyle(feature, resolution) {
        throw new Error('not implemented');
    }

    // Renders legend content for this theme
    renderLegendContent() {
        throw new Error('not implemented');
    }



    ////
    // "Protected" interface
    ////

    // Gets or sets the API data for this theme
    get _apiData() {
        return this.__apiData;
    }
    set _apiData(apiData) {
        this.__apiData = apiData;
    }

    // Gets the URL from which API data is obtained
    _getUrl(view) {
        throw new Error('not implemented');
    }

    // Gets the data required to populate the theme
    _getData(accessToken, view) {
        let authString = 'Bearer '.concat(accessToken);

        return new Promise((resolve, reject) => {
            axios.get(this._getUrl(view), {
                headers: {
                    Authorization: authString,
                    Accept: "application/json",
                    "cache-control": "no-cache",
                }
            })
                .then(response => {
                    DataFormatting.convertResultFields(response.data);
                    this._postProcessData(response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    AppTrace.traceError('Error in Theme._getData: ' + error.message);
                    reject(new Error("Error getting theme data"));
                });
        });
    }

    // Performs any desired post-processing of the API data for the theme
    _postProcessData(apiData) {
        // By default, no post-processing is needed
    }

    _createTheme() {
        // This is a no-op in base class
    }

    // Invoked internally when the theme changes
    _onChange() {
        if (this.onChange != null) {
            this.onChange(this);
        }
    }
}