import moment from 'moment';
import Locale from 'locale/Locale';

import MarketStats from './MarketStats';

export default class ForwardMarketStats extends MarketStats {
    _beforeFilter(data, filters, valueColumns) {
        // Transform selection arrays into objects for easy lookup without searches
        filters.selectedMonths = filters.selectedMonths.reduce((obj, m) => { obj[m] = true; return obj; }, {});
        filters.selectedYears = filters.selectedYears.reduce((obj, y) => { obj[y] = true; return obj; }, {});

        // Change the end date so that we look up to midnight of the next day
        filters.start = moment.utc([filters.start.year(), filters.start.month(), filters.start.date()]);
        filters.end = moment.utc([filters.end.year(), filters.end.month(), filters.end.date()]).add(1, 'days');
    }

    _getMomentCategory(theMoment, filters) {
        // Default: return original moment which we expect to be daily
        let momentCategory = theMoment;

        return momentCategory;
    }

    _rowMeetsFilterCriteria(theMoment, filters) {
        let rowMeetsFilterCriteria = false;

        // Filter for start/end date
        if (filters.start.isSameOrBefore(theMoment) && theMoment.isBefore(filters.end)) {
            // Filter for hour/month/year selections
            if (filters.selectedMonths[theMoment.month() + 1] &&
                filters.selectedYears[theMoment.year()]) {
                    rowMeetsFilterCriteria = true;
            }
        }

        return rowMeetsFilterCriteria;
    }

    ////////////////////////////////////////////////////////////////////////////
    // We need to override how _aggregate works here.  The data is already
    // at a daily level, and we're not supporting additional aggregations 
    // because our PM indicated that this doesn't make sense for this chart.
    // So we will basically just adding the category field.
    ////////////////////////////////////////////////////////////////////////////
    _aggregate(data, filters, valueColumns) {
        let aggregated = new Array(this._filtered.length);

        for (let i = 0; i < this._filtered.length; ++i) {
            let row = Object.assign({}, this._filtered[i]);
            row.category = Locale.formatMomentGranularity(row.momentCategory, 'D');
            aggregated[i] = row;
        }

        return aggregated;
    }
}
