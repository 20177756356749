import React from 'react'

import Locale from 'locale/Locale'
import ErrorBoundary from 'ErrorBoundary'
import Utils from 'Utils'
import MarketClearingStats from '../MarketClearingStats'

import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Legend, Label } from 'recharts'
import Filter from 'webcore-ux/react/components/Icons/Filter'
import { Button } from 'webcore-ux/react/components'
import Palettes from 'common/Palettes'
import PriceForecastComparisonTypes from '../PriceForecastComparisonTypes';

const t = Locale.getResourceString.bind(Locale)

export const MarketChart = (props) => {
    const palette = Palettes.DefaultColors;
    let dataSource = props.granularity === 'M' || props.granularity === 'MoY' ? props.rawDataMonthly : props.rawDataAnnually;
    let chartData = dataSource.slice();
    let series = [];
    let marketClearingStats = new MarketClearingStats();

    // Build the series for the chart based on selected territories, peak types, and scenario IDs
    if (props.compareBy === PriceForecastComparisonTypes.Region) {
        // Series correspond to individual peak type and scenario, varying over territory IDs
        series = props.territoryIds.map(id => { 
            return {
                dataKey: `${props.peakTypes[0]}-${id}-${props.selectedScenarioIds[0]}`,
                name: Utils.getShortServiceTerritoryName(Locale.getJSONFieldValue(props.territories[parseInt(id, 10)])),
            };
        });
    } 
    else if (props.compareBy === PriceForecastComparisonTypes.PeakType) {
        // Series correspond to individual territory ID and scenario, varying over peak types
        series = props.peakTypes.map(pt => { 
            return {
                dataKey: `${pt}-${props.territoryIds[0]}-${props.selectedScenarioIds[0]}`,
                name: t(`priceForecast.series.${pt}`),
            };
        });
    }
    else if (props.compareBy === PriceForecastComparisonTypes.Scenario) {
        // Series correspond to individual peak type and territory ID, varying over scenarios
        series = props.selectedScenarioIds.map(id => { 
            return {
                dataKey: `${props.peakTypes[0]}-${props.territoryIds[0]}-${id}`,
                name: Locale.getJSONFieldValue(
                    props.vintages.filter(v => v.ScenarioId === parseInt(id, 10))[0].ScenarioName
                ),
            };
        });
    }

    if (props.granularity === 'MoY') {
        chartData = marketClearingStats.calculate(series.map(s => s.dataKey), chartData, props.marketClearingFilters)
    }

    let handlePanel = () => {
        props.handlePanel()
    }

    let onTooltipFormatLabel = (value) => {
        return props.onTooltipFormatLabel(value)
    }

    let onFormatValue = (value) => {
        return props.onFormatValue(value)
    }

    return (
        <ErrorBoundary>
            <div className="jemi-view-filter-button" title={t('priceForecast.filters.caption')}>
                <Button
                    variant="primary"
                    onClick={() => {
                        handlePanel()
                    }}
                    disabled={props.filterPanelIsPinned}
                >
                    <Filter fontSize="small" />
                </Button>
            </div>

            <ResponsiveContainer>
                <LineChart
                    data={chartData}
                    margin={{
                        top: 60, right: 20, bottom: 10, left: 10,
                    }}
                >
                    <XAxis 
                        dataKey="category"
                    />
                    <YAxis 
                        tickFormatter={ (v) => onFormatValue(v) }
                        width={90}
                        yAxisId={0}>
                            <Label
                                angle={-90}
                                position="insideLeft"
                                value={`${Locale.getCurrencySymbol()} / ${t('units.megaWattHour')}`}
                                style={{ textAnchor: 'middle' }}
                            />
                    </YAxis>
                    <Tooltip isAnimationActive={false} labelFormatter={onTooltipFormatLabel} formatter={onFormatValue} />
                    <Legend />
                    {
                        series.map((series, index) => {
                            return (
                                <Line
                                    key={index}
                                    dataKey={series.dataKey}
                                    dot={false}
                                    isAnimationActive={false}
                                    stroke={palette[index % palette.length]}
                                    strokeWidth={2} 
                                    name={series.name}
                                />
                            )
                        })
                    }
                </LineChart>
            </ResponsiveContainer>
        </ErrorBoundary>
    )
}
