import React from 'react';
import moment from 'moment';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { GeoJSON } from 'ol/format';
import { RegularShape, Style, Stroke, Fill } from 'ol/style';
import AppConfig from '../../AppConfig';
import AppTrace from '../../AppTrace';
import ErrorState from '../../ErrorState';
import Layer from './Layer';
import Locale from '../../locale/Locale';

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import '../../common/MuiTreeView.scss';

const t = Locale.getResourceString.bind(Locale);
const wkx = require('wkx');

export default class LNGFacilities extends Layer {
    constructor(apiData) {
        super();
    }

    // Gets a string key that identifies this layer in collections, localization resource, etc.
    get key() {
        return "lngFacilities";
    }

    // Gets the stack order of the layer.  Lower zIndex layers are stacked at the bottom
    get zIndex() {
        // Point layers on top: 20 and up
        return 23;
    }

    // Gets the underlying layer object
    get vectorLayer() {
        return this._vectorLayer;
    }

    // Renders popup content for a feature in this layer
    renderPopupContent(feature) {
        let properties = feature.getProperties();
        let owners = [];
        properties.owners.forEach((owner, index) => {
            let key = 'owner' + index;
            owners.push(
                <TreeItem 
                    key={key} 
                    nodeId={key} 
                    label={(<span>
                        {Locale.getJSONFieldValue(owner.name)}
                        &nbsp;&#8208;&nbsp;
                        {owner.percent.toFixed(0) + '%'}
                    </span>
                    )} 
                />);
        });

        return (
            <>
                <span className="feature-popup-title">{t('spatialAwareness.layers.lngFacilities.popup.title')}</span><br />
                <br />
                <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    defaultExpanded={["owners"]}
                    disableSelection={true}
                >
                    <TreeItem nodeId="name" label={(<span><span className="tree-title-field-name">{t('spatialAwareness.layers.lngFacilities.popup.name')}</span>&nbsp;{Locale.getJSONFieldValue(properties.name)}</span>)}></TreeItem>
                    <TreeItem nodeId="storageCapacity" label={(<span><span className="tree-title-field-name">{t('spatialAwareness.layers.lngFacilities.popup.storageCapacity')}</span>&nbsp;{Locale.formatNumber(properties.storageCapacity, 0) + ' ' + t('units.kiloLiters')}</span>)}></TreeItem>
                    <TreeItem nodeId="owners" label={(<span className="tree-title-field-name">{t('spatialAwareness.layers.lngFacilities.popup.owners')}</span>)}>
                        {owners}
                    </TreeItem>
                    <TreeItem nodeId="status" label={(<span><span className="tree-title-field-name">{t('spatialAwareness.layers.lngFacilities.popup.status')}</span>&nbsp;{Locale.getJSONFieldValue(properties.status)}</span>)}></TreeItem>
                    <TreeItem nodeId="type" label={(<span><span className="tree-title-field-name">{t('spatialAwareness.layers.lngFacilities.popup.type')}</span>&nbsp;{Locale.getJSONFieldValue(properties.type)}</span>)}></TreeItem>
                    <TreeItem nodeId="onlineYear" label={(<span><span className="tree-title-field-name">{t('spatialAwareness.layers.lngFacilities.popup.onlineYear')}</span>&nbsp;{Locale.formatMomentGranularity(moment.utc([properties.onlineYear, 1, 1]), 'A')}</span>)}></TreeItem>
                </TreeView>
            </>
        );
    }



    _getUrl(view) {
        return AppConfig.dataService.baseUrl + '/spatial-awareness/lng-facilities';
    }

    _createLayer(view) {
        try {
            // Derive all graphics
            this._allFeatures = this._getAllFeatures();

            this._vectorLayer = new VectorLayer({
                key: this.key,
                source: new VectorSource({
                    features: this._allFeatures,
                }),
                style: this._getStyle,
                opacity: 1.0,
                visible: true,
            });
        }
        catch (err) {
            AppTrace.traceError("LNGFacilities.ctor: " + err)
            ErrorState.setFault("Error mapping LNG facilities");
        }
    }

    // Gets the default style for the selected feature
    _getDefaultStyle(feature, resolution) {
        return new Style({
            image: new RegularShape({
                radius: 6,
                points: 4,
                angle: Math.PI / 4,
                stroke: new Stroke({
                    color: 'rgba(232, 232, 232, 0.5)',
                    width: 0.25
                }),
                fill: new Fill({
                    color: '#800000'
                })
            })
        });
    }

    // Renders legend content for this layer
    _renderDefaultLegendContent() {
        if (this.isVisible) {
            return (
                <div key={this.key + '-legend-content'} className="ev-legend-layer-content">
                    <span className="ev-legend-layer-name">{t('spatialAwareness.layers.lngFacilities.title')}</span><br />
                    <span className="ev-legend-symbol" style={{ color: '#800000' }}>&#9632;</span><span className="ev-legend-theme-value">{t('spatialAwareness.layers.lngFacilities.popup.title')}</span><br />
                </div>
            );
        }
    }

    // Generate all of the features that will be added to the map.
    _getAllFeatures() {
        let features = [];
        let olGeoJSON = new GeoJSON();

        // Iterate over all entities in the API data
        let idIndex = this._apiData.metadata.columns.id.index;
        let nameIndex = this._apiData.metadata.columns.name.index;
        let storageCapacityIndex = this._apiData.metadata.columns.storageCapacity.index;
        let ownersIndex = this._apiData.metadata.columns.owners.index;
        let statusIndex = this._apiData.metadata.columns.status.index;
        let typeIndex = this._apiData.metadata.columns.type.index;
        let onlineYearIndex = this._apiData.metadata.columns.onlineYear.index;
        let geographyIndex = this._apiData.metadata.columns.geography.index;

        for (let i = 0; i < this._apiData.rows.length; ++i) {
            let row = this._apiData.rows[i];

            // Parse wkt format
            let geoJSON = wkx.Geometry.parse(row[geographyIndex]).toGeoJSON();

            // Add a feature
            features.push(new Feature({
                layerKey: this.key,
                entityId: row[idIndex],
                name: row[nameIndex],
                storageCapacity: row[storageCapacityIndex],
                owners: row[ownersIndex],
                status: row[statusIndex],
                type: row[typeIndex],
                onlineYear: row[onlineYearIndex],

                geometry: olGeoJSON.readGeometry(geoJSON, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                })
            }));
        }

        return features;
    }
}