import React, { Component } from 'react';
import { PieChart, Pie, Cell } from 'recharts';

////////////////////////////////////////////////////////////////////////////////
// This class renders the specialized tooltip used in the forward market 
// chart.  The idea is to start with the markup rendered by recharts for the
// standard tooltip, and then customize it to generate our tooltip.
//
// The following shows the JSX-ified markup that serves as a starting point
// for a standard recharts tooltip:
//
// <div 
//     className="recharts-default-tooltip"
//     style={{margin: 0, padding: 10, backgroundColor: '#fff', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap'}}
// >
//     <p 
//         className="recharts-tooltip-label" 
//         style={{margin: 0}}
//     >
//         <span>Label at the top of the tooltip</span>
//     </p>
//     <ul className="recharts-tooltip-item-list" style={{padding: 0, margin: 0}}>
//         {/* Each item in the tooltip looks like this... */}
//         <li className="recharts-tooltip-item" style={{display: 'block', paddingTop: 4, paddingBottom: 4, color: p.color }}>
//             <span className="recharts-tooltip-item-name">Item Name</span>
//             <span className="recharts-tooltip-item-separator">{this.props.separator}</span>
//             <span className="recharts-tooltip-item-value">Item Value</span>
//             <span className="recharts-tooltip-item-unit"></span>
//         </li>
//     </ul>
// </div>
////////////////////////////////////////////////////////////////////////////////
class SupplyAndDemandChartTooltip extends Component {
    constructor(props, context) {
        super(props)
    }

    get _payload() {
        return this.props.payload || [];
    }

    get _items() {
        let items = this._payload.map(p => {
            return (
                <li key={p.dataKey} className="recharts-tooltip-item" style={{display: 'block', paddingTop: 4, paddingBottom: 4, color: p.color }}>
                    <span className="recharts-tooltip-item-name">{p.name}</span>
                    <span className="recharts-tooltip-item-separator">{this.props.separator}</span>
                    <span className="recharts-tooltip-item-value">{this.props.formatter(p.value, p.name, p)}</span>
                    <span className="recharts-tooltip-item-unit"></span>
                </li>
            );
        });

        return items;
    }

    get _chart() {
        // Adapted from:
        // https://recharts.org/en-US/examples/PieChartWithCustomizedLabel
        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.65;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
            const value = percent * 100;

            return (value >= 4) && (
                <text x={x} y={y} textAnchor="middle" dominantBaseline="middle">
                    {`${(value).toFixed(0)}%`}
                </text>
            );
        };

        // Show only the columns that correspond to fuels
        let data = this._payload.filter(p => this.props.barCategoryColumns.find(col => col.name === p.dataKey) != null);
        data.sort((a, b) => a.value - b.value);

        // Get a basis for computing the width and height of the pie chart
        const pixelsPerSeries = 20;
        let seriesCount = this._payload.length;
        let pieHeight = pixelsPerSeries * seriesCount;

        return (
            <PieChart 
                isAnimationActive={false} 
                width={pieHeight + 20}
                height={pieHeight}
            >
                <Pie
                    isAnimationActive={false}
                    data={data}
                    cx={pieHeight / 2 + 15}
                    cy={pieHeight / 2 - 5}
                    outerRadius={pieHeight / 2 - 5}
                    dataKey="value"
                    label={renderCustomizedLabel}
                    labelLine={false}
                >
                {
                    data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)
                }
                </Pie>
            </PieChart>
        );
    }

    render() {
        if (!this.props.active) {
            return null;
        }
        
        return (
            <div
                className="recharts-default-tooltip"
                style={{ margin: 0, padding: 10, backgroundColor: '#fff', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}
            >
                <p
                    className="recharts-tooltip-label"
                    style={{ margin: 0 }}
                >
                    {this.props.labelFormatter(this.props.label)}
                </p>
                <div className="supply-and-demand-tooltip-content">
                    <div className="supply-and-demand-tooltip-visual">
                        {/* This list renders the line items in the tooltip */}
                        <ul className="recharts-tooltip-item-list" style={{ padding: 0, margin: 0 }}>
                            {this._items}
                        </ul>
                    </div>
                    <div className="supply-and-demand-tooltip-visual">
                        {this._chart}
                    </div>
                </div>
            </div>
        );
    }
}

export default SupplyAndDemandChartTooltip;