import React from 'react';
import { withRouter } from "react-router";
import { Button, Checkbox } from 'webcore-ux/react/components';
import LayersIcon from '@material-ui/icons/Layers';
import ErrorBoundary from 'ErrorBoundary';
import Locale from '../locale/Locale';

import PinnableSettings from 'common/PinnableSettings';

import SpatialAwareness from './SpatialAwareness';
import './LayerChooser.scss';

const t = Locale.getResourceString.bind(Locale);

class LayerChooser extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {
            isLayersPanelVisible: false,
            isLayersPanelPinned: false,
        };

        this._onPin = this._onPin.bind(this);
    }

    _onPin(args) {
        this.setState({ isLayersPanelPinned: args.isPinned });
        if (this.props.onPin != null) {
            this.props.onPin(args);
        }
    }

    _onChangeLayerIsVisible(layer, args) {
        args.event.stopPropagation();
        if (this.props.onChangeLayerIsVisible != null) {
            this.props.onChangeLayerIsVisible(layer, args.isChecked);
        }
    }



    render() {
        let layers = [];
        if (this.props.layers != null) {
            layers = this.props.layers.map(l => {
                return (
                    <div key={l.key}>
                        <Checkbox checked={l.isVisible} disabled={!l.isEnabled} label={l.name} onChange={(args) => this._onChangeLayerIsVisible(l, args)} />
                    </div>);
            });
        }

        let layersTitle = t("spatialAwareness.layers.title");

        return (
            <ErrorBoundary>
                <div className="spatial-awareness-map-buttons">
                    <div className="spatial-awareness-layers-button" title={layersTitle}>
                        <Button 
                            disabled={this.state.isLayersPanelPinned || this.props.disabled}
                            variant="primary"
                            onClick={ () => this.setState({ isLayersPanelVisible: !this.state.isLayersPanelVisible }) }>
                            <LayersIcon />
                        </Button>
                    </div>

                    <PinnableSettings
                        contentClassName="layerchooser-layers"
                        open={this.state.isLayersPanelVisible}
                        title={layersTitle}
                        viewKey={SpatialAwareness.ViewKey}
                        onClose={() => this.setState({ isLayersPanelVisible: false })}
                        onPin={(args) => this._onPin(args)}
                    >
                        {layers}
                    </PinnableSettings>
                </div>
            </ErrorBoundary>
        );
    }
}

export default withRouter(LayerChooser);
