import React from 'react';

import './JEMIStripe.scss';

////////////////////////////////////////////////////////////////////////////////
// JEMIStripe encapsulates the branded JEMI app header
////////////////////////////////////////////////////////////////////////////////
class JEMIStripe extends React.Component {
    render() {
        return (
            <div className="jemi-stripe">
                <div className="jemi-brand" />
            </div>
        );
    }
}

export default JEMIStripe;