import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import VerticalTabNavStyles from './VerticalTabNav.scss';

////////////////////////////////////////////////////////////////////////////////
// VerticalTabNav helps support side-navitation tabs used on certain tabs.
////////////////////////////////////////////////////////////////////////////////
class VerticalTabNav extends React.Component {
    constructor(props) {
        super(props);

        this._onClickExpander = this._onClickExpander.bind(this);
    }

    _onClickExpander() {
        if (this.props.onChangeIsExpanded != null) {
            this.props.onChangeIsExpanded(!this.props.isExpanded);
        }
    }

    get _navWidth() {
        return (this.props.isExpanded) ? this.props.expandedWidth : this.props.collapsedWidth;
    }

    get _expanderIcon() {
        return (this.props.isExpanded) ?
            (<ExpandLessIcon className="nav-icon-expanded" />) :
            (<ExpandLessIcon className="nav-icon-collapsed" />);
    }

    render() {
        return (
            <div className="vertical-tab-nav-container">
                <div 
                    className="expander" 
                    style={{ left: 'calc(' + this._navWidth + 'px - ' + VerticalTabNavStyles.expanderDiameterFull + ' / 2)' }}  
                    onClick={this._onClickExpander}
                >
                    {this._expanderIcon}
                </div>

                <div 
                    className={classNames("vertical-tab-nav", this.props.className)}
                    style={{ flex: "0 0 " + this._navWidth + "px" }}
                >
                    {this.props.tabs}
                </div>
    
                <div className="tab-panels">
                    {this.props.tabPanels}
                </div>
            </div>
        );
    }
}

VerticalTabNav.propTypes = {
    isExpanded: PropTypes.bool,
    expandedWidth: PropTypes.number,
    collapsedWidth: PropTypes.number,
    tabs: PropTypes.node,
    tabPanels: PropTypes.node,
}

VerticalTabNav.defaultProps = {
    isExpanded: true,
    expandedWidth: 150,
    collapsedWidth: 50,
}

export default VerticalTabNav;
