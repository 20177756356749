import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Slider from '@material-ui/core/Slider';

import DatePicker from 'common/DatePicker';

import 'common/MuiSlider.scss';
import './DateRangePicker.scss';

////////////////////////////////////////////////////////////////////////////////
// DateRangePicker renders a pair of DatePicker components, and an associated
// range slider control to facilitate the selection of a range of dates.
//
// All dates (min, max, start, end) are expected to be provided as moment
// objects.
//
// DateRangePicker has two events:
// * onChanging(start, end) that fires while the user drags the slider
// * onChange(start, end) that fires when the user releases the slider OR if
// the user changes the start/end date with the dropdowns.
//
// Both events include the updated start and end moment objects, in order (end
// will not be before start).  The intent is  that the client will listen to
// both events as follows:
// * The handler for onChanging will use the reported start/end dates set 
//   client state, which gets set as the start and end props on the next render.
// * The handler for onChange will set state as above AND perform any expensive
//   data manipulations required based on the new dates.
////////////////////////////////////////////////////////////////////////////////
class DateRangePicker extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {
            sliderValue: [0, 0],
        };

        this._onChangeStart = this._onChangeStart.bind(this);
        this._onChangeEnd = this._onChangeEnd.bind(this);
        this._onChangeSlider = this._onChangeSlider.bind(this);
        this._onChangeCommittedSlider = this._onChangeCommittedSlider.bind(this);
    }

    ////////////////////////////////////////////////////////////////////////////
    // Gets the date-only portion of the input moment (midnight) in UTC.
    ////////////////////////////////////////////////////////////////////////////
    static getDate(theMoment) {
        return moment.utc([theMoment.year(), theMoment.month(), theMoment.date()]);
    }

    _clampAndNotify(start, end) {
        if (this.props.onChange != null) {
            // Ensure that start is at least our min date
            if (start.isBefore(this.props.minDate)) {
                start = this.props.minDate;
            }

            // Ensure that end is at most the max date
            if (this.props.maxDate.isBefore(end)) {
                end = this.props.maxDate;
            }

            this.props.onChange(start, end);        
        }
    }

    _onChangeStart(value) {
        if (this.props.onChange != null) {
            let start = value;
            let end = this.props.end;

            // Make sure end isn't before start
            if (end.isBefore(start)) {
                end = start;
            }

            // Clamp and notify
            this._clampAndNotify(start, end);
        }
    }

    _onChangeEnd(value) {
        if (this.props.onChange != null) {
            let start = this.props.start;
            let end = value;

            // Make sure end isn't before start
            if (end.isBefore(start)) {
                start = end;
            }

            // Clamp and notify
            this._clampAndNotify(start, end);
        }
    }

    _onChangeSlider(event, value) {
        if (this.props.onChanging != null) {
            let start = DateRangePicker.getDate(this.props.minDate).add(value[0], 'days');
            let end = DateRangePicker.getDate(this.props.minDate).add(value[1], 'days');
            this.props.onChanging(start, end);
        }
    }

    _onChangeCommittedSlider(event, value) {
        if (this.props.onChange != null) {
            let start = DateRangePicker.getDate(this.props.minDate).add(value[0], 'days');
            let end = DateRangePicker.getDate(this.props.minDate).add(value[1], 'days');
            this.props.onChange(start, end);
        }
    }

    render() {
        let minDate = DateRangePicker.getDate(this.props.minDate);
        let maxDate = DateRangePicker.getDate(this.props.maxDate);
        let start = DateRangePicker.getDate(this.props.start);
        let end = DateRangePicker.getDate(this.props.end);
        let sliderMin = 0;
        let sliderMax = maxDate.diff(minDate, 'days');
        let sliderValue = [
            start.diff(minDate, 'days'),
            end.diff(minDate, 'days'),
        ];

        return (
            <div className="de-date-range-picker">
                <DatePicker className="start-date"
                    minDate={minDate}
                    maxDate={maxDate}
                    value={start}
                    onChange={this._onChangeStart}
                />
                <DatePicker className="end-date"
                    minDate={minDate}
                    maxDate={maxDate}
                    value={end}
                    onChange={this._onChangeEnd}
                />

                <Slider className="date-range"
                    min={sliderMin}
                    max={sliderMax}
                    value={sliderValue} 
                    onChange={this._onChangeSlider}
                    onChangeCommitted={this._onChangeCommittedSlider}
                />
            </div>
        );
    }

}

DateRangePicker.propTypes = {
    // Minimum allowed date
    minDate: PropTypes.instanceOf(moment),
    // Maximum allowed date
    maxDate: PropTypes.instanceOf(moment),
    // Start date
    start: PropTypes.instanceOf(moment),
    // End date
    end: PropTypes.instanceOf(moment),
    // Date range is changing via slider, but not yet committed
    onChanging: PropTypes.func,
    // Date range has changed
    onChange: PropTypes.func,
}

DateRangePicker.defaultProps = {
    minDate: moment(new Date("1900-01-01")),
    maxDate: moment(new Date("2100-01-01")),
    start: moment(),
    end: moment(),
}

export default DateRangePicker;