import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ComposedChart, Bar, Line, Scatter, XAxis, YAxis, Legend, Tooltip } from 'recharts';

import Locale from 'locale/Locale';
import ErrorBoundary from 'ErrorBoundary';
import Palettes from 'common/Palettes';

import CommonStyles from 'common/Common.scss';
import 'common/ReCharts.scss';
import './SupplyAndDemandChart.scss';

/////////////////////////////////////////////////////////////////////////////
// This class encapsulates the supply-and-demand chart used in the 
// Utility Service Territory map popout.
/////////////////////////////////////////////////////////////////////////////
class SupplyAndDemandChart extends Component {
    static _LocalPalette = {
        reserveMargin: '#000',
        demand: '#525252', // grey 70
        totalSupply: '#CFD8DC',
    };

    static _TotalSupplyColumnName = 'totalSupply';

    constructor(props, context) {
        super(props);

        // Initially, data and series are null
        this._data = null;
        this._barSeries = null;
        this._scatterSeries = null;
        this._lineSeries = null;

        this._onTooltipFormatLabel = this._onTooltipFormatLabel.bind(this);
        this._onTooltipFormatValue = this._onTooltipFormatValue.bind(this);
    }

    _onTooltipFormatLabel(value) {
        return (
            <>
                <span>{this.props.getArgumentTitle()}</span>
                &nbsp;:&nbsp;
                <span>{this.props.formatArgument(value)}</span>
            </>
        );
    }

    _onTooltipFormatValue(value, name, props) {
        let formattedValue = (props.dataKey === 'reserveMargin') ?
            this.props.formatTooltipMarginValue(value) :
            this.props.formatTooltipCapacityValue(value);
        return formattedValue;
    }

    _makeSeriesAndData() {
        // Only generate series and data once
        if (this._data == null) {
            // Copy the original data, and add a column for the total supply
            let copy = JSON.parse(JSON.stringify(this.props.data));
            copy.metadata.columns[SupplyAndDemandChart._TotalSupplyColumnName] = {
                // Build the caption from all available locales
                caption: Locale.makeJSONField('spatialAwareness.layers.utilityServiceTerritories.popup.' + SupplyAndDemandChart._TotalSupplyColumnName),
                // The index is the current number of columns
                index: Object.keys(copy.metadata.columns).length,
                name: SupplyAndDemandChart._TotalSupplyColumnName,
                type: 'number',
            };

            // Calculate total supply for all columns
            copy.rows.forEach(row => {
                row.push(this.props.capacityByFuelColumns.reduce((acc, col) => acc + row[col.index], 0));
            });

            // 
            // Now build the data
            //

            // Get argument column
            let argumentColumn = this.props.argumentColumn;

            // Build series info for each value column specified in the props
            this._data = [];
            for (let i = 0; i < copy.rows.length; ++i) {
                // Start a new row object with the X-axis properties
                let row = copy.rows[i];
                let obj = {
                    x: i,
                    argument: this.props.getArgument(row[argumentColumn.index]),
                };

                // Add values
                for (let seriesIndex = 0; seriesIndex < this.props.capacityByFuelColumns.length; ++seriesIndex) {
                    let valueColumn = this.props.capacityByFuelColumns[seriesIndex];
                    obj[valueColumn.name] = this.props.getValue(row[valueColumn.index]);
                }
                obj[SupplyAndDemandChart._TotalSupplyColumnName] = this.props.getValue(row[copy.metadata.columns[SupplyAndDemandChart._TotalSupplyColumnName].index]);
                obj.demand = this.props.getValue(row[this.props.demandColumn.index]);
                obj.reserveMargin = this.props.getValue(row[this.props.reserveMarginColumn.index]);

                this._data.push(obj);
            }

            // Build bar series objects
            this._barSeries = this.props.capacityByFuelColumns.map((col, index) => {
                return {
                    color: Palettes.FuelColors.getColor(col.name),
                    dataKey: col.name,
                    name: this.props.getSeriesName(col),
                    stackId: 0,
                };             
            });

            // Build scatter series object
            this._scatterSeries = [{
                color: SupplyAndDemandChart._LocalPalette[SupplyAndDemandChart._TotalSupplyColumnName],
                dataKey: SupplyAndDemandChart._TotalSupplyColumnName,
                name: this.props.getSeriesName(copy.metadata.columns[SupplyAndDemandChart._TotalSupplyColumnName]),
            }];

            // Build line series objects
            this._lineSeries = [];
            let col = this.props.demandColumn;
            this._lineSeries.push({
                color: SupplyAndDemandChart._LocalPalette.demand,
                dataKey: col.name,
                name: this.props.getSeriesName(col),
                yAxisId: 0,
            });
            col = this.props.reserveMarginColumn;
            this._lineSeries.push({
                color: SupplyAndDemandChart._LocalPalette.reserveMargin,
                dataKey: col.name,
                name: this.props.getSeriesName(col),
                yAxisId: 1,
            });
        }
    }



    componentWillUnmount() {
        this._data = null;
        this._barSeries = null;
        this._scatterSeries = null;
        this._lineSeries = null;

        this._onTooltipFormatLabel = null;
        this._onTooltipFormatValue = null;
    }

    render() {
        const ChartWidth = parseInt(CommonStyles.featurePopupContentWidth);

        if (this.props.data == null || this.props.data.rows == null || this.props.data.rows.length === 0) {
            return null;
        }

        // Make series and chart data
        this._makeSeriesAndData();

        // Create series objects
        let barSeries = this._barSeries.map((info, index) =>
            <Bar
                key={info.dataKey} 
                dataKey={info.dataKey} 
                fill={info.color}
                legendType="none" 
                name={info.name} 
                stackId={info.stackId}
            />
        );
        let scatterSeries = this._scatterSeries.map((info, index) =>
            <Scatter 
                key={info.dataKey} 
                dataKey={info.dataKey} 
                fill={info.color}
                legendType="square" 
                name={info.name} 
                stroke={info.color}
            />
        );
        let lineSeries = this._lineSeries.map((info, index) =>
            <Line
                key={info.dataKey} 
                dataKey={info.dataKey} 
                dot={false}
                name={info.name} 
                stroke={info.color}
                yAxisId={info.yAxisId}
            />
        );

        return(
            <ErrorBoundary>
                <div className="popout-chart supply-and-demand-chart">
                    <ComposedChart 
                        margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
                        width={ChartWidth} 
                        height={this.props.height}
                        data={this._data}
                    >
                        <XAxis 
                            tickFormatter={ (a) => this.props.formatArgument(a) }
                            dataKey="argument">
                        </XAxis>
                        <YAxis 
                            tickFormatter={ (v) => this.props.formatAxisCapacityValue(v) }
                            yAxisId={0}>
                        </YAxis>
                        <YAxis 
                            tickFormatter={ (v) => this.props.formatAxisMarginValue(v) }
                            yAxisId={1} 
                            orientation="right">
                        </YAxis>                        
                        <Tooltip 
                            isAnimationActive={false} 
                            labelFormatter={this._onTooltipFormatLabel}
                            formatter={this._onTooltipFormatValue} 
                        />
                        <Legend />
                        
                        {barSeries}
                        {scatterSeries}
                        {lineSeries}
                    </ComposedChart>
                </div>
            </ErrorBoundary>
        );
    }
}

SupplyAndDemandChart.propTypes = {
    height: PropTypes.number,
    data: PropTypes.object,
    argumentColumn: PropTypes.object,
    capacityByFuelColumns: PropTypes.arrayOf(PropTypes.object),
    demandColumn: PropTypes.object,
    reserveMarginColumn: PropTypes.object,

    getSeriesName: PropTypes.func,

    getArgumentTitle: PropTypes.func,
    getArgument: PropTypes.func,
    getValue: PropTypes.func,

    formatArgument: PropTypes.func,
    formatAxisCapacityValue: PropTypes.func,
    formatAxisMarginValue: PropTypes.func,
    formatTooltipCapacityValue: PropTypes.func,
    formatTooltipMarginValue: PropTypes.func,
}

SupplyAndDemandChart.defaultProps = {
    height: 140,
}

export default SupplyAndDemandChart;
