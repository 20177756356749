import React from 'react';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { GeoJSON } from 'ol/format';
import { Circle, Style, Stroke, Fill } from 'ol/style';
import AppConfig from '../../AppConfig';
import AppTrace from '../../AppTrace';
import ErrorState from '../../ErrorState';
import Layer from './Layer';
import Locale from '../../locale/Locale';
import WeatherStationsPopup from 'spatial-awareness/popup/weather-stations/WeatherStationsPopup';

const t = Locale.getResourceString.bind(Locale);
const wkx = require('wkx');

export default class WeatherStations extends Layer {
    constructor(apiData) {
        super();
    }

    // Gets a string key that identifies this layer in collections, localization resource, etc.
    get key() {
        return "weatherStations";
    }

    // Gets the stack order of the layer.  Lower zIndex layers are stacked at the bottom
    get zIndex() {
        // Point layers on top: 20 and up
        return 24;
    }

    // Gets the underlying layer object
    get vectorLayer() {
        return this._vectorLayer;
    }

    // Renders popup content for a feature in this layer
    renderPopupContent(feature) {
        return (
            <WeatherStationsPopup
                feature={feature}
            />
        );
    }



    _getUrl(view) {
        return AppConfig.dataService.baseUrl + '/spatial-awareness/weather-stations';
    }

    _createLayer(view) {
        try {
            // Derive all graphics
            this._allFeatures = this._getAllFeatures();

            this._vectorLayer = new VectorLayer({
                key: this.key,
                source: new VectorSource({
                    features: this._allFeatures,
                }),
                style: this._getStyle,
                opacity: 1.0,
                visible: true,
            });
        }
        catch (err) {
            AppTrace.traceError("Substations.ctor: " + err)
            ErrorState.setFault("Error mapping substations");
        }
    }

    // Renders legend content for this layer
    _renderDefaultLegendContent() {
        if (this.isVisible) {
            return (
                <div key={this.key + '-legend-content'} className="ev-legend-layer-content">
                    <span className="ev-legend-layer-name">{t('spatialAwareness.layers.weatherStations.title')}</span><br />
                    <span className="ev-legend-symbol" style={{ color: 'rgba(0, 0, 0, 0.75)' }}>&#9675;</span><span className="ev-legend-theme-value">{t('spatialAwareness.layers.weatherStations.popup.title')}</span><br />
                </div>
            );
        }
    }

    // Gets the default style for the selected feature
    _getDefaultStyle(feature, resolution) {
        return new Style({
            image: new Circle({
                radius: 6,
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.75)',
                    width: 1.5
                }),
                fill: new Fill({
                    color: 'rgba(0, 0, 0, 0.0)',
                })
            })
        });
    }

    // Generate all of the features that will be added to the map.
    _getAllFeatures() {
        let features = [];
        let olGeoJSON = new GeoJSON();
        this._allFuels = {};

        // Iterate over all entities in the API data
        let idIndex = this._apiData.metadata.columns.id.index;
        let nameIndex = this._apiData.metadata.columns.name.index;
        let geographyIndex = this._apiData.metadata.columns.geography.index;

        for (let i = 0; i < this._apiData.rows.length; ++i) {
            let row = this._apiData.rows[i];

            // Parse wkt format
            let geoJSON = wkx.Geometry.parse(row[geographyIndex]).toGeoJSON();

            // Add a feature
            features.push(new Feature({
                layerKey: this.key,
                entityId: row[idIndex],
                name: row[nameIndex],

                geometry: olGeoJSON.readGeometry(geoJSON, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                })
            }));
        }

        return features;
    }
}