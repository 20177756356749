import React from 'react';
import ErrorBoundary from 'ErrorBoundary';
import Locale from '../../locale/Locale';

import './Legend.scss';

const t = Locale.getResourceString.bind(Locale);

class Legend extends React.Component {
    constructor(props, context) {
        super(props);

        this.state = {
            isVisible: true,
        };
    }

    static rgbToHex(rgb) {
        return '#' +
            rgb[0].toString(16).padStart(2, '0') +
            rgb[1].toString(16).padStart(2, '0') +
            rgb[2].toString(16).padStart(2, '0');
    }



    render() {
        let legendContent = (<span>{t("spatialAwareness.legend.noLegend")}</span>);
        if (this.props.layers != null) {
            // Render all legend content
            let temp = this.props.layers
                .map(l => l.renderLegendContent())
                .filter(content => content != null);

            // Add dividers
            for (let i = temp.length - 1; i > 0; --i) {
                temp.splice(i, 0, (<div key={'divider-' + i} className="ev-legend-divider" />));
            }

            // Update legend content if we have any to show
            if (temp.length > 0) {
                legendContent = temp;
            }
        }

        return (
            <ErrorBoundary>
                <div className="ev-legend">
                    {legendContent}
                </div>
            </ErrorBoundary>
        );
    }
}

export default Legend;
