export default {
    header: {
        anonymous: 'Anonymous',

        english: 'English',
        japanese: '日本語',

        signIn: 'Sign in',
        signOut: 'Sign out',

        currency: 'Currency',
        language: 'Language',
        downloads: 'Downloads',
        downloadsTooltip: 'Trial access does not include data and shapefile downloads. Please upgrade for access.',

        temperature: 'Temperature',
        speed: 'Speed',
    },

    footer: {
        analysisDerivedFrom: 'Analysis derived from Velocity Suite',
    },

    licensing: {
        caption: 'Copyright and Licensing',
        copyright: '© Copyright {0}-{1} {2}. All rights reserved.',
        thirdParty: 'THIRD PARTY NOTICES AND INFORMATION',
        thisProductMayInclude: 'This software may include or incorporate material from the files below (collectively, third party free/open source software "FOSS"). Please note: {0} is not the original author of the FOSS. The URL for the original file source, the original copyright notice and the license under which {0} received FOSS are set forth below together with the full text of such license. Such notices and license are provided solely for your information. {0}, not the third party, licenses this FOSS to you under the terms in which you received the {0} software or the services. Unless applicable law gives you more rights, {0} reserves all other rights not expressly granted under such agreements, whether by implication, estoppel or otherwise. If you wish to obtain access to the source code to which you are entitled under the applicable FOSS license(s) (such as GNU Lesser/General Public License), or if you have any questions regarding FOSS, you may send your request in writing to:',
    },

    fault: {
        applicationName: 'Japan Energy Market Intelligence',

        caption: 'Application Error',
        buttonRefresh: 'Refresh',
        buttonOkay: 'OK',

        contentOverview: 'The following error occurred while processing your request.',
        contentInstructions: 'Please try refreshing the page, or try again later.  If the problem persists, please contact customer support.',
        contentSupport: 'Support Inquiries:',

        signInFailed: 'Login failed',
        signInInstructions: 'Please sign out and then sign in again.  If the problem persists, please contact customer support.',

        popupBlocked: 'Popup blocked',
        popupInstructions: 'The app is unable to open the login popup.  Please allow popups from this site and refresh the page.  If the problem persists, please contact customer support.',
    },

    loadIndicator: {
        caption: 'Loading, please wait...',
    },

    tabs: {
        spatialAwareness: 'Spatial Awareness',
        supplyAndDemand: 'Supply and Demand',
        priceHistory: 'Price History',
        priceForecast: 'Price Forecast',
        companyFinancials: 'Company Financials',
        weather: 'Weather',
    },

    grids: {
        previous: 'Previous',
        page: 'Page',
        of: '/',
        next: 'Next',
    },

    navigation: {
        viewDetails: 'View Details',
    },

    units: {
        megaWatts: 'MW',
        megaWattHour: 'MWh',
        kiloLiters: 'kl',
        kiloWatts: 'kW',
        kiloWattHour: 'kWh',
        kiloVolts: 'kV',
        sqkilometers: 'Km2',
        mmBtu: 'MMBtu',
        gigajoule: 'GJ',
        percent: '%',
        millionsOf_JPY: 'Millions of Yen',
        millionsOf_USD: 'Millions of Dollars',
        millionsOf_EUR: 'Millions of Euros',
        celsius: '℃',
        fahrenheit: '℉',
        metersPerSecond: 'm/s',
        milesPerHour: 'mph',
        gigaWattHour: 'GWh',
    },

    general: {
        noDataAvailable: 'N/A',
        undetermined: 'Undetermined',
        selectAll: 'Select All',
        clearAll: 'Clear All',
        select: 'Select...',
        multipleSelectedValues: 'Multiple Selected Values...',
        showData: 'Show Data',
        hideData: 'Hide Data',

        lineChart: 'Line Chart',
        barChart: 'Bar Chart',
        areaChart: 'Area Chart',

        listSeparator: ', ',

        expandAll: "Expand All",
        collapseAll: "Collapse All",
    },

    spatialAwareness: {
        caption: 'Electric Infrastructure and Geopolitical Regions',

        search: {
            title: 'Search',
        },

        legend: {
            showLegend: 'Show Legend',
            hideLegend: 'Hide Legend',

            noLegend: 'No Legend',
            undetermined: 'Undetermined',
            under55: 'Under 55',
            '56-77': '56-77',
            '78-154': '78-154',
            187: '187',
            220: '220',
            '250-275': '250-275',
            above500: '500 and above',
        },

        features: {
            title: 'Selected Map Features',
        },

        layers: {
            title: 'Layers',
            download: 'Shapefiles',

            powerPlants: {
                title: 'Power Plants',
                fuel: 'Fuel',

                popup: {
                    title: 'Power Plant',
                    name: 'Name',
                    primaryFuel: 'Primary Fuel',
                    operatingCapacity: 'Operating Capacity',
                    operatingUnits: 'Operating Units',
                    plantStatus: 'Plant Status',
                    owner: 'Majority Owner',
                    firstOnlineDate: 'First Online Date',

                    unitsByFuelAndStatus: 'Fuel and Status',
                    generatingUnits: 'Generating Units',
                    unitName: 'Name',
                    primeMover: 'Prime Mover',
                    fuel: 'Primary Fuel',
                    capacity: 'Capacity',
                    onlineDate: 'Online Date',
                    retirementDate: 'Retirement Date',
                    status: 'Status',
                },
            },

            substations: {
                title: 'Substations',

                popup: {
                    title: 'Substation',
                    name: 'Name',
                    minKV: 'Minimum Voltage',
                    maxKV: 'Maximum Voltage',
                },
            },

            weatherStations: {
                title: 'Weather Stations',

                popup: {
                    title: 'Weather Station',
                    name: 'Name',
                },
            },

            lngFacilities: {
                title: 'LNG Facilities',

                popup: {
                    title: 'LNG Facility',
                    name: 'Name',
                    storageCapacity: 'Storage Capacity',
                    owners: 'Owners',
                    status: 'Status',
                    type: 'Facility Type',
                    onlineYear: 'Online Year',
                    facilityId: 'Facility ID',
                },
            },

            transmissionLines: {
                title: 'Transmission Lines',

                popup: {
                    title: 'Transmission Line',
                    name: 'Name',
                    voltage: 'Voltage',
                    voltageClass: 'VoltageClass',
                },
            },

            utilityServiceTerritories: {
                title: 'Utility Service Territories',

                popup: {
                    title: 'Utility Service Territory',
                    name: 'Company Name',

                    supplyAndDemand: 'Supply and Demand',
                    totalSupply: 'Total Supply',
                    demand: 'Demand',
                    reserveMargin: 'Reserve Margin',
                    capacityByFuel: 'Capacity by Fuel',

                    priceHistory: 'Price History',
                    priceForecast: 'Price Forecast',
                    financialHighlights: 'Financial Highlights',
                    fiscalYear: 'Fiscal Year {0}',

                    assets: 'Assets',
                    liabilities: 'Liabilities',
                    cashStart: 'Cash (Start of Year)',
                    cashEnd: 'Cash (End of Year)',
                    operatingRevenue: 'Operating Revenue',
                    nonOperatingRevenue: 'Non-Operating Revenue',
                    totalRevenue: 'Total Revenue',
                    operatingExpenses: 'Operating Expenses',
                    nonOperatingExpenses: 'Non-Operating Expenses',
                    totalExpenses: 'Total Expenses',
                    netIncome: 'Net Income for Shareholders',

                    localDateTime: 'Local Datetime',
                    year: 'Year',
                },

                columnCaptions: {
                    offPeak: 'Off Peak',
                    onPeak: 'On Peak',
                    average: 'Average',

                    areaPrice: 'Area Price',
                },
            },

            prefectures: {
                title: 'Prefectures',

                popup: {
                    title: 'Prefecture',
                    name: 'Prefecture Name',
                    type: 'Type',
                    source: 'Source',
                    populationUrban: 'Urban Population',
                    populationRural: 'Rural Population',
                    populationTotal: 'Total Population',
                    areaUrban: 'Urban Area',
                    areaRural: 'Rural Area',
                    areaTotal: 'Total Area',
                    densityUrban: 'Urban Population Density',
                    densityRural: 'Rural Population Density',
                    densityTotal: 'Total Population Density',
                },
            },
        },

        animations: {
            title: 'Animations',

            none: {
                title: 'None',
            },

            averagePriceForecast: {
                title: 'Average Price Forecast',
            },

            onPeakPriceForecast: {
                title: 'On Peak Price Forecast',
            },

            offPeakPriceForecast: {
                title: 'Off Peak Price Forecast',
            },

            areaPrice: {
                title: 'Area Price',
            },

            windSpeedAndDirection: {
                title: 'Wind Speed and Direction',
            },
        },
    },

    supplyAndDemand: {
        caption: 'Supply and Demand',

        filters: {
            caption: 'Chart Settings',

            chartSeries: 'Chart Series',

            chartBy: {
                caption: "Chart By",

                neByFuel: 'New Entrants by Primary Fuel',
                neByPrimeMover: 'New Entrants by Prime Mover',
                neByStatus: 'New Entrants by Status',

                refCaseBaseline: 'Reference Case Baseline',
                refCaseHighFuelPrice: 'Reference Case High Fuel Price',
                refCaseRenewableGenerationTargets: 'Reference Case Renewable Generation Targets',
                refCaseFullNuclearRestart: 'Reference Case Full Nuclear Restart',
            },

            utilityServiceTerritories: {
                caption: 'Utility Service Territories',
            },

            chartType: {
                caption: 'Chart Type',
                generationByFuelType: 'Generation by Fuel Type',
                demandByTerritory: 'Demand by Service Territory',
            },

            fuelType: {
                caption: 'Fuels',
            },

            totalDemand: {
                caption: 'Total Demand',
            },

            view: 'View',
            granularity: {
                caption: 'Granularity',
                A: 'Annual',
                M: 'Monthly',
                D: 'Daily',
                H: 'Hourly',
                MoY: 'Month of Year',
                HoD: 'Hour of Day',
            },
            aggregation: {
                caption: 'Aggregation',
                min: 'Min',
                max: 'Max',
                average: 'Average',
                sum: 'Sum',
                stdev: 'Std Deviation',
                count: 'Count'
            },

            dateRange: 'Date Range',
            hour: 'Hour of Day',
            month: 'Month of Year',
            year: 'Year',
        },

        tabs: {
            forward: {
                caption: 'Forward Supply & Demand',
            },
            historic: {
                caption: 'Historical Supply & Demand',
            },
        },

        chart: {
            capacity: 'Capacity',
            generation: 'Generation',
            totalEnergy: 'Total Energy',
            reserveMargin: 'Reserve Margin',
            totalSupply: 'Total Supply',

            supply: {
                axisLabels: {
                    min: 'Minimum Supply',
                    max: 'Maximum Supply',
                    average: 'Average Supply',
                    sum: 'Sum of Supply',
                    stdev: 'Std Deviation of Supply',
                    count: 'Count of Supply',
                },
            },

            demand: {
                axisLabels: {
                    min: 'Minimum',
                    max: 'Maximum',
                    average: 'Average',
                    sum: 'Sum',
                    stdev: 'Std Deviation',
                    count: 'Count',
                },
            },
        },
    },

    companyFinancials: {
        caption: 'Company Financials',

        filters: {
            caption: 'Chart Settings',

            view: 'View',
            chartBy: 'Chart By',

            utilityServiceTerritories: 'Utility Service Territories',

            year: 'Year',
        },

        charts: {
            balanceSheet: {
                caption: 'Balance Sheet',
                yAxisLabel: 'Assets and Liabilities',
            },

            cashFlow: {
                caption: 'Cash Flow',
            },

            statementOfIncome: {
                caption: 'Statement of Income',
            },
        },
    },

    priceHistory: {
        caption: 'Price History',

        filters: {
            caption: 'Filters and Aggregation',

            chartSeries: 'Chart Series',
            view: 'View',
            granularity: {
                caption: 'Granularity',
                A: 'Annual',
                M: 'Monthly',
                D: 'Daily',
                H: 'Hourly',
                MoY: 'Month of Year',
                HoD: 'Hour of Day',
            },
            aggregation: {
                caption: 'Aggregation',
                min: 'Min',
                max: 'Max',
                average: 'Average',
                sum: 'Sum',
                stdev: 'Std Deviation',
                count: 'Count'
            },

            dateRange: 'Date Range',
            hour: 'Hour of Day',
            month: 'Month of Year',
            year: 'Year',
        },

        charts: {
            spotMarket: {
                caption: 'JEPX Spot & Intraday Market',

                axisLabels: {
                    price: {
                        min: 'Minimim Price',
                        max: 'Maximum Price',
                        average: 'Average Price',
                        sum: 'Sum of Price',
                        stdev: 'Std Deviation of Price',
                        count: 'Count of Price',
                    },

                    volume: {
                        min: 'Minimum Volume',
                        max: 'Maximum Volume',
                        average: 'Average Volume',
                        sum: 'Sum of Volume',
                        stdev: 'Std Deviation of Volume',
                        count: 'Count of Volume'
                    }
                },

                seriesGroups: {
                    indexPrices: 'Index Prices',
                    areaPrices: 'Area Prices',
                    intradayPrices: 'Intraday Prices',
                    volumes: 'Trading Volumes',
                },
            },

            forwardMarket: {
                caption: 'JEPX Forward Market',

                buttonTitles: {
                    colorByInterconnect: 'Color by interconnect',
                    colorByPeakType: 'Color by peak type',
                    colorByContractLength: 'Color by contract length',
                    detailedTheme: 'Detailed Theme',
                },

                seriesGroups: {
                    contractLength: 'Contract Length',
                    peakType: 'Peak Type',
                    interconnect: 'Interconnect',
                },

                seriesTypes: {
                    week: 'Week',
                    month: 'Month',
                    year: 'Year',
                    allHours: '24 Hour',
                    daytime: 'Daytime',
                    tokyo: 'Tokyo',
                    kansai: 'Kansai',
                    system: 'System',
                },

                series: {
                    kn_mth_24_total_volume: 'Kansai Monthly 24-Hour',
                    kn_mth_dt_total_volume: 'Kansai Monthly Daytime',
                    kn_wk_24_total_volume: 'Kansai Weekly 24-Hour',
                    kn_wk_dt_total_volume: 'Kansai Weekly Daytime',

                    sys_mth_24_total_volume: 'System Monthly 24-Hour',
                    sys_mth_dt_total_volume: 'System Monthly Daytime',
                    sys_wk_24_total_volume: 'System Weekly 24-Hour',
                    sys_wk_dt_total_volume: 'System Weekly Daytime',
                    sys_yr_24_total_volume: 'System Annual 24-Hour',

                    tk_mth_24_total_volume: 'Tokyo Monthly 24-Hour',
                    tk_mth_dt_total_volume: 'Tokyo Monthly Daytime',
                    tk_wk_24_total_volume: 'Tokyo Weekly 24-Hour',
                    tk_wk_dt_total_volume: 'Tokyo Weekly Daytime',
                    tk_yr_24_total_volume: 'Tokyo Annual 24-Hour',
                },

                axisLabels: {
                    y: 'Total Volume of Contracts',
                },

                tooltips: {
                    category: 'Category',
                    volume: 'Volume',
                    minValue: 'Min Price',
                    maxValue: 'Max Price',
                    avgValue: 'Avg Price',
                },
            },

            lngMarket: {
                caption: 'LNG Market',

                axisLabels: {
                    price: {
                        min: 'Minimim Price',
                        max: 'Maximum Price',
                        average: 'Average Price',
                        sum: 'Sum of Price',
                        stdev: 'Std Deviation of Price',
                        count: 'Count of Price',
                    },
                },
            },
        },
    },

    priceForecast: {
        caption: 'Market Clearing Prices',
        marketChart: {
            caption: 'Market Clearing Prices',
        },
        fuelChart : {
            caption: 'Fuel Price Forecast',
        },
        download: {
            marketClearingPricesMonthly: 'Monthly Market Clearing Prices',
            marketClearingPricesAnnual: 'Annual Market Clearing Prices',
        },
        series: {
            avg_val: 'Average Value',
            off_peak_val: 'Off Peak Value',
            on_peak_val: 'On Peak Value',
        },
        panel: {
            caption: 'Filters and Aggregation',
            compareBy: {
                caption: 'Compare By',
                region: 'Region',
                peakType: 'Peak Type',
                scenario: 'Scenario',
            },
            chartSeries: {
                caption: 'Utility Service Territories',
            },
            fuelType: {
                caption: 'Fuel Type',
            },
            scenario: {
                caption: 'Reference Case Scenario',
            },
            datePicker: {
                caption: 'Date range',
            },
            peakType: {
                caption: 'Peak Type',
                average: 'Average',
                offPeak: 'Off Peak',
                onPeak: 'On Peak',
                all: 'All',
            },
            granularity: {
                caption: 'Granularity',
                A: 'Annual',
                M: 'Monthly',
                MoY: 'Month of Year',
            },
            aggregation: {
                caption: 'Aggregation',
                min: 'Min',
                max: 'Max',
                average: 'Average',
                sum: 'Sum',
                stdev: 'Std Deviation',
                count: 'Count',
            },
            view: {
                caption: 'View',
            },
            month: {
                caption: 'Month of Year',
            },
            year: {
                caption: 'Year',
            }
        },
    },

    weather: {
        caption: "Weather",
        localDateTime: 'Local Datetime',
        weatherStation: 'Weather Station',
        download: 'Hourly Weather',

        temperature: {
            caption: 'Temperature',
        },
        
        windSpeed: {
            caption: 'Wind Speed',
        },
        
        windDirection: {
            caption: 'Wind Direction',
        },

        wind: {
            caption: 'Wind',
        },
        
        sunshineDuration: {
            caption: 'Sunshine Duration',
        },
  
        filters: {
            caption: "Filters and Aggregation",

            stations: {
                upToNStations: "Up to {0} weather stations may be selected",
            },
            granularity: {
                caption: 'Granularity',
                A: 'Annual',
                M: 'Monthly',
                D: 'Daily',
                H: 'Hourly',
                MoY: 'Month of Year',
                HoD: 'Hour of Day',
            },
            aggregation: {
                caption: 'Aggregation',
                min: 'Min',
                max: 'Max',
                average: 'Average',
                sum: 'Sum',
                stdev: 'Std Deviation',
                count: 'Count',
            },
            view: {
                caption: 'View',
            },
            dateRange: {
                caption: 'Date Range',
            },
            hour: {
                caption: 'Hour of Day',
            },
            month: {
                caption: 'Month of Year',
            },
            year: {
                caption: 'Year',
            },
        },

        charts: {
            temperature: {
                axisLabels: {
                    min: 'Minimim Temperature',
                    max: 'Maximum Temperature',
                    average: 'Average Temperature',
                    sum: 'Sum of Temperature',
                    stdev: 'Std Deviation of Temperature',
                    count: 'Count of Temperature',
                },
            },

            wind: {
                showWindDirection: 'Show Wind Direction',
                hideWindDirection: 'Hide Wind Direction',
                
                axisLabels: {
                    min: 'Minimim Wind Speed',
                    max: 'Maximum Wind Speed',
                    average: 'Average Wind Speed',
                    sum: 'Sum of Wind Speed',
                    stdev: 'Std Deviation of Wind Speed',
                    count: 'Count of Wind Speed',
                },
            },

            sunshineDuration: {
                axisLabels: {
                    min: 'Minimim Sunshine Duration',
                    max: 'Maximum Sunshine Duration',
                    average: 'Average Sunshine Duration',
                    sum: 'Sum of Sunshine Duration',
                    stdev: 'Std Deviation of Sunshine Duration',
                    count: 'Count of Sunshine Duration',
                },
            },
        },
    },
};