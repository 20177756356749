import React from 'react';
import AppTrace from './AppTrace';
import ErrorState from './ErrorState';
import Locale from 'locale/Locale';

const t = Locale.getResourceString.bind(Locale);

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = { 
            error: null, 
            errorInfo: null,

            faultStateInfo: null,
        };
    }

    _isFaulted() {
        return (this.state.error != null || this.state.errorInfo != null);
    }
  
    componentDidCatch(error, errorInfo) {
        //debugger;

        try {
            // Convert parameters to safe values
            let message = "ErrorBoundary.componentDidCatch: ";
            if (this.props.stateInfo != null) {
                message += ("stateInfo=\"" + this.props.stateInfo + "\": ");
            }
            let errorMessage = (error != null && error.message != null) ? error.message : "<unknown error>";
            // let callStack = (error != null && error.stack != null) ? error.stack : "";
            // let componentStack = (errorInfo != null && errorInfo.componentStack != null) ? errorInfo.componentStack : "";

            // Trace the error
            AppTrace.traceError(message + errorMessage);
            console.error(message + errorMessage);
        }
        catch (err) {}

        // Set state 
        this.setState({
            error: error,
            errorInfo: errorInfo,

            faultStateInfo: this.props.stateInfo
        });

        // Set a fault based on properties
        if (this.props.setFaults) {
            ErrorState.setFault(t('fault.caption'));
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps != null && nextProps.stateInfo != null && this._isFaulted()) {
            try {
                let stateInfoChanged = (nextProps.stateInfo !== this.state.faultStateInfo);
                if (stateInfoChanged) {
                    // If the stateInfo changed, then clear the error state
                    this.setState({
                        error: null,
                        errorInfo: null,
                        faultStateInfo: null
                    });
                }
            }
            catch (err) {}
        }
    }
  
    render() {
        if (this._isFaulted()) {
            return null;
        }
        else {
            return this.props.children;
        }
    }
}

ErrorBoundary.defaultProps = {
    setFaults: true,
    // This property should contain information that, if changed, indicates we can clear the error state and try to render children again
    stateInfo: null,
};

export default ErrorBoundary;