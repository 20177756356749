class JWT {
    constructor(token) {
        this.token = token
    }

    //Split the token
    _base64Url = () => {
        return this.token.split('.')[1]
    }
    //Clean up to only get a base64
    _base64 = () => {
        return this._base64Url().replace(/-/g, '+').replace(/_/g, '/')
    }
    //Decode and Parse to a JSON
    _decode = () => {
        return JSON.parse(
            decodeURIComponent(
                atob(this._base64())
                    .split('')
                    .map((c) => {
                        let bit = (`00${c.charCodeAt(0).toString(16)}`).slice(-2)
                        return `%${bit}`
                    })
                    .join('')
            )
        )
    }
    //Check if the token has an valid [] in roles
    _tokenHasRoles = () => {
        return this._decode().hasOwnProperty(`roles`) && this._decode().roles.length !== 0 ? true : false
    }
    //Find the role in the token roles
    _hasRole = role => {
        return (this._decode().roles.indexOf(role))
    }
    //Check if the token has the role
    hasRole = role => {
        return this._tokenHasRoles() ? this._hasRole(role) !== -1 ? true : false : false
    }
}

export default JWT