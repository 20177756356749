import AppConfig from 'AppConfig';
import AppTrace from 'AppTrace';
import Authentication from 'Authentication';
import DataFormatting from 'DataFormatting';

const axios = require('axios');

export default class CompanyFinancialsDataFactory {
    get() {
        return new Promise((resolve, reject) => {
            // Get access token
            Authentication.getAccessToken()
                // Get API data
                .then(accessToken => {
                    let authString = 'Bearer '.concat(accessToken);
                    let url = AppConfig.dataService.baseUrl + '/company-financials';

                    return axios.get(url, {
                        headers: {
                            Authorization: authString,
                            Accept: "application/json",
                            "cache-control": "no-cache",
                        }
                    });
                })
                .then(response => {
                    let data = response.data;
                    this._postProcessData(data);

                    resolve(data);
                })
                .catch((error) => {
                    AppTrace.traceError('Error in CompanyFinancialsDataFactory.get: ' + error.message);
                    reject(new Error("Error getting company financials data"));
                });
        });
    }

    _postProcessData(data) {
        // Convert all result fields
        DataFormatting.convertResultFields(data.usts);
        DataFormatting.convertResultFields(data.balanceSheet);
        DataFormatting.convertResultFields(data.cashFlow);
        DataFormatting.convertResultFields(data.statementOfIncome);

        // Postprocess all column captions
        Object.values(data.balanceSheet.metadata.columns).forEach(col => col.caption = DataFormatting.parseJSON(col.caption));
        Object.values(data.cashFlow.metadata.columns).forEach(col => col.caption = DataFormatting.parseJSON(col.caption));
        Object.values(data.statementOfIncome.metadata.columns).forEach(col => col.caption = DataFormatting.parseJSON(col.caption));

        // Postprocess all currencies
        this._postProcessCurrencies(data.balanceSheet);
        this._postProcessCurrencies(data.cashFlow);
        this._postProcessCurrencies(data.statementOfIncome);

        // Create a lookup from utility service territory ID to name
        let idIndex = data.usts.metadata.columns.id.index;
        let nameIndex = data.usts.metadata.columns.name.index;
        data.ustsLookup = data.usts.rows.reduce((obj, row) => {
            obj[row[idIndex]] = row[nameIndex];
            return obj;
        }, {});

        // Derive allYears
        let yearIndex = data.balanceSheet.metadata.columns.year.index;
        let yearLookup = data.balanceSheet.rows.reduce((obj, row) => {
            obj[row[yearIndex]] = true;
            return obj;
        }, {});
        data.allYears = Object.keys(yearLookup).map(y => parseInt(y, 10));
        data.allYears.sort((a, b) => a - b);
    }

    _postProcessCurrencies(data) {
        let currencyColumns = Object.values(data.metadata.columns).filter(c => c.tag != null && c.tag.isCurrencyField);
        let convUSD = data.metadata.columns.conv_usd;
        let convEUR = data.metadata.columns.conv_eur;
        
        for (let i = 0; i < data.rows.length; ++i) {
            let row = data.rows[i];

            // Convert all currency columns
            for (let j = 0; j < currencyColumns.length; ++j) {
                let col = currencyColumns[j];
                let value = row[col.index];

                if (value != null) {
                    row[col.index] = {
                        JPY: value,
                        USD: value * row[convUSD.index],
                        EUR: value * row[convEUR.index],
                    };
                }
                else {
                    row[col.index] = {
                        JPY: null,
                        USD: null,
                        EUR: null,
                    }
                }
            }
        }
    }
}