import moment from 'moment';
import AppConfig from 'AppConfig';
import AppTrace from 'AppTrace';
import Authentication from 'Authentication';
import DataFormatting from 'DataFormatting';
import Locale from 'locale/Locale';
import DateRangePicker from 'common/DateRangePicker';

const axios = require('axios');

export default class HistoricSupplyAndDemandDataFactory {
    get() {
        return new Promise((resolve, reject) => {
            this._getAvailableDataRange()
                .then(availableDataRange => {
                    return this._getChartData(availableDataRange);
                })
                .then(chartData => {
                    resolve(chartData);
                })
                .catch((error) => {
                    AppTrace.traceError('Error in HistoricSupplyAndDemandDataFactory.get: ' + error.message);
                    reject(new Error("Error getting historic supply and demand data"));
                });
        });
    }

    _getAvailableDataRange() {
        return new Promise((resolve, reject) => {
            // Get access token
            Authentication.getAccessToken()
                // Get API data
                .then(accessToken => {
                    let authString = 'Bearer '.concat(accessToken);
                    let url = AppConfig.dataService.baseUrl + '/historic-supply-and-demand/available-data-range';

                    return axios.get(url, {
                        headers: {
                            Authorization: authString,
                            Accept: "application/json",
                            "cache-control": "no-cache",
                        }
                    });
                })
                .then(response => {
                    let data = response.data;
                    // Convert min and max to moment instances
                    data.min = DateRangePicker.getDate(Locale.parseMoment(data.min));
                    data.max = DateRangePicker.getDate(Locale.parseMoment(data.max));
                    resolve(data);
                })
                .catch((error) => {
                    AppTrace.traceError('Error in HistoricSupplyAndDemandDataFactory._getAvailableDataRange: ' + error.message);
                    reject(new Error("Error getting historic supply and demand available data range"));
                });
        });
    }

    _getChartData(availableDataRange) {
        return new Promise((resolve, reject) => {
            // Determine the range of data to be requested.  This will be up to a 
            // configured number of months.
            let max = availableDataRange.max.valueOf();
            let clampedMin = moment.utc(max);
            // Back up by the configured number of months, then add 1 day to avoid an extra day of data
            clampedMin
                .subtract(AppConfig.historicSupplyAndDemand.monthsBack, 'months')
                .add(1, 'days');
            // Take the most recent min date so we don't request more than the configured number
            // of months, or more than what's available in the database
            if (clampedMin.valueOf() > availableDataRange.min.valueOf())
                availableDataRange.min = clampedMin;

            // Get access token
            Authentication.getAccessToken()
                // Get API data
                .then(accessToken => {
                    let authString = 'Bearer '.concat(accessToken);
                    let url = `${AppConfig.dataService.baseUrl}/historic-supply-and-demand/chart-data?minJSTime=${availableDataRange.min.valueOf()}`;

                    return axios.get(url, {
                        headers: {
                            Authorization: authString,
                            Accept: "application/json",
                            "cache-control": "no-cache",
                        }
                    });
                })
                .then(response => {
                    let data = response.data;
                    data.availableDataRange = availableDataRange;
                    this._postProcessData(data);

                    resolve(data);
                })
                .catch((error) => {
                    AppTrace.traceError('Error in HistoricSupplyAndDemandDataFactory._getChartData: ' + error.message);
                    reject(new Error("Error getting historic supply and demand chart data"));
                });
        });
    }

    _postProcessData(data) {
        // Postprocess all results fields
        DataFormatting.convertResultFields(data.usts);
        DataFormatting.convertResultFields(data.chartData);

        // Postprocess all column captions
        Object.values(data.chartData.metadata.columns).forEach(col => col.caption = DataFormatting.parseJSON(col.caption));

        // Create a lookup from utility service territory ID to name
        let idIndex = data.usts.metadata.columns.id.index;
        let nameIndex = data.usts.metadata.columns.name.index;
        data.ustsLookup = data.usts.rows.reduce((obj, row) => {
            obj[row[idIndex]] = row[nameIndex];
            return obj;
        }, {});
        
        // Create a lookup from series key to series names
        data.seriesLookup = {};
        Object.values(data.chartData.metadata.columns).forEach(col => {
            data.seriesLookup[col.name] = col.caption;
        });
    }
}