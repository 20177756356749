export default class SupplyAndDemandStats {
    constructor() {
        this._filters = {};
        this._filtered = [];
        this._aggregated = [];
        this._barCategoryColumns = [];

        this.dl = require('@hitachi.energy.digitalforce.node/datalib').dl;
    }

    get filters() {
        return this._filters;
    }

    get filtered() {
        return this._filtered;
    }

    get aggregated() {
        return this._aggregated;
    }

    get barCategoryColumns() {
        return this._barCategoryColumns;
    }

    calculate(data, filters) {
        const NonValueColumns = {
            year: true,
            elec_srvc_id: true,
        };

        // Clean up old calculated values
        this._cleanup();

        // Bar-category columns will be any column beyond the first 3 (year, elec_srvc_id, peakload)
        this._barCategoryColumns = Object.values(data.metadata.columns).filter(c => c.index >= 3);

        // Save a copy of the original filters sent to us
        this._filters = JSON.parse(JSON.stringify(filters))

        // Filter the data based on state
        let valueColumns = Object.values(data.metadata.columns).filter(c => !NonValueColumns.hasOwnProperty(c.name));
        this._filtered = this._filter(data, filters, valueColumns);
        this._aggregated = this._aggregate(data, filters, valueColumns);
    }



    _cleanup() {
        if (this._aggregated != null) {
            this._aggregated.length = 0;
        }

        if (this._filtered != null) {
            this._filtered.length = 0;
        }

        if (this._barCategoryColumns != null) {
            this._barCategoryColumns.length = 0;
        }
    }

    _beforeFilter(data, filters, valueColumns) {
        // Transform selection arrays into objects for easy lookup without searches
        filters.selectedYears = filters.selectedYears.reduce((obj, y) => { obj[y] = true; return obj; }, {});
        filters.supplyAndDemandServiceTerritories = filters.supplyAndDemandServiceTerritories.reduce((obj, t) => { obj[t] = true; return obj; }, {});
    }

    _filter(data, filters, valueColumns) {
        let filtered = [];

        // Prepare filters for use
        this._beforeFilter(data, filters, valueColumns);

        if (data != null) {
            // Get property indexes
            let yearIndex = data.metadata.columns.year.index;
            let serviceTerritoryIdIndex = data.metadata.columns.elec_srvc_id.index;

            for (let i = 0; i < data.rows.length; ++i) {
                let row = data.rows[i];
                let year = row[yearIndex];
                let serviceTerritoryId = row[serviceTerritoryIdIndex];

                if (this._rowMeetsFilterCriteria(year, serviceTerritoryId, filters)) {
                    let fields = valueColumns.reduce((obj, col) => { 
                        obj[col.name] = row[col.index];
                        return obj; 
                    }, {})
                    Object.assign(fields, { year: year });
                    filtered.push(fields);
                }
            }
        }

        //console.log(filtered);

        return filtered;
    }

    _rowMeetsFilterCriteria(year, serviceTerritoryId, filters) {
        let rowMeetsFilterCriteria = false;

        // Filter for hour/month/year selections
        if (filters.supplyAndDemandServiceTerritories[serviceTerritoryId] &&
            filters.selectedYears[year]) {
            rowMeetsFilterCriteria = true;
        }

        return rowMeetsFilterCriteria;
    }

    _aggregate(data, filters, valueColumns) {
        let aggregated = [];

        // Aggregate the data
        aggregated = this.dl.groupby('year').summarize(
            valueColumns.map(col => { 
                return {
                    name: col.name,
                    ops: ['sum'],
                    as: [col.name]
                };
            })
        ).execute(this._filtered);

        // Order by the categorizied moment
        aggregated.sort(this.dl.comparator('year'));

        // Postprocess...
        for (let i = 0; i < aggregated.length; ++i) {
            let row = aggregated[i];

            // Clean up unchartable values resulting from aggregation
            for (let j = 0; j < valueColumns.length; ++j) {
                let value = row[valueColumns[j].name];
                if (Number.isNaN(value) || !Number.isFinite(value)) {
                    row[valueColumns[j].name] = null;
                }
            }

            // Calculate total supply for this row
            row.total_supply = this.dl.sum(this._barCategoryColumns.map(c => row[c.name]));

            // Calculate reserve margin
            row.reserveMargin = (row.total_supply - row.peakload) / row.peakload;
        }

        //console.log(aggregated);

        return aggregated;
    }
}