import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, ComposedChart, Bar, XAxis, YAxis, Legend, Tooltip, Label } from 'recharts';

import Locale from 'locale/Locale';
import ErrorBoundary from 'ErrorBoundary';

import 'common/ReCharts.scss';

const t = Locale.getResourceString.bind(Locale);

/////////////////////////////////////////////////////////////////////////////
// This class encapsulates the balance sheet chart
/////////////////////////////////////////////////////////////////////////////
class BalanceSheetChart extends Component {
    static _Palette = {
        current_assets_val: '#1B4718', // Green 80
        non_current_assets_val: '#477A43', // Green 60

        current_liabilities_val: '#A30005', // Red 80
        non_current_liabilities_val: '#E12E2E', // Red 60
        tot_net_assets_val: '#EDE56F', // Yellow 20
    };

    constructor(props, context) {
        super(props);

        // Bind event handlers
        this._onFormatValue = this._onFormatValue.bind(this);
    }

    get _series() {
        const barSeriesDataKeys = {
            current_assets_val: { color: '#1B4718', stackId: 0 },
            non_current_assets_val: { color: '#477A43', stackId: 0 },
            current_liabilities_val: { color: '#A30005', stackId: 1 },
            non_current_liabilities_val: { color: '#E12E2E', stackId: 1 },
            tot_net_assets_val: { color: '#EDE56F', stackId: 1 },
        };

        let series = Object.keys(barSeriesDataKeys).map((key) => {
            return (
                <Bar
                    key={key} 
                    dataKey={key} 
                    name={Locale.getJSONFieldValue(this.props.data.metadata.columns[key].caption)} 
                    fill={barSeriesDataKeys[key].color} 
                    stackId={barSeriesDataKeys[key].stackId}
                    yAxisId={0}
                />
            );
        });

        return series;
    }

    get _yAxisLabelText() {
        return t('companyFinancials.charts.balanceSheet.yAxisLabel') + ' : ' + this.props.units;
    }



    _onFormatValue(value, name, props) {
        return Locale.formatNumber(value, 0);
    }



    render() {
        return (
            <ErrorBoundary>
                <div className="company-financials-chart-container">
                    <div className="company-financials-chart-title">
                        {this.props.title}
                    </div>
                    <div className="company-financials-chart">
                        <ResponsiveContainer>
                            <ComposedChart
                                data={this.props.chartData}
                                margin={{
                                    top: 20, right: 10, bottom: 10, left: 10,
                                }}
                                stackOffset='sign'
                            >
                                <XAxis 
                                    dataKey={this.props.chartBy}
                                    tickFormatter={ (v) => this.props.formatXAxisTick(v) }
                                />
                                <YAxis 
                                    tickFormatter={ (v) => this._onFormatValue(v) }
                                    width={110}
                                    yAxisId={0}>
                                    <Label 
                                        angle={-90} 
                                        position='insideLeft'
                                        value={this._yAxisLabelText}
                                        style={{textAnchor: 'middle'}}
                                    />
                                </YAxis>
                                <Tooltip 
                                    isAnimationActive={false} 
                                    labelFormatter={this.props.formatXAxisTick}
                                    formatter={this._onFormatValue} 
                                />
                                <Legend />

                                {this._series}
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </ErrorBoundary>
        );
    }
}

BalanceSheetChart.propTypes = {
    chartBy: PropTypes.string,
    chartData: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.object,
    title: PropTypes.string,
    units: PropTypes.string,
    formatXAxisTick: PropTypes.func,
}

BalanceSheetChart.defaultProps = {
    chartBy: 'year',
    chartData: {},
    data: {},
    title: '',
    units: '',
    formatXAxisTick: (v) => v,
}

export default BalanceSheetChart;
