import AppConfig from 'AppConfig';
import WeatherDataFactory from './WeatherDataFactory';

export default class SunshineDurationDataFactory extends WeatherDataFactory {
    ////////////////////////////////////////////////////////////////////////////
    // "Protected" interface
    ////////////////////////////////////////////////////////////////////////////
    get _key() {
        return 'sunshine-duration'
    }

    _getUrl(ids) {
        let idQuery = ids.map(id => `id=${id}`).join('&');
        return `${AppConfig.dataService.baseUrl}/weather/sunshine-duration?${idQuery}`
    }
    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
}