import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GridOffIcon from '@material-ui/icons/GridOff';
import GridOnIcon from '@material-ui/icons/GridOn';
import { HorizontalPanelResizer, ToggleButton } from 'webcore-ux/react/components'
import { DRAG_BAR_HEIGHT } from 'webcore-ux/react/components/HorizontalPanelResizer/constants';

import Locale from 'locale/Locale';

import './HorizontalSplitContainer.scss';

const t = Locale.getResourceString.bind(Locale);

class HorizontalSplitContainer extends Component {
    constructor(props, context) {
        super(props);

        this._calcTopPanelHeight = this._calcTopPanelHeight.bind(this);
        this._calcBottomPanel = this._calcBottomPanel.bind(this);
        this._onChange = this._onChange.bind(this);
    }

    _onChange(isSplit) {
        if (this.props.onChange != null) {
            this.props.onChange(isSplit);
        }
    }

    ////////////////////////////////////////////////////////////////////////////
    // The intent is to prevent the top panel from getting too small AND
    // to prevent the bottom panel from getting too small.  The way this is
    // done is by returning an expression that looks something like this:
    //   min(100% - 204px, 286px)
    // 
    // This expression is used by the underlying component to set height.
    // * In the sample above, 286 is the max of the current height and
    //   the minimum allowed height for the top panel.  This keeps the 
    //   top panel from gettng too small.
    // * 204 is basically the minimum allowed height for the bottom
    //   panel (plus the drag bar height).  By not allowing the top
    //   panel height to be bigger than 100% - 204px, we're also
    //   enforcing the bottom panel's min height setting.
    ////////////////////////////////////////////////////////////////////////////
    _calcTopPanelHeight(topPanelHeight) {
        return (
            "min( 100% - " + (this.props.bottomPanelMinHeight + DRAG_BAR_HEIGHT) + "px, " + Math.max(topPanelHeight, this.props.topPanelMinHeight) + "px )"
        );
    }

    ////////////////////////////////////////////////////////////////////////////
    // The intent here is to properly size the bottom panel.  Most of the 
    // time, it's not needed - BUT when the user is dragging the slider
    // down and the min bottom panel size is reached, the underlying 
    // component keeps calling this method with a bigger and bigger value
    // for the top panel height.  So we need to intervene and make sure
    // that the bottom panel's actual content doesn't get too small.
    // The expression looks something like this:
    //   max(100% - 334px, 200px)
    //
    // 200px is the min height for the bottom panel.  We take whatever is
    // bigger, that, or the clamped top panel height (clamped to not be)
    // bigger than it's actually allowed to be.
    ////////////////////////////////////////////////////////////////////////////
    _calcBottomPanel(topPanelHeight) {
        topPanelHeight = Math.max(topPanelHeight, this.props.topPanelMinHeight);
        return ("max( 100% - " + (topPanelHeight + DRAG_BAR_HEIGHT) + "px, " + this.props.bottomPanelMinHeight + "px)");
    }

    get _content() {
        return (this.props.isSplit) ?
        (
            <HorizontalPanelResizer
                topPanel={({ topPanelSettings, state, control }) => (
                    <div {...topPanelSettings}>
                        {this.props.topPanelContent}
                    </div>
                )}
                bottomPanel={({ bottomPanelSettings, state, control }) => (
                    <div {...bottomPanelSettings}>
                        {this.props.bottomPanelContent}
                    </div>
                )}
                settings={{
                    initTopPanelHeightPercentage: this.props.topPanelMinHeight / (this.props.topPanelMinHeight + this.props.bottomPanelMinHeight),
                    disable: false,
                    calcTopPanel: this._calcTopPanelHeight,
                    calcBottomPanel: this._calcBottomPanel,
                }}
            />
        ) :
        (
            this.props.topPanelContent
        );
    }

    render() {
        return (
            <>
                <div className={this.props.buttonContainerClassName}>
                    <ToggleButton 
                        size="small" 
                        selected={this.props.isSplit} 
                        title={(this.props.isSplit) ? t('general.hideData') : t('general.showData')}
                        value="isSplit"
                        onClick={() => this._onChange(!this.props.isSplit)}
                    >
                        { (this.props.isSplit) ? this.props.splitIcon : this.props.joinIcon }
                    </ToggleButton>
                </div>
                <div className="horizontal-split-container">
                    {this._content}
                </div>
            </>
        );
    }
}

HorizontalSplitContainer.propTypes = {
    buttonContainerClassName: PropTypes.string,
    isSplit: PropTypes.bool,
    joinIcon: PropTypes.node,
    splitIcon: PropTypes.node,
    topPanelContent: PropTypes.node,
    topPanelMinHeight: PropTypes.number,
    bottomPanelContent: PropTypes.node,
    bottomPanelMinHeight: PropTypes.number,
    onChange: PropTypes.func,
}

HorizontalSplitContainer.defaultProps = {
    buttonContainerClassName: 'company-financials-data-buttons',
    isSplit: false,
    joinIcon: <GridOffIcon fontSize="small" />,
    splitIcon: <GridOnIcon fontSize="small" />,
    topPanelContent: null,
    topPanelMinHeight: 200,
    bottomPanelContent: null,
    bottomPanelMinHeight: 200,
}

export default HorizontalSplitContainer;