import AppConfig from 'AppConfig';
import AppTrace from 'AppTrace';
import Authentication from 'Authentication';
import DataFormatting from 'DataFormatting';

import PriceHistoryDataFactory from './PriceHistoryDataFactory';

const axios = require('axios');

export default class ForwardMarketDataFactory extends PriceHistoryDataFactory {
    get() {
        return new Promise((resolve, reject) => {
            // Get access token
            Authentication.getAccessToken()
                // Get API data
                .then(accessToken => {
                    let authString = 'Bearer '.concat(accessToken);
                    let url = AppConfig.dataService.baseUrl + '/historic-prices/forward-market-chart-data';

                    return axios.get(url, {
                        headers: {
                            Authorization: authString,
                            Accept: "application/json",
                            "cache-control": "no-cache",
                        }
                    });
                })
                .then(response => {
                    let data = response.data;
                    this._dateTimeCol = data.metadata.columns.datetime;
                    DataFormatting.convertResultFields(data);
                    this._postProcessData(data);

                    resolve(data);
                })
                .catch((error) => {
                    AppTrace.traceError('Error in ForwardMarketDataFactory.get: ' + error.message);
                    reject(new Error("Error getting forward market prices"));
                });
        });
    }

    _postProcessData(data) {
        let currencyColumns = Object.values(data.metadata.columns).filter(c => c.tag != null && c.tag.isCurrencyField);
        let countAndVolumeColumns = Object.values(data.metadata.columns).filter(c => c.name.endsWith('num_contracts') || c.name.endsWith('total_volume'));
        let convUSD = data.metadata.columns.conv_usd;
        let convEUR = data.metadata.columns.conv_eur;
        
        for (let i = 0; i < data.rows.length; ++i) {
            let row = data.rows[i];

            // Convert all currency columns
            for (let j = 0; j < currencyColumns.length; ++j) {
                let col = currencyColumns[j];
                let value = row[col.index];

                // Treat all 0 values as nulls
                if (value != null && value !== 0) {
                    row[col.index] = {
                        JPY: value,
                        USD: value * row[convUSD.index],
                        EUR: value * row[convEUR.index],
                    };
                }
                else {
                    row[col.index] = {
                        JPY: null,
                        USD: null,
                        EUR: null,
                    }
                }
            }

            // Treat all 0 values as nulls
            for (let j = 0; j < countAndVolumeColumns.length; ++j) {
                let col = countAndVolumeColumns[j];
                let value = row[col.index];

                // Treat all 0 values as nulls
                if (value === 0) {
                    row[col.index] = null;
                }
            }
        }
    }
}